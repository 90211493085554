import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// Services
import { getArticle, isErrorResult } from "services";
// Constants
import { ROUTES } from "navigation/routes";
import { COOKIES_KEYS } from "configs";
// Types
import { ArticleNavigationState } from "components/find-solution-form/types";

const useArticleNavigationState = () => {
  const navigate = useNavigate();
  const { state }: { state: ArticleNavigationState | null } = useLocation();

  const [pending, setPending] = useState(false);
  const [article, setArticle] = useState("");

  useEffect(() => {
    if (!state) {
      navigate(`${ROUTES.landing}`);
    } else {
      const { searchValue, searchResult } = state;

      if (!searchValue) {
        navigate(`${ROUTES.landing}`);
      } else {
        setPending(true);

        const data = {
          user_id: Cookies.get(COOKIES_KEYS.userId) || "",
          session_id: Cookies.get(COOKIES_KEYS.sessionId) || "",
          user_request: searchValue,
          user_choice: searchResult,
        };

        if (!searchResult) {
          delete data.user_choice;
        }

        getArticle(data).then(response => {
          if (!isErrorResult(response)) {
            setArticle(response.article);
          } else {
            console.error("getArticle: ", response);
          }
          setPending(false);
        });
      }
    }
  }, [state]);

  return { pending, article, searchValue: state?.searchValue || "", searchResult: state?.searchResult };
};
export default useArticleNavigationState;
