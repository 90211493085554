// Components + styling
import { FlexGap, Column, Skeleton } from "components";
// Hooks
import { useMatchBreakpoints } from "hooks";

const SKELETON_ROW_COUNT = 3;

const CaseStudiesListSkeleton: React.FC = () => {
  const { isDesktop } = useMatchBreakpoints();
  return (
    <FlexGap flexDirection="column" width="100%">
      {Array.from(Array(SKELETON_ROW_COUNT)).map((_, idx) => (
        <FlexGap
          flexDirection={{ _: "column", laptop: "row" }}
          gap={isDesktop ? "1.5rem" : "0rem"}
          key={`skeleton-${idx}`}
          width="100%"
          my={{ _: "2rem", laptop: "3rem" }}
        >
          {!isDesktop && (
            <>
              <Skeleton width="30%" my="0.25rem" height="1.6775rem" />
              <Skeleton width="45%" mb="2.066rem" height="2.0925rem" />
            </>
          )}
          <Skeleton width={{ _: "100%", laptop: "42.215rem" }} height={{ _: "24.25rem", laptop: "28.125rem" }} />

          <Column flex="1" justifyContent="space-between">
            <FlexGap flexDirection="column">
              {isDesktop && (
                <>
                  <Skeleton width="30%" my="0.25rem" height="3rem" />
                  <Skeleton width="45%" mb="2rem" height="1.925rem" />
                </>
              )}
              <FlexGap gap="1.5rem" mt={{ _: "2rem", laptop: "0" }}>
                {Array.from(Array(2)).map((_, idx) => (
                  <Skeleton key={idx} width="20%" height={{ _: "1.125rem", laptop: "2.8125rem" }} />
                ))}
              </FlexGap>
            </FlexGap>
            <Skeleton
              mt={{ _: "1rem", laptop: "0" }}
              key={idx}
              width="11rem"
              height={{ _: "1.125rem", laptop: "3.5rem" }}
            />
          </Column>
        </FlexGap>
      ))}
    </FlexGap>
  );
};

export default CaseStudiesListSkeleton;
