import styled from "styled-components";
// Components
import { Box, DefaultSlideImage } from "components";

export const StyledSlideImageWrapper = styled(Box)`
  height: 14.875rem;
  display: flex;
  flex: initial;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 36.0625rem;
    height: auto;
    padding: 0 0 0 2.0625rem;
    transform: scale(0.7);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    transform: scale(1);
  }
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 14.75rem;
  height: auto;
  display: block;
  position: absolute;
  top: -2rem;
  left: calc(50% - 7.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 30rem;
    top: -4.125rem;
    left: calc(50% - 15.5rem);
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 1.5rem;
  height: auto;
  display: block;
  position: absolute;
  top: 4.5rem;
  left: calc(50% - 7.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.625rem;
    top: 8.6875rem;
    left: initial;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 2.125rem;
  height: auto;
  display: block;
  position: absolute;
  top: 1.25rem;
  left: calc(50% + 5.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.125rem;
    top: 4.5rem;
    left: initial;
    right: 2.1875rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 1.5rem;
  height: auto;
  display: block;
  position: absolute;
  top: 8.125rem;
  left: calc(50% + 5.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.875rem;
    top: 18.875rem;
    left: initial;
    right: 2.875rem;
  }
`;
