import React from "react";
// Components + styling
import { StyledEStateBuildingAnimation } from "./styled";
// Animations
import eStateBg from "assets/animations/e-state-bg.json";
// Types
import { SliderAnimationProps } from "components/our-team/components/slider-item-animations/types";

const EStateBuildingAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref }) => {
  return <StyledEStateBuildingAnimation lottieRef={lottieAnimation1Ref} animationData={eStateBg} autoplay loop />;
};

export default EStateBuildingAnimation;
