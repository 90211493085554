import React from "react";
// Components + styling
import { Box, Skeleton } from "components";

const ArticleSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton width="70%" height={{ _: "1.625rem", laptop: "3rem" }} mb={{ _: "0.25rem", laptop: "1rem" }} />
      {Array.from(Array(3)).map((_, index) => (
        <Box key={index}>
          <Skeleton width="80%" height={{ _: "1rem", laptop: "1.5rem" }} mb="0.5rem" />
          <Skeleton width="100%" height={{ _: "1rem", laptop: "1.5rem" }} mb="0.5rem" />
          <Skeleton width="60%" height={{ _: "1rem", laptop: "1.5rem" }} mb="0.5rem" />
          <Skeleton width="40%" height={{ _: "1rem", laptop: "1.5rem" }} mb="1.5rem" />
        </Box>
      ))}
    </Box>
  );
};

export default ArticleSkeleton;
