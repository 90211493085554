import React from "react";
import { motion } from "framer-motion";
// Components + styling
import { Text } from "components";
import { StyledBannerTitle, StyledTitleWrapper } from "./styled";
// Animations
import { bannerTitleVariants } from "../../animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { BannerTitleProps } from "./types";

const BannerTitle: React.FC<BannerTitleProps> = ({ xLeft, xRight, opacity }) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const showTitleMotionProps = {
    initial: "hidden",
    animate: "visible",
    variants: bannerTitleVariants,
  };

  return (
    <StyledBannerTitle scale="h1" as="h1" color="white">
      <StyledTitleWrapper style={{ x: xLeft, opacity }}>
        <motion.span {...showTitleMotionProps}>
          <Text as="span">{t(isMobile ? "Complex Product" : "Complex Product design")}</Text>
        </motion.span>
      </StyledTitleWrapper>

      <StyledTitleWrapper style={{ x: xRight, opacity }}>
        {isMobile && (
          <motion.span {...showTitleMotionProps}>
            <Text as="span">{t("design ")}</Text>
          </motion.span>
        )}
        <motion.span {...showTitleMotionProps}>
          <Text as="span" color="white">
            {t("for startups")}
          </Text>
        </motion.span>
      </StyledTitleWrapper>

      <StyledTitleWrapper style={{ x: xLeft, opacity }}>
        <motion.span {...showTitleMotionProps}>
          <Text as="span" color="white">
            {t(isMobile ? "and Scaling" : "and Scaling Companies")}
          </Text>
        </motion.span>
      </StyledTitleWrapper>

      {isMobile && (
        <StyledTitleWrapper style={{ x: xRight, opacity }}>
          <motion.span {...showTitleMotionProps}>
            <Text as="span" color="white">
              {t("Companies")}
            </Text>
          </motion.span>
        </StyledTitleWrapper>
      )}
    </StyledBannerTitle>
  );
};

export default BannerTitle;
