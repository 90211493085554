import styled from "styled-components";
// Components
import { Box, Flex } from "components";

export const StyledUgreatorInteractionsTabsPanelWrapper = styled.div`
  display: flex;

  div > p {
    min-width: fit-content;
  }
`;

export const StyledUgreatorInteractionsContent = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 3.75rem;
    overflow-y: hidden;
  }
`;

export const StyledUgreatorInteractionsTab = styled(Box)<{ isActive: boolean }>`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
`;

export const StyledUgreatorInteractionsImageWrapper = styled(Flex)<{ backgroundPositionY: number }>`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  background-image: url("/images/case-studies/ugreator/ugreator-interactions-gradient-bg.webp");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: ${({ backgroundPositionY }) => `center ${backgroundPositionY}%`};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    flex-direction: column;
    padding: 0 2rem;
  }
`;

export const StyledUgreatorInteractionsTabImage = styled(StyledUgreatorInteractionsTab)<{ activeIndex: number }>`
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -1.375rem;

  transition:
    opacity ${({ theme }) => theme.transitions.easeInOut_0_5s},
    visibility ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: ${({ activeIndex }) => `${50 - 100 * (activeIndex - 0)}%`};
    left: 50%;
    transform: translate(-50%, calc(-50% - 2.5rem));
    margin-top: 0;
    transition:
      all ${({ theme }) => theme.transitions.$easeInOut_0_5s},
      opacity ${({ theme }) => theme.transitions.$easeInOut_0_2s};
  }
`;
