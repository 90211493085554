import React, { useEffect, useState } from "react";
// Components
import { Page } from "components";
import { Chat, FindSolutionArticle, FindSolutionBanner } from "./components";
// Context
import { useSocketContext } from "context";
// Hooks
import { useArticleNavigationState } from "./hooks";

const FindSolutionPage: React.FC = () => {
  const { socketConnect, socketDisconnect, startChat } = useSocketContext();
  const { pending, article, searchValue, searchResult } = useArticleNavigationState();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isChatWasStarted, setIsChatWasStarted] = useState(false);

  useEffect(() => {
    socketConnect();

    return () => {
      socketDisconnect();
    };
  }, []);

  const handleIsChatOpen = (value: boolean) => {
    setIsChatOpen(value);

    if (value && !isChatWasStarted) {
      startChat(searchValue, searchResult);
      setIsChatWasStarted(true);
    }
  };

  return (
    <Page maxWidth="100vw" px="0" position="relative">
      <FindSolutionBanner isLoading={pending} />

      <FindSolutionArticle pending={pending} article={article} setIsChatOpen={handleIsChatOpen} />

      <Chat isChatOpen={isChatOpen} setIsChatOpen={handleIsChatOpen} />
    </Page>
  );
};

export default FindSolutionPage;
