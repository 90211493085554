import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" {...props} color="transparent">
      <mask id="mask0_1130_2026" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="72">
        <path d="M0 7.62939e-06H72V72H0V7.62939e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1130_2026)">
        <path d="M56 43L49 34H47.5L45.5 46.5L55 47L56 43Z" fill={theme.colors[color]} />
        <path d="M16.5 43L23.5 34H25L27 46.5L17.5 47L16.5 43Z" fill={theme.colors[color]} />
        <path
          d="M45.1866 47.25L45.131 47.6675C44.7338 50.6485 42.191 52.875 39.1836 52.875H32.8151C29.8079 52.875 27.2652 50.6489 26.8677 47.6681L24.1041 26.9438C23.3194 21.053 24.668 14.9203 27.6999 9.84375C29.131 7.44586 30.9376 5.28462 33.0713 3.49569C34.7697 2.07168 37.2295 2.07168 38.9279 3.49569C41.0616 5.28462 42.8682 7.44586 44.2991 9.84375C47.3312 14.9203 48.6797 21.053 47.8951 26.9438L45.1866 47.25Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.625 24.75C41.625 27.8564 39.1064 30.375 36 30.375C32.8936 30.375 30.375 27.8564 30.375 24.75C30.375 21.6436 32.8936 19.125 36 19.125C39.1064 19.125 41.625 21.6436 41.625 24.75Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.25 33.1875L54.9464 42.1664C55.4245 42.7247 55.6875 43.4348 55.6875 44.1703C55.6875 45.8719 54.3094 47.25 52.6078 47.25H19.3922C17.6906 47.25 16.3125 45.8719 16.3125 44.1703C16.3125 43.4348 16.5755 42.7247 17.0536 42.1664L24.75 33.1875"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.2994 9.84375H27.7002"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.8672 60.9922C15.7715 60.0866 16.9836 59.4889 18.3336 59.368C18.5025 59.3511 18.6726 59.3441 18.8441 59.3441"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.1331 60.9922C56.2288 60.0866 55.0167 59.4889 53.6667 59.368C53.4978 59.3511 53.3277 59.3441 53.1562 59.3441"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.375 52.875V59.3438C30.375 62.4502 27.5752 64.9688 24.4688 64.9688C24.4688 61.8623 21.9502 59.3438 18.8438 59.3438C18.6722 59.3438 18.5105 59.3789 18.3431 59.3944C18.3403 59.3859 18.3375 59.3761 18.3333 59.3677C17.0494 55.7269 13.102 53.2125 8.90016 53.8059C4.80516 54.3839 1.40625 58.4508 1.40625 62.1562C1.40625 66.5466 5.28891 70.5938 10.125 70.5938H42.5"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.3281 70.5938H61.875C66.5873 70.5938 70.508 66.7153 70.5938 62.2969C70.6655 58.5478 67.2483 54.3909 63.0998 53.8059C58.898 53.2125 54.9506 55.7269 53.6667 59.3677C53.6625 59.3761 53.6597 59.3859 53.6569 59.3944C53.4895 59.3789 53.3278 59.3438 53.1562 59.3438C50.0498 59.3438 47.5312 61.8623 47.5312 64.9688C44.4248 64.9688 41.625 62.4502 41.625 59.3438V52.875"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Icon;
