import styled from "styled-components";

export const StyledInnerWrapper = styled.div<{ headerHeight: number }>`
  padding: ${({ headerHeight }) => `calc(${headerHeight}px + 2rem) 0 1rem`};
  background: ${({ theme }) => theme.colors.bgDark} url("/images/about-us-banner-bg.webp") no-repeat right bottom;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: ${({ headerHeight }) => `calc(${headerHeight}px + 4.5rem) 0 5rem`};
    border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
  }
`;

export const StyledBannerDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 1.875rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 32.75rem;
    align-items: flex-start;
    padding: 5.75rem 0 0;
  }
`;
