import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M41 46C40.4477 46 40 46.4477 40 47C40 47.5523 40.4477 48 41 48V46ZM55.7071 47.7071C56.0976 47.3166 56.0976 46.6834 55.7071 46.2929L49.3431 39.9289C48.9526 39.5384 48.3195 39.5384 47.9289 39.9289C47.5384 40.3195 47.5384 40.9526 47.9289 41.3431L53.5858 47L47.9289 52.6569C47.5384 53.0474 47.5384 53.6805 47.9289 54.0711C48.3195 54.4616 48.9526 54.4616 49.3431 54.0711L55.7071 47.7071ZM41 48H55V46H41V48Z"
        fill={theme.colors[color]}
      />
      <path
        d="M29 9C39.4934 9 48 17.5066 48 28C48 38.4934 39.4934 47 29 47H12.5M22.5 10.1409C15.2076 12.7957 10 19.7897 10 28C10 33.2136 12.0999 37.9367 15.5 41.3697"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0176 8.88672L33.5248 14.4486"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 8.82031L34.5793 4.33458"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-5-inside-1_1542_24878" fill="white">
        <rect x="1" y="41" width="12" height="12" rx="1" />
      </mask>
      <rect
        x="1"
        y="41"
        width="12"
        height="12"
        rx="1"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeLinejoin="round"
        mask="url(#path-5-inside-1_1542_24878)"
      />
    </Svg>
  );
};

export default Icon;
