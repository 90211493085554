import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Container, Image, Link } from "components";
import { EllipseWrapperIcon } from "components/svg";
// Animations
import { moveUpTextAnimation } from "theme/animations";

export const StyledTestIdeaContainer = styled(Container)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-right: 0;
    padding-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    margin-right: auto;
    padding-right: 4.5rem;
  }
`;

export const StyledFreeLabel = styled.span`
  width: fit-content;
  position: relative;
  color: ${({ theme }) => theme.colors.textAccent};
`;

export const StyledEllipseWrapperIcon = styled(EllipseWrapperIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -45%);
`;

export const StyledPlacesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 85%;
  }
`;

export const StyledLinkCheckExample = styled(Link)`
  ${moveUpTextAnimation}
  margin: 0 auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 0 2.25rem;
  }
`;

export const StyledImagesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(45% - 2rem);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: calc(45% - 3rem);
  }
`;

export const StyledImage = styled(Image)`
  img {
    border-radius: ${({ theme }) => theme.radii.semiMedium};

    ${({ theme }) => theme.mediaQueries.laptop} {
      border-radius: ${({ theme }) => `${theme.radii.semiMedium} 0 0 ${theme.radii.semiMedium}`};
    }

    ${({ theme }) => theme.mediaQueries.laptopXL} {
      border-radius: ${({ theme }) => theme.radii.semiMedium};
    }
  }
`;

export const StyledImageIconWrapper = styled.div`
  position: absolute;
  bottom: -3.125rem;
  left: 45%;

  ${({ theme }) => theme.mediaQueries.laptop} {
    bottom: -7.5rem;
    left: 2.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    bottom: -3.5rem;
    left: -12.5rem;
  }

  ::before {
    ${clearfix}
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: -1.25rem;
    left: -3.5rem;
    transform: rotate(242deg);
    background: url("images/free-landing-image-arrow.svg") no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 4.5rem;
      height: 4.5rem;
      top: -4.75rem;
      left: unset;
      right: -1rem;
      transform: rotate(0);
    }
  }
`;
