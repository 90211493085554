// Constants
import { EXTERNAL_LINKS } from "configs";

export const UGREATOR_TRY_APP_VARIANTS_LIST = [
  {
    label: "Visit the site",
    description: "Discover all the benefits and possibilities of the app, find out how it can enhance your creativity",
    link: EXTERNAL_LINKS.ugreator,
    icon: "/images/case-studies/ugreator/ugreator-try-app-icon-1.svg",
  },
  {
    label: "Become an investor",
    description:
      "Become a part of success! As an investor, you not only support innovations but also have the opportunity to get a share in the exciting journey of development",
    link: EXTERNAL_LINKS.ugreatorInvest,
    icon: "/images/case-studies/ugreator/ugreator-try-app-icon-2.svg",
  },
  {
    label: "Download the App",
    description:
      "The application was launched in October 2023. Download it and become one of the creators on the platform",
    link: EXTERNAL_LINKS.ugreatorAppStore,
    icon: "/images/case-studies/ugreator/ugreator-try-app-icon-3.svg",
  },
];
