import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M5.26172 34.335H50.7359"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0106 17.3046L6.86133 24.8774H9.85197V34.335H13.0106H16.1692V24.8774H19.16L13.0106 17.3046Z"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.995 9.30098L21.8457 16.8737H24.8363V34.335H27.995H31.1536V16.8737H34.1444L27.995 9.30098Z"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="mask0_1301_18278" maskUnits="userSpaceOnUse" x="0" y="1" width="56" height="55">
        <path d="M0 1.51352H56V56H0V1.51352Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1301_18278)">
        <path
          d="M46.561 17.2543L46.5717 10.1507H49.5622L43.4131 2.57786L37.2637 10.1507H40.2543V34.335H46.5717L46.561 26.6149"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.1144 43.0506L45.2365 46.2194C44.5268 47.4156 43.0809 48.0029 41.7051 47.6581L39.7519 47.1643C39.1077 47.0027 38.4198 47.1281 37.8861 47.5155C36.8547 48.2604 35.7337 48.8935 34.5417 49.3969C33.9324 49.6544 33.4786 50.1695 33.3014 50.7919L32.7656 52.6806C32.3849 54.013 31.1393 54.9355 29.7197 54.9355H25.9608C24.539 54.9355 23.2923 54.013 22.9149 52.6806L22.3769 50.7919C22.1997 50.1695 21.7459 49.6544 21.1366 49.3969C19.9434 48.8935 18.8224 48.2604 17.7922 47.5155C17.2574 47.1281 16.5695 47.0027 15.9254 47.1643L13.971 47.6581C12.5963 48.0029 11.1537 47.4156 10.4429 46.2194L8.56285 43.0506C7.85092 41.8545 8.04999 40.3436 9.04847 39.3582L10.4615 37.9589C10.9273 37.497 11.1581 36.8565 11.0826 36.2149C11.0104 35.5976 10.9733 34.9708 10.9733 34.3345H20.5056C20.5056 38.2749 23.7887 41.4694 27.8387 41.4694C31.8884 41.4694 35.1727 38.2749 35.1727 34.3345H44.704C44.704 34.9698 44.6668 35.5976 44.5946 36.2137C44.519 36.8554 44.751 37.497 45.2158 37.9578L46.632 39.3582C47.6272 40.3436 47.8252 41.8545 47.1144 43.0506Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Icon;
