import { useRef } from "react";
// Components + styling
import { Box, Flex, Heading, Text } from "components";
import {
  UgreatorUserFlowsFirstColumn,
  UgreatorUserFlowsFourthColumn,
  UgreatorUserFlowsParallaxImage,
  UgreatorUserFlowsSecondColumn,
  UgreatorUserFlowsThirdColumn,
} from "./components";
import { StyledContentContainer } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const UgreatorUserFlows: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box ref={wrapperRef} position="relative">
      <UgreatorUserFlowsParallaxImage ref={wrapperRef} />

      <StyledContentContainer>
        <Heading as="h2" scale="h2" textAlign={isDesktop ? "center" : "left"} mb={{ _: "0.25rem", laptop: "0.625rem" }}>
          {t("User Flows")}
        </Heading>

        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          color="textFourth"
          maxWidth={{ _: "25rem", laptop: "37.5rem" }}
          textAlign={isDesktop ? "center" : "left"}
          mx={{ _: "0", laptop: "auto" }}
          mb={{ _: "1rem", laptop: "6.25rem" }}
        >
          {t("We have considered user interactions and navigation paths for maximum user convenience and efficiency.")}
        </Text>

        <Flex justifyContent="center">
          <UgreatorUserFlowsFirstColumn />

          <UgreatorUserFlowsSecondColumn />

          <UgreatorUserFlowsThirdColumn />

          <UgreatorUserFlowsFourthColumn />
        </Flex>
      </StyledContentContainer>
    </Box>
  );
};

export default UgreatorUserFlows;
