// Components + Styling;
import { Box, Text } from "components";
import { StyledIconWrapper, StyledPlaceItem, StyledPlaceItemWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { PlaceItemProps } from "./types";

const PlaceItem: React.FC<PlaceItemProps> = ({ label, description, Icon, index }) => {
  const { t } = useTranslation();

  const isFirst = index === 0;

  return (
    <StyledPlaceItemWrapper>
      <StyledPlaceItem>
        <StyledIconWrapper isFirstPlace={isFirst}>
          <Icon width="1.625rem" color={isFirst ? "textAccent" : "textMain"} />
        </StyledIconWrapper>

        <Box mx={{ _: "0.75rem", tablet: "0" }}>
          <Text textScale="p16Medium" color="textFourth" mb="0.25rem">
            {t(label)}
          </Text>
          <Text textScale="p20Bold" color="textMain">
            {t(description)}
          </Text>
        </Box>
      </StyledPlaceItem>
    </StyledPlaceItemWrapper>
  );
};

export default PlaceItem;
