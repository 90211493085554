import { transitions } from "theme/base";

export const portfolioItemShow = {
  hidden: {
    top: "2.1875rem",
    opacity: 0.5,
    transition: transitions.portfolioItemShowMotion,
  },
  visible: {
    top: 0,
    opacity: 1,
    transition: transitions.portfolioItemShowMotion,
  },
};
