export const INTERACTION_SECTIONS_LIST = [
  {
    id: 1,
    title: "Authorization experience",
    description: "Users can quickly and securely log in with their preferred method, knowing their data is safe.",
    image: "/images/e-states-interactions-1.webp",
    imageWidth: "82%",
  },
  {
    id: 2,
    title: "Registration property process",
    description:
      "User can effortlessly and conveniently provide all the necessary information about their property thanks to our unique, user-friendly registration flow.",
    image: "/images/e-states-interactions-2.webp",
    imageWidth: "82%",
  },
  {
    id: 3,
    title: "Viewing estate properties",
    description:
      "An investor can access all the accurately provided property information and instantly calculate their potential profit.",
    image: "/images/e-states-interactions-3.webp",
    imageWidth: "83%",
  },
  {
    id: 4,
    title: "Simplified investment process",
    description:
      "Investors have the flexibility to select the most convenient method for investment process. Our testing has ironed out all issues, making the process user-friendly.",
    image: "/images/e-states-interactions-4.webp",
    imageWidth: "88%",
  },
];
