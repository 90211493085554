import React, { useRef } from "react";
import { useInView } from "framer-motion";
// Components + styling
import { Box, Heading, Text } from "components";
import { EStatesProjectPhasesConnectorLineIcon } from "components/svg";
import {
  EStatesDesktopConnectorImage,
  EStatesDesktopConnectorImageWrapper,
  EStatesDesktopImageWrapper,
  EStatesProjectPhaseItemMotion,
  EStatesProjectPhaseItemWrapper,
  EStatesProjectPhasesList,
} from "./styled";
// Animations
import { animatePhase } from "theme/animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { PROJECT_PHASES_LIST } from "../../constants";

const ProjectPhasesList: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const phasesListRef = useRef<HTMLDivElement | null>(null);
  const isPhasesListInView = useInView(phasesListRef, { once: true, amount: 1 });

  const animatedItem = animatePhase(1, isDesktop);

  return (
    <EStatesProjectPhasesList ref={phasesListRef}>
      {PROJECT_PHASES_LIST.map(({ step, title, description, icon }, index) => {
        const animatedProjectPhases = animatePhase(index, isDesktop);

        return (
          <EStatesProjectPhaseItemWrapper key={step}>
            <EStatesProjectPhaseItemMotion
              src={icon}
              initial="hidden"
              animate={isDesktop ? (isPhasesListInView ? "visible" : "hidden") : ""}
              whileInView={!isDesktop ? "visible" : ""}
              viewport={!isDesktop ? { once: true, amount: 0.3 } : {}}
              variants={animatedProjectPhases}
            >
              <Box width="fit-content" mx={{ _: "0.5rem", laptop: "0" }} pb="2.625rem">
                <Heading scale="h3" as="h3" mb={{ _: "0.25rem", laptop: "0" }}>
                  {t(title)}
                </Heading>
                <Text textScale="p18Regular" color="textFifth" minHeight={{ _: "3.75rem", tablet: "initial" }}>
                  {t(description)}
                </Text>
              </Box>
            </EStatesProjectPhaseItemMotion>
          </EStatesProjectPhaseItemWrapper>
        );
      })}

      {isDesktop && isPhasesListInView && (
        <>
          <EStatesDesktopConnectorImageWrapper>
            <EStatesProjectPhasesConnectorLineIcon width="100%" />
          </EStatesDesktopConnectorImageWrapper>
          <EStatesDesktopImageWrapper
            initial="hidden"
            animate={isDesktop ? (isPhasesListInView ? "visible" : "hidden") : ""}
            whileInView={!isDesktop ? "visible" : ""}
            viewport={!isDesktop ? { once: true, amount: 0.3 } : {}}
            variants={animatedItem}
          >
            <EStatesDesktopConnectorImage
              isHideSkeleton
              src="/images/e-states-project-phases-connector-points.svg"
              aspectRatio={2.3}
            />
          </EStatesDesktopImageWrapper>
        </>
      )}
    </EStatesProjectPhasesList>
  );
};

export default ProjectPhasesList;
