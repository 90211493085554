import { useRef } from "react";
// Components + styling
import { Container } from "components";
import {
  StyledMotionWrapper,
  StyledParallaxImage1,
  StyledParallaxImage2,
  StyledParallaxImage3,
  StyledParallaxImage4,
  StyledParallaxImage5,
  StyledParallaxImage6,
  StyledParallaxImageWrapper,
} from "./styled";
// Hooks
import { useParallaxEffect } from "hooks";

const PlanetcoinBannerParallaxImage: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const parallaxImage = useParallaxEffect(ref, 100, { offset: ["start end", "end start"] });

  return (
    <StyledParallaxImageWrapper ref={ref}>
      <Container width="100%" position="relative">
        <StyledParallaxImage1 src="/images/planetcoin-banner-image-1.webp" aspectRatio={1.6} />
        <StyledMotionWrapper style={{ y: parallaxImage.y, right: 0 }} transition={parallaxImage.selectedTransition}>
          <StyledParallaxImage2 src="/images/planetcoin-banner-image-2.svg" aspectRatio={1.72} />
        </StyledMotionWrapper>
        <StyledMotionWrapper style={{ y: parallaxImage.y }} transition={parallaxImage.selectedTransition}>
          <StyledParallaxImage3 src="/images/planetcoin-banner-image-3.svg" aspectRatio={1.72} />
        </StyledMotionWrapper>

        <StyledMotionWrapper style={{ y: parallaxImage.y }} transition={parallaxImage.selectedTransition}>
          <StyledParallaxImage4 src="/images/planetcoin-banner-image-4.svg" aspectRatio={3.05} />
        </StyledMotionWrapper>
        <StyledMotionWrapper style={{ y: parallaxImage.y }} transition={parallaxImage.selectedTransition}>
          <StyledParallaxImage5 src="/images/planetcoin-banner-image-5.svg" aspectRatio={3.05} />
        </StyledMotionWrapper>
        <StyledMotionWrapper style={{ y: parallaxImage.y, right: 0 }} transition={parallaxImage.selectedTransition}>
          <StyledParallaxImage6 src="/images/planetcoin-banner-image-6.svg" aspectRatio={0.77} />
        </StyledMotionWrapper>
      </Container>
    </StyledParallaxImageWrapper>
  );
};

export default PlanetcoinBannerParallaxImage;
