// Utils
import { removeTrailingSlashIfExists } from "../../utils/pathname-helpers";
import { findMatchingRoute } from "./find-machine-route";
// Constants
import { ROUTES, ROUTE_PARAMS } from "navigation/routes";

export const getBasePath = (path: string) => {
  let basePath = removeTrailingSlashIfExists(path);
  const matchingRoute = findMatchingRoute(basePath);

  if (basePath.startsWith(`/${ROUTES.careers}/`)) {
    basePath = `/:${ROUTE_PARAMS.positionId}`;
  } else if (basePath.startsWith(`/${ROUTES.caseStudies}/`)) {
    basePath = `/:${ROUTES.caseStudies}`;
  } else if (basePath && !matchingRoute) {
    basePath = "/404";
  }

  return basePath;
};
