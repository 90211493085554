// Components + styling
import { Box, Container, Heading, Text } from "components";
import { ProjectPhasesList } from "./components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const ProjectPhases: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Box
      position="relative"
      pt={{ _: "2.5rem", laptop: "11.25rem" }}
      pb={{ laptop: "8rem" }}
      minHeight={{ laptop: "48rem" }}
    >
      <Container>
        <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "0.625rem" }}>
          {t("Project phases")}
        </Heading>
        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          maxWidth={{ _: "auto", laptop: "27.375rem" }}
          mb={{ _: "1rem", laptop: "0" }}
        >
          {t("Our specialists follow clear stages of work on the project to ensure its successful implementation")}
        </Text>

        {!isDesktop && <ProjectPhasesList />}
      </Container>

      {isDesktop && <ProjectPhasesList />}
    </Box>
  );
};

export default ProjectPhases;
