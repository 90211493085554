import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";
// Components
import { Box, Flex, Image, Svg } from "components";

export const EStatesProjectPhasesList = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 90rem;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin: 5.625rem auto 3.125rem;
    position: relative;
  }
`;

export const EStatesDesktopImageWrapper = styled(motion.div)``;

export const EStatesDesktopConnectorImage = styled(Image)`
  width: 100%;
  height: auto;
  position: absolute;
  top: 7.25rem;
  left: 0;

  ${({ theme }) => theme.mediaQueries.laptopM} {
    top: 3rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -21.25rem;
    left: -3rem;

    img {
      margin: 0 auto;
    }
  }
`;

export const EStatesDesktopConnectorImageWrapper = styled(Box)`
  width: 100%;
  height: auto;
  position: absolute;
  top: 7.25rem;
  left: 0;

  ${Svg} {
    filter: ${({ theme }) => theme.shadows.planetcoinPhasesConnectorLine};
  }

  ${({ theme }) => theme.mediaQueries.laptopM} {
    top: 3rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -21.25rem;
    left: -3rem;

    img {
      margin: 0 auto;
    }
  }
`;

export const EStatesProjectPhaseItemMotion = styled(motion.div)<{ src?: string }>`
  width: 100%;
  display: flex;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.4375rem;
    min-height: 16.25rem;
    flex-direction: column;
    justify-content: flex-end;
    background-size: 50% 50%;
  }

  ::before {
    ${clearfix}
    width: 2.875rem;
    height: 2.875rem;
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.dropdown + 1};
    border-radius: ${({ theme }) => theme.radii.circle};
    background-image: ${({ src }) => `url("${src}")`};
    background-repeat: no-repeat;

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: none;
    }
  }
`;

export const EStatesProjectPhaseItemWrapper = styled(Flex)`
  width: 100%;
  position: relative;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 75%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: initial;
    margin-top: 6rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin: 0;
  }

  &:nth-of-type(1) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: 5.125rem;
      left: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: 4.25rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: 5.625rem;
    }

    ::before {
      ${({ theme }) => theme.mediaQueries.laptop} {
        margin-bottom: 3rem;
      }
    }

    ${EStatesProjectPhaseItemMotion} {
      ::after {
        ${clearfix}
        width: 0.1875rem;
        height: 300%;
        position: absolute;
        top: 0;
        left: 1.125rem;
        z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
        background: ${({ theme }) => theme.gradients.eStatesProjectPhasesGradients};
        box-shadow: ${({ theme }) => theme.shadows.eStatesProjectPhasesShadows};

        ${({ theme }) => theme.mediaQueries.laptop} {
          display: none;
        }
      }
    }
  }

  &:nth-of-type(2) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: 1.875rem;
      left: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: 0.375rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: 2.125rem;
      left: -1.625rem;
    }
  }

  &:nth-of-type(3) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: -3.125rem;
      left: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: -5.375rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: -4.875rem;
      left: -2.25rem;
    }
  }

  &:nth-of-type(4) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: -13.125rem;
      left: -5.5rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: -17.375rem;
      left: -3rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: -15.625rem;
      left: -8.375rem;
    }
  }
`;
