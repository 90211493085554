import styled from "styled-components";
import { motion } from "framer-motion";

export const CorporateValueItemWrapper = styled(motion.div)`
  position: relative;
  margin-bottom: 3rem;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;
