import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box, Flex, Heading, Image, Text } from "components";

export const StyledTitleImage = styled(Image)`
  img {
    margin-left: auto;
  }
`;

export const StyledInterviewList = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledAvatar = styled(Image)`
  padding: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.radii.circle};
  transition: ${({ theme }) => theme.transitions.default};

  img {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0.5rem;
  }
`;

export const StyledInterviewHeading = styled(Heading)`
  transition: ${({ theme }) => theme.transitions.default};
`;

export const StyledInterviewText = styled(Text)`
  transition: ${({ theme }) => theme.transitions.default};
`;

export const StyledInterviewItem = styled(Box)`
  position: relative;
  margin-bottom: 1rem;
  padding: 1.875rem 1rem 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.radii.large};
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% - 0.75rem);
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 3rem 2rem 4.5rem;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ::after {
    ${clearfix}
    width: 16rem;
    height: 19.3125rem;
    position: absolute;
    bottom: -7.625rem;
    right: -3rem;
    opacity: 0;
    background-image: url("/images/interview-item-hover.webp");
    background-repeat: no-repeat;
    background-size: cover;
    transition: ${({ theme }) => theme.transitions.default};
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.bgDark};

    ${StyledAvatar} {
      border-color: ${({ theme }) => theme.colors.white};
    }

    ${StyledInterviewHeading}, ${StyledInterviewText} {
      color: ${({ theme }) => theme.colors.white};
    }

    ::after {
      ${({ theme }) => theme.mediaQueries.laptop} {
        opacity: 1;
      }
    }
  }
`;

export const StyledInterviewPointItem = styled(Box)`
  margin-bottom: 1.5rem;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;
