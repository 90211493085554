import styled from "styled-components";
// Components + styling
import { Box } from "components/layout";
import { variantStyles } from "./theme";
// Types
import { animation, SkeletonProps, variant as VARIANT } from "./types";

const StyledSkelton = styled(Box)<SkeletonProps>`
  background-color: ${({ theme }) => theme.colors.disabledLight};
  border-radius: ${({ variant, theme }) => (variant === VARIANT.CIRCLE ? theme.radii.circle : theme.radii.small)};

  min-height: 0.5rem;

  ${({ animation }) => variantStyles(animation)};
`;

StyledSkelton.defaultProps = {
  variant: VARIANT.RECT,
  animation: animation.PULSE,
};

export default StyledSkelton;
