import React from "react";
// Components + styling
import { Heading, Text } from "components";
import { StyledActiveInteractionItem } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { ActiveInteractionItemProps } from "./types";

const ActiveInteractionItem: React.FC<ActiveInteractionItemProps> = ({
  title,
  description,
  id,
  isActive = false,
  onClick,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const handleClickItem = () => {
    if (isDesktop) {
      onClick?.();
    }
  };

  return (
    <StyledActiveInteractionItem isActive={isActive} onClick={handleClickItem}>
      {isDesktop && (
        <>
          <Text textScale="p34Bold" color="textThird" mb="0.75rem">
            0{id}
          </Text>
          <Heading scale="h4" as="h4" $fontWeight="bold" mb="0.5rem">
            {t(title)}
          </Heading>
        </>
      )}
      <Text
        textScale={isDesktop ? "p18Regular" : "p16Regular"}
        maxWidth="26rem"
        color="textFifth"
        m={{ _: "0 auto 1rem", laptop: "0" }}
        textAlign={isDesktop ? "left" : "center"}
      >
        {t(description)}
      </Text>
    </StyledActiveInteractionItem>
  );
};

export default ActiveInteractionItem;
