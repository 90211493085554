import styled from "styled-components";
import { clearfix, clearfixDisplayNone } from "styles";
// Components
import { Box } from "components";

export const StyledDevelopmentSuggestionWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.bgDark};
  border-radius: ${({ theme }) => theme.radii.large};
  padding: 2rem 1.5rem;
  position: relative;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 5rem 1.5rem 8.4375rem;
  }

  &::before {
    ${clearfix}
    width: 31.4375rem;
    height: 25.4375rem;
    position: absolute;
    left: -15.4375rem;
    bottom: -16.3125rem;
    opacity: 0.1;
    border-radius: ${({ theme }) => theme.radii.circle};
    filter: blur(77px);
    background: ${({ theme }) => theme.colors.pink};

    ${({ theme }) => theme.mediaQueries.tablet} {
      bottom: -5.8125rem;
      left: -9.5625rem;
    }
  }

  &::after {
    ${clearfixDisplayNone}
    width: 54.875rem;
    height: 34.5625rem;
    position: absolute;
    top: -14.4375rem;
    right: -32.75rem;
    opacity: 0.1;
    border-radius: ${({ theme }) => theme.radii.circle};
    filter: blur(77px);
    background: ${({ theme }) => theme.colors.pink};

    ${({ theme }) => theme.mediaQueries.tablet} {
      display: block;
    }
  }
`;
