// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 25" {...props} color="transparent">
      <path
        d="M10.0016 14.0999C11.327 14.0999 12.4016 15.1744 12.4016 16.4999C12.4016 17.8254 11.327 18.8999 10.0016 18.8999C8.67608 18.8999 7.60156 17.8254 7.60156 16.4999C7.60156 15.1744 8.67608 14.0999 10.0016 14.0999Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
      />
      <path
        d="M13.9984 6.09988C12.673 6.09988 11.5984 7.1744 11.5984 8.49988C11.5984 9.82537 12.673 10.8999 13.9984 10.8999C15.3239 10.8999 16.3984 9.82537 16.3984 8.49988C16.3984 7.1744 15.3239 6.09988 13.9984 6.09988Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
      />
      <path d="M14.3984 16.4668L19.9984 16.4668" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9.60156 8.4668L4.00156 8.4668" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M4 16.4668L5.6 16.4668" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M20 8.4668L18.4 8.4668" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" />
    </Svg>
  );
};

export default Icon;
