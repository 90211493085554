import React from "react";
// Components + styling
import { Box, Flex, Image, Text } from "components";
import { CloseIcon } from "components/svg";
import { StyledChatHeader } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { ChatHeaderProps } from "./types";

const ChatHeader: React.FC<ChatHeaderProps> = ({ setIsChatOpen, currentManagerData }) => {
  const { t } = useTranslation();
  const { name, surname, image, position } = currentManagerData;

  return (
    <StyledChatHeader>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex>
          <Image width="2.375rem" src={image} aspectRatio={1} variant="circle" />
          <Box mx="0.5rem">
            <Text textScale="p14Bold" color="white" ellipsis>
              {t(`${name} ${surname}`)}
            </Text>
            <Text textScale="p12Regular" color="textFourth" ellipsis>
              {t(`${position}`)}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          setIsChatOpen(false);
        }}
      >
        <CloseIcon width="1.5rem" color="textFourth" />
      </Flex>
    </StyledChatHeader>
  );
};

export default ChatHeader;
