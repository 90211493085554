import { Variants } from "framer-motion";
import { transitions } from "theme/base";
// Constants
import { headerAnimationType } from "./constants";

export const slideVariants = {
  hidden: {
    y: -150,
    height: 0,
    opacity: 0,
    transition: transitions.defaultMotion,
  },
  visible: {
    y: 0,
    opacity: 1,
    height: "initial",
    transition: transitions.defaultMotion,
  },
};

export const letsTalkButtonSlideVariants = {
  hidden: {
    ...slideVariants.hidden,
    height: "max-content",
  },
  visible: {
    ...slideVariants.visible,
    height: "max-content",
  },
};

export const headerShow = (isLaptop: boolean) => ({
  hidden: {
    y: -118,
    transition: transitions.defaultMotion,
  },
  visible: {
    y: 0,
    transition: isLaptop ? transitions.bounceEffect : transitions.defaultMotion,
  },
});

export const getHeaderDropVariants = (isLaptop: boolean): Variants => ({
  [headerAnimationType.initial]: {
    top: 0,
    position: "fixed",
    transition: {
      ...transitions.defaultMotion,
      duration: 0.15,
    },
  },
  [headerAnimationType.hidden]: {
    position: "fixed",
    top: -118,
    transition: {
      ...transitions.defaultMotion,
      duration: 0.15,
    },
  },
  [headerAnimationType.fixed]: {
    position: "fixed",
    top: -118,
  },
  [headerAnimationType.drop]: {
    top: isLaptop ? 16 : 0,
    transition: isLaptop ? transitions.bounceEffect : transitions.defaultMotion,
    position: "fixed",
  },
});
