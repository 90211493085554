import React from "react";
// Context
import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "eStatesPrimary", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 79 8" {...props}>
      <path
        d="M71.5782 4.03909C71.5782 2.39414 72.8941 1.07818 74.5391 1.07818C76.184 1.07818 77.5 2.39414 77.5 4.03909C77.5 5.68404 76.184 7 74.5391 7C72.8941 7 71.5782 5.68404 71.5782 4.03909ZM71.5782 4.03909L0 4.03909"
        stroke={theme.colors[color]}
        strokeWidth="1.97394"
      />
      <path
        d="M77.5 4.03909C77.5 5.68404 76.184 7 74.5391 7C72.8941 7 71.5782 5.68404 71.5782 4.03909C71.5782 2.39414 72.8941 1.07818 74.5391 1.07818C76.184 1.07818 77.5 2.39414 77.5 4.03909Z"
        fill={theme.colors.white}
      />
    </Svg>
  );
};

export default Icon;
