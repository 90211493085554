const EXTERNAL_LINKS = {
  goodFirms: "https://www.goodfirms.co/company/idealogic-design",
  behance: "https://www.behance.net/idealogic_design",
  goodFirmsIdealogic: "https://www.goodfirms.co/company/idealogic-design",
  dribbble: "https://dribbble.com/Idealogic_Design",
  linkedIn: "https://www.linkedin.com/company/idealogic-design/?viewAsMember=true",
  mediumZhitomirskiyV: "https://v-zhitomirskiy.medium.com/",
  idealogicDev: "https://idealogic.dev/",
  behanceAddMed: "https://www.behance.net/gallery/153610947/AddMed-Pill-reminder-application",
  behanceAIBook: "https://www.behance.net/gallery/147676109/AiBook-NFTs-based-on-AI",
  designRushBestDesigns: "https://www.designrush.com/best-designs",

  calendly: "https://calendly.com/idealogic-design/30min",
  freeLandingSendForm:
    "https://docs.google.com/forms/d/e/1FAIpQLSd2ra1w9RgF_sfzcWp1xsRw53KZmI2JIC2gZXL459xGTPotRA/viewform",
  freeLandingCheckExample: "https://www.intelli-setup.com/",

  youtubeMatthewReview: "https://www.youtube.com/watch?v=1vEyecgbEjg",
  youtubeDanReview: "https://www.youtube.com/watch?v=AWk7WE5IEnA",
  youtubePhilippReview: "https://www.youtube.com/watch?v=QqZD20FHS00",
  youtubeNikitinReview: "https://www.youtube.com/watch?v=Y28q6o0WRxs",

  linkedInMatthew: "https://www.linkedin.com/in/realmattschneider/",
  linkedInDan: "https://www.linkedin.com/in/dan-jensen-b66b56118/",
  linkedInPhilipp: "https://www.linkedin.com/in/philipp-tanglmayer-5a778a236/",
  linkedInNikitin: "https://www.linkedin.com/in/yuriy-nikitin-a2a5a8240/",

  eStates: "https://e-states.com/",
  amorsSecret: "https://amorssecret.com/",
  revolveGames: "https://revolvegames.io/",

  ugreator: "https://www.ugreator.com/",
  ugreatorInvest: "https://wefunder.com/ugreator/",
  ugreatorAppStore: "https://apps.apple.com/us/app/ugreator-talent-monetization/id6461457437",

  kazkaInstagram: "https://www.instagram.com/kazka.band?igsh=MXcxbHJzbGc3aWc3Zw==",
  serduchkaInstagram: "https://www.instagram.com/v_serduchka?igsh=MTYydmUwMjN4MTJqaA==",
  demchukTiktok: "https://www.tiktok.com/@demchukmusic?_t=8k1FPdbQFpG",
  bilykInstagram: "https://www.instagram.com/bilyk_iryna?igsh=aHpzZzNlejlpODk0",
};

export default EXTERNAL_LINKS;
