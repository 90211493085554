import React from "react";
import { useNavigate } from "react-router-dom";
// Components + Styling
import { Column, Button, Heading, Text } from "components";
import { UgreatorSlideImage } from "./components";
import { StyledUgreatorContentContainer, StyledUgreatorDescription, StyledUgreatorWrapper } from "./styled";
// Animations
import { portfolioItemShow } from "../../animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Const
import { ROUTES } from "navigation/routes";

const Ugreator: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();
  const navigate = useNavigate();

  return (
    <StyledUgreatorWrapper
      initial={isDesktop ? "hidden" : "visible"}
      variants={portfolioItemShow}
      whileInView="visible"
      viewport={{ once: true }}
    >
      {isDesktop && <UgreatorSlideImage />}

      <Column alignItems={isDesktop ? "flex-start" : "center"}>
        <StyledUgreatorContentContainer>
          <StyledUgreatorDescription>
            <Heading scale="h3" as="h3" color="ugreatorText" mb={{ _: "0.5rem", desktop: "1.25rem" }}>
              {t("Ugreator")}
            </Heading>
            <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="ugreatorText" opacity="0.7">
              {isDesktop
                ? t(
                    "Ugreator, an innovative gamified talent monetization platform, empowers creators to monetize their audience and content effectively, while generously rewarding all users for their activity and engagement.",
                  )
                : t(
                    "Ugreator is a gamified talent monetization platform that helps creators monetize their audience and content while rewarding all users for their activity.",
                  )}
            </Text>
          </StyledUgreatorDescription>
        </StyledUgreatorContentContainer>

        {!isDesktop && <UgreatorSlideImage />}

        <StyledUgreatorContentContainer>
          <Button
            color="bgDark"
            hoverLinesPosition={isDesktop ? "bottomLeft" : "topRight"}
            width={{ _: "15.25rem", laptop: "15.625rem" }}
            variant="primary"
            mx={{ _: "auto", laptop: "0" }}
            onClick={() => navigate(`/${ROUTES.caseStudies}/${ROUTES.ugreatorCase}`)}
          >
            {t("View full case")}
          </Button>
        </StyledUgreatorContentContainer>
      </Column>
    </StyledUgreatorWrapper>
  );
};

export default Ugreator;
