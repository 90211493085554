import styled from "styled-components";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
// Components
import { Box, Image } from "components";
// Types
import { Colors } from "theme/types";

export const StyledImageLottieAnimationWrapper = styled(Box)`
  opacity: 0;
`;

export const StyledMemberImage = styled(Image)`
  border-radius: ${({ theme }) => theme.radii.large};
  background-color: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions.default};

  img {
    border-radius: ${({ theme }) => theme.radii.large};
    mix-blend-mode: multiply;
  }
`;

export const StyledSalesMemberItem = styled(motion.div)<{ hoverColor?: keyof Colors; isImageLoading?: boolean }>`
  width: 10rem;
  margin: 0 0.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 16.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 18.5rem;
    margin: 0 0.75rem;
  }

  &:hover,
  &:focus,
  &:active {
    ${StyledImageLottieAnimationWrapper} {
      opacity: ${({ isImageLoading }) => (isImageLoading ? 0 : 1)};
    }

    ${StyledMemberImage} {
      background-color: ${({ theme, hoverColor, isImageLoading }) =>
        !isImageLoading && hoverColor && theme.colors[hoverColor]};
    }
  }
`;

export const StyledIreneLottieWrapper = styled(Lottie)`
  width: calc(67rem / 16 / 2.6);
  position: absolute;
  bottom: calc(35rem / 16 / 2.6);
  left: calc(90rem / 16 / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    bottom: calc(60rem / 16 / 1.6);
    left: calc(110rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.5625rem;
    bottom: 0.625rem;
    left: 3.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    bottom: 1.2375rem;
    left: 4.4375rem;
  }
`;
