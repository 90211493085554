import React from "react";
// Components
import { ContactUs, OurTeam, Page } from "components";
import {
  Banner,
  FindSolution,
  LandingBackgroundContainer,
  VideoTestimonials,
  Portfolio,
  ProvidedServices,
  TestYourIdea,
  Testimonials,
  UserCases,
  Workflow,
} from "./components";

const LandingPage: React.FC = () => {
  return (
    <Page maxWidth="100vw" px="0">
      <Banner />

      <LandingBackgroundContainer />

      <ProvidedServices />

      <VideoTestimonials />

      <Portfolio />

      <UserCases />

      <FindSolution />

      <TestYourIdea />

      <OurTeam />

      <Workflow />

      <Testimonials />

      <ContactUs />
    </Page>
  );
};

export default LandingPage;
