import { useRef } from "react";
// Components + styling
import { Box, Container, TabsPanel, Heading, Image, Text, ActiveInteractionItem } from "components";
import {
  StyledUgreatorInteractionsContent,
  StyledUgreatorInteractionsImageWrapper,
  StyledUgreatorInteractionsTab,
  StyledUgreatorInteractionsTabImage,
  StyledUgreatorInteractionsTabsPanelWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useSlideAutoChange } from "hooks";
// Constants
import { INTERACTION_SECTIONS_LIST } from "./constants";

const UgreatorInteractions: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { activeInteractionSection, setActiveInteractionSection } = useSlideAutoChange(INTERACTION_SECTIONS_LIST);

  const imageWrapperRef = useRef<HTMLDivElement | null>(null);

  const titlesList = INTERACTION_SECTIONS_LIST.map(({ title }) => t(title));

  return (
    <Box position="relative" pt={{ _: "2.5rem", laptop: "7.5rem" }} pb={{ _: "3.75rem", laptop: "9.875rem" }}>
      <Container overflowY="hidden">
        <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
          {t("Interactions")}
        </Heading>
        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          maxWidth={{ laptop: "40.625rem" }}
          mb={{ _: "1.5rem", laptop: "0" }}
          color="textFourth"
        >
          {t("Description of features that distinguish Ugreator from other social networks")}
        </Text>

        <StyledUgreatorInteractionsContent>
          {!isDesktop && (
            <StyledUgreatorInteractionsTabsPanelWrapper>
              <TabsPanel
                titles={titlesList}
                activeTab={activeInteractionSection}
                onTabClick={setActiveInteractionSection}
                color="textAccent"
              />
            </StyledUgreatorInteractionsTabsPanelWrapper>
          )}

          {!isDesktop && (
            <Box position="relative" minHeight="8.375rem">
              {INTERACTION_SECTIONS_LIST.map((el, index) => (
                <StyledUgreatorInteractionsTab key={index} isActive={activeInteractionSection === index}>
                  <ActiveInteractionItem {...el} />
                </StyledUgreatorInteractionsTab>
              ))}
            </Box>
          )}

          <StyledUgreatorInteractionsImageWrapper
            ref={imageWrapperRef}
            height={{
              _: imageWrapperRef.current?.offsetWidth,
              laptop: (imageWrapperRef.current?.offsetWidth || 500) - 100,
              laptopL: (imageWrapperRef.current?.offsetWidth || 600) - 100,
            }}
            backgroundPositionY={(100 / INTERACTION_SECTIONS_LIST.length) * activeInteractionSection}
          >
            {INTERACTION_SECTIONS_LIST.map(({ image, title }, index) => (
              <StyledUgreatorInteractionsTabImage
                key={index}
                minHeight={{
                  _: "unset",
                  laptop: (imageWrapperRef.current?.offsetWidth || 500) - 100,
                  laptopL: (imageWrapperRef.current?.offsetWidth || 600) - 100,
                }}
                isActive={activeInteractionSection === index}
                activeIndex={activeInteractionSection}
              >
                <Image width="100%" src={image} aspectRatio={1} alt={title} />
              </StyledUgreatorInteractionsTabImage>
            ))}
          </StyledUgreatorInteractionsImageWrapper>

          <Box minWidth={{ laptop: "45%" }} maxWidth={{ laptop: "calc(50% - 3rem)" }}>
            {isDesktop && (
              <>
                {INTERACTION_SECTIONS_LIST.map((el, index) => (
                  <ActiveInteractionItem
                    key={index}
                    isActive={activeInteractionSection === index}
                    onClick={() => setActiveInteractionSection(index)}
                    {...el}
                  />
                ))}
              </>
            )}
          </Box>
        </StyledUgreatorInteractionsContent>
      </Container>
    </Box>
  );
};

export default UgreatorInteractions;
