import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <mask id="mask0_1531_24999" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
        <path d="M0 0H56V56H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1531_24999)">
        <path
          d="M37.2102 32.8538C35.4655 36.158 31.9949 38.4102 27.9983 38.4102C22.2488 38.4102 17.5879 33.7493 17.5879 27.9996C17.5879 22.2501 22.2488 17.5892 27.9983 17.5892C31.8876 17.5892 35.2786 19.7219 37.0659 22.8815"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.9361 44.8906C37.3518 47.6397 32.8673 49.2734 28.001 49.2734C23.1614 49.2734 18.6994 47.6575 15.125 44.9357"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.9354 20.5568C48.8007 22.8734 49.2736 25.3812 49.2736 27.9996C49.2736 30.7109 48.7666 33.3038 47.8418 35.6885"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8789 12.0879C17.6351 8.75217 22.5807 6.72622 27.9993 6.72622C33.3901 6.72622 38.3125 8.73139 42.0616 12.0364"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.1585 35.6885C7.23373 33.3038 6.72656 30.7109 6.72656 27.9996C6.72656 25.2883 7.23373 22.6955 8.1585 20.3108"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.2691 6.72678C51.2691 9.83752 48.7472 12.3594 45.6365 12.3594C42.5258 12.3594 40.0039 9.83752 40.0039 6.72678C40.0039 3.61605 42.5258 1.09419 45.6365 1.09419C48.7472 1.09419 51.2691 3.61605 51.2691 6.72678Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.7735 17.4204V17.5433C51.5203 19.2796 48.6972 20.3125 45.6328 20.3125C42.5684 20.3125 39.7453 19.2796 37.4922 17.5433V17.4204C37.4922 14.6261 39.7579 12.3602 42.5523 12.3602H48.7133C51.5076 12.3602 53.7735 14.6261 53.7735 17.4204Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9976 6.72678C15.9976 9.83752 13.4758 12.3594 10.365 12.3594C7.25428 12.3594 4.73242 9.83752 4.73242 6.72678C4.73242 3.61605 7.25428 1.09419 10.365 1.09419C13.4758 1.09419 15.9976 3.61605 15.9976 6.72678Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.506 17.4204V17.5433C16.2527 19.2796 13.4298 20.3125 10.3653 20.3125C7.30092 20.3125 4.47773 19.2796 2.22461 17.5433V17.4204C2.22461 14.6261 4.49042 12.3602 7.28473 12.3602H13.4457C16.2401 12.3602 18.506 14.6261 18.506 17.4204Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.2691 41.3205C51.2691 44.4313 48.7472 46.9531 45.6365 46.9531C42.5258 46.9531 40.0039 44.4313 40.0039 41.3205C40.0039 38.2098 42.5258 35.6879 45.6365 35.6879C48.7472 35.6879 51.2691 38.2098 51.2691 41.3205Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.7735 52.0143V52.1372C51.5203 53.8733 48.6972 54.9062 45.6328 54.9062C42.5684 54.9062 39.7453 53.8733 37.4922 52.1372V52.0143C37.4922 49.2198 39.7579 46.954 42.5523 46.954H48.7133C51.5076 46.954 53.7735 49.2198 53.7735 52.0143Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9976 41.3205C15.9976 44.4313 13.4758 46.9531 10.365 46.9531C7.25428 46.9531 4.73242 44.4313 4.73242 41.3205C4.73242 38.2098 7.25428 35.6879 10.365 35.6879C13.4758 35.6879 15.9976 38.2098 15.9976 41.3205Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.506 52.0143V52.1372C16.2527 53.8733 13.4298 54.9062 10.3653 54.9062C7.30092 54.9062 4.47773 53.8733 2.22461 52.1372V52.0143C2.22461 49.2198 4.49042 46.954 7.28473 46.954H13.4457C16.2401 46.954 18.506 49.2198 18.506 52.0143Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.334 29.191L27.2216 30.7012L30.6192 25.7935"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Icon;
