// Components + styling
import { Flex, Text } from "components";
import { ArrowLeftIcon } from "components/svg";
import { StyledCheckbox } from "pages/case-studies/components/case-studies-filter/components/case-studies-dropdown/styled";
import { CaseStudiesMobileContentWrapper, StyledAccordion, StyledFilterTitle } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { MobileFilterItemType } from "./types";

const MobileFilterItem: React.FC<MobileFilterItemType> = ({
  type,
  filters,
  filtersData,
  handleFilterChangeAndStore,
  localSelectedAll,
}) => {
  const { t } = useTranslation();

  return (
    <StyledAccordion
      px={{ _: "1rem", laptopL: "0" }}
      rotateIcon={<ArrowLeftIcon color="textMain" />}
      iconRotateInitialDeg={270}
      iconRotateOpenDeg={90}
      title={
        <Flex alignItems="center" style={{ cursor: "pointer" }} mt="1rem">
          <StyledFilterTitle textScale="p16Bold">{t(filters[type].name)}</StyledFilterTitle>
        </Flex>
      }
    >
      <Flex $backgroundColor="white" flexDirection="column">
        <CaseStudiesMobileContentWrapper onClick={() => handleFilterChangeAndStore(type, `All ${type}`)}>
          <Text color="textMain">{t(`All ${type}`)}</Text>
          <StyledCheckbox checked={localSelectedAll[type]} />
        </CaseStudiesMobileContentWrapper>

        {filters[type].options.map(option => {
          const isChecked = filtersData[type].some(filteredOption => filteredOption.id === option.id);
          return (
            <Flex key={option.id} onClick={() => handleFilterChangeAndStore(type, option)}>
              <CaseStudiesMobileContentWrapper>
                <Text>{t(option.title)}</Text>
                <StyledCheckbox checked={isChecked} />
              </CaseStudiesMobileContentWrapper>
            </Flex>
          );
        })}
      </Flex>
    </StyledAccordion>
  );
};

export default MobileFilterItem;
