import { useRef, useState } from "react";
// Components + styling
import { Box, Container, EStatesCarousel, Flex, Heading, Image, RatingStars, Text } from "components";
import { EStatesClientAvatar, EStatesRatingWrapper, EStatesTestimonialDescription } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { EXTERNAL_LINKS } from "configs";

const EStatesClientsTestimonials: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const avatarRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(prevOpen => !prevOpen);

  return (
    <Container pt={{ _: "1.875rem", laptop: "3.25rem" }} pb={{ _: "0.5rem", laptop: "6.25rem" }}>
      {!isDesktop && (
        <Box>
          <Heading scale="h2" as="h2" mb="1.5rem">
            {t("Client’s video testimonial")}
          </Heading>
        </Box>
      )}

      <Flex
        flexDirection={{ _: "column", tablet: "row" }}
        justifyContent={{ _: "flex-start", tablet: "space-between" }}
        alignItems="center"
        mt={{ laptop: "4.75rem" }}
      >
        <Flex ref={avatarRef} position="relative" width="100%" justifyContent="center">
          <EStatesClientAvatar
            width={{ _: "100%", tablet: "29rem", laptop: "39rem", laptopL: "45rem" }}
            src="/images/e-states-testimonial-avatar.webp"
            aspectRatio={1.25}
          />
          <EStatesCarousel
            videoSources={EXTERNAL_LINKS.youtubeMatthewReview}
            position="absolute"
            bottom={{ _: "0.2rem", tablet: "1.9rem" }}
            left={0}
            right={0}
            width="100%"
            justifyContent="center"
            isOpen={open}
            onToggleOpen={toggleOpen}
            text={isDesktop ? "Check video" : "1:58"}
          />
        </Flex>

        <Box width={{ _: "100%", laptop: "50%" }} mx={{ _: "2rem", laptop: "5rem" }}>
          {isDesktop && (
            <Box>
              <Heading scale="h2" as="h2" mt="7e-states-testimonial-avatarrem" mb="2.875rem">
                {t("Client’s video testimonial")}
              </Heading>
            </Box>
          )}

          {!isDesktop && (
            <Box mt="2.5rem">
              <Heading scale="h3" as="h3" mb="0.25rem" textAlign={{ _: "center", tablet: "left" }}>
                {t("Matthew Schneider")}
              </Heading>
              <Text textScale="p16Regular" color="textFourth" mb="0.875rem" textAlign={{ _: "center", tablet: "left" }}>
                {t("CEO, Co-founder of e-States")}
              </Text>
            </Box>
          )}

          <EStatesRatingWrapper>
            <RatingStars rating={5} fillColor="eStatesPrimary" width="1.75rem" isHideTextRating />
          </EStatesRatingWrapper>

          <EStatesTestimonialDescription scale="h6" as="h6">
            {t(
              "We've been working with ideologic for about five contracts now over the course of 6 to 8 months and we are absolutely satisfied. Above satisfied. We are thrilled to have a team that is this competent and communicative and just a joy to work with...",
            )}
          </EStatesTestimonialDescription>

          <Flex
            flexDirection={{ _: "column", tablet: "row" }}
            justifyContent={{ _: "flex-start", tablet: "space-between" }}
            alignItems="center"
          >
            {isDesktop && (
              <Box>
                <Heading scale="h6" as="h6" mb="0.25rem">
                  {t("Matthew Schneider")}
                </Heading>
                <Text textScale="p16Regular" color="textFourth">
                  {t("CEO, Co-founder of e-States")}
                </Text>
              </Box>
            )}

            <Box mr={{ laptop: "2.375rem" }}>
              <Image width={{ _: "5.375rem", tablet: "8.5rem" }} src="/images/e-states-logo.webp" aspectRatio={3.25} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default EStatesClientsTestimonials;
