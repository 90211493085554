import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, Image } from "components";
// Animations
import { elementLevitationAnimation } from "theme/animations";

export const EStatesParallaxImageWrapper = styled(Box)`
  width: 100%;
  height: 23.125rem;
  display: flex;
  flex: initial;
  position: relative;
  margin: 2.5rem auto;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    margin: 5rem auto;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 25rem;
    margin: 6.625rem auto;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 31.875rem;
    margin-bottom: 13.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 40rem;
  }
`;

export const EStatesMotionWrapper = styled(motion.div)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const EStatesParallaxImage1 = styled(Image)`
  width: 35.125rem;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 2.75rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown - 1};

  ${({ theme }) => theme.mediaQueries.tablet} {
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 51.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 63.75rem;
  }
`;

export const EStatesParallaxImage2 = styled(Image)`
  width: 9.0625rem;
  height: auto;
  display: block;
  position: absolute;
  top: 6.375rem;
  left: 0.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.eStatesBannerImgLeft};
  border-radius: ${({ theme }) => theme.radii.large};

  ${() => elementLevitationAnimation("0.5s")};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    top: 3.375rem;
    left: 0.75rem;
    box-shadow: ${({ theme }) => theme.shadows.eStatesBannerImgLeft};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 12.5rem;
    top: 4.375rem;
    left: 4.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.875rem;
    top: 4.375rem;
    left: 7.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 19.25rem;
    top: 6.375rem;
    left: 10.25rem;
  }
`;

export const EStatesParallaxImage3 = styled(Image)`
  width: 11.625rem;
  height: auto;
  display: block;
  position: absolute;
  top: 31.45rem;
  left: 0.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.eStatesBannerImgLeft};
  border-radius: ${({ theme }) => theme.radii.large};

  ${() => elementLevitationAnimation("1.1s")};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    width: 9.0625rem;
    top: 15.75rem;
    left: 0.8rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 21.45rem;
    left: 4.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 13.5rem;
    top: 28.45rem;
    left: 7.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 19.25rem;
    top: 31.45rem;
    left: 10.25rem;
  }
`;

export const EStatesParallaxImage4 = styled(Image)`
  width: 12.125rem;
  height: auto;
  display: none;
  position: absolute;
  top: 1.75rem;
  right: 10.375rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  box-shadow: ${({ theme }) => theme.shadows.eStatesBannerImgRight};
  border-radius: ${({ theme }) => theme.radii.large};

  ${() => elementLevitationAnimation("1.4s")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
    top: 2.75rem;
    right: 5.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 15rem;
    top: 1.75rem;
    right: 8.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 17.47rem;
    top: 1.75rem;
    right: 14.375rem;
  }
`;
