// Components + styling
import { Box } from "components";
import { EStatesUserFlowGridRow, EStatesUserFlowItemWrapper } from "../../styled";
import { EStatesUserFlowItem } from "..";
// Animations
import { eStatesAnimatedItems } from "../../animations";
// Types
import { EStatesUserFlowRowProps } from "./types";

const EStatesUserFlowRow: React.FC<EStatesUserFlowRowProps> = ({ items, gridTemplateColumns, mt }) => {
  return (
    <Box position="relative">
      <EStatesUserFlowItemWrapper
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.8 }}
        variants={eStatesAnimatedItems}
      >
        <EStatesUserFlowGridRow mt={mt} gridTemplateColumns={gridTemplateColumns}>
          {items.map((item, index) => (
            <EStatesUserFlowItem key={index} {...item} />
          ))}
        </EStatesUserFlowGridRow>
      </EStatesUserFlowItemWrapper>
    </Box>
  );
};

export default EStatesUserFlowRow;
