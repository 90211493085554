import React from "react";
// Components + styling
import { SpinnerIcon, ButtonHoverLinesIcon } from "components/svg";
import { StyledButton, StyledButtonContent, StyledButtonGradientLayer, StyledButtonHoverLines } from "./styled";
// Types
import { ButtonProps } from "./types";

export const getExternalLinkProps = () => ({
  target: "_blank",
  rel: "noreferrer noopener",
});

const Button = <E extends React.ElementType = "button">(props: ButtonProps<E>): JSX.Element => {
  const {
    startIcon,
    endIcon,
    children,
    isLoading,
    disabled,
    external,
    filledType,
    scale,
    hoverLinesPosition,
    hoverLinesColor,
    contentBg,
    hideHoverGradient,
    ariaLabel,
    ...rest
  } = props;
  const { hoverBg } = rest;
  const isDisabled = isLoading || disabled;
  const internalProps = external ? getExternalLinkProps() : {};
  const bgAccentHoverColor = !hoverBg || hoverBg === "buttonAccentHover";

  return (
    <StyledButton
      {...internalProps}
      {...rest}
      type={props.type || "button"}
      disabled={isDisabled}
      filledType={filledType}
      scale={scale}
      aria-label={ariaLabel}
    >
      {!hideHoverGradient && bgAccentHoverColor && <StyledButtonGradientLayer hoverBg={hoverBg} />}
      <StyledButtonContent filledType={filledType} scale={scale} contentBg={contentBg}>
        {isLoading ? (
          <SpinnerIcon />
        ) : (
          <>
            {React.isValidElement(startIcon) &&
              React.cloneElement(startIcon, {
                mr: "0.5rem",
              })}
            {children}
            {React.isValidElement(endIcon) &&
              React.cloneElement(endIcon, {
                ml: "0.5rem",
              })}
          </>
        )}
      </StyledButtonContent>

      <StyledButtonHoverLines hoverLinesPosition={hoverLinesPosition} scale={scale}>
        <ButtonHoverLinesIcon width="2rem" color={hoverLinesColor} />
      </StyledButtonHoverLines>
    </StyledButton>
  );
};

Button.defaultProps = {
  isLoading: false,
  variant: "primary",
  filledType: "filled",
  scale: "md",
  disabled: false,
  buttonTheme: "light",
  hoverLinesPosition: "topRight",
  hoverLinesColor: "textFourth",
  isShowHoverLines: true,
  hideHoverGradient: false,
};

export default Button;
