import styled from "styled-components";
// Components
import { Box, Container, Link, Text } from "components";

export const StyledFooterWrapper = styled(Box)`
  width: 100%;
  background: ${({ theme }) => theme.colors.bgDark} url("/images/footer-bg.webp") no-repeat center bottom;

  ${({ theme }) => theme.mediaQueries.tabletSquare} {
    position: fixed;
    bottom: 0;
  }
`;

export const StyledFooterContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledSectionTitle = styled(Text).attrs({
  textScale: "p16Regular",
  color: "white",
})`
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 2.5rem;
  }
`;

export const StyledFooterLink = styled(Link).attrs({
  textScale: "p14Regular",
  color: "textFourth",
  underline: true,
})`
  cursor: pointer;
`;
