import * as yup from "yup";
// Context
import { useTranslation } from "context";

export const initialValues = {
  findSolution: "",
};

const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    findSolution: yup.string().required(t("Field is required")).trim(),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
