// Types
import { Settings } from "react-slick";

export const sliderSettings: Settings = {
  dots: false,
  arrows: false,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 800,
  cssEase: "linear",
  centerMode: true,
  // Need use px, if use rem it not working correctly
  centerPadding: "0px",
};
