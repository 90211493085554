import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Text } from "components";
import { Colors } from "theme/types";

export const StyledTab = styled(Text)<{ isActive?: boolean; color?: keyof Colors }>`
  width: 100%;
  text-align: center;
  position: relative;
  padding: 0 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.textThird};
  color: ${({ theme, isActive, color }) =>
    isActive && color ? theme.colors[color] : isActive ? theme.colors.planetcoinCaseMain : theme.colors.textFifth};

  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;

  &:nth-of-type(1) {
    padding-left: 0;
  }

  &:nth-last-of-type(1) {
    padding-right: 0;
  }

  ::after {
    ${clearfix}
    width: 100%;
    height: 0.1875rem;
    position: absolute;
    left: 0;
    bottom: -0.125rem;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    background-color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.planetcoinCaseMain)};
    transition: ${({ theme }) => theme.transitions.default};
  }
`;
