import React from "react";
// Components + Styling
import { StyledSearchResultItem, StyledSearchResultsWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { FindSolutionSearchResultsProps } from "./types";

const FindSolutionSearchResults: React.FC<FindSolutionSearchResultsProps> = ({
  searchResults,
  onSearchResultClick,
}) => {
  const { t } = useTranslation();

  return (
    <StyledSearchResultsWrapper>
      {searchResults.length ? (
        <>
          {searchResults.map((searchItem, index) => {
            return (
              <StyledSearchResultItem
                key={searchItem + index}
                onClick={() => {
                  onSearchResultClick(searchItem);
                }}
              >
                {searchItem}
              </StyledSearchResultItem>
            );
          })}
        </>
      ) : (
        <StyledSearchResultItem disabled>
          {t("Not found articles for your request. Please, enter a more precise description of the request")}
        </StyledSearchResultItem>
      )}
    </StyledSearchResultsWrapper>
  );
};

export default FindSolutionSearchResults;
