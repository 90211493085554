import styled from "styled-components";
// Components
import { Flex } from "components";

export const StyledTabPanelWrapper = styled(Flex)`
  width: 100%;
  max-width: 40.625rem;
  margin: 0 auto 1.5rem;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
