import React from "react";
// Components + Styling
import { Box, Button, Text } from "components";
import { WorkflowStepEllipseIcon } from "components/svg";
import {
  StyledWorkflowItemWrapper,
  StyledWorkflowItem,
  StyledStepWrapper,
  StyledStepIcon,
  StyledStepLabel,
} from "./styled";
// Animations
import { workflowItemShow } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { WorkflowItemProps } from "./types";

const WorkflowItem: React.FC<WorkflowItemProps> = ({ step, label, description }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  return (
    <StyledWorkflowItemWrapper
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.8 }}
      variants={workflowItemShow}
    >
      <StyledWorkflowItem>
        <StyledStepWrapper>
          <StyledStepIcon>
            <WorkflowStepEllipseIcon width="2.875rem" />
          </StyledStepIcon>
          <StyledStepLabel textScale="decorative26Regular" color="white">
            {step}
          </StyledStepLabel>
        </StyledStepWrapper>

        <Box width="75%" margin={{ _: "0 1rem", laptop: "0 1.25" }}>
          <Text textScale={isDesktop ? "p24Bold" : "p16Bold"} mb={{ _: "0.25rem", laptop: "0.5rem" }} color="white">
            {t(label)}
          </Text>
          <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textThird">
            {t(description)}
          </Text>
        </Box>
      </StyledWorkflowItem>

      {step === 1 && (
        <Box width="fit-content" margin={{ _: "1.5rem auto 3rem", tablet: "1rem auto 3rem 4.25rem" }}>
          <Button
            minWidth="unset"
            width={{ mobileS: "17.25rem", mobileM: "20.375rem", tablet: "13.625rem" }}
            scale="lg"
            hoverLinesPosition={isMobile ? "bottomLeftMobile" : "bottomLeft"}
            onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
          >
            💬 {t("Schedule a call")}
          </Button>
        </Box>
      )}
    </StyledWorkflowItemWrapper>
  );
};

export default WorkflowItem;
