import { OurApproach1Icon, OurApproach2Icon, OurApproach3Icon } from "components/svg";

export const OUR_APPROACH_LIST = [
  {
    Icon: OurApproach1Icon,
    title: "End-to-end development",
    description:
      "Our team focuses on complex product design. We do not limit our interest to visuals or identity only, trying to show the complex vision of functionality and business needs.",
  },
  {
    Icon: OurApproach2Icon,
    title: "Efficient and simple process",
    description:
      "We optimize our development process, parallel what we can and focus on creating an outstanding design as fast as possible.",
  },
  {
    Icon: OurApproach3Icon,
    title: "Not to leave the client after the release",
    description:
      "We have expertise in A/B testing and post-release analysis of our UI/UX. We help with conversion rate optimization of the design elements too.",
  },
];
