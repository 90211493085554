import React from "react";
// Components
import { Box } from "components";
// Hooks
import { useElementByIdHeight } from "hooks";
// Types
import { HashLayoutWrapperProps } from "./types";

export const HashLayoutWrapper: React.FC<HashLayoutWrapperProps> = ({ minHeight, children, ...props }) => {
  const { elementHeight } = useElementByIdHeight("header");

  return (
    <Box pt={elementHeight} minHeight={minHeight ? `calc(${minHeight} - ${elementHeight}px)` : "initial"} {...props}>
      {children}
    </Box>
  );
};
