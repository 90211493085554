// Components + styling
import { Box, FlexGap, PostPreview } from "components";
// Types
import { PostsListProps } from "./types";

export const PostsList: React.FC<PostsListProps> = ({ posts }) => {
  const postsTileList = posts.slice(1);

  return (
    <Box mb={{ _: "1.5rem", laptopL: "3rem" }}>
      <PostPreview post={posts[0]} isMainPost mb={{ _: "1.5rem", tablet: "2rem" }} />
      <FlexGap flexWrap="wrap" mx={{ _: 0, tablet: "-1rem" }}>
        {postsTileList.map(post => (
          <PostPreview
            post={post}
            key={post.id}
            mb={{ _: "1.5rem", tablet: "2rem" }}
            paddingX={{ _: 0, tablet: "1rem" }}
            width={{ _: "100%", tablet: "33.33%" }}
          />
        ))}
      </FlexGap>
    </Box>
  );
};
