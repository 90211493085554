// Components + styling
import { Container, Flex, Heading, Text } from "components";
import { ShortSummarySlideImage } from "./components";
import {
  StyledShortSummaryWrapper,
  StyledSummaryItem,
  StyledSummaryItemHeadingHover,
  StyledSummaryItemTextHover,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { SHORT_SUMMARY_LIST } from "./constants";

const ShortSummary: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useMatchBreakpoints();

  return (
    <StyledShortSummaryWrapper pt={{ _: "2.5rem", laptop: "3.75rem" }} pb={{ _: "1.875rem", laptop: "3.25rem" }}>
      <Container position="relative" mt={{ laptop: "6.25rem" }}>
        <Heading scale="h2" as="h2" maxWidth={{ laptop: "22.875rem" }} mb={{ _: "0.25rem", laptop: "1rem" }}>
          {t("Short summary of our work")}
        </Heading>
        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          maxWidth={{ laptop: "30.625rem" }}
          mb={{ _: "1.5rem", laptop: "3.125rem" }}
        >
          {t("The numbers that we were able to achieve thanks to our work and iterative improvements after release")}
        </Text>

        {isDesktop && <ShortSummarySlideImage />}

        <Flex
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "flex-start" : "space-between"}
          flexWrap={isTablet ? "wrap" : "nowrap"}
          alignItems="center"
        >
          {SHORT_SUMMARY_LIST.map(({ value, label, description }) => (
            <StyledSummaryItem key={label}>
              {isDesktop ? (
                <>
                  <StyledSummaryItemHeadingHover scale="h3" as="h3" mb="1rem">
                    {t(value)}
                  </StyledSummaryItemHeadingHover>
                  <StyledSummaryItemHeadingHover scale="h5" as="h5" maxWidth="12.5rem" mb="2.25rem">
                    {t(label)}
                  </StyledSummaryItemHeadingHover>
                </>
              ) : (
                <>
                  <StyledSummaryItemTextHover textScale="p44Medium">{t(value)}</StyledSummaryItemTextHover>
                  <StyledSummaryItemTextHover textScale="p24Bold" mb="1rem">
                    {t(label)}
                  </StyledSummaryItemTextHover>
                </>
              )}

              <Text textScale="p18Regular" maxWidth="13.5rem" color="textFifth">
                {t(description)}
              </Text>
            </StyledSummaryItem>
          ))}
        </Flex>
      </Container>
    </StyledShortSummaryWrapper>
  );
};

export default ShortSummary;
