import styled from "styled-components";
// Components
import { Box, Flex } from "components";

export const StyledInteractionsContent = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyledTab = styled(Box)<{ isActive: boolean }>`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
`;

export const StyledImageWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  background-image: url("/images/planetcoin-interactions-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    flex-direction: column;
    padding: 0 2rem;
    overflow: hidden;
  }
`;

export const StyleTabImage = styled(StyledTab)<{ activeIndex: number }>`
  /* 2rem its 1rem + 1rem of padding-x StyledImageWrapper*/
  width: calc(100% - 2rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 1;
    top: ${({ activeIndex }) => `${50 - 100 * (activeIndex - 0)}%`};
  }
`;
