import { useNavigate } from "react-router-dom";
// Components + styling
import { Box } from "components";
import { StyledMainPostBox, StyledMainPostInfoWrapper, StyledPostPreviewImage } from "./styled";
import { PostInfo } from "./components";
// Context
import { useThemeContext } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { PostPreviewProps } from "./types";
import { PostInfoData } from "./components/post-info/types";

const PostPreview: React.FC<PostPreviewProps> = ({ post, isMainPost = false, ...rest }) => {
  const { theme } = useThemeContext();
  const { isTablet, isDesktop } = useMatchBreakpoints();
  const navigate = useNavigate();

  if (!post) {
    return null;
  }

  const postInfo: PostInfoData = {
    author: post.author?.name || "",
    date: new Date(post.date).toLocaleString("en-GB", { day: "numeric", month: "long", year: "numeric" }),
    title: post.title,
  };

  const blogClickHandler = () => {
    navigate(`/${ROUTES.blog}/${post.slug}`);
  };

  if ((isTablet || isDesktop) && isMainPost) {
    return (
      <StyledMainPostBox
        src={post.bannerImage?.url || ""}
        aspectRatio={(post.bannerImage?.width || 1) / (post.bannerImage?.height || 1)}
        onClick={blogClickHandler}
      >
        <StyledMainPostInfoWrapper>
          <PostInfo postInfo={postInfo} isMainPost />
        </StyledMainPostInfoWrapper>
      </StyledMainPostBox>
    );
  }

  return (
    <Box {...rest}>
      <Box cursor="pointer" onClick={blogClickHandler}>
        <Box borderRadius={theme.radii.medium} overflow="hidden" mb="1rem">
          <StyledPostPreviewImage width="100%" src={post.coverImage?.url || ""} aspectRatio={1.51} />
        </Box>
        <PostInfo postInfo={postInfo} />
      </Box>
    </Box>
  );
};

export default PostPreview;
