// Types
import { WorkflowItemProps } from "./components/workflow-item/types";

export const WORKFLOW_LIST: WorkflowItemProps[] = [
  {
    label: "Get in touch and schedule a call with us",
    description: "Fill in the application form and book a call with us to discuss your agenda and business needs",
    step: 1,
  },
  {
    label: "Project Estimation",
    description: "We will estimate the time and resources required to create the design of your product",
    step: 2,
  },
  {
    label: "Sign the contracts",
    description: "We sign a contract to formalize our agreement with you on the project. We also sign an NDA if needed",
    step: 3,
  },
  {
    label: "Make a prepayment",
    description: "We start to work after the 50% prepayment if the project cost is less than $10,000",
    step: 4,
  },
  {
    label: "Working on your project",
    description:
      "We execute the project following the signed project plan, communicate with you on every stage of the project and organize at least weekly meetings with updates",
    step: 5,
  },
  { label: "Finalizing the project", description: "We finish the project and receive your feedback", step: 6 },
];
