import React from "react";
import { useNavigate } from "react-router-dom";
// Components + Styling;
import { Button, Flex, Image, Text } from "components";
import { StyledOpenPositionItem } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { OpenPositionItemProps } from "../../types";

const OpenPositionItem: React.FC<OpenPositionItemProps> = ({
  id,
  icon,
  position,
  description,
  experience,
  english,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const handleClickCheckPositions = (id: number) => {
    navigate(`${id}`);
  };

  return (
    <StyledOpenPositionItem>
      <Flex alignItems="center" mb={{ _: "0.5rem", tablet: "1.25rem" }}>
        <Image width={isMobile ? "2.5rem" : "3.75rem"} src={icon} aspectRatio={1} />
        <Text textScale={isDesktop ? "p32Bold" : "p20Bold"} mx="1.25rem">
          {t(position)}
        </Text>
      </Flex>

      <Text
        textScale={isDesktop ? "p22Regular" : "p16Regular"}
        color="textFifth"
        mb={{ _: "1rem", tablet: "1.375rem" }}
      >
        {t(description)}
      </Text>

      <Flex justifyContent="space-between" alignItems="center" mb="1rem">
        <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>{t("Level of experience")}</Text>
        <Text textScale={isDesktop ? "p22Medium" : "p16Medium"}>{t(experience)}</Text>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center" mb={{ _: "1rem", tablet: "2.5rem" }}>
        <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>{t("English")}</Text>
        <Text textScale={isDesktop ? "p22Medium" : "p16Medium"}>{t(english)}</Text>
      </Flex>

      <Flex justifyContent="center" mt="auto">
        <Button
          scale="lg"
          width={isMobile ? "calc(100% - 1rem)" : "100%"}
          maxWidth={isMobile ? "21.375rem" : "19.125rem"}
          filledType="bordered"
          hoverLinesPosition={isMobile ? "topRightMobile" : "topRight"}
          onClick={() => handleClickCheckPositions(id)}
        >
          {t("Check position")}
        </Button>
      </Flex>
    </StyledOpenPositionItem>
  );
};
export default OpenPositionItem;
