import Lottie from "lottie-react";
// Animations
import { IreneSliderAnimation, MelSliderAnimation } from "components/our-team/components/slider-item-animations";
import { StyledIreneLottieWrapper } from "../sales-item/styled";
// Types
import { Colors } from "theme/types";
import { SliderAnimationProps } from "components/our-team/components/slider-item-animations/types";

export type SalesMember = {
  image: string;
  hoverColor: keyof Colors;
  name: string;
  position: string;
  ImageLottieAnimation: React.FC<SliderAnimationProps>;
  ImageLottieWrapper?: typeof Lottie | undefined;
};

export const SALES_LIST: SalesMember[] = [
  {
    image: "/images/mel-lg.webp",
    hoverColor: "melHover",
    name: "Mel Dyu",
    position: "Business Development Manager",
    ImageLottieAnimation: MelSliderAnimation,
  },
  {
    image: "/images/irene-avryutova.webp",
    hoverColor: "ireneHover",
    name: "Irene Avryutova",
    position: "Customer Care Manager",
    ImageLottieAnimation: IreneSliderAnimation,
    ImageLottieWrapper: StyledIreneLottieWrapper,
  },
];
