// Components + styling
import { FlexGap } from "components/layout";
import { Text } from "components/text";
import { DotSeparatorIcon } from "components/svg";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { PostInfoProps } from "./types";

export const PostInfo: React.FC<PostInfoProps> = ({ postInfo, isMainPost = false }) => {
  const { isDesktop } = useMatchBreakpoints();

  const { author, date, title } = postInfo;

  const authorTextColor = isMainPost ? "textThird" : "textFifth";
  const dotIconColor = isMainPost ? "textThird" : "textFourth";
  const dateTextColor = isMainPost ? "textThird" : "textFourth";
  const titleTextColor = isMainPost ? "bgSecond" : "textMain";

  return (
    <>
      <FlexGap gap="0.25rem" height="max-content" mb="0.25rem">
        <Text textScale={isDesktop ? "p16Medium" : "p12Medium"} color={authorTextColor}>
          {author}
        </Text>
        <DotSeparatorIcon width="1.5rem" color={dotIconColor} />
        <Text textScale={isDesktop ? "p16Medium" : "p12Medium"} color={dateTextColor}>
          {date}
        </Text>
      </FlexGap>
      <Text textScale={isDesktop ? "p22Medium" : "p12Medium"} color={titleTextColor}>
        {title}
      </Text>
    </>
  );
};
