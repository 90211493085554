import { transitions } from "theme/base";

export const slideVariants = {
  hidden: {
    y: "100vh",
    opacity: 0,
    transition: transitions.defaultMotion,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: transitions.defaultMotion,
  },
};
