// Components
import { CrownLineIcon, WarningTriangleIcon } from "components/svg";

export const BANNER_CATEGORY_LIST = ["Crypto currency", "Dashboard"];

export const BANNER_STATISTICS_LIST = [
  {
    value: "25",
    label: "pages",
  },
  {
    value: "120+",
    label: "hours spent",
  },
  {
    value: "2",
    label: "designers",
  },
  {
    value: "3",
    label: "updates",
  },
];

export const BANNER_POINTS_LIST = [
  {
    Icon: WarningTriangleIcon,
    label: "The problem",
    description:
      "Create a convenient and intuitive interface for tracking and analyzing cryptocurrency data. Considering the development of graphs, charts, and other visual elements, as well as defining key metrics and indicators to display on the dashboard",
  },
  {
    Icon: CrownLineIcon,
    label: "Our solution",
    description:
      "Cryptocurrency, blockchain, web 3.0 - these are still quite new and complex words. Our main goal is to make easy-to-use interface that requires no previous crypto experience . To make purchase of cryptocurrency as simple as it can be",
  },
];
