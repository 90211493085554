import { forwardRef } from "react";
import { motion } from "framer-motion";
// Components + Styling
import { StyledServicesParallaxWrapper, StyledSlideImage1_1, StyledSlideImage1_2 } from "./styled";
// Context
import { useThemeContext } from "context";
// Hooks
import { useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const ServicesParallaxImage = forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const { theme } = useThemeContext();

  const parallaxOffset: UseScrollOffsetOptions[] = ["start start", "end start"];

  const parallaxImage1 = useParallaxEffect(ref, 100, { offset: parallaxOffset });

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 240, { offset: parallaxOffset }),
    useParallaxEffect(ref, 440, { offset: parallaxOffset }),
    useParallaxEffect(ref, 1100, { offset: parallaxOffset }),
    useParallaxEffect(ref, 480, { offset: parallaxOffset }),
    useParallaxEffect(ref, 320, { offset: parallaxOffset }),
    useParallaxEffect(ref, 440, { offset: parallaxOffset }),
    useParallaxEffect(ref, 320, { offset: parallaxOffset }),
    useParallaxEffect(ref, 460, { offset: parallaxOffset }),
    useParallaxEffect(ref, 560, { offset: parallaxOffset }),
    useParallaxEffect(ref, 500, { offset: parallaxOffset }),
    useParallaxEffect(ref, 700, { offset: parallaxOffset }),
    useParallaxEffect(ref, 360, { offset: parallaxOffset }),
    useParallaxEffect(ref, 520, { offset: parallaxOffset }),
    useParallaxEffect(ref, 580, { offset: parallaxOffset }),
    useParallaxEffect(ref, 660, { offset: parallaxOffset }),
    useParallaxEffect(ref, 800, { offset: parallaxOffset }),
    useParallaxEffect(ref, 900, { offset: parallaxOffset }),
    useParallaxEffect(ref, 1100, { offset: parallaxOffset }),
  ];

  return (
    <StyledServicesParallaxWrapper>
      <motion.div
        style={{ zIndex: theme.zIndices.dropdown, y: parallaxImage1.y }}
        transition={parallaxImage1.selectedTransition}
      >
        <StyledSlideImage1_1 src="/images/services-parallax-image-1-1.svg" aspectRatio={1.07} />
      </motion.div>

      <motion.div style={{ y: parallaxImage1.y }} transition={parallaxImage1.selectedTransition}>
        <StyledSlideImage1_2 src="/images/services-parallax-image-1-2.svg" aspectRatio={2.4} />
      </motion.div>

      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, zIndex, ratio }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];

        return (
          <motion.div key={imageSrc} style={{ zIndex, y }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </StyledServicesParallaxWrapper>
  );
});

ServicesParallaxImage.displayName = "ServicesParallaxImage";

export default ServicesParallaxImage;
