// Components
import { Box, Flex } from "components";
import { MobileFilterItem } from "./components";
// Types
import { CaseStudiesFilterType } from "context/case-studies-context/types";
import { MobileFilterContentProps } from "./types";

const MobileFilterContent: React.FC<MobileFilterContentProps> = ({
  filters,
  localSelectedAll,
  filtersData,
  isLoading,
  handleFilterChangeAndStore,
}) => {
  return (
    <Box height="45%" overflowY="scroll" overflowX="hidden">
      {!isLoading && filters
        ? Object.keys(filters).map(filterType => {
            const type = filterType as CaseStudiesFilterType;

            return (
              <Flex flexDirection="column" key={type}>
                <MobileFilterItem
                  type={type}
                  filters={filters}
                  filtersData={filtersData}
                  handleFilterChangeAndStore={handleFilterChangeAndStore}
                  localSelectedAll={localSelectedAll}
                />
              </Flex>
            );
          })
        : null}
    </Box>
  );
};

export default MobileFilterContent;
