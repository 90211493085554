import { useRef } from "react";
import { useInView } from "framer-motion";
// Components + Styling
import { Container, HashLayoutWrapper, Heading, Text } from "components";
import { ButtonHoverLinesIcon, LinkIcon, TextWaveIcon } from "components/svg";
import { AiBook, PlanetCoin, EStates, Ugreator } from "./components";
import {
  StyledPortfolioDescription,
  StyledPortfolioLines,
  StyledProjectsList,
  StyledWaveUnderlinedText,
  StyledWaveWrapper,
  StyledLinkMoreCases,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";
import { ROUTES } from "navigation/routes";

const Portfolio: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isLargeDesktop } = useMatchBreakpoints();

  const waveTextRef = useRef<HTMLParagraphElement | null>(null);
  const isIconsInView = useInView(waveTextRef, { once: true, amount: 1 });

  return (
    <HashLayoutWrapper id={BLOCK_ID.portfolio} pb="3.75rem">
      <Container position="relative">
        {isIconsInView && (
          <StyledPortfolioLines>
            <ButtonHoverLinesIcon width="2.25rem" animationType="bannerButtonLeftLines" />
          </StyledPortfolioLines>
        )}
        <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "0.5rem", laptopL: "1rem" }}>
          {t("Portfolio")}
        </Heading>
        <StyledPortfolioDescription>
          <Text
            textScale={isLargeDesktop ? "p22Light" : "p16Light"}
            mb={{ _: "0.75rem", tablet: "0" }}
            maxWidth={{ _: "auto", tablet: "17.875rem", laptopL: "24rem" }}
          >
            {t("Check our recent cases and find out how we design products like yours")}
          </Text>
          <Text
            ref={waveTextRef}
            textScale={isLargeDesktop ? "decorative22Light" : "decorative16Light"}
            textAlign={isMobile ? "left" : "right"}
            maxWidth={{ _: "auto", tablet: "21rem", laptopL: "28rem" }}
            px={{ _: "0", tablet: "1rem", laptop: "1.5rem" }}
          >
            {t("Be amazed by our portfolio's versatility - from small projects to")}
            <StyledWaveUnderlinedText as="span">
              {t(" million dollar ")}
              {isIconsInView && (
                <StyledWaveWrapper>
                  <TextWaveIcon width="100%" />
                </StyledWaveWrapper>
              )}
            </StyledWaveUnderlinedText>
            {t("deals")}
          </Text>
        </StyledPortfolioDescription>

        <StyledProjectsList>
          <EStates />
          <Ugreator />
          <AiBook />
          <PlanetCoin />
        </StyledProjectsList>

        <StyledLinkMoreCases textScale="buttonL" color="textAccent" href={`/${ROUTES.caseStudies}`} underline>
          {t("View more cases")}
          <LinkIcon width="1.125rem" ml="0.625rem" color="textAccent" />
        </StyledLinkMoreCases>
      </Container>
    </HashLayoutWrapper>
  );
};

export default Portfolio;
