import React from "react";
// Components + styling
import { StyledFlex, StyledHeaderSocialsLink } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { HEADER_SOCIALS_LINKS } from "./constants";

export const HeaderSocials: React.FC = () => {
  const { isLargeDesktop } = useMatchBreakpoints();

  return (
    <StyledFlex alignItems="center">
      {HEADER_SOCIALS_LINKS.map(({ Icon, iconName, link }) => (
        <StyledHeaderSocialsLink external href={link} key={link} iconName={iconName}>
          <Icon width={isLargeDesktop ? "1.25rem" : "1.625rem"} color="white" />
        </StyledHeaderSocialsLink>
      ))}
    </StyledFlex>
  );
};
