import { motion } from "framer-motion";
// Animations
import { textContainerVariants, textCharacterVariants } from "./animations";
// Types
import { AnimatedCharacterTextProps } from "./types";

const AnimatedCharacterText: React.FC<AnimatedCharacterTextProps> = ({ text, ...props }) => {
  const letters = Array.from(text);

  return (
    <motion.span variants={textContainerVariants} initial="hidden" {...props}>
      {letters.map((letter, index) => (
        <motion.span variants={textCharacterVariants} key={index}>
          {letter}
        </motion.span>
      ))}
    </motion.span>
  );
};

export default AnimatedCharacterText;
