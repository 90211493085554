import { motion } from "framer-motion";
// Components + styling
import { Box, Column, Text } from "components";
import { StyledDefaultFlowItem } from "../../styled";
import {
  StyledThirdColumnActivitiesItem,
  StyledThirdColumnAuctionsItem,
  StyledThirdColumnMyProfileItem,
  StyledThirdColumnUShopItem,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Animation
import { ugreatorAnimatedItems } from "../../animations";
// Constants
import {
  UGREATOR_USER_FLOWS_THIRD_COLUMN_ACTIVITIES_LIST,
  UGREATOR_USER_FLOWS_THIRD_COLUMN_AUCTIONS_LIST,
  UGREATOR_USER_FLOWS_THIRD_COLUMN_HOMEPAGE_LIST,
  UGREATOR_USER_FLOWS_THIRD_COLUMN_MY_PROFILE_LIST,
  UGREATOR_USER_FLOWS_THIRD_COLUMN_U_SHOP_LIST,
} from "./constants";

const UgreatorUserFlowsThirdColumn: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <motion.div
      style={{ position: "relative" }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={ugreatorAnimatedItems(0.5)}
    >
      <Column>
        <Box mb={{ _: "0.75rem", laptop: "2.125rem" }} mx={{ _: "0.125rem", laptop: "0" }}>
          {UGREATOR_USER_FLOWS_THIRD_COLUMN_HOMEPAGE_LIST.map(({ label, borderColor }) => (
            <StyledDefaultFlowItem key={label} mb={{ _: "0.375rem", laptop: "1rem" }} $borderColor={borderColor}>
              <Text textScale={isDesktop ? "p16Medium" : "p6Medium"}>{t(label)}</Text>
            </StyledDefaultFlowItem>
          ))}
        </Box>

        <Box mb={{ _: "0.875rem", laptop: "2.125rem" }}>
          {UGREATOR_USER_FLOWS_THIRD_COLUMN_AUCTIONS_LIST.map(({ label, borderColor }) => (
            <StyledThirdColumnAuctionsItem key={label} $borderColor={borderColor}>
              <Text textScale={isDesktop ? "p16Medium" : "p5Medium"}>{t(label)}</Text>
            </StyledThirdColumnAuctionsItem>
          ))}
        </Box>

        <Box mb={{ _: "0.875rem", laptop: "2.625rem" }}>
          {UGREATOR_USER_FLOWS_THIRD_COLUMN_MY_PROFILE_LIST.map(({ label, borderColor, connectorLinesSrc }) => (
            <StyledThirdColumnMyProfileItem
              key={label}
              $borderColor={borderColor}
              connectorLinesSrc={connectorLinesSrc}
            >
              <Text textScale={isDesktop ? "p16Medium" : "p5Medium"}>{t(label)}</Text>
            </StyledThirdColumnMyProfileItem>
          ))}
        </Box>

        <Box mb={{ _: "1.75rem", laptop: "4.375rem" }}>
          {UGREATOR_USER_FLOWS_THIRD_COLUMN_U_SHOP_LIST.map(({ label, borderColor }) => (
            <StyledThirdColumnUShopItem key={label} $borderColor={borderColor}>
              <Text textScale={isDesktop ? "p14Medium" : "p5Medium"} textAlign="center">
                {t(label)}
              </Text>
            </StyledThirdColumnUShopItem>
          ))}
        </Box>

        <Box mx={{ _: "0.1875rem", laptop: "0.0625rem" }}>
          {UGREATOR_USER_FLOWS_THIRD_COLUMN_ACTIVITIES_LIST.map(({ label, borderColor }) => (
            <StyledThirdColumnActivitiesItem key={label} $borderColor={borderColor}>
              <Text textScale={isDesktop ? "p14Medium" : "p6Medium"} textAlign="center">
                {t(label)}
              </Text>
            </StyledThirdColumnActivitiesItem>
          ))}
        </Box>
      </Column>
    </motion.div>
  );
};

export default UgreatorUserFlowsThirdColumn;
