import styled from "styled-components";
// Components + styling theme
import { Text } from "components";
import { fontSizes, lineHeights, styles } from "./theme";
// Types
import { scales, HeadingProps } from "./types";

const Heading = styled(Text)<HeadingProps>`
  font-family: ${({ theme }) => theme.fonts.ubuntu};

  ${({ scale }) => scale && styles[scale]};
  font-weight: ${({ theme, $fontWeight }) => ($fontWeight ? theme.fontWeight[$fontWeight] : "")};

  font-size: ${({ scale }) => scale && fontSizes[scale].mobile};
  line-height: ${({ scale }) => scale && lineHeights[scale].mobile};

  ${({ theme }) => theme.mediaQueries.laptop} {
    font-size: ${({ scale }) => scale && fontSizes[scale].laptop};
    line-height: ${({ scale }) => scale && lineHeights[scale].laptop};
    font-weight: ${({ theme, $fontWeight }) => ($fontWeight ? theme.fontWeight[$fontWeight] : "")};
  }
`;

Heading.defaultProps = {
  as: scales.h1,
  scale: scales.h1,
};

export default Heading;
