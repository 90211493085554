import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, DefaultSlideImage } from "components";
// Animations
import { elementLevitationAnimation } from "theme/animations";

export const StyledParallaxImageWrapper = styled(Box)`
  width: 100%;
  height: 23.125rem;
  display: flex;
  flex: initial;
  position: relative;
  margin: 2.5rem auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 25rem;
    margin: 6.625rem auto;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 31.875rem;
    margin-bottom: 13.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 40rem;
  }
`;

export const StyledMotionWrapper = styled(motion.div)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledParallaxImage1 = styled(DefaultSlideImage)`
  width: 35.125rem;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 2.75rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown - 1};

  ${({ theme }) => theme.mediaQueries.tablet} {
    left: 50%;
    transform: translateX(-50%);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 51.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 63.75rem;
  }
`;

export const StyledParallaxImage2 = styled(DefaultSlideImage)`
  width: 7.4375rem;
  height: auto;
  display: block;
  position: absolute;
  top: -2.75rem;
  right: 1.75rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  opacity: 0.95;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg2Sm};
  backdrop-filter: blur(0.39px);

  ${() => elementLevitationAnimation("0.2s")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 12.5rem;
    top: -5rem;
    box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg2};
    backdrop-filter: blur(1.12px);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.875rem;
    top: -3.125rem;
    right: 0.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 21.625rem;
    top: -4.625rem;
    right: 4.875rem;
  }
`;

export const StyledParallaxImage3 = styled(DefaultSlideImage)`
  width: 9.0625rem;
  height: auto;
  display: block;
  position: absolute;
  top: 10.875rem;
  left: 0.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  opacity: 0.95;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg3Sm};
  backdrop-filter: blur(0.47px);

  ${() => elementLevitationAnimation("0.5s")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 12.5rem;
    top: 17.25rem;
    left: 0.75rem;
    box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg3};
    backdrop-filter: blur(1px);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.875rem;
    top: 29rem;
    left: -0.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 19.25rem;
    top: 35.375rem;
    left: 3.25rem;
  }
`;

export const StyledParallaxImage4 = styled(DefaultSlideImage)`
  width: 11.625rem;
  height: auto;
  display: none;
  position: absolute;
  top: -1rem;
  left: -0.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  opacity: 0.95;
  border-radius: ${({ theme }) => theme.radii.medium};
  box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg4};
  backdrop-filter: blur(1.81px);

  ${() => elementLevitationAnimation("0.8s")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 13.5rem;
    top: 3.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 15.375rem;
    top: 4.125rem;
    left: 2.5rem;
  }
`;

export const StyledParallaxImage5 = styled(DefaultSlideImage)`
  width: 11.625rem;
  height: auto;
  display: none;
  position: absolute;
  top: 3.25rem;
  left: 1rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  opacity: 0.95;
  border-radius: ${({ theme }) => theme.radii.medium};
  box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg4};
  backdrop-filter: blur(1.81px);

  ${() => elementLevitationAnimation("1.1s")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 13.5rem;
    top: 8.5rem;
    left: 1.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 15.375rem;
    top: 9.75rem;
    left: 4.375rem;
  }
`;

export const StyledParallaxImage6 = styled(DefaultSlideImage)`
  width: 12.125rem;
  height: auto;
  display: none;
  position: absolute;
  top: 9.375rem;
  right: 0.75rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  opacity: 0.95;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerImg3};
  backdrop-filter: blur(1px);

  ${() => elementLevitationAnimation("1.4s")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 15rem;
    top: 17.375rem;
    right: 0.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 20.4375rem;
    top: 20.75rem;
    right: 3.375rem;
  }
`;
