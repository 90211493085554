import { transitions } from "theme/base";

export const cookiesShow = (isDesktop: boolean) => ({
  hidden: {
    bottom: "-31.25rem",
    transition: transitions.defaultMotion,
  },
  visible: {
    bottom: isDesktop ? "4.5rem" : "1rem",
    transition: { ...transitions.defaultMotion, delay: 3.25 },
  },
});
