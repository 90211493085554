import React from "react";
// Components + Styling;
import { Box, Container, Flex, HashLayoutWrapper, Heading, RatingStars, Text } from "components";
import { TestimonialsSlider } from "./components";
import { StyledCompaniesRateList, StyledCompanyRate } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { COMPANIES_RATE_LIST } from "./constants";
import { BLOCK_ID } from "configs";

const Testimonials: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isLargeDesktop } = useMatchBreakpoints();

  return (
    <HashLayoutWrapper id={BLOCK_ID.testimonials} pb="1.5rem">
      <Container pt={{ _: "0", desktop: "0.75rem" }}>
        <Flex
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent={isDesktop ? "space-between" : "center"}
          mb={{ _: "1.25rem", laptop: "1.5rem" }}
        >
          <Box mb={{ _: "2rem", laptop: "0" }}>
            <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
              {t("Testimonials")}
            </Heading>
            <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>{t("What are people saying about us")}</Text>
          </Box>
          <StyledCompaniesRateList flex-direction={isMobile ? "column" : "row"}>
            {COMPANIES_RATE_LIST.map(({ Icon, rate, starColor }, index) => (
              <StyledCompanyRate key={index}>
                <Box mb={{ _: "0.75rem", tablet: "0" }} mx={{ _: "0", tablet: "0.75rem" }}>
                  <Icon height={isMobile ? "1.25rem" : isLargeDesktop ? "2rem" : "1.5rem"} width="100%" />
                </Box>
                <RatingStars rating={rate} fillColor={starColor} width={isMobile ? "1rem" : "1.375rem"} />
              </StyledCompanyRate>
            ))}
          </StyledCompaniesRateList>
        </Flex>

        <TestimonialsSlider />
      </Container>
    </HashLayoutWrapper>
  );
};

export default Testimonials;
