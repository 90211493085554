import { motion } from "framer-motion";
// Components + Styling
import { StyledUserFlowsParallaxImageWrapper } from "./styled";
// Hooks
import { useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const UgreatorUserFlowsParallaxImage = React.forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const parallaxOffset: UseScrollOffsetOptions[] = ["start end", "end start"];

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 80, { offset: parallaxOffset }),
    useParallaxEffect(ref, 50, { offset: parallaxOffset }),
    useParallaxEffect(ref, 95, { offset: parallaxOffset }),
    useParallaxEffect(ref, 70, { offset: parallaxOffset }),
  ];

  return (
    <StyledUserFlowsParallaxImageWrapper>
      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, ratio, zIndex }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];

        return (
          <motion.div key={imageSrc} style={{ y, zIndex, position: "relative" }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </StyledUserFlowsParallaxImageWrapper>
  );
});

UgreatorUserFlowsParallaxImage.displayName = "UgreatorUserFlowsParallaxImage";

export default UgreatorUserFlowsParallaxImage;
