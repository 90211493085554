import { css } from "styled-components";
// Types
import {
  UgreatorUserFlowsSecondColumnConnectionVariantType,
  ugreatorUserFlowsSecondColumnConnectionVariant,
} from "./types";

export const secondColumnFlowConnectorVariantStyles = (
  variant: UgreatorUserFlowsSecondColumnConnectionVariantType = ugreatorUserFlowsSecondColumnConnectionVariant.homepage,
) => {
  return {
    homepage: css`
      width: 13.625rem;
      height: 15.625rem;
      top: -2.25rem;

      ${({ theme }) => theme.mediaQueries.laptop} {
        width: 39.375rem;
        height: 45.375rem;
        top: -6.875rem;
      }
    `,
    auctions: css`
      width: 2.75rem;
      height: 2.375rem;
      top: -0.375rem;

      ${({ theme }) => theme.mediaQueries.laptop} {
        width: 6.9375rem;
        height: 7.375rem;
        top: -1.625rem;
        left: calc(100% + 0.25rem);
      }
    `,
    myProfile: css`
      width: 2.375rem;
      height: 5.875rem;
      top: -0.375rem;
      left: 100%;

      ${({ theme }) => theme.mediaQueries.laptop} {
        width: 6.9375rem;
        height: 17.1875rem;
        top: -1.3125rem;
        left: calc(100% + 0.25rem);
      }
    `,
    uShop: css`
      width: 2.375rem;
      height: 2.5625rem;
      top: -0.5rem;

      ${({ theme }) => theme.mediaQueries.laptop} {
        width: 6.9375rem;
        height: 7.9375rem;
        top: -1.625rem;
        left: calc(100% + 0.25rem);
      }
    `,
    activities: css`
      width: 7rem;
      height: 6.6875rem;
      top: -4.6875rem;
      left: calc(100% + 0.1875rem);

      ${({ theme }) => theme.mediaQueries.laptop} {
        width: 20.0625rem;
        height: 19.4375rem;
        top: -13.625rem;
      }
    `,
  }[variant];
};
