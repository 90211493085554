import styled from "styled-components";
import { flexbox } from "styled-system";
import { clearfix, clearfixDisplayNone } from "styles";
// Components
import { Heading, Image } from "components";
// Types
import { FlexProps } from "components/layout/components/types";

export const StyledBannerImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};
`;

export const StyledUgreatorBannerWrapper = styled.div<{ headerHeight: number }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};
  padding-top: ${({ headerHeight }) => `calc(${headerHeight}px + 2.5rem)`};
  background: 0 center / 100% 100% no-repeat url("/images/case-studies/ugreator/ugreator-banner-gradient-bg.webp");

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-top: ${({ headerHeight }) => `calc(${headerHeight}px + 4.375rem)`};
  }

  ${({ theme }) => theme.mediaQueries.semiLargeHeight} {
    min-height: 50vh;
  }
`;

export const StyledUgreatorBannerSubtitle = styled(Heading)`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const StyledBannerCategory = styled.div`
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radii.large};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledStatisticsList = styled.div<FlexProps>`
  display: flex;
  justify-content: space-between;
  ${flexbox}

  ${({ theme }) => theme.mediaQueries.laptop} {
    position: relative;
    padding: 2.625rem 1rem;

    ::before {
      ${clearfix}
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.4;
      border-radius: ${({ theme }) => theme.radii.xl};
      background: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const StyledStatisticItem = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: center;
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
  }

  ::after {
    ${clearfixDisplayNone}
    width: 1px;
    height: 1.25rem;
    background: ${({ theme }) => `${theme.colors.white}33`};

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
    }
  }

  &:nth-last-of-type(1) {
    ::after {
      display: none;
    }
  }
`;

export const StyledBannerStatisticValue = styled(Heading)`
  text-align: center;
  font-size: 2.125rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledBannerPointsList = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const StyledBannerPointItemWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: flex-start;
    margin-bottom: 0;
  }

  ::after {
    ${clearfixDisplayNone}
    width: 0.3125rem;
    height: 19.75rem;
    background: ${({ theme }) => theme.gradients.planetcoinBannerPointDivider};

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
    }
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;

    ::after {
      ${({ theme }) => theme.mediaQueries.laptop} {
        display: none;
      }
    }
  }
`;

export const StyledBannerPointItem = styled.div`
  max-width: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: initial;
    padding: 0 3.375rem;
  }
`;
