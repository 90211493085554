// Components + styling
import { Button } from "components";
import { MobileFilterContent, MobileFilterHeader } from "./components";
import { SelectedFilters } from "..";
import { CaseStudiesMobileContent, CaseStudiesMobileWrapper, StyledCaseStudiesMobileFilterButton } from "./styled";
// Animations
import { slideVariants } from "components/header/animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions } from "hooks";
import { useFilterHandling } from "./hooks";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { CaseStudiesMobileFilterProps } from "./types";

const CaseStudiesMobileFilter: React.FC<CaseStudiesMobileFilterProps> = ({
  closeModal,
  hasSomeFiltersSelected,
  isModalOpen,
  isLoading,
  filters,
}) => {
  const { t } = useTranslation();
  const {
    elementDimensions: { offsetHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  const {
    filtersData,
    pendingFilters,
    localSelectedAll,
    hasSomeFiltersSelectedNew,
    handleFilterChangeAndStore,
    handleRemoveFilter,
    handleClearAll,
    handleShowResults,
  } = useFilterHandling({
    closeModal,
    isModalOpen,
    filters,
    isLoading,
    hasSomeFiltersSelected,
  });

  return (
    <>
      {isModalOpen ? (
        <CaseStudiesMobileWrapper
          initial="hidden"
          animate={isModalOpen ? "visible" : "hidden"}
          variants={slideVariants}
          offsetHeight={offsetHeight}
        >
          <CaseStudiesMobileContent>
            <div>
              <MobileFilterHeader closeModal={closeModal} handleClearAll={handleClearAll} />

              {hasSomeFiltersSelectedNew && filters && (
                <SelectedFilters
                  filters={filters}
                  onRemove={handleRemoveFilter}
                  selectedFilters={pendingFilters}
                  hasSomeFiltersSelected={hasSomeFiltersSelectedNew}
                />
              )}

              <MobileFilterContent
                localSelectedAll={localSelectedAll}
                handleFilterChangeAndStore={handleFilterChangeAndStore}
                filtersData={filtersData}
                filters={filters}
                isLoading={isLoading}
              />
            </div>

            <StyledCaseStudiesMobileFilterButton>
              <Button minWidth="100%" scale="md" onClick={handleShowResults}>
                {t("Show results")}
              </Button>
            </StyledCaseStudiesMobileFilterButton>
          </CaseStudiesMobileContent>
        </CaseStudiesMobileWrapper>
      ) : null}
    </>
  );
};

export default CaseStudiesMobileFilter;
