import styled from "styled-components";
// Components
import { Box } from "components";

export const StyledOpenPositionItem = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 0 0.5rem;
    padding: 1.875rem 1.625rem;
    border-radius: ${({ theme }) => theme.radii.large};
    box-shadow: ${({ theme }) => theme.shadows.openPositionItem};
    transition: ${({ theme }) => theme.transitions.default};

    &:hover {
      background: ${({ theme }) => theme.colors.white};
      box-shadow: ${({ theme }) => theme.shadows.openPositionItemHover};
    }
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 3.75rem 3.5rem;
  }
`;
