import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 115 121" {...props} animationType="circleWrapper" color="transparent">
      <path
        d="M66.3551 4.70165C37.8503 -6.48543 8.08191 15.3783 2.23676 42.9431C-0.42518 55.497 -0.307714 87.3513 21.0823 105.14C24.8551 108.278 28.513 110.485 31.9811 112.219C70.1471 131.309 96.7283 111.774 107.922 80.704C109.592 76.0703 110.912 70.9515 111.298 64.8073C111.587 60.212 111.343 54.8637 110.287 49.0674C107.197 32.099 97.7113 18.7035 85.4156 9.9042C71.1598 -0.297937 60.1064 0.371532 50.6562 2.6993C42.3925 4.73485 35.0947 8.423 28.4655 13.3668C16.0328 22.6383 2.10257 34.012 1.82721 59.3719C1.70706 70.4486 6.64204 91.1204 19.0739 102.98C32.5452 115.831 46.1397 114.661 55.2229 113.77C79.727 111.366 107.932 89.2593 112.276 55.9344C112.744 52.3407 113.336 48.9023 113.15 44.6396C113.003 41.2715 112.589 37.5732 111.864 33.7308"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
