import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "linkedInLink", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 28 28" {...props}>
      <path
        d="M23.625 0H4.375C1.95875 0 0 1.95875 0 4.375V23.625C0 26.0412 1.95875 28 4.375 28H23.625C26.0412 28 28 26.0412 28 23.625V4.375C28 1.95875 26.0412 0 23.625 0Z"
        fill={theme.colors[color]}
      />
      <path
        d="M10.15 21H7.175V11.6375H10.15V21ZM8.6625 10.325C7.7 10.325 7 9.625 7 8.6625C7 7.7 7.7875 7 8.6625 7C9.625 7 10.325 7.7 10.325 8.6625C10.325 9.625 9.625 10.325 8.6625 10.325ZM21 21H18.025V15.925C18.025 14.4375 17.4125 14 16.5375 14C15.6625 14 14.7875 14.7 14.7875 16.0125V21H11.8125V11.6375H14.6125V12.95C14.875 12.3375 15.925 11.375 17.4125 11.375C19.075 11.375 20.825 12.3375 20.825 15.225V21H21Z"
        fill="white"
      />
    </Svg>
  );
};

export default Icon;
