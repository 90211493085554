import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M10.75 8C10.75 6.20507 12.2051 4.75 14 4.75H28H38.5462C38.6183 4.75 38.6869 4.78112 38.7344 4.83537L45.1881 12.2111C45.228 12.2567 45.25 12.3152 45.25 12.3757V28V48C45.25 49.7949 43.7949 51.25 42 51.25H14C12.2051 51.25 10.75 49.7949 10.75 48V8Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
      />
      <circle cx="18.5" cy="16.5" r="2.5" stroke={theme.colors[color]} strokeWidth="1.5" />
      <circle cx="18.5" cy="29.5" r="2.5" stroke={theme.colors[color]} strokeWidth="1.5" />
      <rect x="23" y="16" width="16" height="1.5" rx="0.75" fill={theme.colors[color]} />
      <rect x="23" y="29" width="16" height="1.5" rx="0.75" fill={theme.colors[color]} />
      <path
        d="M24 41.4286L26.9474 44L32 38"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M39 5V11C39 11.5523 39.4477 12 40 12H45" stroke={theme.colors[color]} strokeWidth="1.5" />
    </Svg>
  );
};

export default Icon;
