import { useRef } from "react";
// Components + styling
import { Box, Container, TabsPanel, Heading, Image, Text, ActiveInteractionItem } from "components";
import {
  EStatesInteractionsContent,
  EStatesInteractionsImageWrapper,
  EStatesInteractionsTab,
  EStatesInteractionsTabImage,
  EStatesInteractionsTabsPanelWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useSlideAutoChange } from "hooks";
// Constants
import { INTERACTION_SECTIONS_LIST } from "./constants";

const EStatesInteractions: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet, isLaptop, isDesktop } = useMatchBreakpoints();

  const { activeInteractionSection, setActiveInteractionSection } = useSlideAutoChange(INTERACTION_SECTIONS_LIST);

  const imageWrapperRef = useRef<HTMLDivElement | null>(null);

  const titlesList = INTERACTION_SECTIONS_LIST.map(({ title }) => t(title));

  const imgAspectRatio = isMobile ? 1.92 : isTablet ? 1.74 : isLaptop ? 3.79 : 3.93;

  return (
    <Box position="relative" pt={{ _: "2.5rem", laptop: "7.5rem" }} pb={{ _: "2.5rem", laptop: "5rem" }}>
      <Container>
        <Heading scale="h2" as="h2" mt="1.85rem" mb={{ _: "0.25rem", laptop: "1rem" }}>
          {t("Interactions")}
        </Heading>
        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          maxWidth={{ laptop: "40.625rem" }}
          mb={{ _: "1.5rem", laptop: "3.75rem" }}
        >
          {t("Briefly about the functions that allow users to effectively manage their assets on a daily basis")}
        </Text>

        <EStatesInteractionsContent>
          {!isDesktop && (
            <EStatesInteractionsTabsPanelWrapper>
              <TabsPanel
                titles={titlesList}
                activeTab={activeInteractionSection}
                onTabClick={setActiveInteractionSection}
                color="eStatesUserFlowInteractions"
              />
            </EStatesInteractionsTabsPanelWrapper>
          )}

          {!isDesktop && (
            <Box position="relative" minHeight="5.375rem">
              {INTERACTION_SECTIONS_LIST.map((el, index) => (
                <EStatesInteractionsTab key={index} isActive={activeInteractionSection === index}>
                  <ActiveInteractionItem {...el} />
                </EStatesInteractionsTab>
              ))}
            </Box>
          )}

          <EStatesInteractionsImageWrapper ref={imageWrapperRef} height={imageWrapperRef.current?.offsetWidth}>
            {INTERACTION_SECTIONS_LIST.map(({ image, title, imageWidth }, index) => (
              <EStatesInteractionsTabImage
                key={index}
                minHeight={{ _: "unset", laptop: imageWrapperRef.current?.offsetWidth }}
                isActive={activeInteractionSection === index}
                activeIndex={activeInteractionSection}
              >
                <Image width={imageWidth} src={image} aspectRatio={imgAspectRatio} alt={title} />
              </EStatesInteractionsTabImage>
            ))}
          </EStatesInteractionsImageWrapper>

          <Box minWidth={{ laptop: "40%" }}>
            {isDesktop && (
              <>
                {INTERACTION_SECTIONS_LIST.map((el, index) => (
                  <ActiveInteractionItem
                    key={index}
                    isActive={activeInteractionSection === index}
                    onClick={() => setActiveInteractionSection(index)}
                    {...el}
                  />
                ))}
              </>
            )}
          </Box>
        </EStatesInteractionsContent>
      </Container>
    </Box>
  );
};

export default EStatesInteractions;
