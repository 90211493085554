import { useQuery } from "@apollo/client";
// Components + styling
import { Box, Container, Flex, Heading, PostPreview, SliderNavButtons } from "components";
import { StyledRelevantArticlesSlider } from "./styled";
// Context
import { useTranslation } from "context";
// Services
import { GetPostsResponse, GetPostsVars, getPosts, DEFAULT_WHERE_PARAMS } from "services";
// Types
import { RelevantArticlesSliderProps } from "./types";
// Hooks
import { useMatchBreakpoints, useSliderController } from "hooks";
// Constants
import { sliderSettings } from "./configs";

export const RelevantArticlesSlider: React.FC<RelevantArticlesSliderProps> = ({ tags, currentPost }) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();
  const { sliderRef, handleClickPrevious, handleClickNext } = useSliderController();

  const tagsIds = tags.map(({ id }) => id);

  const { data } = useQuery<GetPostsResponse, GetPostsVars>(getPosts, {
    variables: {
      where: {
        ...DEFAULT_WHERE_PARAMS,
        tags_some: { id_in: tagsIds },
        id_not: currentPost?.id,
      },
    },
  });

  const { posts = [] } = data || {};

  if (posts.length === 0) {
    return null;
  }

  return (
    <Container maxWidth="53.5rem" padding={{ laptopL: 0 }} mt={{ _: "1.5rem", laptopL: "3rem" }}>
      <Flex justifyContent="space-between" alignItems="center" mb="1rem">
        <Box maxWidth={{ _: "100%", tablet: "35rem", laptop: "50rem" }}>
          <Heading scale="h4" as="h4" $fontWeight="bold">
            {t("News from same categories")}
          </Heading>
        </Box>
        {!isMobile && posts.length > sliderSettings.slidesToShow && (
          <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} />
        )}
      </Flex>
      {posts.length < sliderSettings.slidesToShow ? (
        <Flex justifyContent="space-between">
          {posts.map(post => (
            <Box key={post.id} width={{ _: "100%", tablet: "calc(50% - 1rem)" }}>
              <PostPreview post={post} />
            </Box>
          ))}
        </Flex>
      ) : (
        <StyledRelevantArticlesSlider ref={sliderRef} {...sliderSettings}>
          {posts.map((post, idx) => (
            <PostPreview post={post} paddingX="1rem" key={post.id + idx} />
          ))}
        </StyledRelevantArticlesSlider>
      )}
      {isMobile && posts.length > 1 && (
        <Flex justifyContent="center" mt="1.5rem">
          <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} />
        </Flex>
      )}
    </Container>
  );
};
