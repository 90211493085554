import styled from "styled-components";
// Components
import { Flex } from "components";

export const StyledChatHeader = styled(Flex)`
  width: 100%;
  max-height: 4.5rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem 1rem 1rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge} ${({ theme }) => theme.radii.semiLarge} 0 0;
  background: ${({ theme }) => theme.colors.disabledStrokeDark};
`;
