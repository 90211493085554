import styled from "styled-components";
// Components
import { Text } from "components";

export const StyledCategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 1rem 0 0.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 0 0 0.75rem;
  }
`;

export const StyledCategoriesText = styled(Text)`
  padding: 0.5rem 0.75rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  border: 1px solid ${({ theme }) => theme.colors.lineLight};
`;
