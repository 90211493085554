export const UP_LINE_IMAGES_LIST = [
  {
    src: "/images/e-states-wireframes-up-line-1.webp",
    srcHover: "/images/e-states-wireframes-up-line-1-hover.webp",
  },
  {
    src: "/images/e-states-wireframes-up-line-2.webp",
    srcHover: "/images/e-states-wireframes-up-line-2-hover.webp",
  },
  {
    src: "/images/e-states-wireframes-up-line-3.webp",
    srcHover: "/images/e-states-wireframes-up-line-3-hover.webp",
  },
];

export const DOWN_LINE_IMAGES_LIST = [
  {
    src: "/images/e-states-wireframes-down-line-1.webp",
    srcHover: "/images/e-states-wireframes-down-line-1-hover.webp",
  },
  {
    src: "/images/e-states-wireframes-down-line-2.webp",
    srcHover: "/images/e-states-wireframes-down-line-2-hover.webp",
  },
  {
    src: "/images/e-states-wireframes-down-line-3.webp",
    srcHover: "/images/e-states-wireframes-down-line-3-hover.webp",
  },
];

export const BACKGROUND_LAYER_LIST = [
  {
    imgWidth: "1440px",
    imgHeight: "1200px",
    imgSrc: "/images/e-states-wireframes-first-layer-gradient-bg.webp",
    backgroundSize: { desktop: "100%", mobile: "40%" },
    positionTop: { desktop: "0px", mobile: "-15px" },
    velocityMotion: { baseVelocity: 0.1, speedToScroll: 100 },
  },
  {
    imgWidth: "1440px",
    imgHeight: "1200px",
    imgSrc: "/images/e-states-wireframes-second-layer-gradient-bg.webp",
    backgroundSize: { desktop: "100%", mobile: "30%" },
    positionTop: { desktop: "90px", mobile: "20px" },
    velocityMotion: { baseVelocity: -0.1, speedToScroll: 100 },
  },
];
