import styled from "styled-components";

export const StyledUgreatorWireframesWrapper = styled.div`
  width: 100%;
  min-height: 60.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding: 1.25rem 0;
  background: url("/images/case-studies/ugreator/ugreator-wireframes-bg.webp") no-repeat;
  background-size: contain;
  background-position: right top;

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-position: right -9.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 7.5rem 0 4rem;
  }
`;
