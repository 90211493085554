import { useRef } from "react";
import { useInView } from "framer-motion";
// Components + Styling;
import { Box, Heading, Text, Flex, Button } from "components";
import { LinkIcon, WebflowLogoIcon } from "components/svg";
import { PlaceItem } from "./components";
import {
  StyledEllipseWrapperIcon,
  StyledFreeLabel,
  StyledImage,
  StyledImageIconWrapper,
  StyledImagesWrapper,
  StyledLinkCheckExample,
  StyledPlacesList,
  StyledTestIdeaContainer,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { EXTERNAL_LINKS } from "configs";
import { PLACES_LIST } from "./constants";

const TestYourIdea: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isLaptopXL } = useMatchBreakpoints();

  const ref = useRef<HTMLSpanElement | null>(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div>
      <StyledTestIdeaContainer py={{ _: "2rem", laptop: "3.75rem" }}>
        <Flex
          flexDirection={{ _: "column", laptop: "row" }}
          justifyContent={{ laptop: "space-between" }}
          alignItems="center"
        >
          <Box
            width={{ _: "100%", laptop: "calc(55% - 2rem)", laptopL: "calc(55% - 3rem)" }}
            mb={{ _: "2.625rem", laptop: "0" }}
          >
            <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
              {t("Want to know if people are going to like your ")}
              <StyledFreeLabel ref={ref}>
                {t("product?")}
                {isInView && <StyledEllipseWrapperIcon width="125%" />}
              </StyledFreeLabel>
            </Heading>
            <Text textScale={isDesktop ? "p22Regular" : "p16Regular"} mb={{ _: "1rem", tablet: "4.375rem" }}>
              {t(
                "We offer a special program with three winners for startups and those who want to test their ideas. Fill out the form below, and get an MVP from us to check your hypothesis.",
              )}
            </Text>

            <StyledPlacesList>
              {PLACES_LIST.map(({ label, description, Icon }, index) => (
                <PlaceItem key={label} label={label} description={description} Icon={Icon} index={index} />
              ))}
            </StyledPlacesList>

            <Flex
              flexDirection={{ _: "column", tablet: "row" }}
              justifyContent={{ tablet: "center", laptop: "flex-start" }}
            >
              <Box
                width="100%"
                maxWidth={{ _: "21.375rem", laptop: "16.5rem" }}
                mx={{ _: "auto", tablet: "0" }}
                mb={{ _: "1.375rem", tablet: "0" }}
              >
                <Button
                  width="100%"
                  scale="lg"
                  hoverLinesPosition={isDesktop ? "bottomLeft" : "mobileTopLeft"}
                  as="a"
                  href={EXTERNAL_LINKS.freeLandingSendForm}
                  external
                >
                  {t("Send request")}
                </Button>
              </Box>

              <StyledLinkCheckExample
                textScale="buttonL"
                href={EXTERNAL_LINKS.freeLandingCheckExample}
                external
                color="textAccent"
                underline
              >
                {t("Check Example")}
                <LinkIcon width="1.125rem" ml="0.625rem" color="textAccent" />
              </StyledLinkCheckExample>
            </Flex>
          </Box>

          <StyledImagesWrapper>
            <StyledImage
              width="100%"
              src={
                isDesktop && !isLaptopXL ? "/images/free-landing-example.webp" : "/images/free-landing-example-sm.webp"
              }
              aspectRatio={isDesktop && !isLaptopXL ? 1.03 : 1.41}
            />

            <StyledImageIconWrapper>
              <WebflowLogoIcon width={isDesktop ? "10.375rem" : "6.5rem"} color="textFourth" />
            </StyledImageIconWrapper>
          </StyledImagesWrapper>
        </Flex>
      </StyledTestIdeaContainer>
    </div>
  );
};

export default TestYourIdea;
