import styled from "styled-components";
import { clearfixDisplayNone } from "styles";
// Components
import { Link } from "components";
// Animations
import { moveUpTextAnimation } from "theme/animations";

export const StyledUgreatorTryAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};
  background: 0 center / 100% 100% no-repeat url("/images/case-studies/ugreator/ugreator-try-app-bg-sm.webp");

  ${({ theme }) => theme.mediaQueries.laptop} {
    background: 0 center / 100% 100% no-repeat url("/images/case-studies/ugreator/ugreator-try-app-bg.webp");
  }
`;

export const StyledTryAppVariant = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 2.625rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 2.5rem;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;

    ::after {
      ${clearfixDisplayNone}
      width: 10.375rem;
      height: 9.8125rem;
      position: absolute;
      bottom: -7.25rem;
      right: 0.75rem;
      z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
      background-image: url("/images/case-studies/ugreator/ugreator-try-app-arrow-icon.svg");
      background-repeat: no-repeat;

      ${({ theme }) => theme.mediaQueries.laptop} {
        display: block;
      }

      ${({ theme }) => theme.mediaQueries.laptopL} {
        bottom: -6.25rem;
        right: 2.5rem;
      }
    }
  }
`;

export const StyledTryAppLink = styled(Link)`
  ${moveUpTextAnimation}
`;

export const StyledMainImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: -5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% - 2rem);
    right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: calc(40% - 2rem);
  }
`;
