import React, { useState, useEffect } from "react";
// Components + styling
import { Box, IconButton } from "components";
import { MessageIcon } from "components/svg";
import { ChatForm, ChatHeader, ChatMessageList } from "./components";
import {
  StyledChat,
  StyledChatOuterWrapper,
  StyledChatInnerWrapper,
  StyledChatIconWrapper,
  StyledChatContainer,
} from "./styled";
// Types
import { ChatProps } from "./types";
// Constants
import { MANAGERS_LIST } from "./constants";

const Chat: React.FC<ChatProps> = ({ isChatOpen, setIsChatOpen }) => {
  const [managerIndex, setManagerIndex] = useState(0);

  const currentManagerData = MANAGERS_LIST[managerIndex];

  useEffect(() => {
    const today = new Date();
    const day = today.getDate();

    const newIndex = day % 2 === 0 ? 1 : 0;
    setManagerIndex(newIndex);
  }, []);

  return (
    <StyledChatOuterWrapper>
      <Box position="relative">
        <StyledChatInnerWrapper isChatOpen={isChatOpen}>
          <StyledChat isChatOpen={isChatOpen}>
            <ChatHeader setIsChatOpen={setIsChatOpen} currentManagerData={currentManagerData} />

            <StyledChatContainer>
              <ChatMessageList isChatOpen={isChatOpen} currentManagerData={currentManagerData} />

              <ChatForm />
            </StyledChatContainer>
          </StyledChat>

          <StyledChatIconWrapper isChatOpen={isChatOpen}>
            <IconButton
              Icon={MessageIcon}
              filledType="filled"
              hoverLinesPosition="bottomLeft"
              isStrokeIcon={false}
              onClick={() => {
                setIsChatOpen(!isChatOpen);
              }}
            />
          </StyledChatIconWrapper>
        </StyledChatInnerWrapper>
      </Box>
    </StyledChatOuterWrapper>
  );
};

export default Chat;
