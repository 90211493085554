import React from "react";
// Components + styling
import { StyledBohdanClockAnimation } from "./styled";
// Animations
import bohdanClock from "assets/animations/bohdan-clock.json";
// Types
import { SliderAnimationProps } from "../types";

const BohdanTerpeliukAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref }) => {
  return (
    <>
      <StyledBohdanClockAnimation lottieRef={lottieAnimation1Ref} animationData={bohdanClock} autoplay={false} loop />
    </>
  );
};

export default BohdanTerpeliukAnimation;
