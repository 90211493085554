import React from "react";
// Components + styling
import { Box, Flex, Text } from "components";
import { StyledHeader, AccordionContent, StyledIconWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { AccordionProps } from "./types";

const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  title,
  isOpenFromParent,
  setIsOpenFromParent,
  children,
  rotateIcon,
  bgWhenOpen = "bgDark",
  handleOnContentClick,
  callback,
  iconRotateInitialDeg,
  iconRotateOpenDeg,
  headerProps,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { isDesktop } = useMatchBreakpoints();

  const toggleAccordion = () => {
    if (setIsOpenFromParent) {
      setIsOpenFromParent(!isOpenFromParent);
    } else {
      setIsOpen(!isOpen);
    }
    callback?.();
  };

  const isOpenAccordionItem = isOpenFromParent ? isOpenFromParent : isOpen;

  return (
    <Box $backgroundColor={isOpenAccordionItem ? bgWhenOpen : "bgDark"} {...props}>
      <StyledHeader
        isOpen={isOpenAccordionItem}
        onClick={toggleAccordion}
        isHaveRotateIcon={rotateIcon}
        padding={{ _: "1rem 0", laptop: "1.5rem 0" }}
        {...headerProps}
      >
        <Flex width="100%" justifyContent="space-between" alignItems="center" zIndex="2" position="relative">
          {typeof title === "string" ? (
            <Text textScale={isDesktop ? "p24Bold" : "p16Bold"}>{title}</Text>
          ) : (
            React.isValidElement(title) && title
          )}

          {React.isValidElement(rotateIcon) &&
            React.cloneElement(
              <StyledIconWrapper rotateDeg={isOpenAccordionItem ? iconRotateOpenDeg : iconRotateInitialDeg}>
                {rotateIcon}
              </StyledIconWrapper>,
              {
                width: "2rem",
              },
            )}
        </Flex>
      </StyledHeader>

      <AccordionContent isOpen={isOpenAccordionItem} onClick={() => handleOnContentClick?.()}>
        {children}
      </AccordionContent>
    </Box>
  );
};

export default Accordion;
