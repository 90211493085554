import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

// TODO Move static SVG to the public images
const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 84 26" {...props}>
      <path
        d="M21.8564 1.56055H25.6807V25.5605H21.8564V1.56055ZM39.0654 17.9045C39.0654 21.5775 35.9898 21.8703 35.0749 21.8703C32.7889 21.8703 32.3731 19.7218 32.3731 18.4258V9.24055H28.5488V18.4066C28.5488 20.6818 29.2601 22.5596 30.5068 23.8114C31.6082 24.9183 33.2584 25.5231 35.0462 25.5231C36.3139 25.5231 38.1094 25.1266 39.0654 24.2501V25.5605H42.8897V9.24055H39.0654V17.9045ZM50.5381 3.48055H46.7139V9.24055H43.8457V13.0805H46.7139V25.5605H50.5381V13.0805H53.4063V9.24055H50.5381V3.48055ZM65.4497 20.6809C64.617 21.4325 63.5175 21.8492 62.311 21.8492C59.6512 21.8492 57.698 19.8879 57.698 17.1951C57.698 14.5023 59.5891 12.6236 62.311 12.6236C63.4965 12.6236 64.617 13.0201 65.4707 13.7717L66.0511 14.2729L68.6295 11.6847L67.9842 11.1001C66.467 9.72439 64.4525 8.95255 62.31 8.95255C57.5297 8.95255 54.0583 12.4162 54.0583 17.1749C54.0583 21.9135 57.6129 25.501 62.31 25.501C64.4927 25.501 66.5281 24.7292 68.0263 23.3314L68.6496 22.7468L66.031 20.1605L65.4497 20.6809ZM82.2792 10.6633C81.1778 9.55735 79.8871 8.95255 78.0993 8.95255C76.8316 8.95255 75.3956 9.34903 74.4395 10.2245V1.56055H70.6153V25.5605H74.4395V16.5701C74.4395 12.8972 77.0381 12.6053 77.953 12.6053C80.2389 12.6053 80.1758 14.7548 80.1758 16.0489V25.5605H84.0001V16.069C84.0001 13.7938 83.5268 11.9161 82.2792 10.6633Z"
        fill="#1D303A"
      />
      <path
        d="M62.1846 14.4629C62.9179 14.4629 63.6212 14.7554 64.1397 15.2761C64.6582 15.7967 64.9495 16.5029 64.9495 17.2392C64.9495 17.9755 64.6582 18.6817 64.1397 19.2024C63.6212 19.723 62.9179 20.0155 62.1846 20.0155C61.4513 20.0155 60.748 19.723 60.2295 19.2024C59.711 18.6817 59.4197 17.9755 59.4197 17.2392C59.4197 16.5029 59.711 15.7967 60.2295 15.2761C60.748 14.7554 61.4513 14.4629 62.1846 14.4629Z"
        fill="#FF3D2E"
      />
      <path
        d="M16.5025 19.5345C15.0483 21.0369 13.0731 21.851 10.9946 21.851C6.73446 21.851 3.63684 18.491 3.63684 13.8792C3.63684 9.24621 6.73446 5.88621 10.9946 5.88621C13.053 5.88621 15.0062 6.69934 16.4824 8.18157L17.0637 8.76622L19.6202 6.19918L19.059 5.61454C16.9394 3.42286 14.0712 2.23438 10.9946 2.23438C4.71813 2.23438 0 7.24269 0 13.8993C0 20.5358 4.73917 25.524 10.9946 25.524C14.0923 25.524 16.9604 24.3144 19.08 22.1227L19.6412 21.5381L17.1057 18.9297L16.5025 19.5345Z"
        fill="#1D303A"
      />
    </Svg>
  );
};

export default Icon;
