import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 2.375rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -11.5rem;
    left: 6.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    left: 12.5rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 3.1875rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -9.375rem;
    left: 1.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    left: 4.375rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 2.4375rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -3.75rem;
    left: 6.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    left: 14.375rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 2.375rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -10rem;
    right: 4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 12.5rem;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  width: 1rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -7.5rem;
    right: 0.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 4.375rem;
  }
`;

export const StyledSlideImage6 = styled(DefaultSlideImage)`
  width: 1.5625rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -4.375rem;
    right: 9rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 18.375rem;
  }
`;

export const StyledSlideImage7 = styled(DefaultSlideImage)`
  width: 0.5rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -1.25rem;
    right: 3.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -2.5rem;
    right: 11.875rem;
  }
`;
