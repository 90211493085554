import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { DefaultSlideImage } from "components";

export const StyledParallaxImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: initial;
  position: relative;
  margin: 0 auto;
`;

export const StyledMotionWrapper = styled(motion.div)`
  width: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledBgImage = styled(DefaultSlideImage)`
  width: 100%;
  height: auto;
  display: block;
  z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
`;

export const StyledParallaxPhoneLeft = styled(DefaultSlideImage)`
  width: 50%;
  height: auto;
  display: block;
  position: absolute;
  top: 0rem;
  left: 30%;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transform: translateX(-50%);

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: -2rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -22rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    width: 35%;
    top: -11rem;
    left: 33%;
  }
`;

export const StyledParallaxPhoneRight = styled(DefaultSlideImage)`
  width: 50%;
  height: auto;
  display: block;
  position: absolute;
  top: 0rem;
  right: 40%;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};
  transform: translateX(50%);

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: -4rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -27rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -30rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    width: 35%;
    top: -12rem;
    right: 43%;
  }
`;
