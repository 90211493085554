import React from "react";
// Components + Styling
import {
  StyledSlideImageWrapper,
  StyledSlideImage1,
  StyledSlideImage2,
  StyledSlideImage3,
  StyledSlideImage4,
} from "./styled";

const AiBookSlideImage: React.FC = () => {
  return (
    <StyledSlideImageWrapper>
      <StyledSlideImage1 src="/images/ai-book-slide-1.webp" aspectRatio={0.83} />

      <StyledSlideImage2 src="/images/ai-book-slide-2.webp" aspectRatio={0.67} />

      <StyledSlideImage3 src="/images/ai-book-slide-3.webp" aspectRatio={0.94} />

      <StyledSlideImage4 src="/images/ai-book-slide-4.webp" aspectRatio={0.61} />
    </StyledSlideImageWrapper>
  );
};

export default AiBookSlideImage;
