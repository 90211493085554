import { useRef } from "react";
import { useInView } from "framer-motion";
// Components + styling
import { AnimatedCharacterText, Box, Container, Flex, Heading, Image, RatingStars, Text } from "components";
import { CurlyArrowIcon } from "components/svg";
import {
  StyledClientAvatar,
  StyledClientAvatarLabel,
  StyledCurlyArrowIconWrapper,
  StyledRatingWrapper,
  StyledTestimonialDescription,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const ClientsTestimonials: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const avatarRef = useRef<HTMLDivElement | null>(null);
  const isAvatarInView = useInView(avatarRef, { once: true, amount: 0.5 });

  return (
    <Container pt={{ _: "1.875rem", laptop: "3.25rem" }} pb={{ _: "0.5rem", laptop: "6.25rem" }}>
      {!isDesktop && (
        <Box>
          <Heading scale="h2" as="h2" mb="0.25rem">
            {t("Client’s testimonial")}
          </Heading>
          <Text textScale="p16Regular" mb="1.5rem">
            {t("A few words from the customer")}
          </Text>
        </Box>
      )}

      <Flex
        flexDirection={isDesktop ? "row" : "column"}
        justifyContent={isDesktop ? "space-between" : "flex-start"}
        alignItems="center"
        mt={{ laptop: "4.75rem" }}
      >
        <Box ref={avatarRef} width={isDesktop ? "auto" : "100%"} position="relative">
          {isDesktop && isAvatarInView && (
            <>
              <StyledClientAvatarLabel textScale="decorative22Light">
                <AnimatedCharacterText text={t("Avatar of our customer")} initial="hidden" animate="visible" />
              </StyledClientAvatarLabel>
              <StyledCurlyArrowIconWrapper>
                <CurlyArrowIcon width="2.1875rem" animationType="curlyArrow" />
              </StyledCurlyArrowIconWrapper>
            </>
          )}
          <StyledClientAvatar
            width={isDesktop ? "33rem" : "100%"}
            src="/images/planetcoin-testimonial-customer-avatar.webp"
            aspectRatio={0.89}
          />
        </Box>

        <Box width={{ _: "100%", laptop: "50%" }}>
          {isDesktop && (
            <Box>
              <Heading scale="h2" as="h2" mb="1rem">
                {t("Client’s testimonial")}
              </Heading>
              <Text textScale="p22Regular" mb="2.875rem">
                {t("A few words from the customer")}
              </Text>
            </Box>
          )}

          {!isDesktop && (
            <Box>
              <Heading scale="h3" as="h3" mb="0.25rem" textAlign={isDesktop ? "left" : "center"}>
                {t("Phil Murphy")}
              </Heading>
              <Text textScale="p16Regular" color="textFourth" mb="0.875rem" textAlign={isDesktop ? "left" : "center"}>
                {t("CEO PlanetCoin")}
              </Text>
            </Box>
          )}

          <StyledRatingWrapper>
            <RatingStars rating={5} fillColor="planetcoinCaseMain" width="1.75rem" isHideTextRating />
          </StyledRatingWrapper>

          <StyledTestimonialDescription scale="h6" as="h6">
            {t(
              "Thank you very much for the excellent work! The process was professional, and the result exceeded all my expectations. The design was thoughtful and helped to understand the data more easily. It's a pleasure to work with a team that understands its clients and pays attention to details. I will definitely recommend Idealogic Design to my colleagues and friends. Thank you for your work!",
            )}
          </StyledTestimonialDescription>

          <Flex
            flexDirection={isDesktop ? "row" : "column"}
            justifyContent={isDesktop ? "space-between" : "flex-start"}
            alignItems="center"
          >
            {isDesktop && (
              <Box>
                <Heading scale="h6" as="h6" mb="0.25rem">
                  {t("Phil Murphy")}
                </Heading>
                <Text textScale="p16Regular" color="textFourth">
                  {t("CEO PlanetCoin")}
                </Text>
              </Box>
            )}

            <Box mr={{ laptop: "2.375rem" }}>
              <Image width={isDesktop ? "8.5rem" : "5.375rem"} src="/images/planetcoin-logo.webp" aspectRatio={4.7} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};

export default ClientsTestimonials;
