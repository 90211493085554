import React from "react";
// Components + Styling
import { Box, Container, Flex, HashLayoutWrapper, Heading, Text } from "components";
import { WorkflowLineIcon } from "components/svg";
import { WorkflowItem } from "./components";
import { StyledWorkflowWrapper, StyledWorkflowTitleWrapper, StyledWorkflowLine } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { WORKFLOW_LIST } from "./constants";
import { BLOCK_ID } from "configs";

const Workflow: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  return (
    <HashLayoutWrapper id={BLOCK_ID.howWeWork} $backgroundColor="bgDark">
      <StyledWorkflowWrapper pb={{ _: "4.5rem", laptop: "7.375rem" }}>
        <Container>
          <Flex flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "flex-start" : "space-between"}>
            <StyledWorkflowTitleWrapper>
              <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }} color="white">
                {t("How we would work together")}
              </Heading>
              <Text textScale={isDesktop ? "p22Regular" : "p16Regular"} color="textThird">
                {t(
                  "Our main objective is to make you reach your business goals with the help of design we create for the product",
                )}
              </Text>
            </StyledWorkflowTitleWrapper>

            <Box height="100%" position="relative">
              <StyledWorkflowLine>
                <WorkflowLineIcon width="90%" height="100%" />
              </StyledWorkflowLine>
              {WORKFLOW_LIST.map(({ label, description, step }) => (
                <WorkflowItem key={step} step={step} description={description} label={label} />
              ))}
            </Box>
          </Flex>
        </Container>
      </StyledWorkflowWrapper>
    </HashLayoutWrapper>
  );
};

export default Workflow;
