import { useScroll, useTransform } from "framer-motion";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const useBannerMotions = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  isAnimationActive: boolean,
  offset?: UseScrollOffsetOptions[] | number[],
) => {
  const startAnimationY = isAnimationActive ? -300 : 0;
  const startAnimationXLeft = isAnimationActive ? -window.innerWidth : 0;
  const startAnimationXRight = isAnimationActive ? window.innerWidth : 0;
  const startAnimationDefault = isAnimationActive ? 0 : 1;

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: offset ?? ["end end", "end center"],
  });

  const magneticButtonScroll = useScroll({
    target: ref,
    offset: offset ?? ["end end", "end 25%"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [0, startAnimationY]);
  const xLeft = useTransform(scrollYProgress, [0, 1], [0, startAnimationXLeft]);
  const xRight = useTransform(scrollYProgress, [0, 1], [0, startAnimationXRight]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, startAnimationDefault]);
  const scaleMagneticButton = useTransform(magneticButtonScroll.scrollYProgress, [0.25, 1], [1, startAnimationDefault]);

  return { y, xLeft, xRight, opacity, scaleMagneticButton };
};

export default useBannerMotions;
