import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 197 15" {...props} animationType="inputLabelUnderline" color="transparent">
      <path
        d="M1 5.28714C60.8333 2.28714 183.5 -2.11286 195.5 4.28714C149.333 3.4538 55.7 3.58711 50.5 10.7871C77.3333 9.45378 133.8 7.68711 145 11.2871C156.2 14.8871 102.5 14.5 75 13"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
