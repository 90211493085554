const BLOCK_ID = {
  services: "services",
  portfolio: "portfolio",
  howWeWork: "how-we-work",
  testimonials: "testimonials",
  contactUs: "contact-us",
  header: "header",
} as const;

export default BLOCK_ID;
