// Theme
import { transitions } from "theme/base";

export const imageQuoteShow = {
  hidden: {
    bottom: "0rem",
    opacity: 0,
    transition: transitions.imageQuoteHideMotion,
  },
  visible: {
    bottom: "2.5rem",
    opacity: 1,
    transition: transitions.imageQuoteDelayShowMotion,
  },
};

export const videoSlideAnimationVariant = {
  opened: { width: "60%" },
  collapsed: { width: "20%" },
  fullWidth: { width: "100%" },
};

export const hidingElements = {
  show: { opacity: 1, width: "initial", transition: { duration: 0.5 } },
  hide: { opacity: 0, width: 0, transition: { duration: 0.1 } },
};
