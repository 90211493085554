import { useRef } from "react";
// Components + Styling
import { Box } from "components";
import {
  StyledSlideImageWrapper,
  StyledMotionWrapper,
  StyledSlideImage1,
  StyledSlideImage2,
  StyledSlideImage3,
  StyledSlideImage4,
  StyledSlideImage5,
} from "./styled";
// Context
import { useThemeContext } from "context";
// Hooks
import { useMatchBreakpoints, useParallaxEffect } from "hooks";

const ShortSummarySlideImage: React.FC = () => {
  const { theme } = useThemeContext();
  const { isDesktop } = useMatchBreakpoints();

  const ref = useRef<HTMLDivElement | null>(null);
  const parallaxImage = useParallaxEffect(ref, 50);

  return (
    <StyledSlideImageWrapper ref={ref}>
      <Box position="absolute" right="0">
        <StyledSlideImage1 src="/images/planetcoin-short-summary-image-1.webp" aspectRatio={1.53} />

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage.y } : {}}
          transition={parallaxImage.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImage2 src="/images/planetcoin-short-summary-image-2.webp" aspectRatio={1.32} />
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage.y } : {}}
          transition={parallaxImage.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImage3 src="/images/planetcoin-short-summary-image-3.webp" aspectRatio={1} />
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage.y } : {}}
          transition={parallaxImage.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImage4 src="/images/planetcoin-short-summary-image-4.webp" aspectRatio={0.99} isHideSkeleton />
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage.y } : {}}
          transition={parallaxImage.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImage5 src="/images/planetcoin-short-summary-image-5.webp" aspectRatio={1.04} isHideSkeleton />
        </StyledMotionWrapper>
      </Box>
    </StyledSlideImageWrapper>
  );
};

export default ShortSummarySlideImage;
