import { css } from "styled-components";
// Components + styling
import { Svg } from "components/svg";
import { StyledButtonContent } from "./styled";
// Types
import { Colors, Gradients } from "theme/types";
import { buttonHoverLinesPositions, ButtonThemeType, FilledType, scales, Variant } from "./types";

export const variantStyles = (
  variant: Variant = "primary",
  filledType: FilledType = "filled",
  buttonTheme: ButtonThemeType = "light",
  bgColor: keyof Colors | undefined,
  bg: keyof Gradients | undefined,
  contentBg: keyof Gradients | undefined,
  color: keyof Colors | undefined,
) => {
  return {
    primary: css`
      background: ${({ theme }) =>
        bg ? theme.gradients[bg] : bgColor ? theme.colors[bgColor] : theme.gradients.buttonAccent};
      color: ${({ theme }) => (filledType === "bordered" ? theme.colors.textAccent : theme.colors.white)};
      box-shadow: ${({ theme }) => theme.shadows.button};

      ${StyledButtonContent} {
        background: ${({ theme }) => {
          if (contentBg) {
            return theme.gradients[contentBg];
          } else {
            if (filledType === "bordered") {
              return buttonTheme === "light" ? theme.colors.white : theme.colors.bgDark;
            } else {
              return theme.colors.transparent;
            }
          }
        }};
      }
    `,
    secondary: css`
      background: ${({ theme }) =>
        bg
          ? theme.gradients[bg]
          : bgColor
            ? theme.colors[bgColor]
            : buttonTheme === "dark"
              ? theme.colors.bgDark
              : theme.gradients.buttonLightFill};
      color: ${({ theme }) => {
        if (filledType === "bordered") {
          if (buttonTheme === "light") {
            return theme.colors.bgDark;
          } else {
            return theme.colors.white;
          }
        } else {
          if (buttonTheme === "light") {
            return theme.colors.bgDark;
          } else {
            return theme.colors.white;
          }
        }
      }};

      ${StyledButtonContent} {
        background: ${({ theme }) => {
          if (contentBg) {
            return theme.gradients[contentBg];
          } else {
            if (filledType === "bordered") {
              return buttonTheme === "light" ? theme.colors.white : theme.colors.bgDark;
            } else {
              return theme.colors.transparent;
            }
          }
        }};
      }

      ${Svg} {
        fill: currentColor;
      }
    `,
    transparent: css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => (color ? theme.colors[color] : theme.colors.textMain)};

      &:hover {
        border: 1px solid transparent;
      }
    `,
  }[variant];
};

export const scaleVariants = {
  [scales.XS]: {
    width: "3.5rem",
    height: "3.5rem",
    padding: "0.75rem",
    borderRadius: "50%",
  },
  [scales.SM]: {
    minWidth: "6.75rem",
    height: "2rem",
    padding: "0.5rem 0.75rem",
    borderRadius: "1rem",
  },
  [scales.MD]: {
    minWidth: "8.125rem",
    height: "3.125rem",
    padding: "0.875rem",
    borderRadius: "2.875rem",
  },
  [scales.LG]: {
    minWidth: "16.5rem",
    height: "3.75rem",
    padding: "1rem 1.5rem",
    borderRadius: "2.875rem",
  },
};

export const hoverLinesPositions = {
  [buttonHoverLinesPositions.topRight]: {
    top: "-1.5rem",
    right: "-1.5rem",
  },
  [buttonHoverLinesPositions.topRightMobile]: {
    top: "-1.25rem",
    right: "-1.25rem",
  },
  [buttonHoverLinesPositions.bottomLeft]: {
    bottom: "-1.5rem",
    left: "-1.5rem",
    transform: "rotate(180deg)",
  },
  [buttonHoverLinesPositions.bottomLeftMobile]: {
    bottom: "-1.25rem",
    left: "-1.25rem",
    transform: "rotate(180deg)",
  },
  [buttonHoverLinesPositions.mobileTopLeft]: {
    top: "-2.0625rem",
    left: "0",
    transform: "rotate(292deg)",
  },
};
