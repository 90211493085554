import React from "react";
// Components + styling
import { StyledEyeBlinkAnimation, StyledEyeglassAnimation } from "./styled";
// Animations
import victorEyeBlink from "assets/animations/victor-eye-blink.json";
import victorEyeglass from "assets/animations/victor-eyeglass.json";
// Types
import { SliderAnimationProps } from "../types";

const VictorSliderAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref, lottieAnimation2Ref }) => {
  return (
    <>
      <StyledEyeglassAnimation
        lottieRef={lottieAnimation1Ref}
        animationData={victorEyeglass}
        autoplay={false}
        loop={false}
      />
      <StyledEyeBlinkAnimation lottieRef={lottieAnimation2Ref} animationData={victorEyeBlink} autoplay={false} loop />
    </>
  );
};

export default VictorSliderAnimation;
