import styled from "styled-components";

import { contactUsColorsSchema } from "components/contact-us/constants";

import { ContactUsTheme } from "components/contact-us/types";

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 21.25rem;
    margin-left: 0;
    margin-right: 0;
    gap: 2.25rem;
  }
`;

export const StyledFormGroupWrapper = styled.div`
  display: flex;
`;

export const StyledButtonInnerContainer = styled.div`
  width: 100%;
`;

export const StyledDivider = styled.div<{ contactUsTheme: ContactUsTheme }>`
  width: 100%;
  height: 1px;
  background: ${({ contactUsTheme, theme }) => theme.colors[contactUsColorsSchema[contactUsTheme].dark ?? "dark"]};
  opacity: 0.1;
`;
