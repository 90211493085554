import { useEffect, useState } from "react";
// Hooks
import { useDebounce } from "hooks";
// Services
import { getSearchResult, isErrorResult } from "services";
// Constants
import { REGEX } from "configs";

type FormValues = {
  findSolution: string;
};

const useSearchResults = (values: FormValues, toggleVisible: (value: boolean) => void) => {
  const debouncedSearchValue = useDebounce(values.findSolution, 1000);

  const [pending, setPending] = useState(false);
  const [searchResults, setSearchResults] = useState<string[]>([]);

  useEffect(() => {
    if (debouncedSearchValue) {
      toggleVisible(false);
      setPending(true);

      getSearchResult(debouncedSearchValue).then(response => {
        if (!isErrorResult(response)) {
          const searchResultsList = JSON.parse(response.search_results?.replace(REGEX.doubleSlashRegex, "") || "[]");
          setSearchResults(searchResultsList);
          toggleVisible(true);
        } else {
          console.error("getSearchResult: ", response);
        }

        setPending(false);
      });
    }
  }, [debouncedSearchValue]);

  return { pending, searchValue: debouncedSearchValue, searchResults };
};

export default useSearchResults;
