import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledEyeglassAnimation = styled(Lottie)`
  width: calc(5.25rem / 2.6);
  position: absolute;
  top: calc(3.6875rem / 2.6);
  left: calc(9.125rem / 2.6);
  transform: rotate(7deg);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(5.25rem / 1.6);
    top: calc(3.625rem / 1.6);
    left: calc(9.125rem / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.25rem;
    top: 3.625rem;
    left: 9.125rem;
  }
`;

export const StyledEyeBlinkAnimation = styled(Lottie)`
  width: calc(1.6875rem / 2.6);
  position: absolute;
  top: calc(1.75rem / 2.6);
  left: calc(11.25rem / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(1.6875rem / 1.6);
    top: calc(2.9375rem / 1.6);
    left: calc(11.25rem / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 1.6875rem;
    top: 2.9375rem;
    left: 11.25rem;
  }
`;
