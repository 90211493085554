import React from "react";
// Context
import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg width="8" height="71" viewBox="0 0 8 83" {...props}>
      <path
        d="M3.96091 6.92182C2.31596 6.92182 1 5.60586 1 3.96091C1 2.31596 2.31596 1 3.96091 1C5.60586 1 6.92182 2.31596 6.92182 3.96091C6.92182 5.60586 5.60586 6.92182 3.96091 6.92182ZM3.96091 6.92182L3.96091 82.9186"
        stroke={theme.colors[color]}
        strokeWidth="2.17394"
      />
      <path
        d="M1 3.96091C1 2.31596 2.31596 1 3.96091 1C5.60586 1 6.92182 2.31596 6.92182 3.96091C6.92182 5.60586 5.60586 6.92182 3.96091 6.92182C2.31596 6.92182 1 5.60586 1 3.96091Z"
        fill={theme.colors.white}
      />
    </Svg>
  );
};

export default Icon;
