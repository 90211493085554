import { DefaultTheme } from "styled-components";
import { LayoutProps, SpaceProps, TypographyProps, OpacityProps, FlexboxProps } from "styled-system";
// Types
import { Colors, FontWeight } from "theme/types";

export interface ThemedProps extends TextProps {
  theme: DefaultTheme;
}

export const scales = {
  p44Medium: "p44Medium",
  p34Bold: "p34Bold",
  p34Regular: "p34Regular",
  p32Bold: "p32Bold",
  p30: "p30",
  p28Regular: "p28Regular",
  p24Bold: "p24Bold",
  p24Regular: "p24Regular",
  p22Medium: "p22Medium",
  p22Regular: "p22Regular",
  p22Light: "p22Light",
  p20Bold: "p20Bold",
  p20Regular: "p20Regular",
  p18Bold: "p18Bold",
  p18Regular: "p18Regular",
  p16Bold: "p16Bold",
  p16Medium: "p16Medium",
  p16Regular: "p16Regular",
  p16Light: "p16Light",
  p14Bold: "p14Bold",
  p14Medium: "p14Medium",
  p14Regular: "p14Regular",
  p14Light: "p14Light",
  p12Medium: "p12Medium",
  p12Regular: "p12Regular",
  p12Light: "p12Light",
  p6Bold: "p6Bold",
  p6Medium: "p6Medium",
  p5Medium: "p5Medium",
  buttonL: "buttonL",
  buttonM: "buttonM",
  buttonS: "buttonS",
  decorative26Regular: "decorative26Regular",
  decorative22Bold: "decorative22Bold",
  decorative22Regular: "decorative22Regular",
  decorative22Light: "decorative22Light",
  decorative16Bold: "decorative16Bold",
  decorative16Light: "decorative16Light",
  decorative14Bold: "decorative14Bold",
  decorative14Light: "decorative14Light",
  decorative12Regular: "decorative12Regular",
} as const;

export type Scales = (typeof scales)[keyof typeof scales];

export interface TextProps extends SpaceProps, TypographyProps, LayoutProps, OpacityProps, FlexboxProps {
  color?: keyof Colors;
  ellipsis?: boolean;
  $fontWeight?: keyof FontWeight;
  textTransform?: "uppercase" | "lowercase" | "capitalize";
  textScale?: Scales;
  direction?: "ltr" | "rtl";
  wordBreak?: React.CSSProperties["wordBreak"];
  underline?: boolean;
}
