// Components + styling
import { Wireframes } from "components";
import { EStatesWireframeBackgroundLayer } from "./components";
import { EStatesWireframesWrapper } from "./styled";
// Utils
import { generateRepeatedArray } from "utils";
// Constants
import { BACKGROUND_LAYER_LIST, DOWN_LINE_IMAGES_LIST, UP_LINE_IMAGES_LIST } from "./constants";

const EStatesWireframes: React.FC = () => {
  const upLineImages = generateRepeatedArray({
    sourceArray: UP_LINE_IMAGES_LIST,
    repeatCount: 6,
  });
  const downLineImages = generateRepeatedArray({
    sourceArray: DOWN_LINE_IMAGES_LIST,
    repeatCount: 6,
  });

  return (
    <EStatesWireframesWrapper>
      {BACKGROUND_LAYER_LIST.map(
        ({ positionTop, velocityMotion, imgWidth, imgHeight, backgroundSize, imgSrc }, index) => (
          <EStatesWireframeBackgroundLayer
            key={index}
            positionTop={positionTop}
            velocityMotion={velocityMotion}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            backgroundSize={backgroundSize}
            imgSrc={imgSrc}
          />
        ),
      )}

      <Wireframes
        upLineImages={upLineImages}
        downLineImages={downLineImages}
        titleColor="textMain"
        subTitleColor="textMain"
        zIndexForContainer={50}
        wireframesTitle="Wireframes"
        wireframesSubTitle="The structure for each page and function"
        upBaseVelocity={{ baseVelocity: 0.5 }}
        downBaseVelocity={{ baseVelocity: -0.5 }}
        wireframeItemWidth={{ desktop: 34.125, mobile: 14.8 }}
        wireframeItemRatio={1.4}
        wireframeItemBorderColor="eStatesWireframesBorder"
        isBorder={false}
      />
    </EStatesWireframesWrapper>
  );
};

export default EStatesWireframes;
