// Components + styling
import { Box, FlexGap, Skeleton } from "components";
// types
import { PostsListSkeletonProps } from "./types";

const SKELETON_ROW_COUNT = 3;

export const PostsListSkeleton: React.FC<PostsListSkeletonProps> = ({ page }) => {
  return (
    <Box mb={{ _: "1.5rem", laptopL: "3rem" }}>
      {page === 1 && <Skeleton mb={{ _: "1.5rem", tablet: "2rem" }} height={{ _: "14.25rem", laptopL: "25.68rem" }} />}
      <FlexGap flexWrap="wrap" mx={{ _: 0, tablet: "-1rem" }}>
        {Array.from(Array(SKELETON_ROW_COUNT)).map((_, idx) => (
          <Box
            mb={{ _: "1.5rem", tablet: "2rem" }}
            paddingX={{ _: 0, tablet: "1rem" }}
            width={{ _: "100%", tablet: "33.33%" }}
            key={`skeleton-${idx}`}
          >
            <Skeleton width="100%" height={{ _: "14.25rem", laptopL: "17rem" }} mb={{ _: "1rem" }} />

            <FlexGap flexDirection="column" gap="0.25rem">
              <Skeleton width="70%" height={{ _: "1.125rem", laptopL: "1.56rem" }} />
              <Skeleton width="100%" height={{ _: "1.125rem", laptopL: "1.56rem" }} />
              <Skeleton width="40%" height={{ _: "1.125rem", laptopL: "1.56rem" }} />
            </FlexGap>
          </Box>
        ))}
      </FlexGap>
    </Box>
  );
};
