// Components
import { CrownLineIcon, WarningTriangleIcon } from "components/svg";

export const BANNER_CATEGORY_LIST = ["Crypto currency", "Dashboard"];

export const BANNER_STATISTICS_LIST = [
  {
    value: "20",
    label: "pages",
  },
  {
    value: "2",
    label: "designers",
  },
  {
    value: "50+",
    label: "hours spent",
  },
  {
    value: "3",
    label: "updates",
  },
];

export const BANNER_POINTS_LIST = [
  {
    Icon: WarningTriangleIcon,
    label: "The problem",
    description:
      "The main design issue in e-States is the absence of a user-friendly onboarding process. Users may struggle to navigate the complexities of tokenized real estate, leading to potential confusion and drop-offs.",
  },
  {
    Icon: CrownLineIcon,
    label: "Our solution",
    description:
      "Proposed solutions aim to streamline onboarding, offer legal information, and integrate a user feedback system. These enhancements seek to boost user engagement and facilitate informed decision-making.",
  },
];
