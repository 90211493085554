import React from "react";
// Components + styling
import { Box, Container, Heading, SliderNavButtons, Text } from "components";
import { OurTeamSlider } from "./components";
import { StyledOurTeamTitle } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useSliderController } from "hooks";

const OurTeam: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const { sliderRef, handleClickPrevious, handleClickNext } = useSliderController();

  return (
    <Box py="4rem">
      <StyledOurTeamTitle>
        <Box>
          <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
            {t("Our team")}
          </Heading>
          <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>
            {t("We will take your project and create the product design for your startup")}
          </Text>
        </Box>
        {!isMobile && <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} />}
      </StyledOurTeamTitle>
      <OurTeamSlider ref={sliderRef} />
      {isMobile && (
        <Container mt="1.75rem">
          <Box width="fit-content" mx="auto">
            <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} />
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default OurTeam;
