import { useRef } from "react";
// Components + styling
import { Box, Container, Flex, Heading, Image, RatingStars, Text } from "components";
import { ClientTestimonialParallaxImage } from "./components";
import { StyledClientAvatar, StyledRatingWrapper, StyledTestimonialDescription } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const UgreatorClientTestimonial: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={wrapperRef}>
      <Container pt={{ _: "3.75rem", laptop: "7.5rem" }} pb={{ _: "1.25rem", laptop: "4.875rem" }}>
        {!isDesktop && (
          <Box mb="1.5rem">
            <Heading scale="h2" as="h2" mb="0.25rem">
              {t("Client’s testimonial")}
            </Heading>
            <Text textScale="p16Regular" color="textFourth">
              {t("A few words from the customer")}
            </Text>
          </Box>
        )}

        <Flex
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent={isDesktop ? "space-between" : "flex-start"}
          alignItems="center"
        >
          <Box width={isDesktop ? "auto" : "100%"}>
            <ClientTestimonialParallaxImage ref={wrapperRef} />

            <StyledClientAvatar
              width={isDesktop ? "28.25rem" : "100%"}
              src="/images/case-studies/ugreator/ugreator-testimonial-client-avatar.webp"
              aspectRatio={0.89}
            />
          </Box>

          <Box width={{ _: "100%", laptop: "50%" }}>
            {isDesktop && (
              <Box mb="2.5rem">
                <Heading scale="h2" as="h2" mb="1rem">
                  {t("Client’s testimonial")}
                </Heading>
                <Text textScale="p22Regular" color="textFourth">
                  {t("A few words from the customer")}
                </Text>
              </Box>
            )}

            {!isDesktop && (
              <Box mb="0.875rem">
                <Heading scale="h3" as="h3" mb="0.25rem" textAlign={isDesktop ? "left" : "center"}>
                  {t("Yuriy Nikitin")}
                </Heading>
                <Text textScale="p16Regular" color="textFifth" textAlign={isDesktop ? "left" : "center"}>
                  {t("Founder & CEO Ugreator")}
                </Text>
              </Box>
            )}

            <StyledRatingWrapper>
              <RatingStars rating={5} fillColor="ugreatorMainPurple" width="1.75rem" isHideTextRating />
            </StyledRatingWrapper>

            <StyledTestimonialDescription scale="h6" as="h6">
              {t(
                "Many thanks for the outstanding job! The entire procedure was handled with utmost professionalism, and the outcome surpassed my every expectation. The design was well-conceived, making data comprehension seamless. Collaborating with a team that not only grasps its clients' needs but also focuses on intricate details has been a pleasure. I will unquestionably endorse Idealogic to both my peers and acquaintances. Grateful for your dedicated efforts",
              )}
            </StyledTestimonialDescription>

            <Flex
              flexDirection={isDesktop ? "row" : "column"}
              justifyContent={isDesktop ? "space-between" : "flex-start"}
              alignItems="center"
            >
              {isDesktop && (
                <div>
                  <Heading scale="h6" as="h6" mb="0.25rem">
                    {t("Yuriy Nikitin")}
                  </Heading>
                  <Text textScale="p16Regular" color="textFifth">
                    {t("Founder & CEO Ugreator")}
                  </Text>
                </div>
              )}

              <Image
                width={isDesktop ? "11.875rem" : "11.5rem"}
                src="/images/case-studies/ugreator/ugreator-logo.svg"
                aspectRatio={5.56}
              />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </div>
  );
};

export default UgreatorClientTestimonial;
