import React from "react";
import { useNavigate } from "react-router-dom";
// Components + styling
import { Button, Container, Flex, Heading, Text } from "components";
import { StyledInfoWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { EXTRA_INFO_LIST } from "./constants";

const ExtraInfo: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchBreakpoints();

  const handleClickLink = (link: string | undefined) => {
    if (link) {
      navigate(link);
    }
  };

  return (
    <Container pt={{ _: "3.75rem", laptop: "9.375rem" }} pb={{ _: "0.5rem" }}>
      <Flex flexDirection={isMobile ? "column" : "row"} justifyContent="space-between">
        {EXTRA_INFO_LIST.map(({ title, description, btnLabel, link, href, isExternal }) => (
          <StyledInfoWrapper key={title}>
            <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
              {t(title)}
            </Heading>
            <Text
              textScale={isDesktop ? "p22Regular" : "p16Regular"}
              color="textFifth"
              mb={{ _: "1rem", laptop: "1.5rem" }}
            >
              {t(description)}
            </Text>
            <Button
              width="100%"
              maxWidth={isDesktop ? "16.5rem" : "21.375rem"}
              scale="lg"
              filledType="bordered"
              as="a"
              href={href}
              external={isExternal}
              onClick={() => {
                handleClickLink(link);
              }}
            >
              {t(btnLabel)}
            </Button>
          </StyledInfoWrapper>
        ))}
      </Flex>
    </Container>
  );
};

export default ExtraInfo;
