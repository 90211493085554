export const INTERVIEW_LIST = [
  {
    name: "Steven Clark",
    bio: "Trader, USA, 34 y.o.",
    avatar: "/images/steven-clark.webp",
    goals: [
      "Have all possible functions in one application",
      "Have the strongest protection system because he keeps large sums of money on this application",
    ],
    painPoints: [
      "Tired of all his current services that he uses and now he wants to find new ones with new improved features",
      "Spends a lot of money on all sorts of training courses so the design should look as trustworthy as possible",
    ],
    mainConclusions: ["He wants to have the most reliable and functional service for full-fledged work"],
  },
  {
    name: "Mark Farlow",
    bio: "Student, Australia, 22 y.o.",
    avatar: "/images/mark-farlow.webp",
    goals: [
      "Start trading on crypto exchanges quickly and safely",
      "Have clear and understandable information about all assets, preferably in a more illustrated format",
    ],
    painPoints: [
      "Has a lot of similar services but doesn’t know how to chose the suitable among them",
      "All previously used and services turned out to be either too complex or with too few functions ",
    ],
    mainConclusions: [
      "Has a lot of similar services but don’t know what to chose",
      "The new app should have several key features such as quick asset statistics and an asset allocation map",
    ],
  },
];

export const INTERVIEW_POINTS_TITLES = {
  goals: "Goals",
  painPoints: "Pain points",
  mainConclusions: "Main conclusions",
};
