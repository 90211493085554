import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledUgreatorTryAppParallaxImageWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 5.25rem;
  position: absolute;
  top: 5.25rem;
  left: 1.375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 5.75rem;
    top: 10rem;
    left: 7.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 6.25rem;
    left: -3.125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    top: 8.75rem;
    left: -2.25rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 11.25rem;
  position: absolute;
  top: 13.75rem;
  left: 0.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 12.5rem;
    top: 28.125rem;
    left: 6rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 24.5rem;
    left: -4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    top: 26.25rem;
    left: -3.75rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  display: none;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10.625rem;
    display: block;
    top: -1rem;
    right: -1.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 0;
    right: -2.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    top: 4rem;
    right: -1.875rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  display: none;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 4rem;
    display: block;
    top: 16.875rem;
    right: -1.125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 18.125rem;
    right: -4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    top: 20.625rem;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  display: none;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 6.25rem;
    display: block;
    top: 26.75rem;
    right: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 28.625rem;
    right: 2rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    top: 34.75rem;
    right: 3.75rem;
  }
`;
