import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 516 114" animationType="findSolutionArrow" {...props} color="transparent">
      <path
        d="M0.5 1C24 40 85.9 108.5 145.5 70.5C162 60 159 52 148.5 53C138 54 146 89.5 176 108C200 122.8 242 101.5 260 89C331 39.3333 480.4 -31.2 510 84M510 84L515.5 76M510 84L500.5 80"
        stroke={theme.colors[color]}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
