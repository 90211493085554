import React, { useRef } from "react";
import { useInView } from "framer-motion";
// Components + styling
import { BackgroundImageWireframe } from "..";
import { StyledSingleLayerWrapper, StyledSingleLayer, StyledSingleLayerMotion } from "./styled";
// Hooks
import { useMatchBreakpoints, useScrollVelocity } from "hooks";
// Types
import { BackgroundLayerProps } from "./types";

const EStatesWireframeBackgroundLayer: React.FC<BackgroundLayerProps> = ({
  positionTop,
  velocityMotion,
  imgWidth,
  imgHeight,
  backgroundSize,
  imgSrc,
}) => {
  const { isDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const isInView = useInView(wrapperRef);

  const velocityMotionBackground = useScrollVelocity({ ...velocityMotion, isInView });

  const IMG_WIREFRAME_BG = Array.from({ length: 10 }, (_, i) => i);

  return (
    <StyledSingleLayerWrapper ref={wrapperRef} top={isDesktop ? positionTop.desktop : positionTop.mobile}>
      <StyledSingleLayer>
        <StyledSingleLayerMotion style={{ x: velocityMotionBackground.x }}>
          {IMG_WIREFRAME_BG.map(i => (
            <BackgroundImageWireframe
              key={i}
              width={imgWidth}
              height={imgHeight}
              backgroundSize={isDesktop ? backgroundSize.desktop : backgroundSize.mobile}
              src={imgSrc}
            />
          ))}
        </StyledSingleLayerMotion>
      </StyledSingleLayer>
    </StyledSingleLayerWrapper>
  );
};

export default EStatesWireframeBackgroundLayer;
