import React from "react";
// Components + styling
import { Flex } from "components";
import { FooterAbout, FooterContacts, FooterCopyrights, FooterDescription, FooterSocial } from "./components";
import { StyledFooterWrapper, StyledFooterContainer } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";

const Footer: React.FC = () => {
  const { isMobile, isDesktop } = useMatchBreakpoints();

  return (
    <StyledFooterWrapper>
      <StyledFooterContainer id="footer" pt={{ _: "5rem", tablet: "6.5rem" }} pb={{ _: "2.25rem", tablet: "5.75rem" }}>
        <Flex flexDirection={isDesktop ? "row" : "column"} mb="1.81rem">
          <FooterDescription />

          <Flex
            width={{ _: "100%", laptop: "50%" }}
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
          >
            <Flex width={{ _: "100%", tablet: "66%" }}>
              <FooterAbout />

              <FooterSocial />
            </Flex>

            <FooterContacts />
          </Flex>
        </Flex>

        <FooterCopyrights />
      </StyledFooterContainer>
    </StyledFooterWrapper>
  );
};

export default Footer;
