import { useRef } from "react";
import { useInView } from "framer-motion";
// Components + styling
import { Box, Heading, Text } from "components";
import { ProjectPhasesConnectorLineIcon } from "components/svg";
import {
  StyledDesktopConnectorImage,
  StyledDesktopConnectorImageWrapper,
  StyledProjectPhaseItemMotion,
  StyledProjectPhaseItemWrapper,
  StyledProjectPhasesList,
} from "./styled";
// Animations
import { animatePhase } from "theme/animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { PROJECT_PHASES_LIST } from "../../constants";

const ProjectPhasesList: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const phasesListRef = useRef<HTMLDivElement | null>(null);
  const isPhasesListInView = useInView(phasesListRef, { once: true, amount: 1 });

  return (
    <StyledProjectPhasesList ref={phasesListRef}>
      {PROJECT_PHASES_LIST.map(({ step, title, description, bgSrc }, index) => {
        const animatedProjectPhases = animatePhase(index, isDesktop);

        return (
          <StyledProjectPhaseItemWrapper key={step}>
            <StyledProjectPhaseItemMotion
              bgSrc={bgSrc}
              initial="hidden"
              animate={isDesktop ? (isPhasesListInView ? "visible" : "hidden") : ""}
              whileInView={!isDesktop ? "visible" : ""}
              viewport={!isDesktop ? { once: true, amount: 0.3 } : {}}
              variants={animatedProjectPhases}
            >
              <Box width="fit-content" mx={{ _: "0.5rem", laptop: "0" }} pb="2.625rem">
                <Heading scale="h3" as="h3" mb={{ _: "0.25rem", laptop: "0" }}>
                  {t(title)}
                </Heading>
                <Text textScale="p18Regular" color="textFifth" minHeight={{ _: "3.75rem", tablet: "initial" }}>
                  {t(description)}
                </Text>
              </Box>
            </StyledProjectPhaseItemMotion>
          </StyledProjectPhaseItemWrapper>
        );
      })}

      {isDesktop && isPhasesListInView && (
        <>
          <StyledDesktopConnectorImageWrapper>
            <ProjectPhasesConnectorLineIcon width="100%" />
          </StyledDesktopConnectorImageWrapper>
          <StyledDesktopConnectorImage src="/images/project-phases-connector-points.svg" aspectRatio={2.78} />
        </>
      )}
    </StyledProjectPhasesList>
  );
};

export default ProjectPhasesList;
