import React from "react";
// Components + styling
import { StyledIreneMalchenkoAnimation } from "./styled";
// Animations
import ireneEars from "assets/animations/irene-ears.json";
// Types
import { SliderAnimationProps } from "../types";

const IreneMalchenkoAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref }) => {
  return (
    <>
      <StyledIreneMalchenkoAnimation lottieRef={lottieAnimation1Ref} animationData={ireneEars} autoplay={false} loop />
    </>
  );
};

export default IreneMalchenkoAnimation;
