import React from "react";
// Components + Styling;
import { Flex, Box, Text } from "components";
import { StarIcon } from "components/svg";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { RatingStarsProps } from "./types";

const RatingStars: React.FC<RatingStarsProps> = ({ rating, fillColor, width = "1rem", isHideTextRating = false }) => {
  const { isDesktop } = useMatchBreakpoints();

  const starList = Array.from(Array(Math.round(rating)));

  return (
    <Flex alignItems="center">
      {starList.map((_, index) => (
        <Box key={index}>
          <StarIcon key={index} width={width} color={fillColor} />
        </Box>
      ))}
      {!isHideTextRating && (
        <Text textScale={isDesktop ? "p18Regular" : "p12Medium"} $fontWeight="medium" mx="0.5rem">
          {rating.toFixed(1)}
        </Text>
      )}
    </Flex>
  );
};

export default RatingStars;
