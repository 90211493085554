import React from "react";
// Components + styling
import { AnimatedCharacterText, Flex, Link, Text } from "components";
import {
  StyledTeamMemberItem,
  StyledImageLottieAnimationWrapper,
  StyledLinkedInLinkWrapper,
  StyledTeamMemberContent,
  StyledTeamMemberImage,
  StyledLinkedInText,
  StyledLinkedInIcon,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useLottieAnimation, useMatchBreakpoints } from "hooks";
// Types
import { TeamMemberItemProps } from "./types";

const TeamMemberItem: React.FC<TeamMemberItemProps> = ({
  image,
  name,
  position,
  description,
  hoverColor,
  ImageLottieAnimation,
  index,
  linkedInLink,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const {
    controls,
    lottieAnimation1Ref,
    lottieAnimation2Ref,
    isActive,
    isImageLoading,
    setIsImageLoading,
    handleShowAnimations,
    handleHideAnimations,
  } = useLottieAnimation();

  return (
    <StyledTeamMemberItem
      onMouseEnter={() => {
        handleShowAnimations();
      }}
      onMouseLeave={() => {
        handleHideAnimations();
      }}
    >
      <StyledTeamMemberContent
        mb={{ _: "0.5rem", laptop: "1.5rem" }}
        hoverColor={hoverColor}
        isImageLoading={isImageLoading}
      >
        <StyledTeamMemberImage
          width="100%"
          src={image}
          aspectRatio={index % 2 ? 0.93 : 0.832}
          setExternalLoading={setIsImageLoading}
        />
        <StyledImageLottieAnimationWrapper>
          <ImageLottieAnimation lottieAnimation1Ref={lottieAnimation1Ref} lottieAnimation2Ref={lottieAnimation2Ref} />
        </StyledImageLottieAnimationWrapper>
      </StyledTeamMemberContent>

      <StyledTeamMemberContent>
        <Text textScale={isDesktop ? "p24Bold" : "p16Bold"} mb={{ _: "0.25rem", laptop: "0.5rem" }}>
          {t(name)}
        </Text>
        <Flex flexDirection={{ _: "column", tablet: "row" }} alignItems="flex-start">
          <Text textScale={isDesktop ? "p20Regular" : "p14Regular"} mb={{ _: "0.25rem", laptop: "0.5rem" }}>
            {t(position)}
          </Text>

          {linkedInLink && (
            <Link external href={linkedInLink} color="linkedInLink">
              <StyledLinkedInLinkWrapper>
                <StyledLinkedInIcon color="linkedInLink" />
                <StyledLinkedInText textScale={isDesktop ? "p20Regular" : "p14Regular"}>
                  {t("LinkedIn Profile")}
                </StyledLinkedInText>
              </StyledLinkedInLinkWrapper>
            </Link>
          )}
        </Flex>

        <Text textScale={isDesktop ? "decorative22Light" : "decorative14Light"} color="textFifth">
          {isDesktop ? (
            <AnimatedCharacterText text={t(description)} initial={isActive ? "visible" : "hidden"} animate={controls} />
          ) : (
            <>{t(description)}</>
          )}
        </Text>
      </StyledTeamMemberContent>
    </StyledTeamMemberItem>
  );
};

export default TeamMemberItem;
