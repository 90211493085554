import { useScroll, useTransform } from "framer-motion";

const useTitleMotions = (ref: React.MutableRefObject<HTMLParagraphElement | null>, isAnimationActive: boolean) => {
  const { scrollYProgress } = useScroll({ target: ref, offset: ["start end", "start center"] });

  const startAnimationY = isAnimationActive ? 50 : 0;
  const startAnimationOpacity = isAnimationActive ? 0 : 1;

  const yTitle = useTransform(scrollYProgress, [0, 1], [startAnimationY, 0]);
  const opacityTitle = useTransform(scrollYProgress, [0, 1], [startAnimationOpacity, 1]);

  return { yTitle, opacityTitle };
};

export default useTitleMotions;
