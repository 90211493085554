import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { StyledArrowLeftIcon } from "../case-studies-filter/components/filter-dropdown/styled";

export const CaseStudiesMobileWrapper = styled(motion.div)<{ offsetHeight: number }>`
  position: fixed;

  top: ${({ offsetHeight }) => offsetHeight - 1}px;

  left: 0;
  width: 100%;
  height: -webkit-fill-available !important;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndices.tooltip};
  overflow: scroll;
`;

export const CaseStudiesMobileContent = styled.div`
  width: 100%;
  min-height: 100%;
  max-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  background: ${({ theme }) => theme.colors.white};
  padding: 1rem;
  padding-top: 2.625rem;

  overflow-y: auto;
`;

export const StyledArrowLeftIconMobile = styled(StyledArrowLeftIcon)`
  transform: rotate(90deg);
`;

export const StyledCaseStudiesMobileFilterButton = styled.div`
  margin-top: 1.5rem;
`;
