import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// Components + styling
import { ClickItem } from "components";
import { FullLogoIcon, LogoMobileIcon } from "components/svg";
// Animations
import { slideVariants } from "components/header/animations";
// Hooks
import { useMatchBreakpoints } from "hooks";
import { useHeaderTheme } from "components/header/hooks";
// Constants
import { ROUTES } from "navigation/routes";
import { MOBILE_MENU_ANIMATION_DURATION, headerThemes } from "components/header/constants";

export const HeaderLogo: React.FC<{ isMobileMenuOpen: boolean; setIsMenuOpen: (arg: boolean) => void }> = ({
  isMobileMenuOpen,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const { isMobileS, isDesktop, isLargeDesktop } = useMatchBreakpoints();
  const { headerTheme } = useHeaderTheme();

  const logoColor = headerTheme === headerThemes.light && !isMobileMenuOpen ? "bgDark" : "white";

  const handleClickLogo = () => {
    setIsMenuOpen(false);

    if (pathname === ROUTES.landing && !hash) {
      // Need to use timeout, because of mobiles menu. For correct logic working, we need to wait closing mobile menu. and then scroll to element.
      setTimeout(
        () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        },
        isLargeDesktop ? 0 : MOBILE_MENU_ANIMATION_DURATION,
      );
    } else {
      navigate(ROUTES.landing);
    }
  };

  return (
    <ClickItem maxHeight={{ _: "1.25rem", laptopL: "1.75rem" }} cursor="pointer" onClick={handleClickLogo}>
      {isDesktop ? (
        <FullLogoIcon color={logoColor} width="15.06rem" />
      ) : (
        <>
          {isMobileS ? (
            <LogoMobileIcon color={logoColor} width="5.5rem" />
          ) : (
            <>
              <motion.div initial="hidden" animate={isMobileMenuOpen ? "visible" : "hidden"} variants={slideVariants}>
                <FullLogoIcon color={logoColor} width="11.125rem" />
              </motion.div>
              <motion.div initial="hidden" animate={!isMobileMenuOpen ? "visible" : "hidden"} variants={slideVariants}>
                <LogoMobileIcon color={logoColor} width="5.5rem" />
              </motion.div>
            </>
          )}
        </>
      )}
    </ClickItem>
  );
};
