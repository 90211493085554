import { useQuery } from "@apollo/client";
// Types
import { GetCaseStudiesFiltersResponse, getCaseStudiesFilters } from "services";
import { Filters } from "context/case-studies-context/types";

const useGetCaseStudiesFilters = () => {
  const { data, loading } = useQuery<GetCaseStudiesFiltersResponse>(getCaseStudiesFilters);

  const { categories: category = [], industries: industry = [] } = data || {};

  const filters: Filters | undefined = React.useMemo(() => {
    if (!loading && data) {
      return {
        industry: {
          name: "Industry",
          options: industry,
        },
        category: {
          name: "Category",
          options: category,
        },
      };
    }
  }, [loading]);

  return { isLoading: loading, filters };
};

export default useGetCaseStudiesFilters;
