export enum CustomAnalyticsEventsList {
  FORM_FIELD_INTERACTION = "formFieldInteraction",
  FORM_SUBMISSION_SUCCESS = "formSubmissionSuccess",
}

export enum ConsentAnalyticsSetUp {
  CONSENT = "consent",
  UPDATE = "update",
  GRANTED = "granted",
}

export const customAnalyticsEvent = (eventName: string, eventPayload: object | undefined) => {
  const dataLayer = window.dataLayer || [];

  if (dataLayer) {
    dataLayer.push({ event: eventName, ...eventPayload });
  }
};

export const consentAnalytics = () => {
  const gtag = window.gtag;

  if (gtag) {
    gtag(ConsentAnalyticsSetUp.CONSENT, ConsentAnalyticsSetUp.UPDATE, {
      ad_storage: ConsentAnalyticsSetUp.GRANTED,
      analytics_storage: ConsentAnalyticsSetUp.GRANTED,
      ad_user_data: ConsentAnalyticsSetUp.GRANTED,
      ad_personalization: ConsentAnalyticsSetUp.GRANTED,
    });
  }
};
