// Types
type ImageItem = {
  src: string;
  srcHover: string;
};

type generateRepeatedArrayProps = {
  sourceArray: ImageItem[];
  repeatCount: number;
};

export const generateRepeatedArray = ({ sourceArray, repeatCount }: generateRepeatedArrayProps) => {
  return Array.from({ length: repeatCount }, () => [...sourceArray]).flat() as ImageItem[];
};
