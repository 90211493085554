import React, { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
// Components + Styling;
import { Box, InputGroup, Form, Input, Button, Flex } from "components";
import { SearchIcon } from "components/svg";
import { FindSolutionSearchResults } from "./components";
import { getTooltipStyles } from "./theme";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useForm, useMatchBreakpoints, useTooltip } from "hooks";
import { useSearchResults, useValidationSchema } from "./hooks";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { ArticleNavigationState } from "./types";

const FindSolutionForm: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();
  const { theme } = useThemeContext();

  const { validationSchema, initialValues } = useValidationSchema();
  const { values, isValid, fieldProps, handleSubmit, setFieldValue, setFieldTouched } = useForm({
    initialValues,
    validationSchema,
    onSubmit() {
      submitHandler();
    },
  });

  const { pending, searchValue, searchResults } = useSearchResults(values, toggleVisible);

  const handleSearchResultClick = async (searchResult: string) => {
    const state: ArticleNavigationState = { searchValue, searchResult };

    navigate(`/${ROUTES.findSolution}`, { state });
    toggleVisible(false);
    await resetForm();
  };

  const { targetRef, tooltip, setVisible } = useTooltip(
    values.findSolution ? (
      <FindSolutionSearchResults searchResults={searchResults} onSearchResultClick={handleSearchResultClick} />
    ) : (
      ""
    ),
    {
      placement: "bottom",
      trigger: "focus",
      customStyles: getTooltipStyles(theme),
    },
  );

  const submitHandler = async () => {
    navigate(`/${ROUTES.findSolution}`, { state: { searchValue: values.findSolution } });
    await resetForm();
    toggleVisible(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setFieldValue("findSolution", e.target.value);

    if (!value) {
      toggleVisible(false);
    }
  };

  const resetForm = async () => {
    await setFieldValue("findSolution", "");
    await setFieldTouched("findSolution", true);
  };

  function toggleVisible(value: boolean) {
    setVisible(value);
  }

  return (
    <Form width="100%" onSubmit={handleSubmit}>
      <Flex flexDirection={isMobile ? "column" : "row"} justifyContent="center" alignItems="flex-start">
        <Box width="100%" maxWidth={isMobile ? "100%" : "32.5rem"}>
          <InputGroup scale="lg" startIcon={<SearchIcon color="textFifth" />} isShowError={false} isShowInputLines>
            <Box width="100%">
              <Input
                ref={targetRef}
                autoComplete="off"
                {...fieldProps("findSolution")}
                placeholder={t("Type something")}
                backgroundColor="white"
                disabled={isLoading}
                isFullyWrapBorder
                onChange={handleInputChange}
              />
              {tooltip}
            </Box>
          </InputGroup>
        </Box>

        <Box
          width="100%"
          maxWidth={isMobile ? "100%" : "6.375rem"}
          mx={{ _: "auto", tablet: "0.5rem" }}
          px={{ _: "0.5rem", tablet: "0" }}
        >
          <Button
            width="100%"
            minWidth="unset"
            scale="lg"
            isShowHoverLines={false}
            disabled={!isValid || !searchResults.length}
            isLoading={pending || isLoading}
            type="submit"
          >
            {t("Find")}
          </Button>
        </Box>
      </Flex>
    </Form>
  );
};

export default FindSolutionForm;
