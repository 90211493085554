// Types
import { DefaultTheme } from "styled-components";
import { TooltipOptions } from "hooks/use-tooltip/types";

export const getTooltipStyles = (theme: DefaultTheme): TooltipOptions["customStyles"] => {
  return {
    tooltip: {
      display: "flex",
      flexWrap: "wrap",
      padding: "1.8rem 0 0",
      border: "none",
      boxShadow: "none",
      background: `${theme.colors.transparent}`,
    },
    arrow: {
      display: "none",
    },
  };
};
