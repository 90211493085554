import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 100 100" {...props} fill="none">
      <g clipPath="url(#clip0_7246_3185)">
        <rect opacity="0.3" width="100" height="100" fill={theme.colors[color]} />
        <path
          opacity="0.8"
          d="M72.5063 53.7153L38.5751 73.4032C35.6955 75.0723 32 73.0513 32 69.6864V30.3106C32 26.9511 35.6901 24.9247 38.5751 26.5991L72.5063 46.287C73.1614 46.661 73.7059 47.2015 74.0846 47.8538C74.4633 48.5061 74.6628 49.2469 74.6628 50.0012C74.6628 50.7554 74.4633 51.4963 74.0846 52.1486C73.7059 52.8008 73.1614 53.3414 72.5063 53.7153Z"
          fill={theme.colors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_7246_3185">
          <rect width="100" height="100" rx="50" fill={theme.colors[color]} />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
