import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box } from "components";

export const StyledOpenPositionItemWrapper = styled(Box)`
  height: 100%;

  &::after {
    ${clearfix}
    width: 100%;
    /* bg-image ratio width/height is 19.16 */
    height: calc(100vw / 19.16);
    margin: 1.5rem 0;
    background: url("/images/wave-services.svg") no-repeat center center;
    background-size: 100% auto;

    ${({ theme }) => theme.mediaQueries.tablet} {
      display: none;
    }
  }

  &:nth-last-of-type(1) {
    &::after {
      display: none;
    }
  }
`;
