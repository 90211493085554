import React from "react";
// Components + styling
import { Accordion, AgreementsContactDetails, Box, Container, Heading, Page, Text } from "components";
import { CollectionInformation, SharingInformation, DataProtection } from "./components";
import { StyledAccordionWrapper, StyledPrivacyPolicyLink } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";
import { PRIVACY_POLICY_DEFAULTS_LIST } from "./constants";

const PrivacyPolicyPage: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Page
      maxWidth="100vw"
      px="0"
      pt={{ _: `calc(${headerHeight}px + 2rem)`, laptop: `calc(${headerHeight}px + 4rem)` }}
    >
      <Container>
        <Heading scale="h2" as="h2" textAlign="center" mb="1.375rem">
          {t("Privacy Policy")}
        </Heading>
        <Text textScale={isDesktop ? "p22Regular" : "p16Light"} textAlign="center" mb={{ _: "2rem", laptop: "2.5rem" }}>
          {t(
            "IDEALOGIC DESIGN is part of IDEALOGIC DEVELOPMENT OÜ. IDEALOGIC DESIGN OÜ together with its subsidiaries (“IDEALOGIC DESIGN”) has created this Privacy Policy in line with IDEALOGIC DESIGN’s commitment to your privacy on IDEALOGIC DESIGN WEBSITE and marketing initiatives. The following discloses IDEALOGIC DESIGN’s information gathering, dissemination and protection practices",
          )}
        </Text>

        {PRIVACY_POLICY_DEFAULTS_LIST.map(({ label, description }) => (
          <Box key={label}>
            <StyledAccordionWrapper>
              <Accordion title={t(label)} $backgroundColor="white">
                <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" pb="1.5rem">
                  {t(description)}
                  {PRIVACY_POLICY_DEFAULTS_LIST[4].label === label && (
                    <StyledPrivacyPolicyLink
                      href="mailto:legal@idealogic.design"
                      textScale={isDesktop ? "p18Regular" : "p14Regular"}
                    >
                      legal@idealogic.design
                    </StyledPrivacyPolicyLink>
                  )}
                </Text>
              </Accordion>
            </StyledAccordionWrapper>

            {PRIVACY_POLICY_DEFAULTS_LIST[0].label === label && (
              <>
                <StyledAccordionWrapper>
                  <CollectionInformation />
                </StyledAccordionWrapper>
                <StyledAccordionWrapper>
                  <SharingInformation />
                </StyledAccordionWrapper>
              </>
            )}

            {PRIVACY_POLICY_DEFAULTS_LIST[5].label === label && (
              <StyledAccordionWrapper>
                <DataProtection />
              </StyledAccordionWrapper>
            )}
          </Box>
        ))}
      </Container>

      <AgreementsContactDetails isShowDescription />
    </Page>
  );
};

export default PrivacyPolicyPage;
