// Components + styled
import { Button, Dropdown, FlexGap, Skeleton, Text } from "components";
import { CaseStudiesDropdown } from "..";
import { StyledArrowLeftIcon, StyledFilterDropdown, StyledFilterDropdownWrapper } from "./styled";
// Context
import { useCaseStudyContext, useTranslation } from "context";
//Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CaseStudiesFilterType } from "context/case-studies-context/types";
import { CaseStudiesDropdownProps } from "../case-studies-dropdown/types";
import { CaseStudiesFilterDropdownProps } from "./types";

const CaseStudiesFilterDropdown: React.FC<CaseStudiesFilterDropdownProps> = ({
  hasSomeFiltersSelected,
  filters,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { resetAllFilters } = useCaseStudyContext();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledFilterDropdownWrapper hasSomeFiltersSelected={hasSomeFiltersSelected}>
      {isLoading ? (
        <FlexGap gap="1.5rem" mt="1.5rem">
          {Array.from({ length: 2 }).map((_, index) => (
            <Skeleton key={index} width="6.5rem" height="1.5rem" />
          ))}
        </FlexGap>
      ) : filters ? (
        Object.keys(filters).map(filterType => {
          const type = filterType as CaseStudiesFilterType;
          return (
            <div key={type}>
              <Dropdown<CaseStudiesDropdownProps>
                title={
                  <StyledFilterDropdown>
                    <Text mx="0.25rem" textScale="p16Regular">
                      {t(filters[type].name)}
                    </Text>
                    <StyledArrowLeftIcon />
                  </StyledFilterDropdown>
                }
                dropdownComponent={CaseStudiesDropdown}
                dropdownProps={{
                  type,
                  hasSomeFiltersSelected,
                  isLoading,
                  filters,
                }}
                placement="bottom-start"
              />
            </div>
          );
        })
      ) : null}
      {hasSomeFiltersSelected && isDesktop && (
        <Button
          minWidth="4.5rem"
          scale="sm"
          variant="secondary"
          filledType="bordered"
          onClick={resetAllFilters}
          bgColor="textMain"
          color="textMain"
        >
          {t("Clear all")}
        </Button>
      )}
    </StyledFilterDropdownWrapper>
  );
};

export default CaseStudiesFilterDropdown;
