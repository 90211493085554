import React from "react";
import { useNavigate } from "react-router-dom";
// Components + styling
import { StyledNavList, StyledNavItem } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { NAV_LIST, headerThemes } from "components/header/constants";
// Types
import { NavigationMenuProps } from "./types";
import { useHeaderTheme } from "components/header/hooks";

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ setIsMenuOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLargeDesktop } = useMatchBreakpoints();
  const { headerTheme } = useHeaderTheme();

  const handleClickLink = (link: string) => {
    if (!isLargeDesktop) {
      setIsMenuOpen(false);
    }

    navigate(link);
  };

  return (
    <StyledNavList>
      {NAV_LIST.map(({ label, link }) => (
        <StyledNavItem
          href={link}
          key={label}
          textScale={isLargeDesktop ? "p16Regular" : "p24Regular"}
          color={headerTheme === headerThemes.light ? "bgDark" : isLargeDesktop ? "white" : "textSecond"}
          onClick={() => handleClickLink(link)}
          underline
        >
          {t(label)}
        </StyledNavItem>
      ))}
    </StyledNavList>
  );
};
