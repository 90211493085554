import React from "react";
// Components
import { Flex, Column, Box, Container, Text, Heading } from "components";
import { StyledBenefitsImage, StyledContentWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { CAREERS_BENEFITS_LIST } from "./constants";

const CareersBenefits: React.FC = () => {
  const { t } = useTranslation();
  const { isLargeDesktop, isDesktop } = useMatchBreakpoints();

  return (
    <Container py={{ _: "2.5rem", laptop: "6.25rem" }}>
      <Flex flexDirection={isDesktop ? "row" : "column"}>
        {!isDesktop && (
          <>
            <Heading scale="h2" as="h2" mb="0.25rem">
              {t("With us you will achieve:")}
            </Heading>
            <Text textScale="p16Regular" mb="1rem">
              {t("We invest in our colleagues, their expertise and wellbeing")}
            </Text>
          </>
        )}
        <StyledBenefitsImage
          width="100%"
          src={`/images/${isDesktop ? "careers-benefits" : "careers-benefits-sm"}.webp`}
          aspectRatio={isDesktop ? 0.81 : 1.3}
        />

        <StyledContentWrapper p={{ _: "0", laptop: "1.875rem 0 0 1.875rem", laptopL: "2.5rem 0 0 3.25rem" }}>
          {isDesktop && (
            <>
              <Heading scale={isLargeDesktop ? "h2" : "h4"} as="h2" mb="1rem" $fontWeight="bold">
                {t("With us you will achieve:")}
              </Heading>
              <Text textScale={isLargeDesktop ? "p22Regular" : "p16Regular"} mb="1.5rem">
                {t("We invest in our colleagues, their expertise and wellbeing")}
              </Text>
            </>
          )}
          {CAREERS_BENEFITS_LIST.map(({ Icon, title, description }) => (
            <Flex key={title} alignItems="flex-start" mb={{ _: "1.5rem", laptopL: "2.5rem" }}>
              <Box>
                <Icon width={isLargeDesktop ? "3.5rem" : "3rem"} />
              </Box>
              <Column mx={{ _: "1rem", laptopL: "1.5rem" }}>
                <Text
                  textScale={isLargeDesktop ? "p24Bold" : isDesktop ? "p20Bold" : "p16Bold"}
                  mb={{ _: "0.25rem", laptop: "0.5rem" }}
                >
                  {t(title)}
                </Text>
                <Text
                  textScale={isLargeDesktop ? "p18Regular" : isDesktop ? "p16Regular" : "p14Regular"}
                  color="textFifth"
                >
                  {t(description)}
                </Text>
              </Column>
            </Flex>
          ))}
        </StyledContentWrapper>
      </Flex>
    </Container>
  );
};

export default CareersBenefits;
