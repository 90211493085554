import styled, { css } from "styled-components";
import Slider from "react-slick";
import { clearfix } from "styles";
// Components
import { Box, Flex, Column, Text, Image, Svg } from "components";
// Types
import { Gradients } from "theme/types";

interface StyledSlideImageWrapperProps {
  bgGradient: keyof Gradients;
}

const slideImageStyles = (bgGradient: keyof Gradients) => css`
  display: block;
  max-width: 15.625rem;
  max-height: 21.0625rem;
  margin: ${bgGradient === "testimonialBg3" ? "0" : "auto 0 0"};

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 13.75rem;
    max-height: 21.875rem;
    margin: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 22.5rem;
    max-height: 28.125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    max-width: 35.875rem;
    max-height: 34.875rem;
  }
`;

export const StyledTestimonialsSliderWrapper = styled(Box)`
  position: relative;

  .slick-initialized .slick-slide {
    display: flex;
    /* Need to use !important because React Slick lib is overwrite styles for this component with inline styles */
    height: auto !important;
    margin: 0 auto !important;

    > div {
      display: flex;
    }
  }

  .slick-slide {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
    transform: scale(0.7, 0.7);
  }

  .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"],
  .slick-slide.slick-current.slick-active {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
    transform: scale(1);
  }

  .slick-slide,
  .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
  }
  /* slide when active/center */
  .slick-center,
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
    transform: scale(1);
  }
`;

export const StyledTestimonialsSlider = styled(Slider)`
  .slick-track {
    display: flex;
  }
`;

export const StyledContainerInSlider = styled(Column)`
  /* Need to use display: flex !important because React Slick lib is overwrite styles for this component with inline styles */
  display: flex !important;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.large};
  background: ${({ theme }) => theme.colors.bgSecond};
`;

export const StyledTestimonialsSlide = styled(Column)`
  flex-grow: 1;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
  }
`;

export const StyledSlideImageWrapper = styled(Flex)<StyledSlideImageWrapperProps>`
  width: 100%;
  height: 21.5rem;
  justify-content: center;
  align-items: center;
  padding: ${({ bgGradient }) => (bgGradient === "testimonialBg3" ? "3.875rem 2.5rem" : "3.875rem")};
  border-radius: ${({ theme }) => theme.radii.testimonialsInnerWrapper}
    ${({ theme }) => theme.radii.testimonialsInnerWrapper} 0 0;
  background: ${({ theme, bgGradient }) => theme.gradients[bgGradient]};
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(40% - 0.75rem);
    height: auto;
    padding: 2.75rem;
    border-radius: ${({ theme }) => theme.radii.testimonialsInnerWrapper} 0 0
      ${({ theme }) => theme.radii.testimonialsInnerWrapper};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% - 0.75rem);
    padding: 4rem;
  }
`;

export const StyledSlideImage = styled(Image)<StyledSlideImageWrapperProps>`
  ${({ bgGradient }) => slideImageStyles(bgGradient)};

  img {
    ${({ bgGradient }) => slideImageStyles(bgGradient)}
  }
`;

export const StyledSlideDescription = styled(Box)`
  width: 100%;
  padding: 1rem 1.5rem 6.25rem;
  border-radius: 0 0 ${({ theme }) => theme.radii.large} ${({ theme }) => theme.radii.large};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(60% - 0.75rem);
    border-radius: 0 ${({ theme }) => theme.radii.large} ${({ theme }) => theme.radii.large} 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% + 0.75rem);
    padding: 2.5rem 2.5rem 9.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 3.125rem 4.1875rem 9.375rem 4.1875rem;
  }
`;

export const StyledSliderCompany = styled(Text)`
  margin-bottom: 1.875rem;

  &::before {
    ${clearfix}
    width: 100%;
    height: 1px;
    margin-bottom: 0.75rem;
    background: ${({ theme }) => theme.colors.lineLight};
  }
`;

export const StyledSliderRating = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;

  ${Svg} {
    width: auto;
  }
`;

export const SliderNavButtonsWrapper = styled(Box)`
  position: absolute;
  top: calc(100% - 3.5rem - 1.5rem);
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: calc(100% - 3.5rem - 2.75rem);
    left: calc(50% + 2.5rem);
    transform: none;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: calc(100% - 3.5rem - 2.75rem);
    left: calc(50% + 3.125rem);
  }
`;
