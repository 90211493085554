import styled from "styled-components";
// Components
import { Box } from "components";

export const StyledWorkflowWrapper = styled(Box)`
  background: url("/images/workflow-bg.webp") no-repeat center;
  background-size: contain;

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-position: -2.9375rem 19.25rem;
    background-size: 50% auto;
  }
`;

export const StyledWorkflowTitleWrapper = styled(Box)`
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    align-self: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 9.75rem;
    margin-bottom: 0;
    padding-right: 2rem;
  }
`;

export const StyledWorkflowLine = styled(Box)`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0.625rem;
`;
