import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  return (
    <Svg viewBox="0 0 335 335" {...props} color={color}>
      <path d="M261.263 238.556C261.971 238.686 262.64 238.616 263.269 238.346C263.906 238.083 264.453 237.635 264.908 237.004C265.364 236.373 265.629 235.696 265.703 234.973C265.78 234.265 265.651 233.568 265.319 232.881C264.988 232.208 264.469 231.616 263.759 231.104C263.05 230.592 262.324 230.286 261.582 230.184C260.833 230.091 260.131 230.19 259.475 230.479C258.821 230.784 258.267 231.251 257.811 231.883C257.174 232.765 256.914 233.682 257.03 234.634C257.145 235.585 257.619 236.433 258.45 237.178L261.052 233.571L262.012 234.263L258.502 239.129L257.593 238.474C256.899 237.868 256.385 237.141 256.049 236.294C255.722 235.453 255.611 234.577 255.716 233.666C255.829 232.761 256.173 231.911 256.747 231.115C257.352 230.276 258.099 229.651 258.988 229.24C259.885 228.835 260.831 228.682 261.825 228.781C262.813 228.889 263.748 229.261 264.63 229.898C265.512 230.534 266.16 231.304 266.574 232.208C266.99 233.126 267.143 234.072 267.033 235.044C266.931 236.024 266.578 236.933 265.972 237.772C265.279 238.732 264.43 239.408 263.424 239.8C262.411 240.2 261.351 240.257 260.242 239.971L261.263 238.556Z" />
      <path d="M249.975 246.905C249.769 246.719 249.562 246.511 249.353 246.281L254.04 241.071C253.363 240.533 252.663 240.298 251.941 240.366C251.221 240.449 250.603 240.777 250.09 241.347C249.669 241.815 249.426 242.3 249.362 242.802C249.299 243.319 249.392 243.805 249.643 244.26L248.594 245.426C248.125 244.688 247.927 243.879 248.002 242.998C248.084 242.126 248.475 241.301 249.174 240.523C249.73 239.905 250.369 239.475 251.088 239.233C251.801 238.999 252.536 238.971 253.293 239.15C254.058 239.337 254.778 239.733 255.452 240.339C256.126 240.946 256.595 241.612 256.86 242.338C257.125 243.065 257.175 243.791 257.009 244.517C256.844 245.259 256.477 245.946 255.906 246.581C255.349 247.199 254.722 247.625 254.025 247.858C253.327 248.091 252.626 248.121 251.922 247.947C251.219 247.787 250.57 247.44 249.975 246.905ZM251.207 245.99C251.619 246.361 252.054 246.588 252.512 246.669C252.977 246.758 253.428 246.711 253.864 246.53C254.301 246.363 254.69 246.09 255.033 245.709C255.525 245.162 255.772 244.537 255.772 243.835C255.766 243.14 255.478 242.487 254.91 241.875L251.207 245.99Z" />
      <path d="M248.441 252.364L244.889 248.61C244.595 248.3 244.32 248.142 244.064 248.135C243.815 248.136 243.528 248.29 243.202 248.598L242.424 249.335L241.577 248.44L242.53 247.538C243.119 246.981 243.688 246.699 244.239 246.691C244.79 246.684 245.359 246.99 245.946 247.609L249.499 251.363L250.324 250.582L251.149 251.453L250.324 252.234L251.842 253.838L250.784 254.839L249.266 253.235L247.605 254.808L246.78 253.936L248.441 252.364Z" />
      <path d="M241.716 261.663C241.934 261.492 242.178 261.423 242.448 261.456C242.717 261.489 242.938 261.615 243.108 261.834C243.279 262.052 243.348 262.296 243.315 262.566C243.282 262.835 243.156 263.055 242.938 263.226C242.728 263.39 242.492 263.453 242.231 263.413C241.961 263.38 241.741 263.254 241.57 263.036C241.4 262.817 241.331 262.573 241.364 262.304C241.388 262.041 241.506 261.827 241.716 261.663ZM240.284 260.975L234.885 254.066L236.032 253.17L241.431 260.078L240.284 260.975Z" />
      <path d="M233.771 265.882C232.886 266.477 231.988 266.69 231.076 266.519C230.17 266.357 229.377 265.772 228.699 264.763L225.813 260.475L227.008 259.672L229.777 263.787C230.265 264.512 230.819 264.943 231.438 265.08C232.063 265.225 232.689 265.086 233.318 264.664C233.955 264.235 234.33 263.693 234.442 263.039C234.546 262.391 234.342 261.686 233.83 260.925L231.123 256.903L232.331 256.09L237.226 263.364L236.018 264.177L235.321 263.142C235.333 263.674 235.203 264.179 234.933 264.657C234.654 265.14 234.267 265.548 233.771 265.882Z" />
      <path d="M221.137 271.748L218.797 267.14C218.603 266.76 218.385 266.53 218.141 266.45C217.902 266.38 217.583 266.447 217.183 266.65L216.228 267.135L215.67 266.037L216.839 265.443C217.562 265.075 218.189 264.967 218.719 265.116C219.25 265.265 219.708 265.72 220.095 266.481L222.435 271.088L223.448 270.574L223.992 271.644L222.979 272.158L223.979 274.127L222.681 274.786L221.681 272.818L219.641 273.854L219.097 272.784L221.137 271.748Z" />
      <path d="M209.869 268.477C210.628 268.163 211.389 268.049 212.153 268.137C212.907 268.228 213.592 268.516 214.208 268.999C214.819 269.497 215.297 270.164 215.645 271.002C215.988 271.83 216.113 272.632 216.019 273.41C215.92 274.202 215.63 274.894 215.148 275.486C214.67 276.088 214.047 276.549 213.278 276.867C212.51 277.186 211.744 277.301 210.98 277.214C210.22 277.136 209.532 276.856 208.916 276.372C208.29 275.893 207.804 275.234 207.456 274.397C207.109 273.559 206.99 272.742 207.099 271.947C207.202 271.165 207.497 270.471 207.985 269.865C208.473 269.258 209.101 268.796 209.869 268.477ZM210.36 269.66C209.877 269.86 209.471 270.161 209.141 270.563C208.811 270.965 208.612 271.446 208.544 272.006C208.466 272.569 208.561 273.176 208.831 273.827C209.101 274.477 209.457 274.976 209.902 275.323C210.346 275.67 210.82 275.866 211.323 275.911C211.831 275.966 212.326 275.894 212.809 275.693C213.302 275.489 213.708 275.188 214.028 274.79C214.342 274.406 214.528 273.936 214.587 273.38C214.645 272.825 214.54 272.222 214.27 271.572C213.996 270.912 213.647 270.404 213.223 270.049C212.789 269.698 212.323 269.492 211.825 269.433C211.331 269.384 210.842 269.459 210.36 269.66Z" />
      <path d="M198.865 281.722L196.289 273.341L197.681 272.913L198.062 274.152C198.195 273.642 198.464 273.192 198.868 272.8C199.265 272.421 199.749 272.144 200.32 271.968C200.972 271.768 201.599 271.72 202.201 271.825C202.805 271.941 203.344 272.222 203.816 272.668C204.277 273.118 204.627 273.73 204.866 274.505L206.375 279.414L204.998 279.837L203.546 275.111C203.292 274.285 202.887 273.712 202.331 273.392C201.778 273.082 201.14 273.039 200.416 273.261C199.672 273.49 199.156 273.9 198.869 274.49C198.581 275.08 198.573 275.814 198.842 276.691L200.257 281.294L198.865 281.722Z" />
      <path d="M194.971 278.262C195.151 279.15 195.13 279.96 194.908 280.69C194.688 281.432 194.302 282.049 193.749 282.542C193.185 283.037 192.496 283.367 191.68 283.533C190.625 283.747 189.705 283.666 188.922 283.292C188.128 282.92 187.529 282.301 187.125 281.436L188.662 281.124C188.914 281.617 189.285 281.972 189.775 282.188C190.255 282.407 190.808 282.452 191.436 282.325C192.251 282.16 192.852 281.744 193.24 281.078C193.629 280.422 193.72 279.582 193.512 278.557C193.302 277.522 192.889 276.774 192.273 276.311C191.657 275.848 190.941 275.699 190.126 275.865C189.498 275.992 189.005 276.244 188.646 276.622C188.288 276.999 188.085 277.476 188.038 278.051L186.502 278.363C186.554 277.438 186.871 276.639 187.452 275.966C188.036 275.303 188.846 274.867 189.881 274.657C190.696 274.492 191.46 274.527 192.172 274.764C192.873 275.003 193.469 275.421 193.96 276.018C194.452 276.615 194.788 277.363 194.971 278.262Z" />
      <path d="M179.891 285.379C179.234 285.448 178.625 285.368 178.064 285.138C177.504 284.918 177.046 284.543 176.69 284.012C176.323 283.483 176.096 282.815 176.011 282.009L175.467 276.869L176.899 276.718L177.421 281.65C177.513 282.52 177.801 283.16 178.284 283.57C178.768 283.991 179.387 284.161 180.14 284.081C180.904 284.001 181.489 283.697 181.894 283.172C182.289 282.647 182.438 281.929 182.342 281.017L181.831 276.196L183.279 276.042L184.526 287.816L183.078 287.97L182.623 283.674C182.384 284.15 182.028 284.536 181.555 284.833C181.072 285.131 180.517 285.313 179.891 285.379Z" />
      <path d="M171.206 288.189L171.123 280.188L172.338 280.148L172.774 288.138L171.206 288.189ZM171.561 276.924C171.838 276.915 172.076 277.003 172.274 277.189C172.472 277.375 172.576 277.606 172.585 277.883C172.594 278.16 172.505 278.398 172.32 278.596C172.134 278.794 171.902 278.898 171.625 278.907C171.359 278.916 171.132 278.827 170.944 278.641C170.746 278.455 170.643 278.224 170.634 277.947C170.625 277.669 170.713 277.432 170.899 277.234C171.074 277.036 171.294 276.933 171.561 276.924Z" />
      <path d="M160.772 278.071L156.878 277.797L156.961 276.615L162.308 276.993L161.523 288.117L160.071 288.015L160.772 278.071Z" />
      <path d="M146.123 279.841C146.166 279.567 146.227 279.28 146.307 278.979L153.23 280.067C153.311 279.205 153.127 278.491 152.676 277.923C152.213 277.365 151.602 277.026 150.843 276.906C150.222 276.809 149.683 276.87 149.227 277.09C148.76 277.319 148.403 277.662 148.158 278.12L146.609 277.877C146.972 277.081 147.543 276.474 148.321 276.057C149.097 275.65 150.002 275.527 151.035 275.689C151.857 275.818 152.565 276.119 153.161 276.59C153.745 277.06 154.169 277.661 154.432 278.393C154.693 279.136 154.754 279.956 154.613 280.851C154.472 281.747 154.17 282.504 153.705 283.122C153.241 283.74 152.66 284.178 151.961 284.435C151.249 284.701 150.472 284.768 149.629 284.636C148.807 284.507 148.108 284.213 147.532 283.756C146.956 283.298 146.549 282.727 146.312 282.041C146.062 281.365 145.999 280.632 146.123 279.841ZM147.561 280.375C147.475 280.923 147.523 281.411 147.704 281.839C147.883 282.277 148.168 282.63 148.558 282.896C148.936 283.172 149.378 283.349 149.884 283.429C150.611 283.543 151.269 283.409 151.858 283.026C152.437 282.642 152.827 282.045 153.03 281.234L147.561 280.375Z" />
      <path d="M140.859 281.565L142.06 276.539C142.159 276.124 142.142 275.807 142.008 275.589C141.871 275.38 141.585 275.224 141.149 275.12L140.106 274.871L140.392 273.673L141.669 273.978C142.457 274.166 143.005 274.489 143.312 274.946C143.62 275.404 143.675 276.047 143.476 276.877L142.275 281.904L143.38 282.168L143.101 283.335L141.997 283.071L141.483 285.218L140.067 284.88L140.58 282.733L138.355 282.201L138.634 281.034L140.859 281.565Z" />
      <path d="M134.463 283.603L132.913 283.145L135.416 280.097L136.398 280.387L134.463 283.603Z" />
      <path d="M130.497 270.445C131.129 270.675 131.657 270.985 132.082 271.378C132.503 271.78 132.79 272.225 132.943 272.712C133.091 273.208 133.09 273.713 132.938 274.225L131.524 273.712C131.633 273.286 131.565 272.881 131.318 272.497C131.062 272.109 130.653 271.813 130.092 271.609C129.57 271.42 129.117 271.386 128.733 271.507C128.348 271.629 128.093 271.865 127.965 272.216C127.834 272.577 127.898 272.901 128.157 273.187C128.412 273.484 128.845 273.84 129.455 274.254C130.008 274.637 130.452 274.979 130.784 275.281C131.103 275.59 131.345 275.956 131.509 276.379C131.66 276.808 131.639 277.288 131.446 277.819C131.293 278.241 131.028 278.581 130.65 278.841C130.272 279.101 129.816 279.248 129.282 279.281C128.744 279.324 128.179 279.238 127.588 279.023C126.675 278.692 126.022 278.194 125.628 277.529C125.234 276.864 125.162 276.123 125.414 275.307L126.782 275.803C126.656 276.245 126.706 276.655 126.932 277.032C127.148 277.406 127.522 277.689 128.053 277.882C128.545 278.06 128.974 278.097 129.341 277.992C129.708 277.887 129.954 277.664 130.077 277.323C130.176 277.052 130.172 276.795 130.067 276.553C129.949 276.317 129.779 276.097 129.559 275.892C129.324 275.693 128.995 275.443 128.571 275.142C128.038 274.767 127.615 274.432 127.302 274.137C126.986 273.852 126.753 273.506 126.606 273.101C126.448 272.692 126.455 272.235 126.627 271.73C126.79 271.279 127.063 270.918 127.445 270.648C127.826 270.378 128.286 270.221 128.824 270.178C129.348 270.142 129.905 270.231 130.497 270.445Z" />
      <path d="M116.004 272.634L118.295 268.001C118.484 267.619 118.537 267.306 118.455 267.063C118.368 266.83 118.124 266.614 117.723 266.415L116.762 265.94L117.308 264.836L118.484 265.418C119.21 265.777 119.673 266.214 119.871 266.728C120.068 267.242 119.978 267.882 119.6 268.647L117.309 273.279L118.328 273.783L117.796 274.859L116.777 274.355L115.799 276.334L114.493 275.689L115.472 273.71L113.421 272.696L113.953 271.62L116.004 272.634Z" />
      <path d="M113.265 267.801C112.802 268.567 112.241 269.144 111.583 269.531C110.919 269.928 110.221 270.123 109.49 270.117C108.749 270.105 108.045 269.897 107.379 269.494C106.722 269.097 106.237 268.61 105.924 268.035C105.612 267.459 105.467 266.885 105.489 266.313L104.661 267.682L103.401 266.92L107.939 259.418L109.199 260.18L108.354 261.576C108.866 261.287 109.456 261.145 110.126 261.151C110.78 261.161 111.432 261.362 112.08 261.754C112.746 262.157 113.249 262.686 113.589 263.34C113.929 263.994 114.074 264.712 114.026 265.493C113.977 266.274 113.724 267.043 113.265 267.801ZM106.922 263.944C106.579 264.51 106.395 265.072 106.369 265.63C106.344 266.188 106.461 266.695 106.721 267.151C106.966 267.611 107.321 267.982 107.787 268.264C108.252 268.545 108.743 268.693 109.259 268.705C109.775 268.718 110.274 268.584 110.756 268.302C111.238 268.02 111.65 267.596 111.992 267.03C112.34 266.455 112.53 265.884 112.561 265.317C112.587 264.76 112.477 264.251 112.232 263.791C111.981 263.34 111.623 262.973 111.157 262.692C110.692 262.41 110.201 262.263 109.685 262.25C109.155 262.241 108.649 262.377 108.166 262.659C107.679 262.95 107.264 263.378 106.922 263.944Z" />
      <path d="M96.8621 266.114L103.716 256.46L104.903 257.302L98.0493 266.957L96.8621 266.114Z" />
      <path d="M97.3068 251.509L97.5523 256.683L99.9839 253.669L101.117 254.584L93.6816 263.798L92.5485 262.884L96.9193 257.467L91.8504 258.373L90.2691 257.097L96.2246 256.29L95.7628 250.263L97.3068 251.509Z" />
      <path d="M88.2026 249.395C87.5496 250.009 86.854 250.413 86.1159 250.609C85.3701 250.812 84.6456 250.812 83.9427 250.609C83.2324 250.398 82.6106 250.009 82.0775 249.442C81.5517 248.882 81.2157 248.283 81.0696 247.644C80.9236 247.006 80.9383 246.414 81.1138 245.869L79.9476 246.964L78.9398 245.891L85.3302 239.888L86.3381 240.961L85.1486 242.078C85.719 241.938 86.3257 241.96 86.9689 242.146C87.597 242.332 88.1703 242.701 88.6888 243.253C89.222 243.82 89.564 244.465 89.7151 245.187C89.8661 245.908 89.8131 246.638 89.5561 247.377C89.299 248.117 88.8479 248.789 88.2026 249.395ZM83.1312 243.973C82.6492 244.426 82.3207 244.918 82.1456 245.448C81.9706 245.978 81.9468 246.498 82.0743 247.008C82.1867 247.517 82.4291 247.97 82.8016 248.366C83.174 248.763 83.6069 249.037 84.1002 249.188C84.5936 249.339 85.1103 249.344 85.6505 249.202C86.1907 249.061 86.7018 248.764 87.1838 248.311C87.6736 247.851 88.0099 247.352 88.1927 246.814C88.3677 246.284 88.3991 245.764 88.2867 245.255C88.1665 244.753 87.9202 244.304 87.5478 243.908C87.1753 243.511 86.7424 243.237 86.2491 243.086C85.7407 242.934 85.2164 242.929 84.6762 243.071C84.1282 243.22 83.6132 243.521 83.1312 243.973Z" />
      <path d="M76.7585 240.746C76.1618 240.826 75.5573 240.737 74.9449 240.48C74.3326 240.223 73.803 239.802 73.3563 239.217C72.8773 238.589 72.5951 237.912 72.5099 237.186C72.4247 236.459 72.5433 235.744 72.8657 235.042C73.1797 234.346 73.6928 233.726 74.4049 233.182C75.1085 232.645 75.8459 232.31 76.6171 232.177C77.3882 232.044 78.1206 232.116 78.8142 232.392C79.5013 232.659 80.0812 233.102 80.5538 233.721C81.0134 234.322 81.2866 234.953 81.3734 235.611C81.4537 236.261 81.3778 236.856 81.1456 237.396L82.43 236.415L83.3137 237.572L73.9036 244.758L73.0199 243.601L76.7585 240.746ZM75.308 234.364C74.7824 234.766 74.4055 235.221 74.1774 235.731C73.9492 236.241 73.8726 236.756 73.9475 237.276C74.016 237.787 74.2153 238.259 74.5454 238.691C74.8691 239.115 75.2761 239.428 75.7664 239.631C76.2418 239.832 76.7628 239.89 77.3296 239.806C77.8814 239.72 78.4159 239.48 78.933 239.085C79.4586 238.684 79.8397 238.225 80.0763 237.709C80.298 237.191 80.3788 236.673 80.3189 236.155C80.244 235.635 80.0447 235.163 79.721 234.739C79.3908 234.307 78.9881 233.99 78.5127 233.789C78.0224 233.587 77.5013 233.528 76.9495 233.614C76.3892 233.707 75.8421 233.957 75.308 234.364Z" />
      <path d="M74.6161 225.058C75.0464 225.757 75.2785 226.491 75.3125 227.259C75.3409 228.018 75.1649 228.74 74.7846 229.425C74.3896 230.106 73.8059 230.684 73.0336 231.159C72.2704 231.628 71.4974 231.878 70.7147 231.909C69.9174 231.936 69.1883 231.758 68.5273 231.376C67.8573 230.999 67.3044 230.456 66.8685 229.747C66.4327 229.038 66.1978 228.3 66.1638 227.532C66.1207 226.77 66.2893 226.046 66.6696 225.362C67.0444 224.668 67.6179 224.083 68.3903 223.608C69.1626 223.133 69.9502 222.887 70.7531 222.869C71.5414 222.848 72.2733 223.03 72.9489 223.416C73.6245 223.802 74.1803 224.349 74.6161 225.058ZM73.5258 225.728C73.252 225.283 72.8905 224.929 72.4412 224.667C71.992 224.405 71.4857 224.284 70.9225 224.305C70.3537 224.317 69.7694 224.507 69.1697 224.876C68.5701 225.245 68.1339 225.676 67.8612 226.169C67.5885 226.662 67.4696 227.161 67.5044 227.666C67.5301 228.176 67.6798 228.653 67.9536 229.099C68.233 229.553 68.5945 229.907 69.0382 230.16C69.4672 230.409 69.9605 230.519 70.5181 230.489C71.0758 230.46 71.6544 230.26 72.2541 229.891C72.8629 229.517 73.3092 229.092 73.593 228.617C73.8713 228.133 74.0004 227.64 73.9803 227.139C73.9511 226.644 73.7996 226.174 73.5258 225.728Z" />
      <path d="M59.826 216.288L67.7007 212.432L68.341 213.74L67.1771 214.31C67.7014 214.362 68.1887 214.557 68.6391 214.895C69.0752 215.227 69.4246 215.662 69.6873 216.198C69.9875 216.812 70.1327 217.423 70.1228 218.034C70.1034 218.649 69.9102 219.225 69.5432 219.761C69.1715 220.287 68.6216 220.729 67.8935 221.085L63.2808 223.344L62.6475 222.05L67.0878 219.876C67.8638 219.496 68.3664 219.007 68.5956 218.408C68.8152 217.813 68.7585 217.176 68.4254 216.496C68.083 215.796 67.5977 215.351 66.9696 215.16C66.3415 214.968 65.6155 215.075 64.7917 215.478L60.4663 217.596L59.826 216.288Z" />
      <path d="M58.9274 210.941L63.7367 209.049C64.1338 208.893 64.3835 208.698 64.4859 208.463C64.5783 208.231 64.5426 207.907 64.3786 207.49L63.9862 206.493L65.1327 206.042L65.613 207.263C65.9097 208.017 65.9585 208.651 65.7594 209.165C65.5604 209.679 65.0638 210.092 64.2697 210.404L59.4603 212.296L59.8761 213.353L58.7594 213.792L58.3436 212.735L56.2888 213.543L55.7559 212.188L57.8106 211.38L56.9732 209.251L58.0899 208.812L58.9274 210.941Z" />
      <path d="M55.4798 199.601C54.8994 199.442 54.3775 199.124 53.9141 198.648C53.4508 198.172 53.1278 197.578 52.945 196.865C52.749 196.1 52.7537 195.367 52.9591 194.665C53.1645 193.962 53.5528 193.351 54.124 192.83C54.6849 192.312 55.3993 191.941 56.2673 191.719C57.1249 191.499 57.9345 191.479 58.6962 191.658C59.4579 191.837 60.1041 192.189 60.635 192.714C61.1633 193.228 61.5241 193.862 61.7174 194.616C61.9055 195.35 61.9108 196.037 61.7335 196.677C61.5536 197.307 61.2514 197.825 60.827 198.231L62.3923 197.829L62.7539 199.24L51.2847 202.18L50.9231 200.769L55.4798 199.601ZM56.6367 193.16C55.9961 193.325 55.4713 193.597 55.0622 193.977C54.6531 194.357 54.3815 194.801 54.2474 195.309C54.1107 195.806 54.1098 196.319 54.2449 196.846C54.3773 197.362 54.6297 197.81 55.0019 198.188C55.3611 198.558 55.8179 198.815 56.3724 198.96C56.9139 199.096 57.4999 199.083 58.1301 198.922C58.7708 198.758 59.3008 198.484 59.7202 198.101C60.1266 197.711 60.4034 197.266 60.5505 196.765C60.6846 196.258 60.6854 195.745 60.553 195.229C60.4179 194.702 60.1707 194.253 59.8115 193.883C59.4393 193.505 58.9825 193.247 58.4409 193.111C57.8891 192.977 57.2877 192.993 56.6367 193.16Z" />
      <path d="M50.2073 181.817L58.8731 180.482L59.0947 181.921L57.8138 182.118C58.2988 182.324 58.706 182.655 59.0353 183.112C59.3525 183.56 59.5565 184.079 59.6475 184.669C59.7514 185.344 59.7077 185.971 59.5164 186.551C59.3145 187.132 58.9586 187.624 58.4486 188.027C57.9369 188.418 57.2805 188.676 56.4792 188.799L51.4031 189.581L51.1839 188.158L56.0703 187.405C56.9242 187.274 57.5498 186.956 57.9471 186.453C58.3339 185.95 58.4696 185.325 58.3543 184.577C58.2358 183.807 57.9053 183.237 57.3627 182.868C56.8201 182.498 56.0954 182.383 55.1888 182.523L50.4289 183.256L50.2073 181.817Z" />
      <path d="M58.5418 174.627C58.5857 175.298 58.5133 175.906 58.3246 176.453C58.1253 177.001 57.8335 177.442 57.4492 177.777C57.0543 178.113 56.5918 178.314 56.0617 178.381L55.9635 176.88C56.3971 176.809 56.7404 176.584 56.9935 176.204C57.2458 175.813 57.3525 175.319 57.3135 174.723C57.2772 174.17 57.1263 173.742 56.8605 173.438C56.5948 173.135 56.2757 172.996 55.9032 173.02C55.52 173.045 55.2491 173.234 55.0904 173.586C54.9211 173.939 54.7694 174.478 54.6351 175.203C54.5073 175.864 54.3718 176.407 54.2287 176.833C54.0742 177.25 53.8365 177.618 53.5154 177.938C53.1829 178.249 52.7347 178.422 52.1705 178.459C51.7235 178.489 51.305 178.382 50.915 178.141C50.5251 177.899 50.2076 177.54 49.9627 177.065C49.7071 176.59 49.5588 176.038 49.5177 175.41C49.4543 174.442 49.6479 173.643 50.0985 173.015C50.5491 172.387 51.1987 172.024 52.0474 171.925L52.1426 173.378C51.687 173.44 51.3319 173.651 51.0775 174.009C50.8224 174.357 50.7132 174.813 50.7502 175.378C50.7843 175.899 50.9233 176.307 51.167 176.601C51.4107 176.895 51.7135 177.03 52.0754 177.006C52.3628 176.988 52.5964 176.882 52.7761 176.688C52.9445 176.485 53.0782 176.241 53.1772 175.956C53.2648 175.662 53.3614 175.26 53.467 174.751C53.5962 174.112 53.7331 173.59 53.8776 173.185C54.0115 172.78 54.2343 172.429 54.5462 172.131C54.8573 171.822 55.2787 171.644 55.8101 171.599C56.2891 171.568 56.7289 171.672 57.1295 171.913C57.5301 172.154 57.8582 172.512 58.1138 172.987C58.358 173.452 58.5007 173.999 58.5418 174.627Z" />
      <path d="M47.9735 168.303C47.9744 168.581 47.8792 168.816 47.6878 169.008C47.4964 169.201 47.262 169.298 46.9847 169.299C46.7073 169.3 46.4724 169.204 46.2798 169.013C46.0872 168.821 45.9904 168.587 45.9895 168.31C45.9887 168.043 46.084 167.819 46.2754 167.637C46.4668 167.444 46.7012 167.348 46.9785 167.347C47.2558 167.346 47.4908 167.441 47.6834 167.632C47.876 167.813 47.9727 168.037 47.9735 168.303ZM49.3953 167.595L58.1633 167.567L58.1679 169.023L49.3999 169.051L49.3953 167.595Z" />
      <path d="M49.5016 159.532C49.5727 158.468 49.955 157.628 50.6483 157.011C51.331 156.394 52.279 156.126 53.4923 156.207L58.6488 156.552L58.5527 157.989L53.6038 157.658C52.7311 157.6 52.0513 157.773 51.5645 158.179C51.067 158.584 50.793 159.164 50.7425 159.92C50.6912 160.686 50.8898 161.314 51.3381 161.804C51.7872 162.283 52.4693 162.553 53.3846 162.614L58.2218 162.938L58.1247 164.391L49.3762 163.806L49.4733 162.353L50.7186 162.436C50.2908 162.119 49.9709 161.707 49.7588 161.201C49.5475 160.685 49.4617 160.128 49.5016 159.532Z" />
      <path d="M55.5648 145.261C55.8384 145.306 56.1253 145.369 56.4253 145.451L55.286 152.366C56.1472 152.454 56.8632 152.275 57.434 151.828C57.9961 151.369 58.3395 150.761 58.4644 150.003C58.5667 149.382 58.5096 148.843 58.293 148.386C58.0677 147.916 57.7268 147.558 57.2704 147.309L57.5253 145.762C58.3187 146.131 58.9212 146.706 59.333 147.487C59.7343 148.267 59.8499 149.172 59.68 150.204C59.5447 151.025 59.2392 151.731 58.7633 152.323C58.2891 152.904 57.6849 153.324 56.9506 153.581C56.2058 153.837 55.3861 153.891 54.4915 153.743C53.5969 153.596 52.8423 153.288 52.2277 152.819C51.6131 152.35 51.1797 151.765 50.9276 151.064C50.6668 150.351 50.6057 149.573 50.7445 148.731C50.8797 147.911 51.1783 147.214 51.6402 146.641C52.1021 146.069 52.6765 145.666 53.3635 145.433C54.0416 145.188 54.7754 145.131 55.5648 145.261ZM55.0203 146.695C54.473 146.605 53.9847 146.649 53.5554 146.827C53.1156 147.003 52.761 147.285 52.4917 147.673C52.2136 148.049 52.0329 148.49 51.9497 148.995C51.83 149.721 51.9592 150.38 52.3374 150.972C52.7172 151.554 53.3115 151.949 54.1203 152.158L55.0203 146.695Z" />
      <path d="M61.6477 140.687C61.4817 141.338 61.2243 141.894 60.8755 142.356C60.5164 142.815 60.1023 143.144 59.6331 143.343C59.1536 143.54 58.6515 143.589 58.1267 143.488L58.4982 142.03C58.9326 142.097 59.3289 141.989 59.6872 141.706C60.0481 141.413 60.3024 140.977 60.4499 140.398C60.5869 139.86 60.5761 139.406 60.4174 139.036C60.2587 138.665 59.9985 138.434 59.6367 138.341C59.2646 138.247 58.9486 138.342 58.6885 138.628C58.4182 138.911 58.1069 139.377 57.7546 140.025C57.4285 140.613 57.1314 141.088 56.8633 141.449C56.5875 141.797 56.2473 142.073 55.8428 142.278C55.4305 142.47 54.9505 142.497 54.4027 142.357C53.9686 142.246 53.6036 142.016 53.3077 141.665C53.0118 141.314 52.821 140.875 52.7355 140.347C52.6397 139.816 52.6694 139.246 52.8249 138.636C53.0646 137.695 53.496 136.996 54.119 136.539C54.742 136.081 55.4723 135.937 56.3097 136.106L55.9501 137.517C55.4977 137.435 55.095 137.525 54.7419 137.787C54.3915 138.039 54.1465 138.439 54.0069 138.987C53.8778 139.493 53.8835 139.924 54.0242 140.279C54.1648 140.634 54.4109 140.856 54.7623 140.946C55.0414 141.017 55.2964 140.989 55.5272 140.86C55.7503 140.719 55.953 140.529 56.1352 140.289C56.3097 140.036 56.526 139.684 56.7842 139.232C57.1051 138.665 57.3969 138.211 57.6598 137.87C57.9123 137.528 58.233 137.263 58.622 137.075C59.0135 136.878 59.469 136.84 59.9885 136.961C60.4536 137.08 60.8393 137.316 61.1455 137.669C61.4517 138.022 61.6528 138.464 61.7486 138.995C61.8368 139.513 61.8032 140.077 61.6477 140.687Z" />
      <path d="M64.0901 132.447C63.8729 133.082 63.5722 133.617 63.1878 134.049C62.7934 134.478 62.3543 134.773 61.8708 134.935C61.3771 135.093 60.8728 135.101 60.3577 134.959L60.8439 133.536C61.2715 133.637 61.6752 133.56 62.0548 133.307C62.4379 133.043 62.726 132.629 62.9191 132.063C63.0984 131.539 63.1236 131.085 62.9949 130.703C62.8662 130.321 62.6252 130.069 62.2719 129.949C61.9085 129.825 61.5858 129.895 61.3039 130.159C61.0119 130.42 60.6646 130.859 60.262 131.477C59.8902 132.038 59.5563 132.487 59.2603 132.826C58.9577 133.151 58.5966 133.399 58.1771 133.572C57.7509 133.731 57.2703 133.719 56.7353 133.536C56.3114 133.391 55.9658 133.132 55.6987 132.759C55.4316 132.386 55.2764 131.933 55.2331 131.4C55.1797 130.863 55.2548 130.297 55.4582 129.701C55.7719 128.783 56.2575 128.12 56.9149 127.713C57.5723 127.307 58.3116 127.221 59.1329 127.456L58.6623 128.834C58.2179 128.716 57.8094 128.774 57.4366 129.007C57.0673 129.231 56.7912 129.61 56.6085 130.145C56.4396 130.639 56.4111 131.069 56.5231 131.434C56.6351 131.8 56.8627 132.041 57.2059 132.158C57.4785 132.251 57.7349 132.243 57.9752 132.133C58.2088 132.01 58.426 131.836 58.6267 131.612C58.8207 131.374 59.0643 131.04 59.3576 130.61C59.7225 130.07 60.0495 129.641 60.3386 129.322C60.6176 129.001 60.9583 128.762 61.3609 128.606C61.7669 128.441 62.224 128.439 62.7321 128.601C63.1864 128.756 63.5521 129.022 63.8293 129.399C64.1065 129.775 64.2718 130.232 64.3252 130.768C64.3719 131.292 64.2936 131.851 64.0901 132.447Z" />
      <path d="M64.4679 114.603L65.5744 115.145L62.7666 120.879L61.6602 120.337L64.4679 114.603Z" />
      <path d="M72.7188 98.4528C72.0078 98.3366 71.3405 98.4194 70.7171 98.7013C70.085 98.977 69.5472 99.435 69.1038 100.075C68.6604 100.715 68.4087 101.397 68.3485 102.121C68.2856 102.83 68.4271 103.525 68.773 104.206C69.1162 104.872 69.6474 105.454 70.3664 105.952C71.0855 106.45 71.817 106.742 72.5611 106.829C73.3113 106.908 74.0115 106.796 74.6619 106.494C75.3096 106.177 75.8552 105.699 76.2985 105.058C76.918 104.164 77.1608 103.242 77.0267 102.293C76.8927 101.344 76.4031 100.505 75.558 99.777L73.0253 103.434L72.052 102.759L75.4683 97.827L76.3891 98.4647C77.0946 99.0572 77.6233 99.7738 77.9753 100.614C78.3185 101.449 78.4465 102.323 78.3592 103.235C78.2632 104.142 77.9359 104.999 77.3771 105.805C76.788 106.656 76.0532 107.295 75.1727 107.724C74.2834 108.146 73.341 108.317 72.3452 108.237C71.3556 108.148 70.4136 107.794 69.5192 107.175C68.6248 106.555 67.9621 105.798 67.5312 104.903C67.0976 103.993 66.9265 103.05 67.0178 102.075C67.1003 101.094 67.4361 100.179 68.0253 99.3281C68.6994 98.3547 69.5358 97.6624 70.5343 97.2511C71.5389 96.8311 72.5981 96.7537 73.7118 97.0191L72.7188 98.4528Z" />
      <path d="M83.8362 89.8913C84.0459 90.0728 84.2572 90.2769 84.4701 90.5036L79.8833 95.802C80.5715 96.3272 81.2756 96.5487 81.9956 96.4667C82.7146 96.3695 83.3254 96.0306 83.828 95.45C84.2399 94.9742 84.4732 94.4847 84.5277 93.9817C84.5811 93.4637 84.4782 92.9795 84.2189 92.5294L85.2452 91.3439C85.7287 92.0728 85.9417 92.8779 85.8842 93.7593C85.8186 94.6336 85.4437 95.466 84.7595 96.2563C84.215 96.8854 83.5851 97.3277 82.87 97.5834C82.1619 97.831 81.4276 97.8725 80.6671 97.7079C79.8986 97.5364 79.1715 97.154 78.4861 96.5605C77.8006 95.9671 77.3184 95.3099 77.0397 94.5889C76.7609 93.8679 76.6973 93.1427 76.849 92.4134C76.9996 91.6691 77.3541 90.9743 77.9126 90.3291C78.4572 89.7001 79.076 89.2623 79.7691 89.0158C80.4621 88.7693 81.1623 88.7264 81.8696 88.8873C82.5758 89.033 83.2313 89.3677 83.8362 89.8913ZM82.622 90.8295C82.2026 90.4664 81.7634 90.2485 81.3045 90.1757C80.8375 90.0959 80.3877 90.1509 79.9552 90.3409C79.5216 90.5158 79.1373 90.7968 78.8022 91.1839C78.3205 91.7403 78.086 92.3697 78.0988 93.0721C78.1185 93.7664 78.4185 94.414 78.9986 95.015L82.622 90.8295Z" />
      <path d="M84.9328 84.7264L88.5703 88.3974C88.8706 88.7005 89.149 88.8527 89.4054 88.8538C89.6544 88.8474 89.938 88.6866 90.2562 88.3712L91.0177 87.6167L91.8848 88.4919L90.9529 89.4153C90.377 89.9859 89.8137 90.2812 89.263 90.3013C88.7123 90.3214 88.1367 90.0284 87.5361 89.4222L83.8986 85.7512L83.0916 86.5507L82.247 85.6983L83.0539 84.8988L81.4998 83.3303L82.5341 82.3055L84.0882 83.8739L85.7135 82.2635L86.5581 83.116L84.9328 84.7264Z" />
      <path d="M91.7749 74.9886C91.5596 75.1635 91.3169 75.2369 91.0469 75.209C90.7768 75.181 90.5543 75.0594 90.3794 74.8442C90.2045 74.6289 90.1311 74.3862 90.159 74.1161C90.187 73.8461 90.3086 73.6236 90.5238 73.4487C90.7308 73.2806 90.9652 73.2138 91.227 73.2485C91.4971 73.2765 91.7196 73.3981 91.8945 73.6133C92.0693 73.8286 92.1428 74.0712 92.1148 74.3413C92.0952 74.6047 91.9818 74.8204 91.7749 74.9886ZM93.2192 75.6499L98.7479 82.4552L97.6178 83.3732L92.0891 76.568L93.2192 75.6499Z" />
      <path d="M99.2132 70.9074C100.085 70.2923 100.978 70.0598 101.894 70.2099C102.803 70.3514 103.608 70.9188 104.31 71.9122L107.29 76.1343L106.114 76.9647L103.253 72.9126C102.749 72.1981 102.186 71.7795 101.564 71.657C100.936 71.5258 100.312 71.6786 99.6938 72.1154C99.0663 72.5583 98.7037 73.108 98.6058 73.7647C98.5166 74.4152 98.7366 75.1152 99.2656 75.8646L102.061 79.8252L100.872 80.6648L95.8154 73.5018L97.0049 72.6621L97.7246 73.6817C97.7015 73.1496 97.8197 72.6419 98.0791 72.1585C98.3471 71.6689 98.7252 71.2519 99.2132 70.9074Z" />
      <path d="M111.675 64.7475L114.117 69.3022C114.318 69.6782 114.542 69.9033 114.787 69.9774C115.028 70.0421 115.345 69.9687 115.74 69.757L116.685 69.2504L117.267 70.3362L116.111 70.9562C115.396 71.3392 114.772 71.462 114.239 71.3245C113.705 71.187 113.237 70.7422 112.833 69.9902L110.391 65.4355L109.39 65.9723L108.823 64.9147L109.824 64.3779L108.781 62.432L110.064 61.744L111.107 63.6899L113.124 62.6088L113.691 63.6664L111.675 64.7475Z" />
      <path d="M123.424 67.5612C122.671 67.8896 121.912 68.017 121.147 67.9435C120.391 67.8657 119.701 67.5907 119.076 67.1185C118.457 66.6322 117.966 65.9736 117.603 65.1425C117.245 64.3213 117.105 63.521 117.184 62.7417C117.269 61.9484 117.547 61.2512 118.018 60.6502C118.484 60.0393 119.099 59.5675 119.862 59.2349C120.624 58.9023 121.388 58.7727 122.153 58.8462C122.914 58.91 123.607 59.178 124.232 59.6502C124.867 60.1181 125.365 60.7676 125.728 61.5987C126.09 62.4297 126.225 63.244 126.13 64.0416C126.041 64.8251 125.759 65.5245 125.282 66.1396C124.806 66.7547 124.186 67.2286 123.424 67.5612ZM122.912 66.388C123.391 66.179 123.792 65.8704 124.114 65.4622C124.436 65.0539 124.627 64.5695 124.685 64.0089C124.752 63.444 124.646 62.8389 124.364 62.1936C124.083 61.5483 123.717 61.0562 123.266 60.7174C122.816 60.3785 122.339 60.1911 121.834 60.1551C121.325 60.1093 120.831 60.1909 120.352 60.3999C119.864 60.6131 119.463 60.9217 119.15 61.3257C118.843 61.7157 118.666 62.1888 118.617 62.7452C118.569 63.3015 118.686 63.9023 118.967 64.5476C119.253 65.2027 119.611 65.7039 120.042 66.0513C120.483 66.3945 120.952 66.591 121.451 66.6411C121.946 66.6813 122.433 66.597 122.912 66.388Z" />
      <path d="M134.093 54.124L136.821 62.4568L135.437 62.9099L135.034 61.6782C134.909 62.1902 134.649 62.6459 134.252 63.0452C133.862 63.431 133.383 63.7168 132.816 63.9027C132.167 64.1151 131.541 64.1742 130.938 64.0798C130.331 63.9754 129.788 63.7043 129.308 63.2666C128.838 62.8256 128.477 62.2199 128.224 61.4494L126.626 56.5684L127.995 56.1203L129.533 60.8189C129.802 61.64 130.217 62.2056 130.779 62.5155C131.337 62.8154 131.976 62.8475 132.696 62.6118C133.436 62.3696 133.944 61.9506 134.221 61.3551C134.497 60.7595 134.493 60.0258 134.207 59.154L132.709 54.5771L134.093 54.124Z" />
      <path d="M137.808 57.5528C137.609 56.6683 137.612 55.8584 137.818 55.1231C138.021 54.3774 138.394 53.752 138.936 53.247C139.489 52.7396 140.171 52.3944 140.983 52.2113C142.034 51.9743 142.955 52.0344 143.746 52.3917C144.548 52.7466 145.16 53.3521 145.583 54.2081L144.053 54.5531C143.79 54.0657 143.411 53.7192 142.917 53.5137C142.432 53.3058 141.878 53.2723 141.254 53.4131C140.442 53.5962 139.85 54.025 139.477 54.6996C139.102 55.3637 139.03 56.2056 139.26 57.2254C139.492 58.2555 139.921 58.9951 140.548 59.4444C141.174 59.8936 141.893 60.0267 142.704 59.8436C143.329 59.7028 143.816 59.4397 144.167 59.0545C144.517 58.6693 144.709 58.1885 144.743 57.6122L146.273 57.2672C146.241 58.1929 145.942 58.9985 145.375 59.6841C144.805 60.3592 144.006 60.813 142.975 61.0454C142.164 61.2285 141.4 61.2095 140.683 60.9885C139.976 60.7651 139.371 60.3604 138.867 59.7743C138.363 59.1881 138.01 58.4476 137.808 57.5528Z" />
      <path d="M152.61 50.1092C153.266 50.0252 153.877 50.0922 154.442 50.3101C155.007 50.5175 155.473 50.8825 155.841 51.4054C156.219 51.9268 156.46 52.5896 156.563 53.3937L157.219 58.5199L155.791 58.7028L155.161 53.7829C155.05 52.9153 154.748 52.2818 154.256 51.8824C153.763 51.4724 153.141 51.3155 152.39 51.4117C151.628 51.5092 151.05 51.8252 150.656 52.3595C150.272 52.8926 150.139 53.614 150.256 54.5239L150.871 59.3327L149.427 59.5176L147.923 47.7734L149.368 47.5885L149.916 51.8736C150.145 51.3926 150.492 50.9986 150.959 50.6916C151.435 50.3832 151.986 50.1891 152.61 50.1092Z" />
      <path d="M161.085 47.0963L161.345 55.0941L160.131 55.1602L159.52 47.1816L161.085 47.0963ZM160.978 58.3669C160.701 58.3819 160.462 58.2988 160.26 58.1176C160.057 57.9363 159.949 57.7072 159.934 57.4303C159.919 57.1534 160.002 56.9138 160.183 56.7117C160.364 56.5095 160.593 56.4009 160.87 56.3858C161.137 56.3713 161.366 56.455 161.557 56.6368C161.759 56.8181 161.868 57.0472 161.883 57.3241C161.898 57.601 161.815 57.8406 161.634 58.0427C161.463 58.2443 161.245 58.3524 160.978 58.3669Z" />
      <path d="M172.181 56.9579L176.08 57.1609L176.018 58.3433L170.665 58.0647L171.245 46.9277L172.699 47.0034L172.181 56.9579Z" />
      <path d="M186.46 54.826C186.423 55.1008 186.368 55.3894 186.295 55.6918L179.35 54.7591C179.287 55.6225 179.488 56.3329 179.951 56.8901C180.427 57.4383 181.045 57.7634 181.806 57.8656C182.43 57.9494 182.967 57.8762 183.418 57.6462C183.88 57.4069 184.229 57.0555 184.463 56.5919L186.017 56.8006C185.672 57.6046 185.116 58.224 184.347 58.6589C183.58 59.0832 182.678 59.2258 181.642 59.0867C180.817 58.976 180.102 58.6916 179.496 58.2336C178.901 57.7769 178.464 57.1855 178.185 56.4592C177.907 55.7224 177.829 54.9046 177.949 54.006C178.07 53.1074 178.355 52.344 178.806 51.7156C179.256 51.0873 179.828 50.6367 180.521 50.3639C181.226 50.0819 182.001 49.9977 182.847 50.1112C183.672 50.222 184.377 50.4996 184.963 50.9443C185.549 51.3889 185.969 51.951 186.222 52.6308C186.487 53.3014 186.566 54.0331 186.46 54.826ZM185.01 54.3245C185.084 53.7748 185.025 53.288 184.835 52.8642C184.645 52.4298 184.353 52.0838 183.957 51.8262C183.573 51.5594 183.127 51.3919 182.62 51.3238C181.89 51.2258 181.235 51.3746 180.655 51.7702C180.085 52.1673 179.708 52.7731 179.523 53.5878L185.01 54.3245Z" />
      <path d="M191.618 52.9493L190.531 58.0016C190.441 58.4188 190.466 58.735 190.605 58.9504C190.746 59.1554 191.036 59.3051 191.474 59.3993L192.522 59.6248L192.263 60.8293L190.98 60.5533C190.188 60.3827 189.633 60.0723 189.315 59.6221C188.997 59.1718 188.928 58.5296 189.108 57.6953L190.195 52.643L189.084 52.404L189.337 51.2309L190.447 51.4698L190.912 49.3113L192.335 49.6176L191.871 51.7761L194.108 52.2575L193.855 53.4306L191.618 52.9493Z" />
      <path d="M197.994 50.7567L199.554 51.1795L197.121 54.2832L196.133 54.0153L197.994 50.7567Z" />
      <path d="M202.111 63.7538C201.474 63.5391 200.939 63.2403 200.505 62.8576C200.075 62.4648 199.778 62.0269 199.614 61.544C199.454 61.0509 199.444 60.5466 199.584 60.031L201.009 60.5117C200.91 60.9397 200.988 61.3431 201.243 61.7218C201.508 62.1039 201.924 62.3904 202.49 62.5813C203.015 62.7586 203.469 62.7822 203.85 62.652C204.232 62.5218 204.482 62.2798 204.602 61.9261C204.725 61.5622 204.653 61.2398 204.388 60.9589C204.126 60.6679 203.685 60.3223 203.066 59.922C202.504 59.5523 202.053 59.2202 201.713 58.9255C201.387 58.6241 201.137 58.264 200.963 57.8451C200.803 57.4195 200.813 56.9389 200.994 56.4032C201.137 55.9787 201.395 55.6322 201.767 55.3637C202.139 55.0951 202.591 54.9382 203.124 54.8929C203.661 54.8375 204.227 54.9103 204.823 55.1115C205.743 55.4217 206.408 55.9048 206.817 56.5606C207.226 57.2164 207.314 57.9555 207.082 58.7777L205.703 58.3123C205.819 57.8675 205.76 57.4591 205.525 57.0873C205.3 56.7188 204.92 56.4442 204.384 56.2635C203.889 56.0965 203.459 56.0696 203.094 56.183C202.73 56.2964 202.489 56.5249 202.373 56.8686C202.281 57.1414 202.291 57.3978 202.401 57.6377C202.525 57.8709 202.7 58.0874 202.925 58.2872C203.164 58.4804 203.498 58.7227 203.929 59.0144C204.471 59.3772 204.902 59.7026 205.221 59.9904C205.544 60.2682 205.784 60.608 205.941 61.01C206.108 61.4154 206.111 61.8724 205.951 62.3812C205.798 62.836 205.533 63.2027 205.158 63.4814C204.782 63.76 204.326 63.927 203.79 63.9825C203.267 64.0312 202.707 63.955 202.111 63.7538Z" />
      <path d="M216.815 61.3037L214.614 65.9794C214.432 66.3654 214.385 66.6791 214.472 66.9204C214.563 67.152 214.811 67.3633 215.217 67.5541L216.187 68.0107L215.662 69.1253L214.475 68.5665C213.741 68.2212 213.271 67.7933 213.063 67.2829C212.855 66.7725 212.933 66.1312 213.297 65.3592L215.498 60.6835L214.47 60.1996L214.981 59.1139L216.009 59.5978L216.95 57.6001L218.267 58.2203L217.326 60.218L219.397 61.1925L218.885 62.2782L216.815 61.3037Z" />
      <path d="M219.403 65.9174C219.849 65.1402 220.396 64.5504 221.045 64.148C221.699 63.7363 222.392 63.5252 223.124 63.5145C223.865 63.5092 224.573 63.7002 225.248 64.0877C225.915 64.4699 226.411 64.9451 226.736 65.5132C227.062 66.0814 227.221 66.6519 227.212 67.2247L228.008 65.8369L229.285 66.5694L224.922 74.1747L223.645 73.4422L224.457 72.0266C223.952 72.3274 223.365 72.4825 222.696 72.4919C222.041 72.4974 221.385 72.3117 220.728 71.9348C220.053 71.5473 219.538 71.0305 219.183 70.3842C218.828 69.738 218.666 69.0239 218.696 68.2421C218.727 67.4602 218.962 66.6853 219.403 65.9174ZM225.834 69.6256C226.163 69.052 226.334 68.486 226.347 67.9277C226.36 67.3694 226.231 66.8651 225.961 66.4148C225.705 65.9606 225.341 65.5981 224.869 65.3274C224.397 65.0567 223.903 64.9209 223.388 64.92C222.872 64.9191 222.376 65.0651 221.9 65.358C221.425 65.6509 221.023 66.0842 220.693 66.6578C220.359 67.2407 220.183 67.8159 220.165 68.3835C220.152 68.9418 220.273 69.4481 220.529 69.9023C220.79 70.3473 221.157 70.7051 221.629 70.9759C222.101 71.2466 222.594 71.3824 223.11 71.3833C223.641 71.3802 224.144 71.2322 224.62 70.9393C225.1 70.6372 225.505 70.1993 225.834 69.6256Z" />
      <path d="M236.006 67.3022L229.339 77.0872L228.136 76.2674L234.802 66.4824L236.006 67.3022Z" />
      <path d="M236.123 82.0789L235.778 76.9111L233.405 79.9706L232.255 79.0782L239.511 69.7227L240.662 70.615L236.396 76.1146L241.446 75.1116L243.052 76.357L237.113 77.2784L237.691 83.2949L236.123 82.0789Z" />
      <path d="M245.136 83.8389C245.775 83.2106 246.461 82.7902 247.194 82.5777C247.936 82.3576 248.66 82.3411 249.367 82.528C250.082 82.7226 250.713 83.0975 251.259 83.6527C251.797 84.2003 252.147 84.7916 252.307 85.4265C252.468 86.0615 252.467 86.6535 252.304 87.2028L253.444 86.081L254.476 87.1305L248.225 93.2781L247.193 92.2286L248.356 91.0843C247.789 91.2379 247.182 91.2291 246.535 91.0577C245.903 90.8864 245.321 90.5308 244.79 89.9908C244.244 89.4356 243.888 88.799 243.72 88.0811C243.553 87.3631 243.589 86.6318 243.829 85.887C244.069 85.1423 244.505 84.4596 245.136 83.8389ZM250.33 89.1436C250.801 88.6799 251.119 88.1809 251.282 87.6468C251.444 87.1126 251.456 86.5923 251.317 86.0857C251.193 85.5793 250.941 85.1322 250.559 84.7443C250.178 84.3564 249.739 84.0925 249.242 83.9526C248.745 83.8126 248.229 83.8196 247.692 83.9735C247.155 84.1274 246.651 84.4361 246.179 84.8998C245.7 85.371 245.375 85.8774 245.205 86.419C245.042 86.9532 245.023 87.4735 245.147 87.9799C245.278 88.4788 245.535 88.9222 245.916 89.3101C246.297 89.698 246.736 89.9619 247.233 90.1019C247.745 90.2419 248.269 90.235 248.806 90.0811C249.35 89.9198 249.858 89.6072 250.33 89.1436Z" />
      <path d="M256.945 92.3824C257.54 92.2914 258.146 92.3686 258.763 92.6142C259.38 92.8597 259.917 93.2707 260.375 93.8472C260.866 94.4654 261.161 95.137 261.259 95.8621C261.358 96.5871 261.253 97.3038 260.944 98.0123C260.643 98.7142 260.142 99.3437 259.44 99.9007C258.747 100.451 258.016 100.8 257.247 100.947C256.479 101.094 255.745 101.036 255.046 100.774C254.354 100.52 253.766 100.088 253.282 99.4777C252.811 98.8845 252.526 98.2596 252.427 97.6029C252.335 96.9545 252.399 96.3584 252.621 95.8145L251.356 96.8192L250.45 95.6788L259.724 88.3174L260.629 89.4578L256.945 92.3824ZM258.515 98.7353C259.033 98.3241 259.401 97.8616 259.62 97.3476C259.838 96.8337 259.905 96.3176 259.82 95.7992C259.742 95.2891 259.534 94.821 259.196 94.395C258.864 93.9772 258.452 93.6717 257.957 93.4782C257.478 93.2865 256.956 93.2379 256.391 93.3324C255.841 93.4286 255.311 93.679 254.802 94.0835C254.284 94.4947 253.911 94.9606 253.684 95.4811C253.473 96.0034 253.402 96.5229 253.471 97.0396C253.556 97.558 253.764 98.026 254.096 98.4438C254.434 98.8698 254.842 99.1787 255.322 99.3704C255.816 99.5639 256.338 99.6125 256.888 99.5163C257.446 99.4134 257.989 99.1531 258.515 98.7353Z" />
      <path d="M259.468 108.083C259.025 107.391 258.779 106.662 258.731 105.894C258.688 105.136 258.851 104.411 259.219 103.72C259.601 103.031 260.174 102.442 260.937 101.953C261.691 101.47 262.46 101.205 263.242 101.16C264.038 101.119 264.771 101.283 265.438 101.653C266.115 102.017 266.678 102.55 267.127 103.25C267.576 103.951 267.825 104.684 267.873 105.452C267.93 106.213 267.775 106.94 267.408 107.631C267.046 108.332 266.483 108.927 265.72 109.416C264.956 109.905 264.174 110.166 263.371 110.199C262.583 110.235 261.848 110.067 261.166 109.693C260.483 109.32 259.917 108.783 259.468 108.083ZM260.546 107.392C260.828 107.832 261.196 108.179 261.65 108.433C262.104 108.686 262.612 108.798 263.175 108.766C263.744 108.744 264.324 108.543 264.917 108.163C265.51 107.783 265.938 107.344 266.201 106.846C266.465 106.348 266.574 105.847 266.53 105.343C266.495 104.833 266.336 104.359 266.054 103.919C265.767 103.47 265.399 103.123 264.95 102.878C264.517 102.636 264.021 102.536 263.464 102.576C262.907 102.616 262.333 102.826 261.74 103.206C261.138 103.592 260.7 104.024 260.425 104.505C260.156 104.994 260.036 105.489 260.065 105.99C260.104 106.484 260.264 106.952 260.546 107.392Z" />
      <path d="M274.482 116.627L266.679 120.627L266.015 119.331L267.168 118.74C266.643 118.697 266.152 118.511 265.696 118.182C265.254 117.857 264.896 117.429 264.624 116.898C264.312 116.29 264.156 115.681 264.155 115.071C264.163 114.455 264.345 113.876 264.703 113.333C265.064 112.8 265.606 112.349 266.328 111.979L270.898 109.636L271.555 110.917L267.155 113.173C266.386 113.567 265.893 114.065 265.675 114.669C265.466 115.267 265.535 115.903 265.88 116.577C266.235 117.27 266.729 117.707 267.36 117.886C267.992 118.066 268.716 117.947 269.532 117.528L273.818 115.331L274.482 116.627Z" />
      <path d="M275.389 121.685L270.623 123.682C270.229 123.846 269.984 124.047 269.886 124.285C269.799 124.518 269.842 124.841 270.015 125.254L270.429 126.243L269.293 126.719L268.786 125.509C268.473 124.761 268.41 124.128 268.598 123.61C268.786 123.092 269.273 122.668 270.06 122.339L274.827 120.342L274.388 119.294L275.495 118.831L275.934 119.878L277.97 119.025L278.533 120.368L276.496 121.221L277.38 123.332L276.273 123.795L275.389 121.685Z" />
      <path d="M279.035 132.693C279.619 132.84 280.147 133.146 280.621 133.611C281.095 134.077 281.431 134.664 281.629 135.373C281.841 136.133 281.853 136.867 281.663 137.573C281.473 138.28 281.098 138.9 280.538 139.433C279.989 139.963 279.282 140.349 278.42 140.59C277.567 140.829 276.758 140.867 275.993 140.704C275.227 140.542 274.573 140.204 274.031 139.691C273.492 139.189 273.117 138.562 272.908 137.812C272.704 137.083 272.683 136.397 272.846 135.753C273.013 135.119 273.303 134.595 273.719 134.18L272.163 134.615L271.77 133.213L283.173 130.023L283.565 131.426L279.035 132.693ZM278.019 139.157C278.656 138.979 279.174 138.696 279.575 138.307C279.976 137.918 280.238 137.468 280.361 136.957C280.487 136.457 280.476 135.945 280.33 135.421C280.186 134.907 279.924 134.465 279.544 134.096C279.176 133.733 278.714 133.486 278.157 133.354C277.612 133.229 277.027 133.254 276.4 133.43C275.763 133.608 275.239 133.893 274.828 134.285C274.43 134.684 274.163 135.135 274.027 135.638C273.904 136.149 273.915 136.661 274.058 137.175C274.205 137.699 274.462 138.142 274.829 138.504C275.209 138.874 275.672 139.122 276.216 139.246C276.771 139.368 277.372 139.338 278.019 139.157Z" />
      <path d="M284.818 150.904L276.178 152.395L275.93 150.961L277.207 150.74C276.718 150.543 276.305 150.219 275.968 149.769C275.642 149.327 275.429 148.812 275.327 148.223C275.211 147.551 275.243 146.923 275.424 146.339C275.615 145.754 275.962 145.256 276.465 144.844C276.969 144.443 277.621 144.174 278.42 144.036L283.481 143.162L283.726 144.581L278.854 145.422C278.003 145.569 277.383 145.898 276.995 146.409C276.617 146.918 276.493 147.546 276.622 148.292C276.754 149.059 277.095 149.623 277.644 149.983C278.194 150.342 278.92 150.444 279.824 150.288L284.57 149.469L284.818 150.904Z" />
      <path d="M276.596 157.734C276.538 157.064 276.597 156.454 276.774 155.903C276.961 155.352 277.243 154.904 277.62 154.56C278.007 154.216 278.465 154.005 278.994 153.926L279.125 155.425C278.693 155.505 278.355 155.738 278.11 156.124C277.866 156.52 277.77 157.015 277.822 157.61C277.871 158.163 278.031 158.588 278.303 158.885C278.576 159.183 278.898 159.315 279.27 159.283C279.652 159.249 279.919 159.054 280.07 158.699C280.231 158.342 280.371 157.8 280.49 157.072C280.603 156.409 280.726 155.863 280.86 155.433C281.006 155.014 281.235 154.64 281.549 154.313C281.875 153.995 282.319 153.812 282.882 153.763C283.329 153.724 283.749 153.821 284.144 154.054C284.54 154.287 284.865 154.639 285.12 155.109C285.386 155.578 285.546 156.126 285.601 156.753C285.686 157.72 285.51 158.522 285.073 159.16C284.636 159.798 283.995 160.175 283.148 160.292L283.022 158.842C283.476 158.77 283.826 158.552 284.073 158.188C284.32 157.834 284.419 157.376 284.37 156.813C284.324 156.292 284.176 155.887 283.926 155.599C283.676 155.31 283.371 155.182 283.009 155.213C282.722 155.238 282.491 155.35 282.316 155.547C282.152 155.754 282.023 156.001 281.931 156.287C281.85 156.583 281.762 156.987 281.667 157.499C281.552 158.141 281.427 158.666 281.291 159.074C281.166 159.481 280.951 159.837 280.646 160.142C280.341 160.458 279.924 160.644 279.394 160.701C278.916 160.743 278.474 160.648 278.068 160.416C277.662 160.183 277.326 159.833 277.06 159.363C276.806 158.904 276.651 158.361 276.596 157.734Z" />
      <path d="M287.331 164.036C287.324 163.758 287.414 163.521 287.601 163.325C287.788 163.128 288.02 163.026 288.297 163.019C288.575 163.012 288.812 163.102 289.008 163.289C289.205 163.476 289.307 163.709 289.314 163.986C289.321 164.252 289.23 164.479 289.043 164.665C288.856 164.862 288.624 164.963 288.346 164.97C288.069 164.977 287.832 164.887 287.635 164.7C287.439 164.524 287.337 164.302 287.331 164.036ZM285.925 164.775L277.16 164.996L277.123 163.54L285.888 163.32L285.925 164.775Z" />
      <path d="M286.007 172.88C285.959 173.946 285.595 174.794 284.916 175.426C284.247 176.058 283.305 176.347 282.09 176.293L276.928 176.062L276.992 174.624L281.947 174.845C282.821 174.884 283.496 174.695 283.974 174.279C284.463 173.863 284.724 173.277 284.757 172.52C284.792 171.753 284.579 171.129 284.12 170.65C283.661 170.181 282.973 169.926 282.056 169.885L277.213 169.668L277.278 168.214L286.037 168.605L285.972 170.06L284.726 170.004C285.16 170.312 285.489 170.716 285.713 171.217C285.935 171.729 286.033 172.283 286.007 172.88Z" />
      <path d="M280.268 187.327C279.994 187.288 279.705 187.231 279.404 187.155L280.388 180.217C279.525 180.148 278.813 180.344 278.252 180.803C277.701 181.274 277.371 181.89 277.263 182.65C277.175 183.273 277.244 183.811 277.471 184.263C277.706 184.728 278.055 185.079 278.517 185.317L278.297 186.869C277.495 186.518 276.88 185.957 276.451 185.185C276.032 184.415 275.897 183.512 276.043 182.477C276.16 181.653 276.45 180.94 276.912 180.338C277.373 179.746 277.968 179.313 278.697 179.04C279.435 178.767 280.254 178.695 281.151 178.822C282.049 178.95 282.81 179.241 283.435 179.696C284.06 180.151 284.507 180.726 284.774 181.421C285.051 182.128 285.129 182.904 285.01 183.749C284.893 184.573 284.61 185.276 284.161 185.859C283.712 186.441 283.147 186.857 282.465 187.105C281.792 187.365 281.06 187.439 280.268 187.327ZM280.78 185.88C281.33 185.958 281.817 185.903 282.242 185.716C282.678 185.53 283.026 185.24 283.286 184.846C283.556 184.464 283.727 184.02 283.799 183.513C283.902 182.784 283.758 182.128 283.367 181.544C282.974 180.972 282.371 180.59 281.558 180.399L280.78 185.88Z" />
      <path d="M274.32 191.933C274.471 191.278 274.716 190.716 275.054 190.247C275.403 189.78 275.809 189.441 276.274 189.231C276.749 189.023 277.25 188.964 277.777 189.053L277.438 190.518C277.003 190.461 276.609 190.578 276.257 190.869C275.903 191.171 275.658 191.612 275.524 192.194C275.399 192.735 275.42 193.188 275.587 193.555C275.754 193.922 276.02 194.148 276.383 194.232C276.758 194.318 277.071 194.216 277.325 193.924C277.589 193.635 277.889 193.162 278.227 192.507C278.54 191.911 278.826 191.43 279.086 191.063C279.354 190.709 279.688 190.425 280.087 190.21C280.495 190.009 280.975 189.972 281.525 190.099C281.962 190.2 282.332 190.422 282.636 190.766C282.94 191.11 283.14 191.545 283.238 192.071C283.345 192.599 283.328 193.17 283.187 193.784C282.968 194.729 282.553 195.438 281.94 195.91C281.328 196.381 280.601 196.542 279.76 196.391L280.088 194.973C280.542 195.045 280.942 194.946 281.289 194.675C281.634 194.416 281.87 194.01 281.997 193.459C282.115 192.95 282.099 192.52 281.951 192.168C281.802 191.816 281.551 191.599 281.198 191.518C280.917 191.453 280.663 191.487 280.435 191.621C280.215 191.767 280.017 191.962 279.84 192.206C279.671 192.462 279.463 192.819 279.215 193.277C278.907 193.851 278.626 194.312 278.37 194.658C278.126 195.007 277.811 195.279 277.427 195.475C277.04 195.681 276.585 195.729 276.063 195.619C275.595 195.511 275.204 195.284 274.89 194.938C274.576 194.592 274.365 194.154 274.257 193.626C274.157 193.11 274.178 192.546 274.32 191.933Z" />
      <path d="M272.063 200.272C272.265 199.631 272.554 199.091 272.928 198.65C273.313 198.212 273.745 197.907 274.225 197.734C274.715 197.565 275.219 197.545 275.737 197.675L275.283 199.109C274.853 199.018 274.452 199.104 274.078 199.366C273.701 199.638 273.422 200.059 273.242 200.628C273.075 201.157 273.06 201.611 273.197 201.99C273.335 202.369 273.581 202.615 273.937 202.728C274.303 202.844 274.624 202.766 274.9 202.495C275.186 202.228 275.523 201.781 275.912 201.154C276.271 200.585 276.594 200.128 276.883 199.783C277.178 199.451 277.533 199.194 277.949 199.012C278.371 198.844 278.852 198.845 279.391 199.016C279.818 199.151 280.169 199.402 280.445 199.768C280.72 200.135 280.886 200.585 280.941 201.117C281.007 201.652 280.945 202.22 280.755 202.82C280.462 203.746 279.992 204.419 279.344 204.841C278.696 205.262 277.959 205.365 277.132 205.148L277.571 203.76C278.018 203.868 278.425 203.801 278.793 203.559C279.157 203.327 279.424 202.942 279.595 202.403C279.752 201.904 279.771 201.474 279.651 201.112C279.53 200.749 279.297 200.513 278.952 200.404C278.677 200.317 278.421 200.331 278.183 200.446C277.952 200.574 277.739 200.753 277.544 200.982C277.355 201.225 277.119 201.564 276.836 202C276.483 202.549 276.166 202.985 275.884 203.31C275.613 203.638 275.278 203.885 274.879 204.049C274.477 204.224 274.02 204.236 273.508 204.086C273.05 203.941 272.679 203.683 272.393 203.313C272.107 202.943 271.932 202.491 271.866 201.955C271.807 201.433 271.873 200.872 272.063 200.272Z" />
      <path d="M272.11 218.103L270.992 217.587L273.667 211.79L274.785 212.307L272.11 218.103Z" />
    </Svg>
  );
};

export default Icon;
