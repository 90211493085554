import { css, keyframes } from "styled-components";
// Types
import { ThemedProps } from "./types";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const crownDash = keyframes`
  to {
    stroke-dashoffset: 900;
  }
`;

const dashRhombus = (color: string) => keyframes`
  99% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-width: 0;
    fill: ${color};
  }
`;

export const spinAnimation = css`
  animation: ${rotate} 2s linear infinite;
`;

// TODO In header button & how we work button 3rd line is weird dashed, need to fix
export const defaultButtonHoverLinesAnimation = css`
  path {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;

    &:nth-child(1) {
      animation: ${dash} 0.25s linear forwards;
    }
    &:nth-child(2) {
      animation: ${dash} 0.25s linear 0.1s forwards;
    }
    &:nth-child(3) {
      animation: ${dash} 0.25s linear 0.2s forwards;
    }
  }
`;

export const bannerButtonLeftLinesAnimation = css`
  path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &:nth-of-type(1) {
      animation: ${dash} 0.3s linear 1s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.3s linear 1.2s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.3s linear 1.4s forwards;
    }
  }
`;

export const bannerButtonRightLinesAnimation = css`
  path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &:nth-of-type(1) {
      animation: ${dash} 0.3s linear 1.6s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.3s linear 1.8s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.3s linear 2s forwards;
    }
  }
`;

export const crownAnimation = css`
  path {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;

    &:nth-of-type(1) {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: ${crownDash} 1s linear 1s forwards;
    }
    &:nth-of-type(2) {
      animation: ${dash} 0.2s linear 2s forwards;
    }
    &:nth-of-type(3) {
      animation: ${dash} 0.2s linear 2.2s forwards;
    }
    &:nth-of-type(4) {
      animation: ${dash} 0.2s linear 2.4s forwards;
    }
    &:nth-of-type(5) {
      animation: ${dash} 0.2s linear 2.6s forwards;
    }
  }
`;

export const circleWrapperAnimation = css`
  path {
    stroke-dasharray: 800;
    stroke-dashoffset: 800;
    animation: ${dash} 0.5s linear forwards;
  }
`;

export const inputLabelUnderlineAnimation = css`
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: ${dash} 1s linear 0.25s forwards;
`;

export const textWaveAnimation = css`
  path {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: ${dash} 2s linear 1s forwards;
  }
`;

export const portfolioRemarkRhombusAnimation = ({ theme }: ThemedProps) => {
  return css`
    path {
      stroke-dasharray: 500;
      stroke-dashoffset: 500;
      animation: ${dashRhombus(theme.colors.textFourth)} 5s linear 1s forwards;
    }
  `;
};

export const planetcoinPhasesLineAnimation = css`
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: ${dash} 5s linear 0.25s forwards;
`;

export const curlyArrowAnimation = css`
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: ${dash} 1s linear 1s forwards;
`;

export const titleStraightLinesAnimation = css`
  path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &:nth-of-type(1) {
      animation: ${dash} 0.3s linear 0.4s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.3s linear 0.6s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.3s linear 0.8s forwards;
    }
  }
`;

export const findSolutionArrowAnimation = css`
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  animation: ${dash} 1.5s linear 0.5s forwards;
`;

export const ellipseWrapperAnimation = css`
  path {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: ${dash} 1s linear forwards;
  }
`;
