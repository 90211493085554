import styled from "styled-components";
import { motion } from "framer-motion";
// Components + styling
import { Heading } from "components";
import { fillTextWithGradient } from "components/text";

export const StyledBannerTitle = styled(Heading)`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-bottom: 0;
  }
`;

export const StyledTitleWrapper = styled(motion.span)`
  height: 2.375rem;
  display: block;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 4.25rem;
  }

  span {
    position: relative;
  }

  &:nth-of-type(1) {
    span {
      span {
        ${fillTextWithGradient("textAccent")}
      }
    }
  }

  &:nth-of-type(2) {
    span {
      &:nth-of-type(1) {
        span {
          ${fillTextWithGradient("textAccent")}
        }

        ${({ theme }) => theme.mediaQueries.tablet} {
          span {
            -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
          }
        }
      }
    }
  }
`;
