import React, { useState } from "react";
// Components + styling
import { Box, Button, Container, Flex, Heading, InputGroup, Text, Form, Input, ThankForRequest } from "components";
import { SalesList } from "./components";
// Context
import { useTranslation } from "context";
// Hooks
import { useForm, useMatchBreakpoints } from "hooks";
import { useValidationSchema } from "./hooks";
// Services
import { isErrorResult, submitGetFeedback } from "services";

const SendEmail: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isLargeDesktop, isLaptop } = useMatchBreakpoints();

  const [isShowThankYou, setIsShowThankYou] = useState(false);

  const { validationSchema, initialValues } = useValidationSchema();
  const { values, errors, touched, isValid, fieldProps, handleSubmit, resetForm } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  const [pending, setPending] = useState(false);

  const submitHandler = (formValues: typeof values) => {
    const formData = new FormData();

    Object.entries(formValues).forEach(([key, value]) => formData.append(key, value));

    setPending(true);

    submitGetFeedback(formData).then(response => {
      if (!isErrorResult(response)) {
        setIsShowThankYou(true);
        resetForm();
      } else {
        console.error("submitGetFeedback at SendEmail:", response);
      }
      setPending(false);
    });
  };

  return (
    <Box $backgroundColor="bgDark">
      <Container py={{ _: "2rem", laptop: "9.375rem" }}>
        <Flex flexDirection={isDesktop ? "row" : "column"}>
          <Box pr={{ _: "0", laptop: "2rem" }}>
            <Heading
              scale={isDesktop ? "h2" : "h4"}
              as="h2"
              $fontWeight="bold"
              color="white"
              mb={{ _: "0.25rem", laptop: "1rem" }}
            >
              {t("Are you ready to design a new product?")}
            </Heading>

            <Text
              textScale={isDesktop ? "p22Regular" : "p16Regular"}
              color={isDesktop ? "textFourth" : "white"}
              mb={{ _: "1rem", laptop: "2.5rem" }}
            >
              {t("Share your idea with us, and our team will write you back swiftly")}
            </Text>

            {!isDesktop && <SalesList />}

            {isShowThankYou ? (
              <ThankForRequest setIsShowThankYou={setIsShowThankYou} textColor="white" isHideBgImage isHideButton />
            ) : (
              <Form width="100%" onSubmit={handleSubmit}>
                <InputGroup
                  scale={isLargeDesktop ? "lg" : isLaptop ? "md" : "sm"}
                  label={t("Please contact me at")}
                  error={errors.email}
                  isTouched={touched.email}
                  isColumnLabel={isMobile || isLaptop}
                  labelColor="white"
                >
                  <Input {...fieldProps("email")} placeholder={t("Your email here")} color="white" />
                </InputGroup>

                <Box width="fit-content" mx={{ _: "auto", laptop: "0" }}>
                  <Button
                    width="100%"
                    maxWidth={isDesktop ? "25.875rem" : "21.375rem"}
                    scale="lg"
                    disabled={!isValid}
                    isLoading={pending}
                    type="submit"
                  >
                    {t("Contact me")}
                  </Button>
                </Box>
              </Form>
            )}
          </Box>
          {isDesktop && <SalesList />}
        </Flex>
      </Container>
    </Box>
  );
};

export default SendEmail;
