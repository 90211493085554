import React from "react";
// Components + styling
import { StyledWireframeImage } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { WireframeItemProps } from "./types";

const WireframeItem: React.FC<WireframeItemProps> = ({
  src,
  srcHover,
  itemWidth,
  itemRatio,
  borderColor,
  shadow,
  hoverBorderRadius,
  isBorder,
  isHideBaseImgOnHover,
}) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledWireframeImage
      width={`${isDesktop ? itemWidth.desktop : itemWidth.mobile}rem`}
      height={`${(isDesktop ? itemWidth.desktop : itemWidth.mobile) / itemRatio}rem`}
      src={src}
      srcHover={srcHover}
      color={borderColor}
      shadow={shadow}
      hoverBorderRadius={hoverBorderRadius}
      isBorder={isBorder}
      isHideBaseImgOnHover={isHideBaseImgOnHover}
    />
  );
};

export default WireframeItem;
