import { motion } from "framer-motion";
// Components + Styling
import { StyledWireframesParallaxImageWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints, useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const WireframesParallaxImage = React.forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const { isMobile } = useMatchBreakpoints();

  const parallaxOffset: UseScrollOffsetOptions[] = ["start end", "start start"];

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 70, { offset: parallaxOffset }),
    useParallaxEffect(ref, 50, { offset: parallaxOffset }),
    useParallaxEffect(ref, 95, { offset: parallaxOffset }),
  ];

  return (
    <StyledWireframesParallaxImageWrapper>
      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, ratio }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];
        const isParallaxActive = !isMobile || index !== 0;

        return (
          <motion.div key={imageSrc} style={{ y: isParallaxActive ? y : 0 }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </StyledWireframesParallaxImageWrapper>
  );
});

WireframesParallaxImage.displayName = "WireframesParallaxImage";

export default WireframesParallaxImage;
