import React from "react";
// Components + styling
import { Box, RatingStars, SliderNavButtons, Text } from "components";
import { QuoteIcon } from "components/svg";
import {
  StyledTestimonialsSliderWrapper,
  StyledTestimonialsSlider,
  StyledContainerInSlider,
  StyledTestimonialsSlide,
  StyledSlideImageWrapper,
  StyledSlideDescription,
  StyledSliderCompany,
  StyledSliderRating,
  SliderNavButtonsWrapper,
  StyledSlideImage,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useSliderController } from "hooks";
// Configs
import { sliderSettings } from "./config";
// Constants
import { TESTIMONIALS_REVIEW_LIST } from "../../constants";

const TestimonialsSlider: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isLargeDesktop, isDesktop } = useMatchBreakpoints();

  const { sliderRef, handleClickPrevious, handleClickNext } = useSliderController();

  return (
    <StyledTestimonialsSliderWrapper>
      <StyledTestimonialsSlider ref={sliderRef} {...sliderSettings}>
        {TESTIMONIALS_REVIEW_LIST.map(
          ({ image, imageRatio, imageBg, title, description, company, RatingCompanyIcon, rating, starColor }) => (
            <StyledContainerInSlider key={description}>
              <StyledTestimonialsSlide>
                <StyledSlideImageWrapper bgGradient={imageBg}>
                  <StyledSlideImage width="100%" src={image} aspectRatio={imageRatio} bgGradient={imageBg} />
                </StyledSlideImageWrapper>
                <StyledSlideDescription>
                  <Box mb={{ _: "0.5rem", tablet: "0.75rem" }}>
                    <QuoteIcon width="2rem" />
                  </Box>
                  <Text textScale={isDesktop ? "p22Medium" : "p16Medium"} mb="0.75rem">
                    {t(title)}
                  </Text>
                  <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mb="0.75rem">
                    {t(description)}
                  </Text>
                  <StyledSliderCompany textScale={isDesktop ? "p22Medium" : "p16Medium"} color="textFourth">
                    {t(company)}
                  </StyledSliderCompany>
                  <StyledSliderRating>
                    <RatingCompanyIcon
                      height={isMobile ? "1.25rem" : isLargeDesktop ? "2rem" : "1.5rem"}
                      width="100%"
                    />
                    <RatingStars rating={rating} fillColor={starColor} width={isMobile ? "1rem" : "1.375rem"} />
                  </StyledSliderRating>
                </StyledSlideDescription>
              </StyledTestimonialsSlide>
            </StyledContainerInSlider>
          ),
        )}
      </StyledTestimonialsSlider>
      <SliderNavButtonsWrapper>
        <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} />
      </SliderNavButtonsWrapper>
    </StyledTestimonialsSliderWrapper>
  );
};

export default TestimonialsSlider;
