import styled from "styled-components";
import { clearfix, clearfixDisplayNone } from "styles";
// Components
import { Box, Column, Flex, Heading } from "components";

export const StyledPlanetcoinBannerWrapper = styled(Column)`
  min-height: 100vh;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};
  padding-top: 2.5rem;
  background:
    0 bottom / 100% no-repeat url("/images/planetcoin-banner-bg.svg"),
    ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-top: 4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.semiLargeHeight} {
    min-height: 50vh;
  }

  ::before {
    ${clearfix}
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.planetcoinBgDark};
  }

  ::after {
    ${clearfix}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndices.buttonGradient};
    background: 0 center / 100% 100% no-repeat url("/images/planetcoin-banner-gradient-sm.webp");

    ${({ theme }) => theme.mediaQueries.laptop} {
      background-image: url("/images/planetcoin-banner-gradient.webp");
    }
  }
`;

export const StyledPlanetcoinBannerSubtitle = styled(Heading)`
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.textFourth};

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 1.5rem;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
`;

export const StyledBannerCategory = styled(Box)`
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radii.large};
  background: ${({ theme }) => theme.gradients.planetcoinBannerCategory};
  box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerCategory};
  backdrop-filter: blur(1.81px);
`;

export const StyledStatisticsList = styled(Flex)`
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.laptop} {
    position: relative;
    padding: 2.625rem 1rem;

    ::before {
      ${clearfix}
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.5;
      border-radius: ${({ theme }) => theme.radii.xl};
      box-shadow: ${({ theme }) => theme.shadows.planetcoinBannerCategory};
      backdrop-filter: blur(1.81px);
      background: ${({ theme }) => theme.gradients.planetcoinBannerCategory};
    }
  }
`;

export const StyledStatisticItem = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: center;
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
  }

  ::after {
    ${clearfixDisplayNone}
    width: 1px;
    height: 1.25rem;
    background: ${({ theme }) => `${theme.colors.white}33`};

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
    }
  }

  &:nth-last-of-type(1) {
    ::after {
      display: none;
    }
  }
`;

export const StyledBannerStatisticValue = styled(Heading)`
  text-align: center;
  font-size: 2.125rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.planetcoinWhite};
`;

export const StyledBannerPointsList = styled(Flex)`
  flex-direction: column;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const StyledBannerPointItemWrapper = styled(Flex)`
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: flex-start;
    margin-bottom: 0;
  }

  ::after {
    ${clearfixDisplayNone}
    width: 0.3125rem;
    height: 19.75rem;
    background: ${({ theme }) => theme.gradients.planetcoinBannerPointDivider};

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
    }
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;

    ::after {
      ${({ theme }) => theme.mediaQueries.laptop} {
        display: none;
      }
    }
  }
`;

export const StyledBannerPointItem = styled(Column)`
  max-width: 40rem;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: initial;
    padding: 0 3.375rem;
  }
`;
