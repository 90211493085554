import { FC } from "react";
import { useTheme } from "styled-components";
// Components
import { Text, Flex, Box } from "components";
import { EStatesBox } from "./styled";
import {
  EStatesUserFlowTopLineRouteIcon,
  EStatesUserFlowLeftLineRouteIcon,
  EStatesUserFlowRightLineRouteIcon,
} from "components/svg";
// Context
import { useTranslation } from "context";
// Types
import { BoxItemProps } from "./types";

// IMPORTANT use only px for map user flow
const BoxItem: FC<BoxItemProps> = ({
  text,
  isShowTopLine,
  topLineColor,
  isShowLeftLine,
  isShowRightLine,
  children,
  isMultiply,
  ...props
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Flex
      position="relative"
      mt={isMultiply ? "0" : "66px"}
      zIndex={isShowRightLine ? theme.zIndices.tooltip : undefined}
    >
      {isShowLeftLine && <EStatesUserFlowLeftLineRouteIcon width="90px" height="8px" ml="-11.5px" mr="-6px" />}

      <EStatesBox {...props}>
        {isShowTopLine && (
          <Box position="absolute" top="-73px">
            <EStatesUserFlowTopLineRouteIcon color={topLineColor} />
          </Box>
        )}

        <Text textAlign="center">{t(text)}</Text>
      </EStatesBox>

      {isShowRightLine && <EStatesUserFlowRightLineRouteIcon width="90px" height="8px" ml="-5.5px" mr="-11px" />}

      {children}
    </Flex>
  );
};

export default BoxItem;
