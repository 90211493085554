import { useRef } from "react";
// Components + styling
import { Wireframes } from "components";
import { WireframesParallaxImage } from "./components";
import { StyledUgreatorWireframesWrapper } from "./styled";
// Utils
import { generateRepeatedArray } from "utils";
// Constants
import { DOWN_LINE_IMAGES_LIST, UP_LINE_IMAGES_LIST } from "./constants";

const UgreatorWireframes: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const upLineImages = generateRepeatedArray({
    sourceArray: UP_LINE_IMAGES_LIST,
    repeatCount: 6,
  });
  const downLineImages = generateRepeatedArray({
    sourceArray: DOWN_LINE_IMAGES_LIST,
    repeatCount: 6,
  });

  return (
    <StyledUgreatorWireframesWrapper ref={wrapperRef}>
      <WireframesParallaxImage ref={wrapperRef} />

      <Wireframes
        upLineImages={upLineImages}
        downLineImages={downLineImages}
        wireframesTitle="Wireframes"
        wireframesSubTitle="We have developed more than 500 screens"
        titleColor="textMain"
        subTitleColor="textFourth"
        upBaseVelocity={{ baseVelocity: 0.5 }}
        downBaseVelocity={{ baseVelocity: -0.5 }}
        wireframeItemWidth={{ desktop: 13.625, mobile: 13.625 }}
        wireframeItemRatio={0.46}
        wireframeItemBorderColor="transparent"
        wireframeItemShadow="ugreatorWireframesShadow"
        wireframeItemHoverBorderRadius="xxl"
        isBorder
        isHideBaseImgOnHover
      />
    </StyledUgreatorWireframesWrapper>
  );
};

export default UgreatorWireframes;
