import React from "react";
// Components + styling
import { Button, Container, Flex, Box, Heading, Text, Image } from "components";
import { StyledInnerWrapper, StyledBannerDescription, StyledBannerImageWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";

const CareersBanner: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Box $backgroundColor="white">
      <StyledInnerWrapper headerHeight={headerHeight}>
        <Container>
          <Flex flexDirection={isDesktop ? "row" : "column"} justifyContent="space-between">
            <StyledBannerDescription>
              <Heading scale="h2" as="h2" color="white" mb={{ _: "0.5rem", laptop: "1rem" }}>
                {t("Careers")}
              </Heading>
              <Text
                textScale={isDesktop ? "p24Regular" : "p16Regular"}
                color="textFourth"
                textAlign={isDesktop ? "left" : "center"}
                mb={{ _: "1rem", laptop: "2.5rem" }}
              >
                {t(
                  "Hi! I am Aliona, HR manager at Idealogic Design. We will be the first to talk about the position you apply for and have the introduction call to talk about common values and vision",
                )}
              </Text>
              <Button
                width="100%"
                maxWidth="18.75rem"
                scale="lg"
                onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
              >
                {t("Contact me")}
              </Button>
            </StyledBannerDescription>
            <StyledBannerImageWrapper>
              <Image width="100%" src="/images/aliona.webp" aspectRatio={0.83} />
            </StyledBannerImageWrapper>
          </Flex>
        </Container>
      </StyledInnerWrapper>
    </Box>
  );
};

export default CareersBanner;
