// Styled components
import { StyledSlideImage1, StyledSlideImage2, StyledSlideImage3, StyledSlideImage4 } from "./styled";
// Theme
import { zIndices } from "theme/base";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage1,
    imageSrc: "/images/case-studies/ugreator/ugreator-user-flows-parallax-img-1.webp",
    ratio: 1, // ratio of image width/height
    zIndex: zIndices.buttonContent,
  },
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/case-studies/ugreator/ugreator-user-flows-parallax-img-2.webp",
    ratio: 0.59, // ratio of image width/height
    zIndex: zIndices.buttonGradient,
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/case-studies/ugreator/ugreator-user-flows-parallax-img-3.webp",
    ratio: 0.9, // ratio of image width/height
    zIndex: zIndices.buttonContent,
  },
  {
    StyledComponent: StyledSlideImage4,
    imageSrc: "/images/case-studies/ugreator/ugreator-user-flows-parallax-img-4.webp",
    ratio: 0.54, // ratio of image width/height
    zIndex: zIndices.buttonGradient,
  },
];
