import styled from "styled-components";
// Components
import { StyledLineMotion, StyledWireframesLine, StyledWireframesLineWrapper } from "components/wireframes/styled";

export const StyledSingleLayerWrapper = styled(StyledWireframesLineWrapper)`
  position: absolute;
  right: 0;
  left: 0;
`;

export const StyledSingleLayer = styled(StyledWireframesLine)``;

export const StyledSingleLayerMotion = styled(StyledLineMotion)``;
