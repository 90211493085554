// Types
import { UgreatorUserFlowsFirstColumnItem, ugreatorUserFlowsFirstColumnConnectionType } from "./types";

export const UGREATOR_USER_FLOWS_FIRST_COLUMN_LIST: UgreatorUserFlowsFirstColumnItem[] = [
  {
    label: "Authorization",
    borderColor: "ugreatorUserFlowColumn1",
    connectionType: ugreatorUserFlowsFirstColumnConnectionType.right,
  },
  {
    label: "Verification",
    borderColor: "ugreatorUserFlowColumn1",
    connectionType: ugreatorUserFlowsFirstColumnConnectionType.top,
  },
  {
    label: "Daily Bonus",
    borderColor: "ugreatorUserFlowColumn1",
    connectionType: ugreatorUserFlowsFirstColumnConnectionType.top,
  },
];
