// Components
import { Heading, Text, Image, Button, Column } from "components";
// Context
import { useCaseStudyContext, useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const CaseStudiesNoResults: React.FC = () => {
  const { t } = useTranslation();
  const { resetAllFilters } = useCaseStudyContext();

  const { isDesktop, isMobile } = useMatchBreakpoints();

  return (
    <Column mt={{ _: "2rem", laptop: "3rem" }} alignItems="center">
      <Column mb={{ _: "3rem", laptop: "4.5rem" }} alignItems="center">
        <Heading mb={{ _: "0.5rem", laptop: "0.75rem" }} as="h4" scale="h4" $fontWeight="bold">
          {t("No results found for this filter")}
        </Heading>

        <Text mb="1.5rem" textScale={isDesktop ? "p20Regular" : "p16Regular"} color="textFifth">
          {t("Please try another filter or")}
        </Text>

        <Button
          width="100%"
          maxWidth={{ _: "15rem", tablet: "16.5rem" }}
          mx="auto"
          scale={isDesktop ? "lg" : "md"}
          variant={"primary"}
          color="textMain"
          hoverLinesPosition={isMobile ? "mobileTopLeft" : "bottomLeft"}
          filledType="bordered"
          onClick={resetAllFilters}
        >
          {t("Clear all filters")}
        </Button>
      </Column>

      <Image
        width={{ _: "100%", tablet: "39rem" }}
        src="images/case-studies/case-studies-no-results.webp"
        aspectRatio={1.64}
      />
    </Column>
  );
};

export default CaseStudiesNoResults;
