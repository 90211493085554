import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Flex } from "components";

export const StyledUserCasesList = styled(Flex)<{ isCustomCursorVisible: boolean }>`
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 1.75rem;
  cursor: ${({ isCustomCursorVisible }) => (isCustomCursorVisible ? "none" : "initial")};

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const StyledCustomCursor = styled(motion.div)`
  width: 7.25rem;
  height: 7.25rem;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  /* Need 50% of width for negative margin left for centralize custom cursor block  */
  margin: 0 0 0 -3.625rem;
  border-radius: ${({ theme }) => theme.radii.circle};
  text-align: center;
  user-select: none;
  pointer-events: none;
  transform-origin: center;
  background: ${({ theme }) => theme.colors.textMain};

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: flex;
  }
`;
