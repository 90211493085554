import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledAirplaneAnimation = styled(Lottie)`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledHeartAnimation = styled(Lottie)`
  width: calc(95rem / 16 / 2.6);
  position: absolute;
  top: calc(183rem / 16 / 2.6);
  left: calc(101rem / 16 / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(95rem / 16 / 1.6);
    top: calc(183rem / 16 / 1.6);
    left: calc(101rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.9375rem;
    top: 11.4375rem;
    left: 6.3125rem;
  }
`;
