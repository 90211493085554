import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 26 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.67 4.045c0-.925-.242-1.718-.725-2.382C11.14.583 9.775.028 7.845 0H.2v15.607h7.128c.804 0 1.548-.069 2.236-.21.688-.142 1.282-.402 1.786-.784a4.338 4.338 0 0 0 1.12-1.228c.471-.733.706-1.564.706-2.489 0-.897-.206-1.66-.616-2.286-.414-.629-1.021-1.087-1.828-1.378.531-.268.933-.565 1.207-.888.489-.58.731-1.347.731-2.3ZM23.338 2.67h-6.78V.728h6.78V2.67Zm-5.99 6.024c.112-.751.384-1.347.816-1.786.432-.44 1.043-.66 1.826-.66.723 0 1.326.207 1.817.621.485.417.758 1.023.814 1.825h-5.273ZM22.74 4.3c.817.36 1.49.93 2.02 1.708.483.686.793 1.482.935 2.386.082.529.116 1.295.102 2.291h-8.524c.051 1.157.455 1.97 1.226 2.434.466.29 1.027.435 1.687.435.694 0 1.261-.176 1.696-.528.24-.192.45-.456.63-.795h3.124c-.083.683-.462 1.38-1.133 2.087C23.455 15.438 21.988 16 20.102 16c-1.558 0-2.933-.472-4.12-1.42-1.194-.946-1.787-2.487-1.787-4.62 0-2 .537-3.533 1.61-4.601 1.077-1.065 2.47-1.6 4.184-1.6 1.019 0 1.935.18 2.751.541ZM3.353 12.898V8.736H7.25c.677.006 1.205.095 1.582.264.672.304 1.007.861 1.007 1.675 0 .96-.347 1.607-1.04 1.948-.381.183-.917.275-1.604.275h-3.84Zm0-10.188v3.443H7.2c.685 0 1.244-.129 1.674-.387.428-.258.643-.716.643-1.37 0-.728-.283-1.208-.849-1.443-.49-.161-1.114-.243-1.87-.243H3.352Z"
      />
    </Svg>
  );
};

export default Icon;
