export const TERMS_CONDITIONS_LIST = [
  {
    label: "Using the Website",
    description:
      "The usage of this Website is limited to individuals and companies who have the lawful capability to enter into binding agreements according to the applicable law in their country of residence. By accepting these Terms, you confirm that you have reached the legal age as required in your country of residence. It is not permitted to use this Website for any unauthorized or unlawful purpose. We do not guarantee the accuracy, reliability, performance, or completeness of any content and information that we provide You through the Website. We will make every effort to ensure that the information on the Website is from credible sources. We will not be held responsible for any use or interpretation of such information. All information on this Website is solely intended for Your guidance and should not be solely relied upon or used as the basis for making decisions. Your usage of the Website and all the information provided is Your own responsibility. From time to time, some information on this Website may contain typographical errors, inaccuracies, or omissions. We hold the right to correct any such errors, inaccuracies, or omissions and to modify or update any information on this Website at any given time without prior notice. We are not obliged to update, modify, or clarify any information on this Website, except when legally required to do so.",
  },
  {
    label: "Intellectual Property",
    description:
      "We exclusively own all materials on this Website, all texts, articles, images, and logos, and they are the intellectual property of IDEALOGIC DESIGN. These materials cannot be copied or distributed for any commercial or private purposes without prior written consent from IDEALOGIC DESIGN.",
  },
  {
    label: "Third-party Links",
    description:
      "The Website may contain materials from third parties and links to third-party websites that are not affiliated with us. We do not guarantee the accuracy, completeness, or reliability of any third-party content, and we do not assume any responsibility for such content. We shall not be liable for any damages or harm arising from or in connection with the purchase or use of any goods, services, resources, content, or any other transactions made through any third-party websites. It is your responsibility to carefully review and understand the policies and practices of any third party websites before engaging in any transaction. Any complaints, claims, concerns, or questions regarding third-party products should be directed to the respective third party.",
  },
  {
    label: "Limitation of Liability",
    description:
      "IDEALOGIC DESIGN shall not be held liable for any direct, indirect, incidental, punitive, special, or consequential damages of any kind arising from Your use of the Website. This limitation of liability applies even if IDEALOGIC DESIGN has been advised of the possibility of such damages. However, if the laws of Your state or jurisdiction do not allow the exclusion or limitation of incidental or consequential damages, our liability shall be limited to the maximum extent permitted by law.",
  },
  {
    label: "Termination",
    description:
      "The validity of these Terms will remain in effect until terminated by either party. You have the option to terminate these Terms at any time by informing us of your decision to discontinue using the Website. We maintain the right to suspend or terminate your access to the Website if we have knowledge or reason to believe that you have violated, or violate any term or provision outlined in these Terms.",
  },
  {
    label: "Governing Law",
    description:
      "These Terms and any separate agreements whereby we provide You services shall be governed by and construed under the laws of Estonia.",
  },
  {
    label: "Changes to Terms and Conditions",
    description:
      "We hold the right to change, revise, or substitute any part of these Terms by publishing updates and/or modifications on this Website. In order to continue using the Website, you might be asked to read and accept these Terms once again when they are revised. It is highly recommended that you review these Terms each time you access or use this Website.",
  },
];
