// Components
import { Page } from "components";
import {
  ClientsTestimonials,
  Interactions,
  PlanetcoinBanner,
  PlanetcoinInterview,
  PlanetcoinWireframes,
  ProjectPhases,
  ShortSummary,
} from "./components";
// Hooks
import { useElementDimensions } from "hooks";
// Constants
import { BLOCK_ID } from "configs";

const PlanetcoinCasePage: React.FC = () => {
  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    // If in one of the components will use position: sticky, need to remove overflowX="hidden" for correct work sticky.(but need to add wrapper with overflowX="hidden" for <PlanetcoinWireframes />)
    <Page maxWidth="100vw" overflowX="hidden" px="0" mt={`-${headerHeight}px`}>
      <PlanetcoinBanner />

      <ProjectPhases />

      <PlanetcoinInterview />

      <PlanetcoinWireframes />

      <Interactions />

      <ShortSummary />

      <ClientsTestimonials />
    </Page>
  );
};

export default PlanetcoinCasePage;
