import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  return (
    <Svg viewBox="0 0 56 56" {...props} color={color}>
      <path d="M38.6743 17.3298C37.6888 13.3754 35.3466 9.87437 32.043 7.43472C31.7305 7.204 31.2903 7.27028 31.0596 7.58266C30.8289 7.89503 30.8952 8.33528 31.2076 8.566C34.0646 10.6759 36.1404 13.6474 37.1326 17.0166C36.2425 16.8774 35.3307 16.8045 34.4022 16.8045C24.6988 16.8045 16.8044 24.6988 16.8044 34.4023C16.8044 35.3242 16.8751 36.2356 17.0137 37.1308C13.895 36.2087 11.0862 34.3407 9.01891 31.7933C6.68922 28.9226 5.40625 25.3018 5.40625 21.5978C5.40625 12.6697 12.6698 5.40625 21.5978 5.40625C24.1726 5.40625 26.6343 5.99284 28.9146 7.14981C29.261 7.3255 29.6841 7.18722 29.8598 6.84091C30.0355 6.49459 29.8972 6.07141 29.5509 5.89572C27.0715 4.63778 24.3957 4 21.5978 4C11.8944 4 4 11.8943 4 21.5978C4 25.6234 5.39463 29.559 7.927 32.6794C10.3286 35.6388 13.6521 37.7545 17.3243 38.6726C17.8694 40.8649 18.8366 42.9351 20.1983 44.7929C20.4279 45.1061 20.8679 45.1739 21.181 44.9443C21.4941 44.7147 21.562 44.2747 21.3325 43.9615C20.2158 42.4381 19.3878 40.7593 18.8658 38.9838C18.8968 38.9887 18.9279 38.994 18.9588 38.9987C18.9943 39.004 19.0294 39.0065 19.0643 39.0065C19.4064 39.0065 19.7063 38.7565 19.7587 38.4078C19.8165 38.0238 19.552 37.6657 19.168 37.608C18.9468 37.5747 18.7252 37.535 18.5042 37.4924C18.3101 36.4836 18.2107 35.4504 18.2107 34.4021C18.2107 25.474 25.4743 18.2105 34.4023 18.2105C35.4593 18.2105 36.4925 18.3136 37.4936 18.5078C37.5359 18.7273 37.5752 18.9473 37.6081 19.1667C37.6605 19.5153 37.9604 19.7655 38.3026 19.7655C38.3373 19.7655 38.3725 19.7628 38.4079 19.7576C38.7919 19.6999 39.0565 19.3418 38.9988 18.9578C38.9945 18.9293 38.9897 18.9007 38.9852 18.8721C45.6881 20.8537 50.5938 27.0653 50.5938 34.4022C50.5938 43.3303 43.3302 50.5938 34.4022 50.5938C30.154 50.5938 26.1395 48.9607 23.0984 45.9953C22.8203 45.7242 22.3752 45.7298 22.1042 46.0079C21.833 46.2859 21.8387 46.7311 22.1167 47.0022C25.422 50.225 29.7851 52 34.4022 52C44.1056 52 52 44.1057 52 34.4022C52 26.1717 46.32 19.2437 38.6743 17.3298Z" />
      <path d="M24.5862 38.2435C24.5341 37.8587 24.1801 37.5893 23.7951 37.6412C23.1045 37.7347 22.3987 37.7843 21.6971 37.7884C21.3087 37.7908 20.9958 38.1074 20.9981 38.4957C21.0003 38.8826 21.3147 39.1947 21.7011 39.1947H21.7053C22.4671 39.1902 23.2337 39.1363 23.9839 39.0347C24.3687 38.9825 24.6384 38.6283 24.5862 38.2435Z" />
      <path d="M26.4589 38.4849C26.5258 38.4849 26.5939 38.4752 26.6613 38.455C27.389 38.2367 28.1098 37.9678 28.8033 37.6561C29.1575 37.4969 29.3156 37.0808 29.1564 36.7266C28.9972 36.3723 28.5809 36.2143 28.2268 36.3735C27.589 36.6602 26.9262 36.9073 26.2571 37.1081C25.8852 37.2198 25.6741 37.6118 25.7857 37.9837C25.8772 38.2882 26.1565 38.4849 26.4589 38.4849Z" />
      <path d="M37.1121 26.2497C36.9117 26.9194 36.6648 27.5826 36.3782 28.221C36.2192 28.5753 36.3775 28.9913 36.7318 29.1503C36.8252 29.1923 36.923 29.2121 37.0192 29.2121C37.2877 29.2121 37.5441 29.0575 37.6611 28.7967C37.9727 28.1025 38.2412 27.3812 38.4592 26.6529C38.5706 26.2809 38.3593 25.8891 37.9873 25.7777C37.6153 25.6665 37.2235 25.8777 37.1121 26.2497Z" />
      <path d="M36.1416 30.2286C35.8165 30.0164 35.3806 30.108 35.1685 30.4332C34.7864 31.0186 34.362 31.585 33.9072 32.1168C33.6547 32.4118 33.6893 32.8557 33.9843 33.1082C34.1169 33.2215 34.2793 33.277 34.4412 33.277C34.6395 33.277 34.8367 33.1935 34.9757 33.0309C35.47 32.4532 35.931 31.8378 36.3461 31.2018C36.5584 30.8766 36.4668 30.4409 36.1416 30.2286Z" />
      <path d="M38.248 24.5749C38.2798 24.5792 38.3115 24.5813 38.3428 24.5813C38.6894 24.5813 38.9912 24.325 39.0387 23.9721C39.14 23.2219 39.1933 22.4551 39.1973 21.6931C39.1992 21.3048 38.8862 20.9883 38.4979 20.9863C38.1092 20.9888 37.7931 21.2974 37.791 21.6858C37.7874 22.3876 37.7382 23.0935 37.645 23.7841C37.5932 24.169 37.8631 24.523 38.248 24.5749Z" />
      <path d="M30.8249 36.4557C30.9568 36.4557 31.0901 36.4187 31.2087 36.3412C31.8441 35.9262 32.4591 35.4651 33.0367 34.9706C33.3316 34.7181 33.366 34.2742 33.1135 33.9792C32.8609 33.6843 32.417 33.6499 32.1221 33.9024C31.5905 34.3575 31.0245 34.7819 30.4398 35.1638C30.1147 35.3762 30.0233 35.8119 30.2356 36.137C30.3705 36.3434 30.5955 36.4557 30.8249 36.4557Z" />
    </Svg>
  );
};

export default Icon;
