import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 20 20" {...props} color="transparent">
      <path
        d="M10.5538 9.98597L8.08381 12.456C7.75817 12.7806 7.4998 13.1664 7.32351 13.5911C7.14722 14.0158 7.05648 14.4711 7.05648 14.931C7.05648 15.3908 7.14722 15.8461 7.32351 16.2708C7.4998 16.6955 7.75817 17.0813 8.08381 17.406C8.40848 17.7316 8.79423 17.99 9.21894 18.1663C9.64364 18.3426 10.099 18.4333 10.5588 18.4333C11.0187 18.4333 11.474 18.3426 11.8987 18.1663C12.3234 17.99 12.7091 17.7316 13.0338 17.406L16.9238 13.516C18.235 12.2023 18.9714 10.422 18.9714 8.56597C18.9714 6.7099 18.235 4.92965 16.9238 3.61597C15.6101 2.30477 13.8299 1.56836 11.9738 1.56836C10.1177 1.56836 8.33749 2.30477 7.02381 3.61597L2.78381 7.85597C0.443809 10.196 0.443809 13.996 2.78381 16.346"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
