import styled from "styled-components";
// Components
import { Flex, Input } from "components";
import { ArrowLeftIcon } from "components/svg";

export const StyledFormWrapper = styled(Flex)`
  align-items: center;
  padding-top: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.colors.lineSecond};
`;

export const StyledInput = styled(Input)`
  margin-right: 1.5rem;
  border-bottom: 0;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-bottom: 0;
  }
`;

export const StyledArrowRightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`;
