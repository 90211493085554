export const eStatesAnimatedItems = {
  hidden: {
    top: "3.125rem",
    opacity: 0,
    transition: { duration: 0.3, type: "easyInOut" },
  },
  visible: {
    top: 0,
    opacity: 1,
    transition: { duration: 0.3, type: "easyInOut" },
  },
};
