import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledHornAnimation = styled(Lottie)`
  width: calc(160rem / 16 / 2.6);
  position: absolute;
  top: calc(16rem / 16 / 2.6);
  right: calc(120rem / 16 / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(160rem / 16 / 1.6);
    top: calc(16rem / 16 / 1.6);
    right: calc(120rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10rem;
    top: 1rem;
    right: 7.5rem;
  }
`;

export const StyledCatAnimation = styled(Lottie)`
  width: calc(46rem / 16 / 2.6);
  position: absolute;
  top: calc(183rem / 16 / 2.6);
  left: calc(101rem / 16 / 2.6);
  transform: rotate(-24deg);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(46rem / 16 / 1.6);
    top: calc(183rem / 16 / 1.6);
    left: calc(101rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.875rem;
    top: 11.4375rem;
    left: 6.3125rem;
  }
`;
