import React from "react";
// Components
import { Button, Container, Heading, Text } from "components";
import { LinkIcon } from "components/svg";
import { StyledDevelopmentSuggestionWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { EXTERNAL_LINKS } from "configs";

const DevelopmentSuggestion: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  return (
    <Container pt={{ _: "1.25rem", laptop: "3.75rem" }} pb={{ _: "2.5rem", laptop: "8.125rem" }}>
      <StyledDevelopmentSuggestionWrapper>
        <Heading
          scale={isDesktop ? "h2" : "h3"}
          as={isDesktop ? "h2" : "h3"}
          color="white"
          mb={{ _: "1.25rem", laptop: "1rem" }}
        >
          {t("We also do development")}
        </Heading>

        <Text
          maxWidth="48.125rem"
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          color={isDesktop ? "textFourth" : "textSecond"}
          mx="auto"
          mb={{ _: "2rem", laptop: "3rem" }}
        >
          {t("We have a specialized team of developers, business analysts, and QA testers. Learn more about our work.")}
        </Text>

        <Button
          width={isMobile ? "calc(100% - 1rem)" : "100%"}
          maxWidth={{ _: "18.4375rem", tablet: "16.5rem" }}
          scale={isDesktop ? "lg" : "md"}
          endIcon={<LinkIcon color="white" />}
          hoverLinesPosition="bottomLeft"
          as="a"
          href={EXTERNAL_LINKS.idealogicDev}
          external
        >
          {t("Go to the site")}
        </Button>
      </StyledDevelopmentSuggestionWrapper>
    </Container>
  );
};

export default DevelopmentSuggestion;
