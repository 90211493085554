// Components + styling
import { Flex, Text } from "components";
import { StyledMainDropdownTitle } from "./styled";
import { getTooltipStyles } from "./theme";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useTooltip } from "hooks";
// Types
import { DropdownComponentProps, DropdownProps } from "./types";

const Dropdown = <T extends DropdownComponentProps>({
  title,
  dropdownComponent,
  dropdownProps,
  isUnderline = false,
  isHeaderThemeLight = false,
  placement = "auto",
}: DropdownProps<T>) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const DropdownComponent = dropdownComponent;

  const { targetRef, tooltip, setVisible, tooltipVisible } = useTooltip(
    <DropdownComponent {...dropdownProps} onDropdownMenuClick={onDropdownMenuClick} />,
    {
      customStyles: getTooltipStyles(theme),
      tooltipOffset: [0, 0],
      hideTimeout: 0,
      placement,
    },
  );

  function onDropdownMenuClick() {
    setVisible(prev => !prev);
    dropdownProps.handleCloseMobileMenu?.();
  }

  return (
    <div ref={targetRef}>
      <Flex>
        {typeof title === "string" ? (
          <Text textScale="p16Regular">{t(title)}</Text>
        ) : (
          <StyledMainDropdownTitle isUnderline={isUnderline && tooltipVisible} isHeaderThemeLight={isHeaderThemeLight}>
            {React.isValidElement(title) && title}
          </StyledMainDropdownTitle>
        )}
      </Flex>
      {tooltip}
    </div>
  );
};

export default Dropdown;
