import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textAccent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 286 90" {...props} animationType="ellipseWrapper" color="transparent">
      <path
        d="M224.737 76.9083C178.524 94.6045 89.6629 91.6106 44.05 76.1256C23.2767 69.0732 -15.0287 48.6386 8.28029 29.3459C12.3914 25.9429 17.3886 23.1785 22.5589 20.7863C79.4598 -5.53918 158.822 -2.85818 219.889 12.8938C228.997 15.243 237.959 18.0318 246.204 21.8223C252.371 24.6572 258.333 28.1709 263.135 32.2715C277.193 44.2757 273.521 56.3565 258.396 66.5335C240.86 78.333 216.879 81.9896 194.252 83.9921C174.466 85.7432 154.705 86.0795 134.826 85.3648C97.5431 84.0247 54.5776 81.8923 23.3086 65.7603C9.65109 58.714 -5.02209 43.6574 6.68481 31.4711C19.3708 18.266 49.2828 13.9902 69.4018 11.2037C123.677 3.68622 209.555 7.41374 258.993 27.1407C264.324 29.2681 269.725 31.2505 274.494 34.048C278.262 36.2584 281.87 38.7788 285 41.5065"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
