import React from "react";
// Context
import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "eStatesPrimary", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 79 8" {...props}>
      <path
        d="M6.92183 3.96096C6.92183 2.31601 5.60586 1.00005 3.96091 1.00005C2.31596 1.00005 1 2.31601 1 3.96096C1 5.60591 2.31596 6.92188 3.96091 6.92188C5.60586 6.92188 6.92183 5.60591 6.92183 3.96096ZM6.92183 3.96096L78.5 3.96096"
        stroke={theme.colors[color]}
        strokeWidth="1.97394"
      />
      <path
        d="M1 3.96096C1 5.60591 2.31596 6.92188 3.96091 6.92188C5.60586 6.92188 6.92183 5.60591 6.92183 3.96096C6.92183 2.31601 5.60586 1.00005 3.96091 1.00005C2.31596 1.00005 1 2.31601 1 3.96096Z"
        fill={theme.colors.white}
      />
    </Svg>
  );
};

export default Icon;
