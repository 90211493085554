// Types
import { Gradients } from "theme/types";

export const ugreatorUserFlowsSecondColumnConnectionVariant = {
  homepage: "homepage",
  auctions: "auctions",
  myProfile: "myProfile",
  uShop: "uShop",
  activities: "activities",
} as const;

export type UgreatorUserFlowsSecondColumnConnectionVariantType =
  keyof typeof ugreatorUserFlowsSecondColumnConnectionVariant;

export type UgreatorUserFlowsSecondColumnItem = {
  label: string;
  variant: UgreatorUserFlowsSecondColumnConnectionVariantType;
  connectorLinesSrc: string;
  borderGradient: keyof Gradients;
  itemGradient: keyof Gradients;
};

export type StyledSecondColumnFlowItemProps = {
  variant: UgreatorUserFlowsSecondColumnConnectionVariantType;
  connectorLinesSrc: string;
  borderGradient: keyof Gradients;
  itemGradient: keyof Gradients;
};
