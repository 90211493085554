import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "eStatesPrimary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 1443 517" {...props} animationType="planetcoinPhasesLine" color="transparent">
      <path
        d="M2.5 371C75.6667 440.334 245.1 562 337.5 494C417.5 426.5 458.5 349 594 364C729.5 379 756 371.001 815.5 314.5C875 258 983.5 75.1004 1123.5 129.5C1298.5 197.5 1421 59 1440.5 3"
        stroke="url(#paint0_linear_5001_9738)"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5001_9738"
          x1="2.5"
          y1="513.994"
          x2="1440.5"
          y2="513.994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors[color]} stopOpacity="0" />
          <stop offset="0.25" stopColor={theme.colors[color]} />
          <stop offset="0.708333" stopColor={theme.colors[color]} />
          <stop offset="1" stopColor={theme.colors[color]} stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
