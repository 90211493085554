import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMotionValueEvent, useScroll } from "framer-motion";
// Utils
import { removeTrailingSlashIfExists } from "utils";
// Constants
import { ROUTES } from "navigation/routes";
import { LIGHT_HEADER_THEME_PATHNAME_LIST, headerThemes, headerThemesType } from "../constants";
import { breakpointMap } from "theme/base";

export const useHeaderTheme = () => {
  const { pathname } = useLocation();
  const { positionId, slug } = useParams();
  const [headerTheme, setHeaderTheme] = useState<headerThemesType>(headerThemes.dark);

  const { scrollY } = useScroll();

  const isPathInLightThemePathList = LIGHT_HEADER_THEME_PATHNAME_LIST.includes(removeTrailingSlashIfExists(pathname));
  const isUserOnIdPage = !!slug || !!positionId;
  const isUserOnPlanetcoinCasePage =
    removeTrailingSlashIfExists(pathname) === `/${ROUTES.caseStudies}/${ROUTES.planetcoinCase}`;

  const isUserOnUgreatorCasePage =
    removeTrailingSlashIfExists(pathname) === `/${ROUTES.caseStudies}/${ROUTES.ugreatorCase}`;

  const handleChangeTheme = (latest: number) => {
    if (window.innerWidth < breakpointMap.laptopL) {
      setHeaderTheme(headerThemes.dark);
      return;
    }

    if (isUserOnPlanetcoinCasePage) {
      if (latest) {
        setHeaderTheme(headerThemes.dark);
      } else {
        setHeaderTheme(headerThemes.planetcoinCase);
      }
    }

    if (isUserOnUgreatorCasePage) {
      if (latest) {
        setHeaderTheme(headerThemes.dark);
      } else {
        setHeaderTheme(headerThemes.ugreatorCase);
      }
    }

    if (isPathInLightThemePathList || isUserOnIdPage) {
      if (latest) {
        setHeaderTheme(headerThemes.dark);
      } else {
        setHeaderTheme(headerThemes.light);
      }
    }
  };

  useMotionValueEvent(scrollY, "change", handleChangeTheme);

  useEffect(() => {
    if (window.innerWidth < breakpointMap.laptopL) {
      setHeaderTheme(headerThemes.dark);
      return;
    }

    if (isPathInLightThemePathList || isUserOnIdPage) {
      setHeaderTheme(headerThemes.light);
    } else if (isUserOnPlanetcoinCasePage) {
      setHeaderTheme(headerThemes.planetcoinCase);
    } else if (isUserOnUgreatorCasePage) {
      setHeaderTheme(headerThemes.ugreatorCase);
    } else {
      setHeaderTheme(headerThemes.dark);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pathname]);

  const handleResize = () => {
    handleChangeTheme(window.scrollY);
  };

  return {
    headerTheme,
    isPathInLightThemePathList,
    isUserOnIdPage,
    isUserOnPlanetcoinCasePage,
    isUserOnUgreatorCasePage,
  };
};
