import styled from "styled-components";
import { motion } from "framer-motion";
// Components + styling
import { Box, Button, Flex, Text } from "components";
// Animations
import { elementLevitationAnimation, appearanceAnimation } from "theme/animations";
import { bannerButtonShow } from "../../animations";

export const StyledBannerButtonWrapper = styled(Box)<{ isHovered: boolean }>`
  text-align: center;

  z-index: ${({ theme }) => theme.zIndices.header};
  ${({ isHovered }) => !isHovered && elementLevitationAnimation()};
`;

export const StyledBannerButtonInnerWrapper = styled(Flex)`
  width: 17.5rem;
  height: 13.75rem;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 21.875rem;
    height: 21.875rem;
  }
`;

export const StyledButtonFillWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
  border-radius: ${({ theme }) => theme.radii.circle};
  overflow: hidden;
  transition: ${({ theme }) => theme.transitions.linear_0_2s};
`;

export const StyledBannerButton = styled(Button)<{ isHovered: boolean }>`
  width: 7.25rem;
  min-width: 7.25rem;
  height: 7.25rem;
  position: relative;
  opacity: 0;
  border-radius: ${({ theme }) => theme.radii.circle};
  animation: ${bannerButtonShow} 0.25s linear 0.75s forwards;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 11rem;
    height: 11rem;
  }

  ${StyledButtonFillWrapper} {
    transform: ${({ isHovered }) => `scale(${isHovered ? 1.3 : 1})`};
  }
`;

export const StyledButtonFill = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: ${({ theme }) => theme.zIndices.dropdown - 2};
  border-radius: ${({ theme }) => theme.radii.circle};
  transform: translate3d(0, 110%, 0);
  pointer-events: none;
  background: ${({ theme }) => theme.gradients.buttonAccent};
`;

export const StyledButtonLabelWrapper = styled(motion.div)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledButtonLabel = styled(Text)<{ isHovered: boolean }>`
  opacity: 0;
  animation: ${appearanceAnimation} 0.25s linear 1.25s forwards;
  transform: ${({ isHovered }) => `scale(${isHovered ? 0.8 : 1})`};
  transition: ${({ theme }) => theme.transitions.linear_0_2s};
`;

export const StyledHoverButtonLines = styled(Box)<{ iconPosition: "left" | "right" }>`
  position: absolute;
  left: ${({ iconPosition }) => iconPosition === "left" && "-4.25rem"};
  right: ${({ iconPosition }) => iconPosition === "right" && "-4.25rem"};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transform: ${({ iconPosition }) => (iconPosition === "left" ? "rotate(-135deg)" : "rotate(45deg)")};
`;
