import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" {...props} color="transparent">
      <mask id="mask0_1130_2052" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="72">
        <path d="M0 7.62939e-06H72V72H0V7.62939e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1130_2052)">
        <path
          d="M1.40625 70.5938H70.5938"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.40625 70.5938C1.40625 65.9334 5.60531 62.1563 10.2656 62.1563C11.3625 62.1563 12.8334 62.3658 13.7953 62.7469C14.7712 59.1652 18.0464 56.5313 21.9375 56.5313C24.1102 56.5313 26.0916 57.3525 27.5864 58.7011C27.9155 54.3417 31.5562 50.9063 36 50.9063C40.4438 50.9063 44.0845 54.3417 44.4136 58.7011C45.9084 57.3525 47.8898 56.5313 50.0625 56.5313C53.9536 56.5313 57.2287 59.1652 58.2047 62.7469C59.1666 62.3658 60.6375 62.1563 61.7344 62.1563C66.3947 62.1563 70.5938 65.9334 70.5938 70.5938"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36 50.9062V11.25"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.4375 15.4688C44.4375 19.9351 41.6472 23.9151 39.2758 26.491C37.4771 28.4449 34.5229 28.4449 32.7242 26.491C30.3528 23.9151 27.5625 19.9351 27.5625 15.4688C27.5625 11.0024 30.3528 7.02241 32.7242 4.44648C34.5229 2.49261 37.4771 2.49261 39.2758 4.44647C41.6472 7.0224 44.4375 11.0024 44.4375 15.4688Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.7474 36.1354C56.8793 38.3691 52.0373 37.9425 48.6208 37.1767C46.0295 36.5958 44.5526 34.0376 45.3452 31.503C46.3902 28.1612 48.4417 23.7545 52.3099 21.5216C56.1778 19.288 61.0198 19.7146 64.4363 20.4804C67.0276 21.0612 68.5045 23.6195 67.7119 26.154C66.6669 29.4958 64.6154 33.9025 60.7474 36.1354Z"
          fill={theme.colors[color]}
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.5284 28.8278L36 40.6797"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57 29L47 34"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.6903 21.5216C23.5585 23.7545 25.61 28.1612 26.655 31.503C27.4476 34.0376 25.9707 36.5958 23.3794 37.1767C19.9629 37.9425 15.1209 38.3691 11.2528 36.1354C7.38479 33.9025 5.3333 29.4958 4.28827 26.154C3.49567 23.6195 4.97262 21.0612 7.56392 20.4804C10.9804 19.7146 15.8223 19.288 19.6903 21.5216Z"
          fill="#333333"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4714 28.8278L35.9999 40.6797"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 29L25 34"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Icon;
