import { MotionValue } from "framer-motion";
// Components
import AmbassadorItem from "../ambassador-item";
import { StyledAmbassadorsSliderWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { AMBASSADORS_LIST } from "../../constants";

const AmbassadorsSlider = React.forwardRef<
  HTMLDivElement | null,
  { x: MotionValue<number>; isAnimationActive: boolean }
>(({ x, isAnimationActive }, ref) => {
  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledAmbassadorsSliderWrapper
      ref={ref}
      style={{ x: isMobile || !isAnimationActive ? 0 : x }}
      isAnimationActive={isAnimationActive}
    >
      {AMBASSADORS_LIST.map(ambassador => (
        <AmbassadorItem key={ambassador.description} {...ambassador} />
      ))}
    </StyledAmbassadorsSliderWrapper>
  );
});

AmbassadorsSlider.displayName = "AmbassadorsSlider";

export default AmbassadorsSlider;
