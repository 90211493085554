import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box } from "components";

export const StyledCorporateValuesWrapper = styled(Box)`
  position: relative;
  background: ${({ theme }) => theme.colors.bgDark};

  &::before {
    ${clearfix}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 15rem;
    left: 3rem;
    background: url("/images/corporate-values-bg.webp") no-repeat;
    background-size: contain;
    filter: blur(50px);

    ${({ theme }) => theme.mediaQueries.tablet} {
      width: 47.75rem;
      height: 47.75rem;
      top: 15rem;
      left: 0;
    }
  }
`;

export const StyledCorporateValuesTitleWrapper = styled(Box)`
  width: 100%;
  margin-bottom: 2rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    align-self: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 9.75rem;
    margin-bottom: 0;
    padding-right: 2rem;
  }
`;
