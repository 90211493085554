import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Column, Box, Button } from "components";

export const StyledPlanetCoinMotionWrapper = styled(motion.div)`
  position: relative;
`;

export const StyledPlanetCoinWrapper = styled(Column)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
  border-radius: ${({ theme }) => theme.radii.xxl};
  background: ${({ theme }) => theme.colors.bgDark} url("/images/planet-coin-bg.webp") no-repeat;
  background-size: cover;
  transition: ${({ theme }) => theme.transitions.default};
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 34.375rem;
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 40.625rem;
  }
`;

export const StyledContentContainer = styled(Box)`
  padding: 1.25rem 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    padding: 2.5rem 3rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 4.5rem 6.0625rem 4.5rem 6rem;
  }

  &:nth-of-type(2) {
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    padding-bottom: 1.25rem;

    ${({ theme }) => theme.mediaQueries.tablet} {
      width: 50%;
      margin: 0 auto -7rem 0;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      position: absolute;
      margin: 0;
    }
  }
`;

export const StyledPlanetcoinButton = styled(Button)`
  box-shadow: none;
`;
