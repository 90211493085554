import { OpenPositionItemProps } from "./types";

const WE_OFFER_POINTS = [
  "Stable and timely payroll",
  "Regular salary reviews",
  "Paid sick leave",
  "Flexible schedule",
  "Friendly team",
];

export const OPEN_POSITIONS_LIST: OpenPositionItemProps[] = [
  {
    id: 1,
    icon: "/images/open-position-1.svg",
    position: "Middle UI/UX Designer",
    description:
      "In this role, you will work with developers and customers to implement their design needs, participate in new product feature creation, etc",
    experience: "3 years",
    english: "Upper Intermediate",
    requirements: [
      "3+ years of experience in UI/UX design",
      "Proficiency with design tools such as Sketch, Adobe XD, and Figma",
      "Understanding of user-centered design principles",
      "Strong portfolio showcasing previous work in UI/UX design",
      "Excellent communication and collaboration skills",
      "Ability to work independently and manage multiple projects simultaneously",
      "Be up-to-date with the latest design trends and be able to incorporate them into their work while maintaining a consistent brand identity.",
    ],
    bePlus: [
      "Understanding of HTML&CSS and adaptive markup basics",
      "Be able to make a presentation of your work",
      "Upper intermediate english skills",
    ],
    duties: [
      "Creating user-centered designs that are intuitive and visually appealing",
      "Creating wireframes and prototypes",
      "Working closely with developers to ensure that designs are implemented properly",
      "Be able to communicate your designs effectively to stakeholders and be comfortable presenting their work",
    ],
    weOffer: WE_OFFER_POINTS,
  },
  {
    id: 2,
    icon: "/images/open-position-2.svg",
    position: "Middle 3D Designer",
    description:
      "You will be responsible for creating high-quality 3D models, animations, and visualizations for various projects across a range of industries",
    experience: "2 years",
    english: "Upper Intermediate",
    requirements: [
      "At least 2-3 years of professional experience working in 3D design, with a strong portfolio of work demonstrating expertise in the field",
      "Experience with 3D modeling software such as Maya, 3DS Max, or Blender",
      "Experience with texture mapping, lighting, and rendering",
      "Knowledge of current design trends and techniques",
      "Strong communication skills and the ability to work collaboratively with other designers and artists",
      "Ability to manage multiple projects and deadlines simultaneously",
    ],
    bePlus: [
      "Familiarity with programming languages such as Python or C++",
      "Functional understanding of responsive design, HTML, CSS, etc. (to communicate with an engineer if needed)",
      "Upper intermediate english skills",
    ],
    duties: [
      "Collaborating with other designers and artists to develop and implement concepts and designs",
      "Creating 3D models, textures, and animations, and integrating them into game engines or other software programs",
      "Ensuring that 3D models and animations are optimized for performance and functionality",
      "Working closely with project managers, programmers, and other team members to meet project milestones and deadlines",
      "Staying up-to-date with industry trends and advancements in 3D design and technology",
      "Mentoring and providing guidance to junior 3D designers, as needed",
    ],
    weOffer: WE_OFFER_POINTS,
  },
  {
    id: 3,
    icon: "/images/open-position-3.svg",
    position: "Motion Designer",
    description:
      "For this position, you will need to stay up to date with the latest motion technology to deliver high-quality motion graphics",
    experience: "1-2 years",
    english: "Upper Intermediate",
    requirements: [
      "Proficiency in motion graphics software such as After Effects, Cinema 4D, and/or Maya",
      "Experience in creating animation for web, mobile, and/or video platforms",
      "Strong understanding of design principles and ability to apply them to motion graphics",
      "Ability to work collaboratively with a team, as well as independently",
      "Excellent communication and time management skills",
    ],
    bePlus: [
      "Understanding of HTML&CSS and adaptive markup basics",
      "Be able to make a presentation of your work",
      "Upper intermediate english skills",
    ],
    duties: [
      "Collaborate with the creative team to conceptualize and develop visually engaging animations and special effects for video content",
      "Design and build animations and motion graphics using industry-standard software such as After Effects and Premiere",
      "Work with the video production team to ensure that animations and graphics integrate smoothly with live-action footage",
      "Stay up-to-date with the latest trends and techniques in motion design and animation",
    ],
    weOffer: WE_OFFER_POINTS,
  },
  {
    id: 4,
    icon: "/images/open-position-4.svg",
    position: "2D Artist",
    description:
      "Skilled in creating 2D assets and environments for games and animations, using a variety of software and techniques",
    experience: "2 years",
    english: "Upper Intermediate",
    requirements: [
      "Proficiency in digital art software, such as Adobe Creative Suite and/or Autodesk Maya",
      "Experience with 2D and/or 3D art creation",
      "Strong understanding of color theory, composition, and design principles",
      "Ability to work collaboratively with other artists and stakeholders",
      "Excellent attention to detail and commitment to quality",
      "Strong time management and organizational skills",
      "Experience with motion graphics is a plus",
    ],
    bePlus: ["Be able to make a presentation of your work", "Upper intermediate english skills"],
    duties: [
      "Create and design digital graphics, illustrations, and animations for various projects",
      "Work closely with the design and development teams to ensure that the visual elements of projects are cohesive and effective",
      "Use industry-standard software such as Adobe Creative Suite and 3D modeling software to create digital art",
      "Keep up to date with the latest design and technology trends to ensure that the organization's work stays relevant and innovative",
      "Collaborate with other artists and designers to share knowledge and expertise",
    ],
    weOffer: WE_OFFER_POINTS,
  },
  {
    id: 5,
    icon: "/images/open-position-5.svg",
    position: "Product designer",
    description:
      "You’ll have to create products that are both functional and aesthetically pleasing, while meeting the needs of the user and the goals of the business",
    experience: "5 years",
    english: "Upper Intermediate",
    requirements: [
      "5+ years of experience",
      "Attention to visual details",
      "Ability to meet deadlines and collaborate with a team",
      "Working with design systems",
      "Experience in creating the design for both complicated systems' and marketing products interfaces",
      "Understanding of UI/UX, UI/UX Guide, responsive, mobile first, design thinking concepts",
      "Experience creating and controlling libraries and Style Guide",
    ],
    bePlus: [
      "Understanding of HTML&CSS and adaptive markup basics",
      "Ability to conduct A/B tests",
      "Upper intermediate english skills",
    ],
    duties: [
      "Conducting market research and user testing",
      "Collaborating with cross-functional teams",
      "Creating design prototypes",
      "Overseeing the manufacturing process",
      "Creating user interfaces and ensuring that the product meets the needs and expectations of the target audience.",
    ],
    weOffer: WE_OFFER_POINTS,
  },
];
