// Styled components
import { StyledSlideImage1, StyledSlideImage2, StyledSlideImage3 } from "./styled";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage1,
    imageSrc: "/images/case-studies/ugreator/ugreator-wireframes-icon-1.webp",
    ratio: 0.53, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/case-studies/ugreator/ugreator-wireframes-icon-2.webp",
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/case-studies/ugreator/ugreator-wireframes-icon-3.webp",
    ratio: 0.95, // ratio of image width/height
  },
];
