import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
// Components + styling
import { Button, ContactUs, Container, Flex, Page } from "components";
import { NoBlogPlug } from "components/post-preview/components";
import { HeadingAndFilters, PostsList, PostsListSkeleton } from "./components";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions } from "hooks";
// Services
import {
  GetPostsResponse,
  GetPostsVars,
  GetTagsResponse,
  POSTS_PER_PAGE,
  DEFAULT_WHERE_PARAMS,
  Post,
  getPosts,
  getTags,
} from "services";
// Constants
import { ROUTES } from "navigation/routes";
import { BLOCK_ID } from "configs";

const BlogsPage: React.FC = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<string[]>([]);
  const [viewPosts, setViewPosts] = useState<Post[]>([]);

  const skipValue = page === 1 ? undefined : (page - 1) * POSTS_PER_PAGE + 1; // added + 1, as it's Main post,
  const whereParameter =
    filters.length === 0
      ? DEFAULT_WHERE_PARAMS
      : {
          ...DEFAULT_WHERE_PARAMS,
          tags_some: { id_in: filters },
        };

  const { data, loading } = useQuery<GetPostsResponse, GetPostsVars>(getPosts, {
    // if load first page, need POSTS_PER_PAGE + 1 (Main post) posts, for other pages need only POSTS_PER_PAGE
    variables: { skip: skipValue, first: page === 1 ? POSTS_PER_PAGE + 1 : POSTS_PER_PAGE, where: whereParameter },
  });

  const { posts = [], postsConnection } = data || {};
  const { aggregate, pageInfo } = postsConnection || {};

  const totalPosts = aggregate?.count || 1;

  const tagsData = useQuery<GetTagsResponse>(getTags);
  const { tags = [] } = tagsData.data || {};

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  useEffect(() => {
    if (loading) {
      return;
    }

    if (page !== 1) {
      setViewPosts([...viewPosts, ...posts]);
    } else {
      setViewPosts(posts);
    }
  }, [posts]);

  const handleFilterSelect = (id?: string) => {
    setPage(1);

    if (!id) {
      setFilters([]);
      return;
    }

    let updatedFilters: string[];

    if (filters.includes(id)) {
      updatedFilters = filters.filter(filterId => filterId !== id);
    } else {
      updatedFilters = [...filters, id];
    }

    if (tags.length && tags.length === updatedFilters.length) {
      setFilters([]);
    } else {
      setFilters(updatedFilters);
    }
  };

  const loadMorePosts = () => {
    setPage(page + 1);
  };

  return (
    <Page maxWidth="100vw" px="0" pt={`calc(${headerHeight}px + 3.75rem)`}>
      <Container>
        {(loading || posts.length !== 0) && (
          <HeadingAndFilters filters={filters} tags={tags} onFilterClick={handleFilterSelect} />
        )}

        {!loading && posts.length === 0 && (
          <NoBlogPlug navigateLinkText="Go to home page" text="Articles not found :(" navigateLink={ROUTES.landing} />
        )}

        {!loading && posts.length !== 0 && (
          <>
            <PostsList posts={viewPosts} />

            {viewPosts.length !== 0 && pageInfo?.hasNextPage && (
              <Flex justifyContent="center">
                <Button isLoading={loading} onClick={loadMorePosts} scale="lg">
                  {t("Show more")}
                  {` (+${
                    totalPosts - viewPosts.length >= POSTS_PER_PAGE ? POSTS_PER_PAGE : totalPosts - viewPosts.length
                  })`}
                </Button>
              </Flex>
            )}
          </>
        )}

        {loading && (
          <>
            {page !== 1 && <PostsList posts={viewPosts} />}
            <PostsListSkeleton page={page} />
          </>
        )}
      </Container>

      <ContactUs />
    </Page>
  );
};

export default BlogsPage;
