import { useRef } from "react";
// Components + styling
import { Container } from "components";
import {
  EStatesParallaxImageWrapper,
  EStatesParallaxImage1,
  EStatesMotionWrapper,
  EStatesParallaxImage2,
  EStatesParallaxImage3,
  EStatesParallaxImage4,
} from "./styled";
// Hooks
import { useParallaxEffect } from "hooks";

const EStatesBannerParallaxImage: React.FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const parallaxImage = useParallaxEffect(ref, 100, { offset: ["start end", "end start"] });

  return (
    <EStatesParallaxImageWrapper ref={ref}>
      <Container width="100%" position="relative">
        <EStatesParallaxImage1
          width="inherit"
          height="inherit"
          src="/images/e-states-banner-image-1.webp"
          aspectRatio={1.6}
        />

        <EStatesMotionWrapper style={{ y: parallaxImage.y }} transition={parallaxImage.selectedTransition}>
          <EStatesParallaxImage2
            width="inherit"
            height="inherit"
            src="/images/e-states-banner-image-2.webp"
            aspectRatio={0.81}
          />
        </EStatesMotionWrapper>

        <EStatesMotionWrapper style={{ y: parallaxImage.y }} transition={parallaxImage.selectedTransition}>
          <EStatesParallaxImage3
            width="inherit"
            height="inherit"
            src="/images/e-states-banner-image-3.webp"
            aspectRatio={3.05}
          />
        </EStatesMotionWrapper>

        <EStatesMotionWrapper style={{ y: parallaxImage.y, right: 0 }} transition={parallaxImage.selectedTransition}>
          <EStatesParallaxImage4
            width="inherit"
            height="inherit"
            src="/images/e-states-banner-image-4.webp"
            aspectRatio={0.49}
          />
        </EStatesMotionWrapper>
      </Container>
    </EStatesParallaxImageWrapper>
  );
};

export default EStatesBannerParallaxImage;
