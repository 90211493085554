import React from "react";
// Components + styling
import { Box, Text } from "components";
import { StyledSalesMemberItem, StyledMemberImage, StyledImageLottieAnimationWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useLottieAnimation, useMatchBreakpoints } from "hooks";
// Types
import { SalesMember } from "../sales-list/constants";

const SalesItem: React.FC<SalesMember> = ({
  name,
  position,
  image,
  hoverColor,
  ImageLottieAnimation,
  ImageLottieWrapper,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const {
    lottieAnimation1Ref,
    lottieAnimation2Ref,
    isImageLoading,
    setIsImageLoading,
    handleShowAnimations,
    handleHideAnimations,
  } = useLottieAnimation();

  return (
    <StyledSalesMemberItem
      onMouseEnter={() => {
        handleShowAnimations();
      }}
      onMouseLeave={() => {
        handleHideAnimations();
      }}
      hoverColor={hoverColor}
      isImageLoading={isImageLoading}
    >
      <Box mb={{ _: "0.5rem", laptop: "1.5rem" }} position="relative">
        <StyledMemberImage width="100%" src={image} aspectRatio={1} setExternalLoading={setIsImageLoading} />
        <StyledImageLottieAnimationWrapper>
          <ImageLottieAnimation
            lottieAnimation1Ref={lottieAnimation1Ref}
            lottieAnimation2Ref={lottieAnimation2Ref}
            ImageLottieWrapper={ImageLottieWrapper}
          />
        </StyledImageLottieAnimationWrapper>
      </Box>
      <Text textScale={isDesktop ? "p24Bold" : "p16Bold"} mb={{ _: "0.25rem", laptop: "0.5rem" }} color="white">
        {t(name)}
      </Text>
      <Text textScale={isDesktop ? "p20Regular" : "p14Regular"} color={isDesktop ? "textFourth" : "textSecond"}>
        {t(position)}
      </Text>
    </StyledSalesMemberItem>
  );
};

export default SalesItem;
