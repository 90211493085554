import React, { useRef } from "react";
// Components + Styling;
import { Box, Text } from "components";
import { LetsTalkIcon } from "components/svg";
import {
  StyledProposalText,
  StyledScheduleCallButtonWrapper,
  StyledMotionButtonWrapper,
  StyledScheduleCallButton,
  StyledScheduleButtonIconWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
import { useRotateOnScroll } from "../../hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";

const CallToAction: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isLargeDesktop } = useMatchBreakpoints();

  const scheduleCallRef = useRef<HTMLDivElement | null>(null);

  const { rotate } = useRotateOnScroll();

  const buttonTransition = {
    ease: [0.75, 0.01, 0.3, 2.33],
    duration: 0.5,
  };

  return (
    <Box>
      <StyledProposalText textScale={isDesktop ? "decorative22Light" : "decorative16Light"}>
        {t("We are ready to create the design for your product")}
      </StyledProposalText>

      <StyledScheduleCallButtonWrapper ref={scheduleCallRef}>
        <StyledMotionButtonWrapper whileHover={{ scale: isLargeDesktop ? 1.8 : 1.5 }} transition={buttonTransition}>
          <StyledScheduleCallButton isShowHoverLines={false} onClick={() => scrollToElementById(BLOCK_ID.contactUs)}>
            <Text
              width={{ _: "4.25rem", laptopL: "5.25rem" }}
              textScale={isLargeDesktop ? "decorative22Bold" : "decorative16Bold"}
              color="white"
            >
              {t("Schedule a call")}
            </Text>
          </StyledScheduleCallButton>
        </StyledMotionButtonWrapper>

        <StyledScheduleButtonIconWrapper style={{ rotate: rotate }}>
          <LetsTalkIcon width="100%" height="100%" />
        </StyledScheduleButtonIconWrapper>
      </StyledScheduleCallButtonWrapper>
    </Box>
  );
};

export default CallToAction;
