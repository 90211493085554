// Components + styling
import { StyledWireframeBackgroundImage } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { BackgroundImageWireframeProps } from "./types";

const BackgroundImageWireframe: React.FC<BackgroundImageWireframeProps> = props => {
  const { isDesktop } = useMatchBreakpoints();

  return <StyledWireframeBackgroundImage height={`${(isDesktop ? 39.125 : 17.25) / 1.02}rem`} {...props} />;
};

export default BackgroundImageWireframe;
