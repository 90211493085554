import React, { useRef } from "react";
// Components + Styling;
import { Box, Text } from "components";
import { StyledUserCase, StyledCircleWrapperIcon } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { UserCaseProps } from "./types";

const UserCase: React.FC<UserCaseProps> = ({ Icon, label, handleMouseEnter, handleMouseLeave }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const ref = useRef<HTMLDivElement | null>(null);

  const handleUserCaseClick = () => {
    scrollToElementById(BLOCK_ID.contactUs);
    handleMouseLeave();
    ref.current?.blur();
  };

  return (
    <StyledUserCase
      ref={ref}
      onClick={handleUserCaseClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box position="relative">
        <Icon width={isMobile ? "2.5rem" : "4.5rem"} />
        <StyledCircleWrapperIcon width={isMobile ? "3.875rem" : "7.25rem"} />
      </Box>
      <Text textScale={isDesktop ? "p22Medium" : "p16Medium"} mx={{ _: "1rem" }}>
        {t(label)}
      </Text>
    </StyledUserCase>
  );
};

export default UserCase;
