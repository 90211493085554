import React, { useRef } from "react";
import { motion } from "framer-motion";
// Components + Styling
import {
  StyledSlideImageWrapper,
  StyledSlideImageTopLeftTitle,
  StyledSlideImageTopRightTitle,
  StyledSlideMiddleImage,
  StyledSlideTopLeftImage,
  StyledSlideTopRightImage,
  StyledSlideBottomLeftImage,
  StyledSlideBottomRightImage,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useParallaxEffect } from "hooks";

const UgreatorSlideImage: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const ref = useRef(null);
  const parallaxTitle1 = useParallaxEffect(ref, 160);
  const parallaxTitle2 = useParallaxEffect(ref, 170);

  const parallaxMiddleImage = useParallaxEffect(ref, 125);

  const parallaxTopLeftImage = useParallaxEffect(ref, 150);
  const parallaxTopRightImage = useParallaxEffect(ref, 180);

  const parallaxBottomLeftImage = useParallaxEffect(ref, 80);
  const parallaxBottomRightImage = useParallaxEffect(ref, 90);

  return (
    <StyledSlideImageWrapper ref={ref}>
      <motion.div style={isDesktop ? { y: parallaxTitle1.y } : {}} transition={parallaxTitle1.selectedTransition}>
        <StyledSlideImageTopLeftTitle
          textScale={isDesktop ? "decorative22Light" : "decorative14Light"}
          color="textMain"
        >
          {t("Auctions")}
        </StyledSlideImageTopLeftTitle>
      </motion.div>

      <motion.div
        style={isDesktop ? { y: parallaxBottomRightImage.y } : {}}
        transition={parallaxBottomRightImage.selectedTransition}
      >
        <StyledSlideBottomRightImage src="/images/ugreator-slide-bottom-right.webp" aspectRatio={0.91} />
      </motion.div>

      <motion.div style={isDesktop ? { y: parallaxTitle2.y } : {}} transition={parallaxTitle2.selectedTransition}>
        <StyledSlideImageTopRightTitle
          textScale={isDesktop ? "decorative22Light" : "decorative14Light"}
          color="textMain"
        >
          {t("Talent Monetization")}
        </StyledSlideImageTopRightTitle>
      </motion.div>

      <motion.div
        style={isDesktop ? { y: parallaxTopLeftImage.y } : {}}
        transition={parallaxTopLeftImage.selectedTransition}
      >
        <StyledSlideTopLeftImage src="/images/ugreator-slide-top-left.webp" aspectRatio={1.02} />
      </motion.div>

      <motion.div
        style={isDesktop ? { y: parallaxTopRightImage.y } : {}}
        transition={parallaxTopRightImage.selectedTransition}
      >
        <StyledSlideTopRightImage src="/images/ugreator-slide-top-right.webp" aspectRatio={1.04} />
      </motion.div>

      <motion.div
        style={isDesktop ? { y: parallaxBottomLeftImage.y } : {}}
        transition={parallaxBottomLeftImage.selectedTransition}
      >
        <StyledSlideBottomLeftImage src="/images/ugreator-slide-bottom-left.webp" aspectRatio={1} />
      </motion.div>

      <motion.div
        style={isDesktop ? { y: parallaxMiddleImage.y } : {}}
        transition={parallaxMiddleImage.selectedTransition}
      >
        <StyledSlideMiddleImage src="/images/ugreator-slide-middle.webp" aspectRatio={0.49} />
      </motion.div>
    </StyledSlideImageWrapper>
  );
};

export default UgreatorSlideImage;
