import React, { useRef } from "react";
import { motion } from "framer-motion";
// Components + Styling
import { Container, HashLayoutWrapper } from "components";
import { ServiceItem, ServicesParallaxImage } from "./components";
import {
  StyledOuterWrapper,
  StyledInnerWrapper,
  StyledProvidedServicesWrapper,
  StyledProvidedServicesTitle,
  StyledProvidedServicesList,
  StyledDesktopServicesConnector,
  StyledServicesSectionImage,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useAnimationStatus, useMatchBreakpoints } from "hooks";
import { usePinScrollEffect, useServiceItemMotions, useTitleMotions } from "./hooks";
// Constants
import { PROVIDED_SERVICES_LIST } from "./constants";
import { BLOCK_ID } from "configs";
import { animationStatusCheckVariants } from "hooks/use-animation-status/types";

const ProvidedServices: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const outerWrapperRef = useRef<HTMLDivElement | null>(null);
  const titleRef = useRef<HTMLParagraphElement | null>(null);

  const { isAnimationActive } = useAnimationStatus(animationStatusCheckVariants.services);
  const textUseAnimations = useAnimationStatus(animationStatusCheckVariants.banner);

  const { scale, borderRadius, opacityConnector } = usePinScrollEffect(outerWrapperRef, isAnimationActive);
  const { serviceItemMotionsList } = useServiceItemMotions(outerWrapperRef, textUseAnimations.isAnimationActive);
  const { yTitle, opacityTitle } = useTitleMotions(titleRef, textUseAnimations.isAnimationActive);

  return (
    <StyledOuterWrapper ref={outerWrapperRef} id={BLOCK_ID.services} isAnimationActive={isAnimationActive}>
      <StyledInnerWrapper>
        <StyledProvidedServicesWrapper
          isAnimationActive={isAnimationActive}
          style={{
            scale,
            borderRadius,
          }}
        >
          <HashLayoutWrapper pt="4.5rem">
            <Container>
              <motion.div
                style={{
                  y: yTitle,
                  opacity: opacityTitle,
                }}
              >
                <StyledProvidedServicesTitle ref={titleRef} scale="h2" as="h2">
                  {t("What services do we provide?")}
                </StyledProvidedServicesTitle>
              </motion.div>

              <StyledProvidedServicesList>
                {PROVIDED_SERVICES_LIST.map(({ Icon, label }, index) => {
                  const { y, opacity } = serviceItemMotionsList[index];
                  return <ServiceItem key={label} Icon={Icon} label={label} y={y} opacity={opacity} />;
                })}

                <StyledDesktopServicesConnector style={{ opacity: opacityConnector }} />
              </StyledProvidedServicesList>

              {!isAnimationActive && (
                <StyledServicesSectionImage
                  width={isMobile ? "100%" : "75%"}
                  src="/images/services-section-bg.svg"
                  aspectRatio={1.1}
                />
              )}
            </Container>
          </HashLayoutWrapper>

          {isAnimationActive && <ServicesParallaxImage ref={outerWrapperRef} />}
        </StyledProvidedServicesWrapper>
      </StyledInnerWrapper>
    </StyledOuterWrapper>
  );
};

export default ProvidedServices;
