// Constants
import { EXTERNAL_LINKS } from "configs";

export const VIDEO_LIST = [
  {
    videoSrc: "/videos/testimonials/testimonial-philipp-tanglmayer-tharsesis.mp4",
    imageQuote:
      "Idealogic not only focused on development itself but also on every improvement possible to make the successful project",
    name: "Philipp Tanglmayer",
    position: "CEO at Tharsesis AG",
    borderRadius: "1.25rem 0 0 1.25rem",
    videoUrl: EXTERNAL_LINKS.youtubePhilippReview,
    projectLink: EXTERNAL_LINKS.amorsSecret,
    linkedInLink: EXTERNAL_LINKS.linkedInPhilipp,
  },
  {
    videoSrc: "/videos/testimonials/testimonial-matthew-schneider-e-states.mp4",
    imageQuote: "We have been working together for about 8 month and we’re absolutely satisfied about our work",
    name: "Matthew Schneider",
    position: "CEO, Co-founder of e-States",
    borderRadius: "0px",
    videoUrl: EXTERNAL_LINKS.youtubeMatthewReview,
    projectLink: EXTERNAL_LINKS.eStates,
    linkedInLink: EXTERNAL_LINKS.linkedInMatthew,
  },
  {
    videoSrc: "/videos/testimonials/testimonial-yuriy-nikitin-ugreator.mp4",
    imageQuote:
      "Idealogic not only exceeded our expectations but also boosted our ideas with their innovative insights",
    name: "Yuriy Nikitin",
    position: "CEO, Founder of Ugreator",
    borderRadius: "0px",
    videoUrl: EXTERNAL_LINKS.youtubeNikitinReview,
    projectLink: EXTERNAL_LINKS.ugreator,
    linkedInLink: EXTERNAL_LINKS.linkedInNikitin,
  },
  {
    videoSrc: "/videos/testimonials/testimonial-dan-jensen-revolve.mp4",
    imageQuote: "Idealogic brought exceptional skills, innovative ideas and collaborative spirit to our project",
    name: "Dan Jensen",
    position: "CEO, Co-founder of Revolve Games",
    borderRadius: "0 1.25rem 1.25rem 0",
    videoUrl: EXTERNAL_LINKS.youtubeDanReview,
    projectLink: EXTERNAL_LINKS.revolveGames,
    linkedInLink: EXTERNAL_LINKS.linkedInDan,
  },
];
