// Constants
import { BLOCK_ID } from "configs";
import { ROUTES } from "navigation/routes";

export const headerThemes = {
  light: "light",
  dark: "dark",
  planetcoinCase: "planetcoinCase",
  ugreatorCase: "ugreatorCase",
} as const;

export const headerAnimationType = {
  static: "static",
  initial: "initial",
  hidden: "hidden",
  fixed: "fixed",
  drop: "drop",
} as const;

export const MOBILE_MENU_ANIMATION_DURATION = 1000;

export type headerThemesType = keyof typeof headerThemes;

export const LIGHT_HEADER_THEME_PATHNAME_LIST = [
  `/${ROUTES.privacyPolicy}`,
  `/${ROUTES.termsConditions}`,
  `/${ROUTES.blog}`,
  `/${ROUTES.caseStudies}`,
  `/${ROUTES.caseStudies}/${ROUTES.eStatesCase}`,
];

export const CONTACT_US_OUT_PATHNAME_LIST = [
  `/${ROUTES.privacyPolicy}`,
  `/${ROUTES.termsConditions}`,
  `/${ROUTES.findSolution}`,
];

export const NAV_LIST = [
  { label: "Services", link: `${ROUTES.landing}#${BLOCK_ID.services}` },
  { label: "Case studies", link: `/${ROUTES.caseStudies}` },
  { label: "How we work", link: `${ROUTES.landing}#${BLOCK_ID.howWeWork}` },
  { label: "Testimonials", link: `${ROUTES.landing}#${BLOCK_ID.testimonials}` },
  { label: "About us", link: `/${ROUTES.aboutUs}` },
  { label: "Careers", link: `/${ROUTES.careers}` },
  { label: "Blog", link: `/${ROUTES.blog}` },
];
