import styled from "styled-components";
import { clearfix } from "styles";

export const StyledHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    min-width: fit-content;
    margin-bottom: 1rem;
  }

  ::after {
    ${clearfix}
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    background-image: url("/images/like.svg");
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 3.25rem;
      height: 3.25rem;
      margin: 0 0.75rem;
    }
  }
`;

export const StyledButtonWrapper = styled.div`
  width: fit-content;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  margin-top: 1.5rem;
  margin: 1.5rem auto 0;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 1.5rem 0 0;
  }
`;
