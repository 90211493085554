import styled from "styled-components";
// Components
import { Text } from "components";
// Types
import { LinkProps } from "./types";

export const StyledLink = styled(Text)<LinkProps>`
  width: fit-content;
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ theme, color }) => (color ? theme.colors[color] : theme.gradients.buttonAccent)};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
