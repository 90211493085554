import styled from "styled-components";
// Components
import { Box, Link } from "components";

export const StyledInnerWrapper = styled(Box)`
  border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledContactLink = styled(Link).attrs({ color: "textFifth", external: true, underline: true })`
  cursor: pointer;
`;
