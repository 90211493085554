// Components
import { EditServiceIcon, GrowthIcon, PaintPaletteIcon, RocketIcon } from "components/svg";
// Theme
import { breakpoints } from "theme/base";

export const PROVIDED_SERVICES_LIST = [
  { Icon: PaintPaletteIcon, label: "Create an identity and initial branding" },
  { Icon: GrowthIcon, label: "Your first presentation pack for pitching" },
  { Icon: EditServiceIcon, label: "Redesign and product design before launch" },
  { Icon: RocketIcon, label: "UX/UI for your MVP (or first prototypes)" },
];

export const HEIGHT_BREAKPOINT_ANIMATION = {
  [breakpoints.mobileS]: 700,
  [breakpoints.tablet]: 500,
};
