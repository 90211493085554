import styled from "styled-components";

export const StyledCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const StyledCardItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineSecond};

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
`;
