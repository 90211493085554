import styled from "styled-components";
// Components
import { IconButton } from "components/button/components";
import { ArrowLeftIcon } from "components/svg";

export const StyledIconButton = styled(IconButton)`
  padding: 0.125rem;
`;

export const StyledArrowRightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`;
