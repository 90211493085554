// Components
import { CrownLineIcon, WarningTriangleIcon } from "components/svg";

export const BANNER_CATEGORY_LIST = ["Social Network", "Mobile App"];

export const BANNER_STATISTICS_LIST = [
  {
    value: "500+",
    label: "pages",
  },
  {
    value: "2",
    label: "designers",
  },
  {
    value: "1K+",
    label: "hours spent",
  },
  {
    value: "4",
    label: "month of dev",
  },
];

export const BANNER_POINTS_LIST = [
  {
    Icon: WarningTriangleIcon,
    label: "The problem",
    description:
      "Creators strive to monetize the content they've painstakingly crafted, yet often face challenges in expanding their reach and growing their audiences. Meanwhile, followers actively engage with content but do not reap any rewards for their participation.",
  },
  {
    Icon: CrownLineIcon,
    label: "Our solution",
    description:
      "In this platform, every post within the feed operates as a content auction, allowing users to earn U-stars, the in-game currency, through daily quests, bonuses, and rewarded video ads. Additionally, creators can offer exclusive premium content to their fans, setting an unlock price and earning rewards for their engaging posts.",
  },
];
