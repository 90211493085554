import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledUserFlowsParallaxImageWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 6.625rem;
  position: absolute;
  top: -3.125rem;
  right: -1.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 11.75rem;
    top: -6.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 15.75rem;
    top: -13.125rem;
    right: 2.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -18.75rem;
    right: 6.875rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 10.9375rem;
  position: absolute;
  top: -4.375rem;
  right: -6.375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 13.125rem;
    top: 1.5rem;
    right: -3rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.3125rem;
    top: -5.625rem;
    right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -11.25rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 2.1875rem;
  position: absolute;
  top: 25rem;
  left: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4.75rem;
    top: 21.875rem;
    left: 4rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 7.875rem;
    top: 55.625rem;
    left: 2.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 58.125rem;
    left: 3.5rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 6.625rem;
  position: absolute;
  top: 22.5rem;
  left: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 13.75rem;
    top: 13.125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 16.25rem;
    top: 57.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 26.625rem;
    top: 45.625rem;
  }
`;
