import React, { useRef } from "react";
// Components + styling
import { Container, Box, Heading, Text, FindSolutionForm } from "components";
import { FindSolutionParallaxBg } from "./components";
import { StyledBgWrapper, StyledInnerWrapper } from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";

const FindSolutionBanner: React.FC<{ isLoading?: boolean }> = ({ isLoading = false }) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { isDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Box ref={wrapperRef} $backgroundColor="white">
      <StyledInnerWrapper headerHeight={headerHeight}>
        <Container position="relative" zIndex={theme.zIndices.dropdown}>
          <Heading scale="h5" as="h5" mb={{ _: "0.25rem", laptop: "0.5rem" }} textAlign="center" color="white">
            {t("Let's find your solution")}?
          </Heading>

          <Text
            textScale={isDesktop ? "p14Regular" : "p12Regular"}
            mb={{ _: "1rem", laptop: "1.5rem" }}
            textAlign="center"
            color="textThird"
          >
            {t("For example: Blockchain, wallet, brand identity and etc.")}
          </Text>

          <FindSolutionForm isLoading={isLoading} />

          {isDesktop && <FindSolutionParallaxBg ref={wrapperRef} />}
        </Container>

        <StyledBgWrapper />
      </StyledInnerWrapper>
    </Box>
  );
};

export default FindSolutionBanner;
