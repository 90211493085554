import { useScroll, useTransform } from "framer-motion";

const useScrollXByScrollY = (wrapperRef: React.MutableRefObject<HTMLDivElement | null>, distance: number) => {
  const { scrollYProgress } = useScroll({
    target: wrapperRef,
    offset: ["start 25%", "end end"],
  });

  const x = useTransform(scrollYProgress, [0, 1], [0, -distance]);

  return {
    x,
  };
};

export default useScrollXByScrollY;
