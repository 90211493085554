import { Flex } from "components";
import styled from "styled-components";

export const StyledCompaniesRateList = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    justify-content: center;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 66%;
    justify-content: flex-end;
  }
`;

export const StyledCompanyRate = styled(Flex)`
  width: calc(50% - 0.75rem);
  flex-direction: column;
  align-items: center;
  padding: 1.125rem 0.75rem;
  border: 2px solid ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.large};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: fit-content;
    flex-direction: row;
    margin: 0 0.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 2rem 2.75rem;
  }
`;
