import { forwardRef } from "react";
import Slider from "react-slick";
// Components + styling
import OpenPositionItem from "../open-position-item";
import { StyledOpenPositionSlider } from "./styled";
import { StyledOpenPositionItemWrapper } from "../../styled";
// Configs
import { sliderSettings } from "./configs";
// Constants
import { OPEN_POSITIONS_LIST } from "../../constants";

const OpenPositionsSlider = forwardRef<Slider | null>((_, ref) => {
  return (
    <StyledOpenPositionSlider ref={ref} {...sliderSettings}>
      {OPEN_POSITIONS_LIST.map(({ id, ...rest }) => (
        <StyledOpenPositionItemWrapper key={id}>
          <OpenPositionItem id={id} {...rest} />
        </StyledOpenPositionItemWrapper>
      ))}
    </StyledOpenPositionSlider>
  );
});

OpenPositionsSlider.displayName = "OpenPositionsSlider";

export default OpenPositionsSlider;
