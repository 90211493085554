import { useRef } from "react";
import { useInView } from "framer-motion";
// Components + styling
import { Box, Column, Heading, Image } from "components";
import { BoxItem, EStatesUserFlowRow } from "./components";
import {
  EStatesUserFlowItemWrapper,
  EStatesUserFlowLine,
  EStatesUserFlowLineMotion,
  EStatesUserFlowMainRoutingImage,
  EStatesUserFlowMapBox,
  EStatesUserFlowMapWrap,
  EStatesUserFlowRoutingContainer,
  EStatesUserFlowText,
  EStatesUserFlowWrapper,
} from "./styled";
// Animations
import { eStatesAnimatedItems } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useScrollVelocity } from "hooks";
// Constants
import { ESTATES_USER_FLOW_ROWS, ESTATES_USER_FLOW_WIDTH } from "./constants";

const EStatesUserFlow: React.FC = () => {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(wrapperRef);

  const upLineVelocityMotionBackground = useScrollVelocity({
    baseVelocity: 0.9,
    speedToScroll: 100,
    isInView,
    opacityRange: 2,
  });

  return (
    <EStatesUserFlowWrapper position="relative" pt="3.125rem" pb={{ _: "3.125rem", laptop: "10.625rem" }}>
      <Column alignItems={{ _: "start", tablet: "center" }} maxWidth="38.75rem" mb="2.5rem">
        <Heading as="h2" scale="h2">
          {t("User Flows")}
        </Heading>
        <EStatesUserFlowText textScale="p22Regular">
          {t(
            "To better understand the problems and desires of users of cryptocurrency applications, we conducted an interview.  Here is a short summary",
          )}
        </EStatesUserFlowText>
      </Column>

      <Box ref={wrapperRef} height={{ _: "2rem", tablet: "4rem" }}>
        <EStatesUserFlowLine>
          <EStatesUserFlowLineMotion style={{ x: upLineVelocityMotionBackground.x }}>
            <Image
              src="/images/e-states-user-flows-first-layer.webp"
              width={{ _: "35rem", laptop: "80rem" }}
              aspectRatio={100}
            />
          </EStatesUserFlowLineMotion>
        </EStatesUserFlowLine>
      </Box>

      <EStatesUserFlowMapWrap>
        <EStatesUserFlowMapBox width={ESTATES_USER_FLOW_WIDTH}>
          <Box width={ESTATES_USER_FLOW_WIDTH} minWidth={ESTATES_USER_FLOW_WIDTH} position="relative">
            <EStatesUserFlowItemWrapper
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.8 }}
              variants={eStatesAnimatedItems}
            >
              <BoxItem text="Sign In" isMultiply mx="auto" $backgroundColor="eStatesUserFlowBoxBg">
                <EStatesUserFlowRoutingContainer px={{ _: "4.5rem" }}>
                  <EStatesUserFlowMainRoutingImage
                    width="100%"
                    height="auto"
                    src="/images/e-states-user-flow-main-routing.svg"
                    aspectRatio={100}
                    mt="3rem"
                  />
                </EStatesUserFlowRoutingContainer>
              </BoxItem>
            </EStatesUserFlowItemWrapper>
          </Box>

          {ESTATES_USER_FLOW_ROWS.map((row, index) => (
            <EStatesUserFlowRow key={index} {...row} />
          ))}
        </EStatesUserFlowMapBox>
      </EStatesUserFlowMapWrap>

      <Image
        mt={{ _: "1rem", tablet: "5rem" }}
        src="/images/e-states-user-flows-second-layer.webp"
        width="100%"
        aspectRatio={100}
      />
    </EStatesUserFlowWrapper>
  );
};

export default EStatesUserFlow;
