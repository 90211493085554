import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, Flex, Heading, Image } from "components";

export const StyledOuterWrapper = styled(Box)<{ isAnimationActive: boolean }>`
  height: ${({ isAnimationActive }) => (isAnimationActive ? "200vh" : "auto")};
  min-height: ${({ isAnimationActive }) => (isAnimationActive ? "31.25rem" : "auto")};
  box-sizing: content-box;
  display: block;
  position: relative;
  inset: auto;
`;

export const StyledInnerWrapper = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  position: -webkit-sticky;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  margin: auto;
  inset: 0px auto auto 0px;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledProvidedServicesWrapper = styled(motion.div)<{ isAnimationActive: boolean }>`
  height: ${({ isAnimationActive }) => (isAnimationActive ? "100vh" : "auto")};
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};
  overflow: ${({ isAnimationActive }) => (isAnimationActive ? "hidden" : "auto")};
  /* overflow: "hidden"; */

  transform: translate3d(0px, 0px, 0px);
  background:
    0 bottom / 100% 100% no-repeat url("/images/service-section-secondary-bg-sm.webp"),
    ${({ theme }) => theme.colors.bgDark};
`;

export const StyledProvidedServicesTitle = styled(Heading)`
  max-width: 11.125rem;
  margin: 0 auto;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 48.125rem;
  }
`;

export const StyledProvidedServicesList = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 2.5rem 0 5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 60.5rem;
    margin: 3rem auto 0;
  }
`;

export const StyledDesktopServicesConnector = styled(motion.div)`
  width: 49.1875rem;
  height: 4.9375rem;
  display: none;
  position: absolute;
  top: 4.625rem;
  left: 8.625rem;
  background: no-repeat url("/images/services-item-connector-lg.svg");
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: block;
  }
`;

export const StyledServicesSectionImage = styled(Image)`
  margin: 0 auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 1.5rem;
  }

  div,
  img {
    margin: 0 auto;
  }
`;
