// Components
import { BehanceIcon, DribbbleIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
// Types
import { SvgProps } from "components/svg/types";

type HEADER_SOCIAL_LINK = {
  Icon: React.FC<SvgProps>;
  iconName: string;
  link: string;
};

export const HEADER_SOCIALS_LINKS: HEADER_SOCIAL_LINK[] = [
  {
    Icon: BehanceIcon,
    iconName: "behance",
    link: EXTERNAL_LINKS.behance,
  },
  {
    Icon: DribbbleIcon,
    iconName: "dribbble",
    link: EXTERNAL_LINKS.dribbble,
  },
];
