import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
// Styles
import { GlobalStyle } from "styles";
// Components
import { ErrorBoundary, Loader, ErrorBoundaryFallback } from "components";
// Context
import {
  CaseStudyProvider,
  LanguageContextProvider,
  SocketContextProvider,
  ThemeContextProvider,
  useThemeContext,
} from "context";
// Navigation
import Navigation from "navigation";
// Services
import { apolloClient } from "services";

const ThemedApp: React.FC = () => {
  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Suspense fallback={<Loader />}>
        <ErrorBoundary fallbackComponent={ErrorBoundaryFallback}>
          <LanguageContextProvider fallback={<Loader />}>
            <CaseStudyProvider>
              <SocketContextProvider>
                <Navigation />
              </SocketContextProvider>
            </CaseStudyProvider>
          </LanguageContextProvider>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <HelmetProvider>
          <ThemeContextProvider>
            <ThemedApp />
          </ThemeContextProvider>
        </HelmetProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
