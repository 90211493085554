import { forwardRef } from "react";
import Slider from "react-slick";
// Components + styling
import TeamMemberItem from "../team-member-item";
import { StyledOurTeamSlider } from "./styled";
// Configs
import { sliderSettings } from "./configs";
// Constants
import { OUR_TEAM_LIST } from "components/our-team/constants";

const OurTeamSlider = forwardRef<Slider | null>((_, ref) => {
  return (
    <StyledOurTeamSlider ref={ref} {...sliderSettings}>
      {OUR_TEAM_LIST.map(
        ({ image, name, position, description, hoverColor, ImageLottieAnimation, linkedInLink }, index) => {
          return (
            <TeamMemberItem
              key={index}
              image={image}
              name={name}
              position={position}
              description={description}
              hoverColor={hoverColor}
              ImageLottieAnimation={ImageLottieAnimation}
              index={index}
              linkedInLink={linkedInLink}
            />
          );
        },
      )}
    </StyledOurTeamSlider>
  );
});

OurTeamSlider.displayName = "OurTeamSlider";

export default OurTeamSlider;
