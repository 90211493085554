import React from "react";
// Components + styling
import { Box, Button, Container, Flex } from "components";
import { NoBlogPlug } from "components/post-preview/components";
import { ArticleSkeleton, CeoSticker } from "./components";
import { StyledArticleContent, StyledArticleWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { FindSolutionArticleProps } from "./types";

const FindSolutionArticle: React.FC<FindSolutionArticleProps> = ({ pending, article, setIsChatOpen }) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isLargeDesktop } = useMatchBreakpoints();

  return (
    <StyledArticleWrapper pb={{ _: "2.5rem", laptop: "5.5rem" }}>
      <Container pt={{ _: "1.5rem", laptop: "2.5rem" }} pb={{ _: "1rem", laptop: "0" }}>
        <Flex flexDirection={isDesktop ? "row" : "column"} justifyContent={isDesktop ? "space-between" : "flex-start"}>
          <Box width={{ _: "100%", laptop: "calc(100% - 27.5rem)" }} mb={{ _: "2rem", laptop: "0" }}>
            {pending && <ArticleSkeleton />}

            {!pending && !article && <NoBlogPlug hideButton />}

            {!pending && article && <StyledArticleContent dangerouslySetInnerHTML={{ __html: article }} mb="1.5rem" />}

            <Box width="100%" maxWidth={isMobile ? "100%" : "15rem"}>
              <Button
                width="100%"
                minWidth="unset"
                scale="lg"
                hoverLinesPosition={isLargeDesktop ? "topRight" : "mobileTopLeft"}
                onClick={() => {
                  setIsChatOpen(true);
                }}
              >
                {t("Lets talk in livechat")}
              </Button>
            </Box>
          </Box>

          <CeoSticker />
        </Flex>
      </Container>
    </StyledArticleWrapper>
  );
};

export default FindSolutionArticle;
