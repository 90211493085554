import React from "react";
// Components + styling
import { Accordion, ListPoint, Text } from "components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { COLLECTION_INFO_LIST, DATA_COLLECTS_LIST } from "./constants";

const CollectionInformation: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Accordion title={t("Collection of information")} $backgroundColor="white">
      <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mb="1rem">
        {t(
          "IDEALOGIC DESIGN collects information to provide better services to its users and to better understand the visitors to its websites and what content is of interest to them. IDEALOGIC DESIGN collects information in the following ways:",
        )}
      </Text>
      <ul>
        {COLLECTION_INFO_LIST.map((point, index) => (
          <ListPoint key={point} textScale={isDesktop ? "p18Regular" : "p14Regular"}>
            {t(point)}
            <ul>
              {index === COLLECTION_INFO_LIST.length - 1 &&
                DATA_COLLECTS_LIST.map(item => (
                  <ListPoint key={item} textScale={isDesktop ? "p18Regular" : "p14Regular"}>
                    {t(item)}
                  </ListPoint>
                ))}
            </ul>
          </ListPoint>
        ))}
      </ul>
      <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mt="1rem" pb="1.5rem">
        {t(
          "Other than as required by law, IDEALOGIC DESIGN WEBSITE do not collect any special categories of personal information about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). IDEALOGIC DESIGN will not collect any information about criminal convictions and offenses. IDEALOGIC DESIGN will only use your personal information for the purposes for which it was collected, unless it reasonably considers that it needs to use it for another reason that is compatible with the original purpose. If IDEALOGIC DESIGN needs to use your personal information for an unrelated purpose, IDEALOGIC DESIGN will notify you and explain the legal basis which allows IDEALOGIC DESIGN to do so",
        )}
      </Text>
    </Accordion>
  );
};

export default CollectionInformation;
