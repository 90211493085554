import styled from "styled-components";
import Slider from "react-slick";

export const StyledOpenPositionSlider = styled(Slider)`
  padding: 0 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0;
  }

  .slick-list {
    padding: 0 0.25rem !important;
  }

  .slick-track {
    display: flex;
    padding: 1.5rem 0;
  }

  .slick-slide {
    height: inherit;
  }

  .slick-slide > div {
    height: 100%;
  }
`;
