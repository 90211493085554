import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M25.6775 5.14245C27.0944 4.42406 28.7683 4.42173 30.1872 5.13616L45.2486 12.7199C46.9356 13.5693 48 15.2936 48 17.1823C48 20.7379 48 26.6833 48 31.2385C48 31.6056 47.9612 31.9714 47.8737 32.328C45.5827 41.6562 38.4476 48.8965 29.1498 51.6658C28.355 51.9025 27.5067 51.9011 26.7129 51.661C17.5869 48.9005 10.693 41.7763 8.15945 32.3807C8.05399 31.9896 8.00652 31.5855 8.00648 31.1805C8.00594 26.3309 8.00339 20.6132 8.00165 17.178C8.0007 15.2932 9.05963 13.568 10.7407 12.7157L25.6775 5.14245Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M23 28.5714L26.6842 32L33 24"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="28.5" cy="28" rx="11.5" ry="11" stroke={theme.colors[color]} strokeWidth="1.5" />
    </Svg>
  );
};

export default Icon;
