import styled from "styled-components";
// Components
import { Box, Container } from "components";
// Types
import { StyledDefaultFlowItemProps } from "./types";

export const StyledContentContainer = styled(Container)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  padding-top: 1.125rem;
  padding-bottom: 2.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-top: 3.75rem;
    padding-bottom: 7.5rem;
  }
`;

export const StyledDefaultFlowItem = styled(Box)<StyledDefaultFlowItemProps>`
  width: 3.75rem;
  height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme, $borderColor }) => theme.colors[$borderColor]};
  border-radius: ${({ theme }) => theme.radii.userFlowsItem};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10.9375rem;
    height: 3.9375rem;
  }
`;
