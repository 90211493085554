import { css } from "styled-components";
// Constants
import { scales } from "./types";

export const styles = {
  [scales.p44Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 2.75rem;
    line-height: 1.5;
  `,
  [scales.p34Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 2.125rem;
    line-height: 1.2;
  `,
  [scales.p34Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 2.125rem;
    line-height: 1.2;
  `,
  [scales.p32Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 2rem;
    line-height: 1.3;
  `,
  [scales.p30]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 1.875rem;
    line-height: 1.2;
  `,
  [scales.p28Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.75rem;
    line-height: 1.2;
  `,
  [scales.p24Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1.5rem;
    line-height: 1.3;
  `,
  [scales.p24Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.5rem;
    line-height: 1.3;
  `,
  [scales.p22Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 1.375rem;
    line-height: 1.4;
  `,
  [scales.p22Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.375rem;
    line-height: 1.4;
  `,
  [scales.p22Light]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 1.375rem;
    line-height: 1.4;
  `,
  [scales.p20Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1.25rem;
    line-height: 1.4;
  `,
  [scales.p20Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.25rem;
    line-height: 1.4;
  `,
  [scales.p18Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1.125rem;
    line-height: 1.5;
  `,
  [scales.p18Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.125rem;
    line-height: 1.5;
  `,
  [scales.p16Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.p16Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.p16Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.p16Light]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.p14Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 0.875rem;
    line-height: 1.5;
  `,
  [scales.p14Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 0.875rem;
    line-height: 1.5;
  `,
  [scales.p14Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 0.875rem;
    line-height: 1.5;
  `,
  [scales.p14Light]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 0.875rem;
    line-height: 2;
  `,
  [scales.p12Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 0.75rem;
    line-height: 1.5;
  `,
  [scales.p12Regular]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 0.75rem;
    line-height: 1.6;
  `,
  [scales.p12Light]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 0.75rem;
    line-height: 1.6;
  `,
  [scales.p6Bold]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 0.375rem;
    line-height: 1.3;
  `,
  [scales.p6Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 0.375rem;
    line-height: 1.3;
  `,
  [scales.p5Medium]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 0.3125rem;
    line-height: 1.3;
  `,
  [scales.buttonL]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1.125rem;
    line-height: 1.5;
  `,
  [scales.buttonM]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.buttonS]: css`
    font-family: ${({ theme }) => theme.fonts.ubuntu};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 0.75rem;
    line-height: 1.5;
  `,
  [scales.decorative22Bold]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1.375rem;
    line-height: 1.2;
  `,
  [scales.decorative22Regular]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.375rem;
    line-height: 1.2;
  `,
  [scales.decorative26Regular]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 1.625rem;
    line-height: 1.2;
  `,
  [scales.decorative22Light]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 1.375rem;
    line-height: 1.2;
  `,
  [scales.decorative16Bold]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.decorative16Light]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 1rem;
    line-height: 1.5;
  `,
  [scales.decorative14Bold]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 0.875rem;
    line-height: 1.3;
  `,
  [scales.decorative14Light]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.light};
    font-size: 0.875rem;
    line-height: 1.3;
  `,
  [scales.decorative12Regular]: css`
    font-family: ${({ theme }) => theme.fonts.kalam};

    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 0.75rem;
    line-height: 1.2;
  `,
};
