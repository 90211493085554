import React from "react";
// Components + styling
import {
  Client1Icon,
  Client2Icon,
  Client3Icon,
  Client4Icon,
  Client5Icon,
  Client6Icon,
  Client7Icon,
} from "components/svg";
import { StyledSliderWrapper, StyledClientsWrapper, StyledClientsWrapperIn, StyledClientsItem } from "./styled";

const ClientsSlider: React.FC = () => {
  const clientsLogoList = [Client1Icon, Client2Icon, Client3Icon, Client4Icon, Client5Icon, Client6Icon, Client7Icon];

  return (
    <StyledSliderWrapper>
      <StyledClientsWrapper>
        <StyledClientsWrapperIn>
          {/* TODO Check animation, refactor to not use triple copy of clientsLogoList */}
          {[...clientsLogoList, ...clientsLogoList, ...clientsLogoList, ...clientsLogoList].map(
            (ClientsLogo, index) => (
              <StyledClientsItem key={index}>
                <ClientsLogo width="100%" />
              </StyledClientsItem>
            ),
          )}
        </StyledClientsWrapperIn>
      </StyledClientsWrapper>
    </StyledSliderWrapper>
  );
};

export default ClientsSlider;
