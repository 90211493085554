// Components + styling
import { Box, Container, Heading, Text } from "components";
import { ProjectPhasesList } from "./components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const UgreatorProjectPhases: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Box
      position="relative"
      pt={{ _: "1.875rem", laptop: "5rem" }}
      pb={{ laptop: "3.75rem" }}
      minHeight={{ laptop: "49rem" }}
    >
      <Container>
        <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "0.625rem" }}>
          {t("Project phases")}
        </Heading>
        <Text
          color="textFourth"
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          maxWidth={{ _: "auto", laptop: "27.375rem" }}
          mb={{ _: "1rem", laptop: "0" }}
        >
          {t(
            "Our experts adhere to distinct phases during the project's execution to guarantee its successful completion.",
          )}
        </Text>

        {!isDesktop && <ProjectPhasesList />}
      </Container>

      {isDesktop && <ProjectPhasesList />}
    </Box>
  );
};

export default UgreatorProjectPhases;
