import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";
// Components
import { Box, DefaultSlideImage, Text } from "components";

export const StyledSlideImageWrapper = styled(Box)`
  width: 100%;
  height: 11.875rem;
  display: flex;
  flex: initial;
  position: relative;
  margin: auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 11.375rem;
    margin: 0.6875rem 0 0.8125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    height: auto;
    padding: 0 0 0 2.0625rem;
  }
`;

export const StyledMotionWrapper = styled(motion.div)<{ zIndex: number }>`
  ${({ theme }) => theme.mediaQueries.laptop} {
    position: absolute;
    right: 0;
    z-index: ${({ zIndex }) => zIndex};
  }
`;

export const StyledSlideImageTitle1 = styled(Text)`
  max-width: 5.25rem;
  text-align: center;
  position: absolute;
  top: -1rem;
  right: 14rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transform: rotate(-3deg);

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -12.5rem;
    right: 20.875rem;
    transform: rotate(-6deg);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -15rem;
    right: 30.875rem;
  }

  &::after {
    ${clearfix}
    width: 4.375rem;
    height: 4.0625rem;
    position: absolute;
    top: 100%;
    right: -0.9375rem;
    background: url("images/planet-coin-slide-arrow-1.svg") no-repeat;
    background-position: center 0;
    background-size: 1.9375rem auto;

    ${({ theme }) => theme.mediaQueries.laptop} {
      top: calc(100% + 0.375rem);
      background-size: contain;
    }
  }
`;

export const StyledSlideImageTitle2 = styled(Text)`
  width: 6.25rem;
  text-align: center;
  position: absolute;
  top: 9.375rem;
  right: 14.375rem;
  transform: rotate(-3deg);
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.tablet} {
    bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 8.25rem;
    max-width: 8.25rem;
    top: 13.125rem;
    right: 26.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 12.875rem;
    right: 39.125rem;
  }

  &::after {
    ${clearfix}
    width: 4.375rem;
    height: 5.6875rem;
    position: absolute;
    right: -3.625rem;
    top: -1.25rem;
    background: url("images/planet-coin-slide-arrow-2.svg") no-repeat;
    background-size: 2.75rem auto;

    ${({ theme }) => theme.mediaQueries.laptop} {
      height: 5.6875rem;
      top: -1.875rem;
      right: -4.6875rem;
      background-size: contain;
    }
  }
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 8.625rem;
  height: auto;
  display: block;
  position: absolute;
  top: 3rem;
  right: 11.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 13.25rem;
    top: -3.4375rem;
    right: 14.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 18.75rem;
    top: -5.625rem;
    right: 23.625rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 14.125rem;
  height: auto;
  display: block;
  position: absolute;
  right: 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 20.75rem;
    top: -9.1875rem;
    right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 28.875rem;
    top: -11.25rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  display: none;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 21.375rem;
    height: 25rem;
    display: block;
    position: absolute;
    top: -8.4375rem;
    right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 29.5rem;
    height: 25rem;
    top: -12.5rem;
  }
`;
