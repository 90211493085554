import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box, Flex, Text } from "components";

export const StyledVideoWrapper = styled(Flex)`
  align-items: center;
  flex-direction: column;
  width: inherit;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    margin-top: 4rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    margin-top: 2rem;
  }
  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-top: 0;
  }
`;

export const StyledMatthewFeedbackImageWrapper = styled(Flex)`
  z-index: 12;
  cursor: pointer;

  & img {
    border-radius: ${({ theme }) => theme.radii.large};
    border: 0.25rem solid ${({ theme }) => theme.colors.eStatesBgBlue};
  }

  ${({ theme }) => theme.mediaQueries.mobileS} {
    width: 6.5rem;
    height: 6.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10.125rem;
    height: 10.125rem;
  }
`;

export const StyledAuthorInformation = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.5rem 7.2rem 1.5rem 4.25rem;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.large};
  margin-top: -2rem;
  width: 100%;
  height: 9.87rem;
  max-height: 9.625rem;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 21.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
    align-items: start;
    margin-right: 0.25rem;
    margin-left: -1rem;
    padding-left: 3rem;
    width: 35%;
    margin-top: 0;
    border-radius: 0;
  }
`;

export const StyledDescriptionBlock = styled(Flex)`
  display: block;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.white};
  max-width: 60%;
  border-top-right-radius: ${({ theme }) => theme.radii.large};
  border-bottom-right-radius: ${({ theme }) => theme.radii.large};
  display: none;

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
    align-items: center;
    height: 9.87rem;
    max-height: 9.625rem;
    width: 55%;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 60%;
    padding: 1.625rem 2.5rem;
  }
`;

export const StyledVideoFeedbackTitleWrapper = styled(Box)`
  position: absolute;
  top: -2.875rem;
  right: -1.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -2.25rem;
    left: 90%;
    right: initial;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -3.25rem;
  }
`;

export const StyledVideoFeedbackTitle = styled(Text)`
  width: 10rem;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 19rem;
  }

  &::before {
    ${clearfix}
    width: 4.375rem;
    height: 3.7rem;
    position: absolute;
    right: 5.625rem;
    top: -1.25rem;
    background: url("images/e-states-slide-arrow-left.svg") no-repeat;
    transform: rotate(45deg);
    background-size: 0.75rem auto;

    ${({ theme }) => theme.mediaQueries.mobileS} {
      right: -1.375rem;
      top: 2rem;
      background-size: 1rem auto;
    }
    ${({ theme }) => theme.mediaQueries.tablet} {
      background-size: 1rem auto;
      right: -1.375rem;
      top: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      background: url("images/e-states-slide-arrow-right.svg") no-repeat;
      width: 2.375rem;
      height: 3.4rem;
      top: 0.1875rem;
      right: 16.4rem;
      background-size: contain;
      transform: rotate(45deg);
      background-size: 1.25rem auto;
    }
  }
`;
