// Components + styled
import { Box, Button, Text } from "components";
import { SettingsIcon } from "components/svg";
import { CaseStudiesFilterDropdown } from "./components";
import { CaseStudiesFilterCount } from "./styled";
import { CaseStudiesNoResults, SelectedFilters, CaseStudiesCard, CaseStudiesMobileFilter } from "..";
// Context
import { useCaseStudyContext, useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
import { useFilterHandling, useGetCaseStudiesFilters } from "./hooks";
// Types
import { Category } from "services";
import { CaseStudiesFilterType } from "context/case-studies-context/types";

const CaseStudiesFilter: React.FC = () => {
  const { t } = useTranslation();
  const { selectedFilters, caseStudies, isLoading: isCaseLoading, handleRemoveFilter } = useCaseStudyContext();
  const { isDesktop } = useMatchBreakpoints();

  const { isModalOpen, hasSomeFiltersSelected, totalSelectedFiltersCount, openModal, closeModal, setModalOpen } =
    useFilterHandling();
  const { filters, isLoading } = useGetCaseStudiesFilters();

  const removeFilters = (type: CaseStudiesFilterType, option: Category) => {
    handleRemoveFilter(filters!, type, option);
  };

  React.useEffect(() => {
    if (isDesktop) {
      setModalOpen(false);
    }
  }, [isDesktop]);

  return (
    <Box>
      <Box mb={{ laptop: "3rem" }}>
        {isDesktop ? (
          <CaseStudiesFilterDropdown
            filters={filters}
            isLoading={isLoading}
            hasSomeFiltersSelected={hasSomeFiltersSelected}
          />
        ) : (
          !isLoading && (
            <Box position="absolute" top="0.25rem" right="0rem">
              <Button
                minWidth="4.5rem"
                variant="secondary"
                bgColor="white"
                endIcon={<SettingsIcon />}
                onClick={openModal}
                hoverLinesPosition="bottomLeft"
                textScale="p16Regular"
              >
                {t("Filters")}

                {totalSelectedFiltersCount > 0 && (
                  <CaseStudiesFilterCount>
                    <Text textScale="p12Regular" color="white">
                      {totalSelectedFiltersCount}
                    </Text>
                  </CaseStudiesFilterCount>
                )}
              </Button>
            </Box>
          )
        )}

        {isDesktop && filters && hasSomeFiltersSelected && (
          <SelectedFilters
            filters={filters}
            selectedFilters={selectedFilters}
            onRemove={removeFilters}
            hasSomeFiltersSelected={hasSomeFiltersSelected}
          />
        )}
      </Box>

      {caseStudies.length > 0 ? (
        <CaseStudiesCard filteredCards={caseStudies} isLoading={isCaseLoading} />
      ) : (
        <CaseStudiesNoResults />
      )}

      <CaseStudiesMobileFilter
        filters={filters}
        isLoading={isLoading}
        hasSomeFiltersSelected={hasSomeFiltersSelected}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </Box>
  );
};

export default CaseStudiesFilter;
