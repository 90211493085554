// Types
import { TranslateFunction } from "context/language-context/types";

const URL = process.env.REACT_APP_URL;

export const getDefaultMeta = (t: TranslateFunction) => {
  return {
    title: t("Custom UX/UI Design Solutions"),
    description: t(
      "We do UX/UI for the applications and platforms that offer new functionality and services to their clients. Make your solution noticeable with Idealogic design help.",
    ),
    image: `${URL}/logo512.png`,
    keywords: "design, ui, ux, ui/ux, startup",
  };
};
