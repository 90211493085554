export const UP_LINE_IMAGES_LIST = [
  {
    src: "/images/wireframes-up-line-1.webp",
    srcHover: "/images/wireframes-up-line-1-hover.webp",
  },
  {
    src: "/images/wireframes-up-line-2.webp",
    srcHover: "/images/wireframes-up-line-2-hover.webp",
  },
  {
    src: "/images/wireframes-up-line-3.webp",
    srcHover: "/images/wireframes-up-line-3-hover.webp",
  },
  {
    src: "/images/wireframes-up-line-4.webp",
    srcHover: "/images/wireframes-up-line-4-hover.webp",
  },
];

export const DOWN_LINE_IMAGES_LIST = [
  {
    src: "/images/wireframes-down-line-1.webp",
    srcHover: "/images/wireframes-down-line-1-hover.webp",
  },
  {
    src: "/images/wireframes-down-line-2.webp",
    srcHover: "/images/wireframes-down-line-2-hover.webp",
  },
  {
    src: "/images/wireframes-down-line-3.webp",
    srcHover: "/images/wireframes-down-line-3-hover.webp",
  },
  {
    src: "/images/wireframes-down-line-4.webp",
    srcHover: "/images/wireframes-down-line-4-hover.webp",
  },
];
