import { BLOCK_ID } from "configs";

const OFFSETS_BY_BLOCK_ID = {
  [BLOCK_ID.services]: 70,
};

export const scrollToElementById = (id: string) => {
  const element = document.getElementById(id);
  const header = document.getElementById("header");

  if (element && header) {
    const top = element.offsetTop;
    const headerRect = header.getBoundingClientRect();

    const extraTopOffset = OFFSETS_BY_BLOCK_ID[id as keyof typeof OFFSETS_BY_BLOCK_ID] ?? headerRect.height / 2;

    window.scrollTo({
      top: top - extraTopOffset,
      behavior: "smooth",
    });
  }
};
