import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "bgDark", ...props }) => {
  return (
    <Svg viewBox="0 0 23 23" {...props} color={color}>
      <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
      <path d="M9.49189 14.5996H8.06787L8.07686 13.5484H9.49189C9.84528 13.5484 10.1433 13.4691 10.3858 13.3104C10.6284 13.1486 10.8111 12.9135 10.9339 12.6051C11.0597 12.2966 11.1226 11.9238 11.1226 11.4865V11.1676C11.1226 10.8322 11.0866 10.5372 11.0147 10.2826C10.9459 10.0281 10.8425 9.81393 10.7048 9.64023C10.567 9.46654 10.3978 9.33626 10.1972 9.24941C9.99652 9.15957 9.76592 9.11465 9.50537 9.11465H8.04092V8.05898H9.50537C9.94261 8.05898 10.3424 8.13385 10.7048 8.28359C11.0701 8.43034 11.3861 8.64147 11.6526 8.91699C11.9192 9.19251 12.1243 9.52194 12.2681 9.90527C12.4148 10.2856 12.4882 10.7094 12.4882 11.1766V11.4865C12.4882 11.9507 12.4148 12.3745 12.2681 12.7578C12.1243 13.1411 11.9192 13.4706 11.6526 13.7461C11.3891 14.0186 11.0731 14.2298 10.7048 14.3795C10.3394 14.5262 9.93512 14.5996 9.49189 14.5996ZM8.83154 8.05898V14.5996H7.48389V8.05898H8.83154ZM14.6804 10.7992V14.5996H13.3866V9.73906H14.604L14.6804 10.7992ZM16.1448 9.70762L16.1224 10.907C16.0595 10.898 15.9831 10.8906 15.8933 10.8846C15.8064 10.8756 15.7271 10.8711 15.6552 10.8711C15.4725 10.8711 15.3138 10.8951 15.179 10.943C15.0472 10.9879 14.9364 11.0553 14.8466 11.1451C14.7597 11.235 14.6938 11.3443 14.6489 11.473C14.607 11.6018 14.583 11.7486 14.5771 11.9133L14.3165 11.8324C14.3165 11.518 14.3479 11.229 14.4108 10.9654C14.4737 10.6989 14.5651 10.4668 14.6849 10.2691C14.8076 10.0715 14.9574 9.91875 15.1341 9.81094C15.3108 9.70313 15.5129 9.64922 15.7405 9.64922C15.8124 9.64922 15.8858 9.65521 15.9606 9.66719C16.0355 9.67617 16.0969 9.68965 16.1448 9.70762Z" />
    </Svg>
  );
};

export default Icon;
