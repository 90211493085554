import { useRef } from "react";
// Components + styling
import { Heading, Text } from "components";
import { AmbassadorsSlider } from "./components";
import { StyledOuterWrapper, StyledInnerWrapper, StyledContentContainer } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useAnimationStatus, useElementDimensions, useMatchBreakpoints } from "hooks";
import { useScrollXByScrollY } from "./hooks";
// Constants
import { BLOCK_ID } from "configs";
import { animationStatusCheckVariants } from "hooks/use-animation-status/types";
import { AMBASSADORS_LIST } from "./constants";

const UgreatorAmbassadors: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const ambassadorsRef = useRef<HTMLDivElement | null>(null);

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  const { isAnimationActive } = useAnimationStatus(animationStatusCheckVariants.ugreatorAmbassadors);

  const sliderFullWidth = ambassadorsRef.current?.offsetHeight
    ? ambassadorsRef.current?.offsetHeight * AMBASSADORS_LIST.length
    : window.innerWidth;

  const { x } = useScrollXByScrollY(wrapperRef, sliderFullWidth);

  return (
    <div>
      <StyledOuterWrapper ref={wrapperRef} isAnimationActive={isAnimationActive} headerHeight={headerHeight}>
        <StyledInnerWrapper headerHeight={headerHeight}>
          <StyledContentContainer>
            <Heading
              scale="h2"
              as="h2"
              textAlign={{ _: "left", laptop: "center" }}
              mb={{ _: "0.25rem", laptop: "0.625rem" }}
            >
              {t("Ambassadors")}
            </Heading>

            <Text
              textScale={isDesktop ? "p22Regular" : "p16Regular"}
              textAlign={{ _: "left", laptop: "center" }}
              mb={{ _: "1.75rem", tablet: isAnimationActive ? "0" : "2.5rem" }}
              color="textFourth"
            >
              {t("Celebrities who use the application and create their arts")}
            </Text>

            {(isMobile || !isAnimationActive) && (
              <AmbassadorsSlider ref={ambassadorsRef} x={x} isAnimationActive={isAnimationActive} />
            )}
          </StyledContentContainer>

          {!isMobile && isAnimationActive && (
            <AmbassadorsSlider ref={ambassadorsRef} x={x} isAnimationActive={isAnimationActive} />
          )}
        </StyledInnerWrapper>
      </StyledOuterWrapper>
    </div>
  );
};

export default UgreatorAmbassadors;
