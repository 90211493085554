import { useScroll, useTransform } from "framer-motion";

const useRotateOnScroll = () => {
  const { scrollY } = useScroll();
  const rotate = useTransform(scrollY, value => value / 5);

  return { rotate };
};

export default useRotateOnScroll;
