import styled from "styled-components";
// Components + styling
import { Box } from "components";
import { StyledContactLink } from "components/agreements-contact-details/styled";

export const StyledPrivacyPolicyLink = styled(StyledContactLink)`
  display: inline-block;
`;

export const StyledAccordionWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineLight};
`;
