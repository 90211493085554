import React, { useRef } from "react";
import { useInView } from "framer-motion";
// Components + Styling;
import { Container, Box, Text, Heading, FindSolutionForm } from "components";
import { FindSolutionLineIcon } from "components/svg";
import { FindSolutionParallaxImage } from "./components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const FindSolution: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isLargeDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const preTitleRef = useRef<HTMLParagraphElement | null>(null);
  const isInView = useInView(preTitleRef, { once: true });

  return (
    <Box ref={wrapperRef}>
      <Container py={{ _: "3.75rem", laptop: "4rem" }} position="relative">
        {isDesktop && isInView && (
          <Box position="absolute" top="-3.375rem" left={isLargeDesktop ? "13.75rem" : "3.75rem"}>
            <FindSolutionLineIcon width="31.875rem" color="textFourth" />
          </Box>
        )}
        <Text
          ref={preTitleRef}
          textScale={isDesktop ? "decorative22Light" : "decorative16Light"}
          maxWidth="19rem"
          mx="auto"
          mb={{ _: "1rem", laptop: "1.5rem" }}
          textAlign="center"
        >
          {t("You don’t have an idea but want to launch your startup?")}
        </Text>

        <Heading scale="h3" as="h3" mb="0.5rem" textAlign="center">
          {t("Let's find your solution")}!
        </Heading>

        <Text textScale={isDesktop ? "p20Regular" : "p14Regular"} mb="1.5rem" textAlign="center">
          {t("For example: Blockchain, wallet, brand identity and etc.")}
        </Text>

        <FindSolutionForm />

        <FindSolutionParallaxImage ref={wrapperRef} />
      </Container>
    </Box>
  );
};

export default FindSolution;
