import React from "react";
// Components + styling
import { Flex } from "components";
import SalesItem from "../sales-item";

// Constants
import { SALES_LIST } from "./constants";

const SalesList: React.FC = () => {
  return (
    <Flex
      width={{ _: "auto", laptop: "36rem", laptopL: "40rem" }}
      justifyContent="center"
      mb={{ _: "2rem", laptop: "0" }}
    >
      {SALES_LIST.map(({ name, position, image, hoverColor, ImageLottieAnimation, ImageLottieWrapper }) => (
        <SalesItem
          key={name}
          name={name}
          position={position}
          image={image}
          hoverColor={hoverColor}
          ImageLottieAnimation={ImageLottieAnimation}
          ImageLottieWrapper={ImageLottieWrapper}
        />
      ))}
    </Flex>
  );
};

export default SalesList;
