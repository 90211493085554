import { useRef } from "react";
// Components + styling
import { Box, Container, Flex, Heading, Image, Text } from "components";
import { UgreatorTryAppParallaxImage } from "./components";
import { StyledMainImageWrapper, StyledTryAppLink, StyledTryAppVariant, StyledUgreatorTryAppWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { UGREATOR_TRY_APP_VARIANTS_LIST } from "./constants";

const UgreatorTryApp: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={wrapperRef}>
      <StyledUgreatorTryAppWrapper>
        <Container width="100%" pt={{ _: "3rem", laptop: "7.5rem" }} pb={{ _: "2rem", laptop: "15rem" }}>
          <Flex
            flexDirection={{ _: "column", laptop: "row" }}
            justifyContent={{ _: "flex-start", laptop: "space-between" }}
            alignItems={{ _: "flex-start", laptop: "center" }}
          >
            <Box width={{ _: "100%", laptop: "calc(50% - 2rem)", laptopL: "calc(60% - 2rem)" }}>
              <Heading scale="h2" as="h2" mb={{ _: "1.5rem", laptop: "3rem" }} color="white">
                {t("Try this App by yourself")}
              </Heading>

              {UGREATOR_TRY_APP_VARIANTS_LIST.map(({ label, description, icon, link }) => (
                <StyledTryAppVariant key={label}>
                  <Image src={icon} width={isDesktop ? "3.5rem" : "2.75rem"} aspectRatio={1} />

                  <Box
                    width={{ _: "calc(100% - 4rem)", laptop: "calc(100% - 6.5rem)" }}
                    mx={{ _: "0.625rem", laptop: "1.5rem" }}
                  >
                    <StyledTryAppLink
                      textScale={isDesktop ? "p24Bold" : "p20Bold"}
                      mb={{ _: "0.25rem", laptop: "0.5rem" }}
                      color="white"
                      external
                      href={link}
                      underline
                    >
                      {t(label)}
                    </StyledTryAppLink>
                    <Text textScale="p18Regular" color="white">
                      {t(description)}
                    </Text>
                  </Box>
                </StyledTryAppVariant>
              ))}
            </Box>

            {isDesktop && (
              <StyledMainImageWrapper>
                <UgreatorTryAppParallaxImage ref={wrapperRef} />

                <Image
                  width="100%"
                  src="/images/case-studies/ugreator/ugreator-try-app-main-img.webp"
                  aspectRatio={0.81}
                />
              </StyledMainImageWrapper>
            )}
          </Flex>
        </Container>

        {!isDesktop && (
          <StyledMainImageWrapper>
            <UgreatorTryAppParallaxImage ref={wrapperRef} />

            <Image width="80%" src="/images/case-studies/ugreator/ugreator-try-app-main-img.webp" aspectRatio={0.81} />
          </StyledMainImageWrapper>
        )}
      </StyledUgreatorTryAppWrapper>
    </div>
  );
};

export default UgreatorTryApp;
