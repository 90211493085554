import styled from "styled-components";
// Components
import { Box, Flex } from "components";

export const StyledChatMessagesList = styled(Box)`
  ${({ theme }) => theme.mediaQueries.mediumHeight} {
    height: 17.375rem;
    max-height: 17.375rem;
    overflow-y: scroll;
  }
`;

export const StyledChatMessage = styled(Box)<{ isPreviousMessageTypeSame: boolean }>`
  width: 100%;
  min-width: 15.625rem;
  max-width: 21.25rem;
  margin-top: ${({ isPreviousMessageTypeSame }) => (isPreviousMessageTypeSame ? "0.25rem" : "0.75rem")};
  padding: 0.75rem 0.5rem 0.75rem 0.75rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-width: 21.25rem;
  }

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;

export const StyledChatInboxMessage = styled(StyledChatMessage)`
  margin-right: auto;
  border-bottom-left-radius: ${({ theme }) => theme.radii.semiSmall};
  border-top-left-radius: ${({ theme, isPreviousMessageTypeSame }) =>
    isPreviousMessageTypeSame ? theme.radii.semiSmall : theme.radii.semiLarge};
  background: ${({ theme }) => theme.colors.textSecond};
`;

export const StyledChatSentMessage = styled(StyledChatMessage)`
  margin-left: auto;
  margin-top: 0;
  border-bottom-right-radius: ${({ theme }) => theme.radii.semiSmall};
  border-top-right-radius: ${({ theme, isPreviousMessageTypeSame }) =>
    isPreviousMessageTypeSame ? theme.radii.semiSmall : theme.radii.semiLarge};
  background: ${({ theme }) => theme.colors.disabledStrokeDark};
`;

export const StyledChatSentMessageWrapper = styled(Box)<{ isPreviousMessageTypeSame: boolean }>`
  margin-top: ${({ isPreviousMessageTypeSame }) => (isPreviousMessageTypeSame ? "0.25rem" : "0.75rem")};

  &:nth-of-type(1) {
    margin-top: 0;
  }
`;

export const StyledManagerStatus = styled(Flex)<{ isTyping: boolean }>`
  align-items: center;
  margin: 0.75rem 0;
  opacity: ${({ isTyping }) => (isTyping ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
`;
