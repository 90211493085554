import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 27 908" {...props} color="transparent">
      <path
        d="M11.5002 1.28613C17.366 5.98344 -7.66688 11.6341 11.5002 100.99C30.6673 190.346 -12.499 324.338 11.5005 436.955C35.5001 549.573 -21.5001 651.468 11.5002 740.177C44.5004 828.887 11.5002 906.929 11.5002 906.929"
        stroke={theme.colors[color]}
        strokeDasharray="9 9"
      />
    </Svg>
  );
};

export default Icon;
