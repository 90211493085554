import styled from "styled-components";
import { motion } from "framer-motion";
// Components + styling
import { Column, Text } from "components";
// Animations
import { appearanceAnimation } from "theme/animations";

export const StyledBannerWrapper = styled(Column)`
  min-height: inherit;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIconWrapper = styled(motion.div)`
  text-align: center;
  margin-top: 4rem;
`;

export const StyledIconDescription = styled(Text).attrs({ color: "textFourth" })`
  text-align: center;
  margin-bottom: 0.125rem;
  opacity: 0;
  animation: ${appearanceAnimation} 0.25s linear 0.5s forwards;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-bottom: 0.5rem;
  }
`;
