import React from "react";
// Components + styling
import { StyledTab } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TabProps } from "./types";

const Tab: React.FC<TabProps> = ({ title, onClick, isActive, color }) => {
  const { isMobileS } = useMatchBreakpoints();

  return (
    <StyledTab textScale={isMobileS ? "p14Regular" : "p18Regular"} isActive={isActive} color={color} onClick={onClick}>
      {title}
    </StyledTab>
  );
};

export default Tab;
