import styled from "styled-components";

export const StyledFilterList = styled.div`
  display: block;
  width: 13.125rem;
  max-height: 37.5rem;
  overflow-y: scroll;
  z-index: ${({ theme }) => theme.zIndices.header};

  border-radius: ${({ theme }) => theme.radii.semiLarge};
  border: 1px solid ${({ theme }) => theme.colors.lineLight};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.caseStudiesListInputs};
`;

export const StyledFilterOption = styled.div<{ isSelected: boolean }>`
  padding: 1.5rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.lineSecond};

  color: ${({ theme }) => theme.colors.textMain};

  cursor: pointer;

  &:first-child:hover {
    border-top-right-radius: ${({ theme }) => theme.radii.semiLarge};
    border-top-left-radius: ${({ theme }) => theme.radii.semiLarge};
  }
  &:last-child:hover {
    border-bottom-right-radius: ${({ theme }) => theme.radii.semiLarge};
    border-bottom-left-radius: ${({ theme }) => theme.radii.semiLarge};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.lineSecond};
  }

  ${({ isSelected }) => isSelected && `font-weight: bold;`}
`;

export const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid ${({ theme, checked }) => (checked ? theme.colors.textMain : theme.colors.textFifth)};
  border-radius: ${({ theme }) => theme.radii.semiSmall};
  margin-right: 0.35rem;

  &::after {
    content: "";
    display: block;
    width: 0.9375rem;
    height: 1rem;
    background: ${({ checked }) => checked && "url(/images/check-mark.svg) no-repeat center center"};
    background-size: contain;
  }
`;
