export const ROUTES = {
  landing: "/",
  aboutUs: "about-us",
  careers: "careers",
  termsConditions: "terms-conditions",
  privacyPolicy: "privacy-policy",
  blog: "blog",
  findSolution: "find-solution",
  caseStudies: "case-studies",
  eStatesCase: "e-states",
  planetcoinCase: "planetcoin",
  ugreatorCase: "ugreator",
};

export const ROUTE_PARAMS = {
  positionId: "positionId",
  slug: "slug",
  caseStudy: "caseStudy",
};

export const QUERY_PARAMS = {
  page: "page",
};
