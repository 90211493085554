export const userRoleTypes = {
  USER: "user",
  ASSISTANT: "assistant",
  SYSTEM: "system",
} as const;

export type UserRole = (typeof userRoleTypes)[keyof typeof userRoleTypes];

export interface ChatMessage {
  content: string;
  role: UserRole;
  created_at: number;
  isRead?: boolean;
  error?: string;
}

export interface ChatUpdateResponse {
  messages: ChatMessage[];
}
