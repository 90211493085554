import { css } from "styled-components";
// Types
import { UgreatorUserFlowsFirstColumnConnectionType, ugreatorUserFlowsFirstColumnConnectionType } from "./types";

export const firstColumnFlowVariantStyles = (
  connectionType: UgreatorUserFlowsFirstColumnConnectionType = ugreatorUserFlowsFirstColumnConnectionType.top,
) => {
  return {
    top: css`
      margin-top: 0.875rem;

      ::before {
        width: 0.375rem;
        height: 1rem;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%);
        background-image: url("/images/case-studies/ugreator/ugreator-user-flows-top-connector.svg");
      }

      ${({ theme }) => theme.mediaQueries.laptop} {
        margin-top: 2.75rem;

        ::before {
          height: 3rem;
          top: -3rem;
        }
      }
    `,
    right: css`
      ::before {
        width: 2.375rem;
        height: 0.375rem;
        top: 50%;
        right: -2.375rem;
        transform: translateY(-50%);
        background-image: url("/images/case-studies/ugreator/ugreator-user-flows-right-connector.svg");

        ${({ theme }) => theme.mediaQueries.laptop} {
          width: 7.0625rem;
          right: -6.875rem;
        }
      }
    `,
  }[connectionType];
};
