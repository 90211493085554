import React from "react";
// Components + styling
import { Accordion, Box, ListPoint, Text } from "components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { SHARING_INFO_LIST } from "./constants";

const SharingInformation: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Accordion title={t("Sharing of information")} $backgroundColor="white">
      <Box pb="1.5rem">
        <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mb="1rem">
          {t("IDEALOGIC may disclose your personal information to the following categories of recipients:")}
        </Text>
        <ul>
          {SHARING_INFO_LIST.map(point => (
            <ListPoint key={point} textScale={isDesktop ? "p18Regular" : "p14Regular"}>
              {t(point)}
            </ListPoint>
          ))}
        </ul>
      </Box>
    </Accordion>
  );
};

export default SharingInformation;
