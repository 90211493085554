type PositionInfoSectionList = {
  label: string;
  value: "requirements" | "bePlus" | "duties" | "weOffer";
};

export const POSITION_INFO_SECTIONS_LIST: PositionInfoSectionList[] = [
  {
    label: "Requirements",
    value: "requirements",
  },
  {
    label: "It would be a plus",
    value: "bePlus",
  },
  {
    label: "Duties",
    value: "duties",
  },
  {
    label: "We offer",
    value: "weOffer",
  },
];
