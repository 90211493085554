import { useEffect, useState } from "react";
// Constants
import { MOBILE_MENU_ANIMATION_DURATION } from "components/header/constants";

const useElementByIdHeight = (id: string) => {
  const [elementHeight, setElementHeight] = useState(0);

  useEffect(() => {
    updateElementHeight();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    // Need to use timeout, because after resize header height is includes mobile header height and desktop header height
    setTimeout(() => {
      updateElementHeight();
    }, MOBILE_MENU_ANIMATION_DURATION);
  };

  const updateElementHeight = () => {
    const { offsetHeight } = document.getElementById(id) ?? { offsetHeight: 0 };
    setElementHeight(offsetHeight);
  };

  return { elementHeight };
};

export default useElementByIdHeight;
