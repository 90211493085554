import styled from "styled-components";
// Components
import { Box } from "components";

export const StyledActiveInteractionItem = styled(Box)<{ isActive: boolean }>`
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 0.5rem;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    transform: translateX(${({ isActive }) => (isActive ? "0" : "-14%")})
      scale(${({ isActive }) => (isActive ? 1 : 0.75)});
    transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
    cursor: pointer;

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
`;
