// Components
import { Box, Page } from "components";
import {
  UgreatorBanner,
  UgreatorAmbassadors,
  UgreatorProjectPhases,
  UgreatorUserFlows,
  UgreatorWireframes,
  UgreatorInteractions,
  UgreatorTryApp,
  UgreatorClientTestimonial,
} from "./components";
// Hooks
import { useElementDimensions } from "hooks";
// Constants
import { BLOCK_ID } from "configs";

const UgreatorCasePage: React.FC = () => {
  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Page maxWidth="100vw" px="0" mt={`-${headerHeight}px`}>
      <UgreatorBanner />

      <UgreatorAmbassadors />

      {/* If in one of the components will use position: sticky, need to remove overflowX="hidden" for correct work sticky.(but need to add wrapper with overflowX="hidden" for <UgreatorWireframes />) */}
      <Box overflowX="hidden">
        <UgreatorProjectPhases />

        <UgreatorUserFlows />

        <UgreatorWireframes />
      </Box>

      <UgreatorInteractions />

      <UgreatorTryApp />

      <UgreatorClientTestimonial />
    </Page>
  );
};

export default UgreatorCasePage;
