import React from "react";
// Components + Styling;
import { Container, Box, Flex, Heading, Text } from "components";
import { CallToAction, UserCase } from "./components";
import { StyledUserCasesList, StyledCustomCursor } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
import { useCustomCursor } from "./hooks";
// Constants
import { USER_CASES_LIST } from "./constants";

const UserCases: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { ref, isCustomCursorVisible, cursorVariant, variants, transition, handleMouseEnter, handleMouseLeave } =
    useCustomCursor();

  return (
    <Box>
      <Container py={{ _: "3.75rem", laptop: "4rem" }}>
        <Flex flexDirection={isDesktop ? "row" : "column"} justifyContent={isDesktop ? "space-between" : "flex-start"}>
          <Box width={{ _: "100%", laptop: "75%" }} mb={{ _: "1.75rem", laptop: "0" }}>
            <Box mb={{ _: "1.5rem" }}>
              <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
                {t("You may need our services if:")}
              </Heading>
              <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>
                {t("Take a look at our customer portraits")}
              </Text>
            </Box>

            <StyledUserCasesList ref={ref} isCustomCursorVisible={isCustomCursorVisible}>
              {USER_CASES_LIST.map(({ Icon, label }) => (
                <UserCase
                  key={label}
                  Icon={Icon}
                  label={label}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                />
              ))}
            </StyledUserCasesList>
          </Box>

          <CallToAction />
        </Flex>

        <StyledCustomCursor variants={variants} animate={cursorVariant} transition={transition}>
          <Text width="4.375rem" textScale="p16Medium" color="white">
            {t("Schedule a call")}
          </Text>
        </StyledCustomCursor>
      </Container>
    </Box>
  );
};

export default UserCases;
