import {
  TransparencyIcon,
  InnovativenessIcon,
  ClientThinkingIcon,
  ResultOrientednessIcon,
  ReliabilityIcon,
  EffectivenessIcon,
} from "components/svg";

export const CORPORATE_VALUES_LIST = [
  {
    title: "Transparency",
    description:
      "We believe that there are fewer problems and faster solutions if communication is open and clear, whether things are good or bad.",
    Icon: TransparencyIcon,
  },
  {
    title: "Innovativeness",
    description: "We encourage fresh views on the usual problems and present unique solutions.",
    Icon: InnovativenessIcon,
  },
  {
    title: "Client-thinking",
    description: "We practice a custom approach according to the client’s business needs and final goals.",
    Icon: ClientThinkingIcon,
  },
  {
    title: "Result orientedness",
    description: "Working on the project, we always keep in mind the future user and anticipate their wishes.",
    Icon: ResultOrientednessIcon,
  },
  {
    title: "Reliability",
    description: "We are precise with delivery deadlines to prevent business failure.",
    Icon: ReliabilityIcon,
  },
  {
    title: "Effectiveness",
    description: "We secure every detail to present the best possible final solution as soon as possible.",
    Icon: EffectivenessIcon,
  },
];
