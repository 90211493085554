import { Colors } from "theme/types";
import { ContactUsTheme } from "./types";

export const contactUsColorsSchema: { [key in ContactUsTheme]: Partial<{ [key in keyof Colors]: keyof Colors }> } = {
  light: {
    textFourthForDarkBg: "textFifthContrast",
    bgDark: "white",
    textThird: "textFifth",
    textMain: "textMain",
    gray: "gray",
    dark: "dark",
  },
  dark: {
    textFourthForDarkBg: "textFourthForDarkBg",
    bgDark: "bgDark",
    textThird: "textThird",
    textMain: "white",
    gray: "gray",
    dark: "white",
  },
};
