import React from "react";
// Components + Styling
import { Button, Flex, Box, Heading, Image, Text } from "components";
import { StyledButtonWrapper, StyledHeadingWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { ThankForRequestProps } from "./types";

const ThankForRequest: React.FC<ThankForRequestProps> = ({
  textColor,
  isHideBgImage,
  isHideButton,
  setIsShowThankYou,
}) => {
  const { t } = useTranslation();
  const { isDesktop, isLargeDesktop } = useMatchBreakpoints();

  return (
    <Flex
      flexDirection={{ _: "column", laptop: "row" }}
      justifyContent={{ _: "center", laptop: "space-between" }}
      alignItems="center"
    >
      <Box minWidth={{ _: "100%", laptop: "fit-content" }}>
        <StyledHeadingWrapper>
          <Heading scale="h2" as="h2" textAlign={isDesktop ? "left" : "center"} color={textColor ?? "textMain"}>
            {t("Thank you!")}
          </Heading>
        </StyledHeadingWrapper>

        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          minWidth={"fit-content"}
          mb={{ _: "1.5rem", laptop: "2rem" }}
          color={textColor ?? "textMain"}
        >
          {t("We will write you back as soon as possible")}
        </Text>

        {!isDesktop && !isHideBgImage && (
          <Image width="100%" src={"/images/thanks-for-request-sm.webp"} aspectRatio={1.32} />
        )}

        {!isHideButton && (
          <StyledButtonWrapper>
            <Button
              width="100%"
              maxWidth="21.375rem"
              scale="lg"
              hoverLinesPosition={isLargeDesktop ? "bottomLeft" : "mobileTopLeft"}
              onClick={() => setIsShowThankYou(false)}
            >
              {t("Ok. I’m waiting")}
            </Button>
          </StyledButtonWrapper>
        )}
      </Box>

      {isDesktop && !isHideBgImage && (
        <Image width={isLargeDesktop ? "60%" : "50%"} src="/images/thanks-for-request.webp" aspectRatio={1.17} />
      )}
    </Flex>
  );
};

export default ThankForRequest;
