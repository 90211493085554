// Components + styling
import { Box, Container, Flex, HashLayoutWrapper, Heading, Text } from "components";
import { PlanetcoinBannerParallaxImage } from "./components";
import {
  StyledBannerCategory,
  StyledBannerPointItem,
  StyledBannerPointsList,
  StyledBannerStatisticValue,
  StyledPlanetcoinBannerSubtitle,
  StyledPlanetcoinBannerWrapper,
  StyledStatisticsList,
  StyledStatisticItem,
  StyledBannerPointItemWrapper,
} from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useAnimationStatus, useElementDimensions, useMatchBreakpoints } from "hooks";
// Constants
import { BANNER_CATEGORY_LIST, BANNER_POINTS_LIST, BANNER_STATISTICS_LIST } from "./constants";
import { animationStatusCheckVariants } from "hooks/use-animation-status/types";
import { BLOCK_ID } from "configs";

const PlanetcoinBanner: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { isDesktop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  const { isAnimationActive } = useAnimationStatus(animationStatusCheckVariants.planetcoinBanner);

  return (
    <div>
      <HashLayoutWrapper
        minHeight={isAnimationActive ? "100vh" : "50vh"}
        pt={`${headerHeight * 2}px`}
        $backgroundColor="planetcoinBgDark"
      >
        <StyledPlanetcoinBannerWrapper>
          <Container>
            <Box maxWidth={{ _: "40rem", laptop: "47.625rem" }} position="relative">
              <Heading scale="h2" as="h2" mb={{ _: "0.5rem", laptop: "1.5rem" }} color="white" textAlign="center">
                {t("Planetcoin")}
              </Heading>

              <StyledPlanetcoinBannerSubtitle scale={isDesktop ? "h5" : "h4"} as="h4">
                {t(
                  "Crypto platform, where you can manage your wallet, trade, make transactions, and keep track of your cryptocurrency",
                )}
              </StyledPlanetcoinBannerSubtitle>
            </Box>

            <Flex justifyContent="center" mb="2.5rem">
              {BANNER_CATEGORY_LIST.map(category => (
                <StyledBannerCategory key={category}>
                  <Text textScale="p14Regular" color="white">
                    {t(category)}
                  </Text>
                </StyledBannerCategory>
              ))}
            </Flex>

            <StyledStatisticsList justifyContent="space-between" position="relative">
              {BANNER_STATISTICS_LIST.map(({ value, label }) => (
                <StyledStatisticItem key={label}>
                  <Box p={{ laptop: "0 3.375rem" }} position="relative" zIndex={theme.zIndices.dropdown}>
                    <StyledBannerStatisticValue scale="h4" as="h4">
                      {t(value)}
                    </StyledBannerStatisticValue>

                    <Text textScale="p16Regular" color="white" textAlign="center" opacity="0.8">
                      {t(label)}
                    </Text>
                  </Box>
                </StyledStatisticItem>
              ))}
            </StyledStatisticsList>
          </Container>

          <PlanetcoinBannerParallaxImage />

          <Container mb={{ _: "5.625rem", laptop: "14rem" }} position="relative">
            <StyledBannerPointsList>
              {BANNER_POINTS_LIST.map(({ Icon, label, description }) => (
                <StyledBannerPointItemWrapper key={label}>
                  <StyledBannerPointItem>
                    <Icon width={isDesktop ? "5.25rem" : "3.1875rem"} mb={{ _: "0.5rem", laptop: "0.75rem" }} />
                    <Heading
                      scale={isDesktop ? "h3" : "h2"}
                      as="h2"
                      mb={{ _: "0.5rem", laptop: "0.75rem" }}
                      color="white"
                      textAlign="center"
                    >
                      {t(label)}
                    </Heading>
                    <Text textScale="p18Regular" color="textFourth" textAlign="center">
                      {t(description)}
                    </Text>
                  </StyledBannerPointItem>
                </StyledBannerPointItemWrapper>
              ))}
            </StyledBannerPointsList>
          </Container>
        </StyledPlanetcoinBannerWrapper>
      </HashLayoutWrapper>
    </div>
  );
};

export default PlanetcoinBanner;
