import styled from "styled-components";
import { clearfixDisplayNone } from "styles";
// Components
import { Box, Column, Heading, Image, Text } from "components";

export const StyledClientAvatarLabel = styled(Text)`
  max-width: 7.5rem;
  text-align: center;
  position: absolute;
  top: -4.5rem;
  right: -0.75rem;
  transform: rotate(3deg);
`;

export const StyledCurlyArrowIconWrapper = styled(Box)`
  margin-left: -2rem;
  position: absolute;
  top: -1.25rem;
  right: 5.75rem;
  transform: rotate(48deg);
`;

export const StyledClientAvatar = styled(Image)`
  margin-bottom: 1.5rem;

  img {
    margin: 0 auto;

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin: 0;
    }
  }
`;

export const StyledRatingWrapper = styled(Column)`
  align-items: center;
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: flex-start;
  }

  ::before {
    ${clearfixDisplayNone}
    width: 2rem;
    height: 2rem;
    background: url("/images/planetcoin-quotes.svg");
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
      margin-bottom: 1.25rem;
    }
  }
`;

export const StyledTestimonialDescription = styled(Heading)`
  text-align: center;
  margin-bottom: 1.125rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    text-align: left;
    margin-bottom: 2.125rem;
  }
`;
