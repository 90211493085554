// Context
import { useCaseStudyContext } from "context";

const useFilterHandling = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const { selectedFilters } = useCaseStudyContext();

  const hasSomeFiltersSelected = Object.values(selectedFilters).some(options => options.length > 0);
  const totalSelectedFiltersCount = Object.values(selectedFilters).reduce((acc, filters) => acc + filters.length, 0);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return {
    isModalOpen,
    hasSomeFiltersSelected,
    totalSelectedFiltersCount,
    openModal,
    closeModal,
    setModalOpen,
  };
};

export default useFilterHandling;
