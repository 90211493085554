// Styled components
import {
  StyledSlideImage1,
  StyledSlideImage2,
  StyledSlideImage3,
  StyledSlideImage4,
  StyledSlideImage5,
  StyledSlideImage6,
  StyledSlideImage7,
} from "./styled";
// Theme
import { zIndices } from "theme/base";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage1,
    imageSrc: "/images/landing-find-solution-img-1.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/landing-find-solution-img-2.svg",
    zIndex: zIndices.dropdown,
    ratio: 0.94, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/landing-find-solution-img-3.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage4,
    imageSrc: "/images/landing-find-solution-img-4.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage5,
    imageSrc: "/images/landing-find-solution-img-5.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage6,
    imageSrc: "/images/landing-find-solution-img-6.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage7,
    imageSrc: "/images/landing-find-solution-img-7.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
];
