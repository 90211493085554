import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box, Text } from "components";

export const StyledCeoSticker = styled(Box)`
  width: 100%;
  height: fit-content;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  box-shadow: ${({ theme }) => theme.shadows.card};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 26rem;
    margin-top: 2.5rem;
    padding: 2rem 1.5rem 1.5rem;
  }
`;

export const StyledStickerDescription = styled(Text)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  padding: 1rem 1.25rem 1.25rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  background-color: ${({ theme }) => `${theme.colors.findSolutionGreyBg}80`};

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 1.25rem 1.5rem 1.5rem;
  }

  ::before {
    ${clearfix}
    width: 0.875rem;
    height: 0.875rem;
    position: absolute;
    top: -0.875rem;
    left: 1.5rem;
    background-image: url("/images/triangle.svg");
    background-repeat: no-repeat;
  }
`;
