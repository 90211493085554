import { DefaultTheme } from "styled-components";

export const getTooltipStyles = (theme: DefaultTheme) => {
  return {
    tooltip: {
      width: "100%",
      border: "none",
      boxShadow: "none",
      background: theme.colors.transparent,
    },
    arrow: {
      display: "none",
    },
  };
};
