import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" {...props} color="transparent">
      <path
        d="M10.8621 45.3506V45.3492"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="mask0_1130_2079" maskUnits="userSpaceOnUse" x="0" y="0" width="72" height="72">
        <path d="M0 7.62939e-06H72V72H0V7.62939e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1130_2079)">
        <path
          d="M70.5 20.5H53.5C61.4998 21.5005 62.0002 26.9995 62.0002 30.9995C62.0002 34.1995 57.0002 36.6662 54.5002 37.4995H44.5L41.5 40.9995C39.1 42.9995 42.5 46.1662 44.5 47.4995H50.5C65.3 52.2995 57.6667 60.8332 52 64.5C58.1667 64.6667 67.4383 64.4515 69.0002 62.4995C71.0002 60 70.5 35 70.5 20.5Z"
          fill={theme.colors[color]}
          stroke={theme.colors[color]}
        />
        <path
          d="M52.8945 20.3434C57.9959 20.3434 62.1323 24.2012 62.1323 28.9609C62.1323 33.7205 57.9959 37.5798 52.8945 37.5798H46.3056C43.4481 37.5798 41.1323 39.7394 41.1323 42.4057C41.1323 45.0704 43.4481 47.2315 46.3056 47.2315H49.1884C54.3014 47.2315 58.4476 51.0991 58.4476 55.8687C58.4476 60.6395 54.3014 64.5059 49.1884 64.5059"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M10.8623 20.3418H70.5936"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M18.0791 13.9182C18.0791 14.1431 18.2612 14.3252 18.4861 14.3252C18.7109 14.3252 18.893 14.1431 18.893 13.9182C18.893 13.6935 18.7108 13.5113 18.4861 13.5113C18.2613 13.5113 18.0791 13.6935 18.0791 13.9182Z"
          fill={theme.colors[color]}
          stroke={theme.colors[color]}
          strokeWidth="2"
        />
        <path
          d="M23.707 13.9182C23.707 14.1431 23.8892 14.3252 24.114 14.3252C24.3387 14.3252 24.5209 14.143 24.5209 13.9182C24.5209 13.6936 24.3387 13.5113 24.114 13.5113C23.8892 13.5113 23.707 13.6935 23.707 13.9182Z"
          fill={theme.colors[color]}
          stroke={theme.colors[color]}
          strokeWidth="2"
        />
        <path
          d="M29.3345 13.9182C29.3345 14.1431 29.5166 14.3252 29.7414 14.3252C29.9662 14.3252 30.1484 14.143 30.1484 13.9182C30.1484 13.6936 29.9661 13.5113 29.7414 13.5113C29.5167 13.5113 29.3345 13.6935 29.3345 13.9182Z"
          fill={theme.colors[color]}
          stroke={theme.colors[color]}
          strokeWidth="2"
        />
        <path
          d="M10.8623 45.3496V45.3482"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5 7.49382H62.5939C67.0122 7.49382 70.5939 11.0755 70.5939 15.4938V20.342V56.5059C70.5939 60.9241 67.0122 64.5059 62.5939 64.5059H49.1887H18.8623C14.444 64.5059 10.8623 60.9241 10.8623 56.5059V53.4444"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8623 35.1006V20.3433V20.3417V15.4937C10.8623 11.0754 14.444 7.49365 18.8623 7.49365H34.4"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.4534 50.8687C21.5008 52.8214 18.3349 52.8214 16.3823 50.8687L9.27334 43.7598C7.32071 41.8072 7.32072 38.6413 9.27336 36.6887L18.5036 27.4585C19.2847 26.6775 20.551 26.6775 21.3321 27.4585L32.6836 38.81C33.4646 39.5911 33.4646 40.8574 32.6836 41.6384L23.4534 50.8687Z"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.9092 49.3957L6.4318 57.8731C5.28219 59.0228 3.41821 59.0228 2.2686 57.8731C1.11899 56.7234 1.11885 54.8596 2.26846 53.7098L10.746 45.2325"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M27 28L21 34"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.5277 34.9996L26 39.5273"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8623 35.1004L25.0422 49.2803"
          stroke={theme.colors[color]}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
    </Svg>
  );
};

export default Icon;
