// Components
import { LaunchRocketIcon, PaintIcon, TreeIcon } from "components/svg";
// Types
import { UserCaseItem } from "./components/user-case/types";

export const USER_CASES_LIST: UserCaseItem[] = [
  { Icon: LaunchRocketIcon, label: "You have an idea and want to launch your startup" },
  { Icon: TreeIcon, label: "You need redesign to drive your product’s growth" },
  { Icon: PaintIcon, label: "You need help with designing your product and branding" },
];
