import React from "react";
// Components + styling
import { StyledUfoAnimation } from "./styled";
// Animations
import melUfo from "assets/animations/mel-ufo.json";
// Types
import { SliderAnimationProps } from "../types";

const MelSliderAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref }) => {
  return <StyledUfoAnimation lottieRef={lottieAnimation1Ref} animationData={melUfo} autoplay={false} loop />;
};

export default MelSliderAnimation;
