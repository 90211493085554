import React, { useRef } from "react";
// Components + Styling
import { Box } from "components";
import { EStateBuildingAnimation } from "../e-state-animation";
import {
  StyledSlideImageWrapper,
  StyledMotionWrapper,
  StyledSlideImageTitleLeft,
  StyledSlideImageTitleRight,
  StyledSlideMainImage,
} from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useLottieAnimation, useMatchBreakpoints, useParallaxEffect } from "hooks";

const EStatesSlideImage: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { isDesktop } = useMatchBreakpoints();

  const { lottieAnimation1Ref, lottieAnimation2Ref } = useLottieAnimation();

  const ref = useRef(null);
  const parallaxTitle1 = useParallaxEffect(ref, 25);
  const parallaxTitle2 = useParallaxEffect(ref, 25);
  const parallaxImage1 = useParallaxEffect(ref, 25);

  return (
    <StyledSlideImageWrapper ref={ref}>
      <Box position="absolute" right="0">
        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxTitle1.y } : {}}
          transition={parallaxTitle1.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImageTitleLeft textScale={isDesktop ? "decorative26Regular" : "decorative14Light"} color="white">
            {t("Smart Contracts")}
          </StyledSlideImageTitleLeft>
        </StyledMotionWrapper>
        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxTitle2.y } : {}}
          transition={parallaxTitle2.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImageTitleRight textScale={isDesktop ? "decorative26Regular" : "decorative14Light"} color="white">
            {t("Tokenized Properties")}
          </StyledSlideImageTitleRight>
        </StyledMotionWrapper>
        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage1.y } : {}}
          transition={parallaxImage1.selectedTransition}
          zIndex={theme.zIndices.dropdown + 1}
        >
          <EStateBuildingAnimation
            lottieAnimation1Ref={lottieAnimation1Ref}
            lottieAnimation2Ref={lottieAnimation2Ref}
          />
          <StyledSlideMainImage src="/images/e-states-slide-img.webp" aspectRatio={1.67} />
        </StyledMotionWrapper>
      </Box>
    </StyledSlideImageWrapper>
  );
};

export default EStatesSlideImage;
