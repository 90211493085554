import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 22 22" {...props}>
      <path d="M18.2971 8.20055L18.2971 8.25348C18.2971 8.88168 18.2971 9.19577 18.1459 9.45275C17.9947 9.70973 17.7201 9.86228 17.171 10.1674L16.592 10.489C16.991 9.1402 17.1241 7.69099 17.1733 6.45163C17.1754 6.39848 17.1778 6.34469 17.1802 6.29033L17.1819 6.25225C17.6572 6.41731 17.924 6.54037 18.0905 6.77137C18.2972 7.05807 18.2972 7.4389 18.2971 8.20055Z" />
      <path d="M3.70068 8.20055L3.70069 8.25348C3.7007 8.88168 3.70071 9.19577 3.85192 9.45275C4.00313 9.70973 4.2777 9.86228 4.82684 10.1674L5.40614 10.4892C5.00712 9.14033 4.874 7.69104 4.8248 6.45163C4.82269 6.39849 4.82031 6.34469 4.81791 6.29033L4.81622 6.25216C4.34074 6.41727 4.07381 6.54033 3.90729 6.77137C3.70065 7.05807 3.70066 7.4389 3.70068 8.20055Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1935 3.95619C13.3731 3.81777 12.3007 3.70312 10.999 3.70312C9.69737 3.70312 8.62499 3.81777 7.80462 3.95619C6.97354 4.09641 6.55801 4.16652 6.21082 4.59412C5.86363 5.02172 5.88198 5.48388 5.91867 6.4082C6.04465 9.5814 6.72943 13.5448 10.4516 13.8956V16.4751H9.40777C9.05988 16.4751 8.76034 16.7206 8.69212 17.0617L8.55402 17.7522H6.61998C6.31768 17.7522 6.07261 17.9973 6.07261 18.2996C6.07261 18.6019 6.31768 18.847 6.61998 18.847H15.3779C15.6802 18.847 15.9252 18.6019 15.9252 18.2996C15.9252 17.9973 15.6802 17.7522 15.3779 17.7522H13.4438L13.3057 17.0617C13.2375 16.7206 12.938 16.4751 12.5901 16.4751H11.5463V13.8956C15.2686 13.545 15.9534 9.58147 16.0794 6.4082C16.1161 5.48388 16.1345 5.02172 15.7873 4.59412C15.4401 4.16652 15.0245 4.09641 14.1935 3.95619Z"
      />
    </Svg>
  );
};

export default Icon;
