import { keyframes } from "styled-components";

export const clientsShow = keyframes`
  to {
    opacity: 1;
  }
  `;

//  TODO Check animation, maybe need refactor to not use triple copy of clientsLogoList
export const sliderAutoplay = keyframes`
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-84rem);
  }
`;
