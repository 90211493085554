import React from "react";
// Components + styling
import { Box, Form, IconButton } from "components";
import { StyledArrowRightIcon, StyledInput, StyledFormWrapper } from "./styled";
// Context
import { useSocketContext, useTranslation } from "context";
// Hooks
import { useForm } from "hooks";
import { useValidationSchema } from "./hooks";

const ChatForm: React.FC = () => {
  const { t } = useTranslation();
  const { sendMessage } = useSocketContext();

  const { validationSchema, initialValues } = useValidationSchema();
  const { values, isValid, fieldProps, handleSubmit, resetForm } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  const submitHandler = (formValues: typeof values) => {
    sendMessage(formValues.message);
    resetForm();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <StyledFormWrapper>
        <StyledInput scale="sm" {...fieldProps("message")} placeholder={t("Enter your message")} px="0" />

        <Box width="100%" maxWidth="3.5rem">
          <IconButton
            width="100%"
            filledType="filled"
            Icon={StyledArrowRightIcon}
            isShowHoverLines={false}
            disabled={!isValid || !values.message}
            type="submit"
          />
        </Box>
      </StyledFormWrapper>
    </Form>
  );
};

export default ChatForm;
