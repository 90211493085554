import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 187 97" {...props}>
      <path
        d="M32.515 39.026h-4.909v18.44h4.909v-18.44ZM45.06 47.563l-4.958-8.537h-5.156v18.44h4.658v-9.58h.078l5.625 9.58h4.51v-18.44H45.13v8.537h-.07ZM52.265 39.026v18.44h6.17c5.824 0 8.573-1.469 8.573-9.232 0-7.764-2.798-9.208-8.647-9.208h-6.096Zm9.764 9.233c0 4.655-1.314 4.95-3.148 4.95h-1.71v-9.705h1.512c2.081 0 3.346.124 3.346 4.751v.004ZM73.673 39.026h-4.905v18.44h4.905v-18.44ZM114.217 38.804c-6.838 0-8.054 4.502-8.054 9.456 0 4.953 1.216 9.43 8.054 9.43s8.029-4.502 8.029-9.43c0-4.929-1.191-9.456-8.029-9.456Zm0 14.533c-2.7 0-3.075-1.866-3.075-4.9 0-3.034.372-4.879 3.075-4.879 2.703 0 3.05 1.867 3.05 4.879 0 3.012-.371 4.9-3.05 4.9ZM148.611 38.804c-6.841 0-8.057 4.502-8.057 9.456 0 4.953 1.216 9.43 8.057 9.43 6.842 0 8.029-4.502 8.029-9.43 0-4.929-1.191-9.456-8.029-9.456Zm0 14.533c-2.7 0-3.074-1.866-3.074-4.9 0-3.034.371-4.879 3.074-4.879 2.704 0 3.046 1.867 3.046 4.879 0 3.012-.371 4.9-3.046 4.9ZM158.224 54.546c-.895 0-1.114.607-1.114 1.401 0 .795.219 1.395 1.114 1.395.894 0 1.12-.603 1.12-1.395 0-.79-.226-1.401-1.12-1.401Zm0 2.6c-.732 0-.902-.517-.902-1.195s.17-1.2.902-1.2c.731 0 .904.519.904 1.2 0 .681-.173 1.196-.904 1.196Z"
        fill="#B1BEDE"
        fillOpacity=".5"
      />
      <path
        d="M158.838 55.633c0-.334-.109-.526-.54-.526h-.636v1.668h.339v-.589h.215l.258.589h.35l-.279-.62c.223-.075.29-.26.29-.519l.003-.004Zm-.554.276h-.28v-.503h.28c.166 0 .219.07.219.251s-.057.252-.219.252ZM96.753 50.35h3.166v.322c0 2.037.064 2.938-2.353 2.938-2.255 0-3.026-1.047-3.026-5.202 0-4.154 1.29-4.853 3.223-4.853 1.435 0 2.453.372 3.297.848l2.626-4.105c-1.389-.898-3.421-1.494-5.7-1.494-6.343 0-8.375 3.062-8.375 9.456 0 5.574 1.488 9.43 7.51 9.43 1.066 0 1.982-.074 2.773-.223h4.513V46.344h-7.11l-.54 4.005h-.004ZM131.147 50.35h3.167v.322c0 2.037.063 2.938-2.354 2.938-2.255 0-3.025-1.047-3.025-5.202 0-4.154 1.29-4.853 3.223-4.853 1.435 0 2.452.372 3.297.848l2.626-4.105c-1.389-.898-3.421-1.494-5.7-1.494-6.344 0-8.376 3.062-8.376 9.456 0 5.574 1.488 9.43 7.51 9.43 1.067 0 1.982-.074 2.774-.223h4.512V46.344h-7.11l-.54 4.005h-.004ZM88.317 43.529v-4.503H76.123v18.44h12.195v-4.382h-7.385v-2.76h4.852l.59-4.379h-5.442V43.53h7.385Z"
        fill="#B1BEDE"
        fillOpacity=".5"
      />
    </Svg>
  );
};

export default Icon;
