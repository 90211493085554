import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 24 25" {...props}>
      <g clipPath="url(#clip0_5214_10077)">
        <path d="M9.65925 19.8103C11.8044 19.8104 13.8882 19.0947 15.5806 17.7765L21.9653 24.1613C22.4423 24.6219 23.2023 24.6087 23.663 24.1317C24.1123 23.6665 24.1123 22.9289 23.663 22.4636L17.2782 16.0789C20.5491 11.8683 19.7874 5.80344 15.5769 2.53253C11.3663 -0.738373 5.30149 0.0233079 2.03058 4.23385C-1.24033 8.44439 -0.478646 14.5093 3.73189 17.7802C5.42702 19.0971 7.51269 19.8114 9.65925 19.8103ZM4.52915 5.02741C7.36245 2.19405 11.9561 2.194 14.7895 5.0273C17.6229 7.86061 17.6229 12.4543 14.7896 15.2877C11.9563 18.121 7.36261 18.1211 4.52925 15.2878C4.5292 15.2877 4.5292 15.2877 4.52915 15.2877C1.69584 12.475 1.67915 7.89805 4.49181 5.06474C4.50424 5.05226 4.51667 5.03984 4.52915 5.02741Z" />
      </g>
      <defs>
        <clipPath id="clip0_5214_10077">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
