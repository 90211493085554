import styled from "styled-components";
// Components
import { Container } from "components";

export const StyledOurTeamTitle = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 2.25rem;
  }
`;
