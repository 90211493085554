import styled from "styled-components";
import { clearfixDisplayNone } from "styles";
// Components
import { Heading, Image } from "components";

export const StyledClientAvatar = styled(Image)`
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 0;
  }

  img {
    margin: 0 auto;

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin: 0;
    }
  }
`;

export const StyledRatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: flex-start;
  }

  ::before {
    ${clearfixDisplayNone}
    width: 2rem;
    height: 2rem;
    background: url("/images/case-studies/ugreator/ugreator-quotes.svg");
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
      margin-bottom: 1.25rem;
    }
  }
`;

export const StyledTestimonialDescription = styled(Heading)`
  text-align: center;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    text-align: left;
    margin-bottom: 1.25rem;
  }
`;
