import React from "react";
// Components + styling
import { StyledBatmanAnimation, StyledMaskAnimation } from "./styled";
// Animations
import iliaBatman from "assets/animations/ilia-batman.json";
import iliaMask from "assets/animations/ilia-mask.json";
// Types
import { SliderAnimationProps } from "../types";

const IliaSliderAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref, lottieAnimation2Ref }) => {
  return (
    <>
      <StyledBatmanAnimation lottieRef={lottieAnimation1Ref} animationData={iliaBatman} autoplay={false} loop />
      <StyledMaskAnimation lottieRef={lottieAnimation2Ref} animationData={iliaMask} autoplay={false} loop={false} />
    </>
  );
};

export default IliaSliderAnimation;
