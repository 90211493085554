import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "lineDark", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 61 66" {...props} color="bgDark">
      <path
        d="M60 30.6426C60 38.8304 57.4203 47.3522 52.8063 53.8153C48.196 60.2731 41.5837 64.6426 33.5 64.6426C25.3759 64.6426 17.1205 60.6088 10.8868 54.4987C4.65297 48.3885 0.5 40.2602 0.5 32.1426C0.5 15.7442 10.8028 1.14258 27 1.14258C35.208 1.14258 43.4663 3.24929 49.6598 7.97751C55.8344 12.6913 60 20.0415 60 30.6426Z"
        stroke={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
