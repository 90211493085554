import styled from "styled-components";
import { space } from "styled-system";
// Constants
import { animationTypeMapper } from "./constants";
// Types
import { SvgProps } from "./types";

const Svg = styled.svg<SvgProps>`
  align-self: center;
  fill: ${({ theme, color }) => color && theme.colors[color]};
  flex-shrink: 0;
  ${({ animationType }) => animationType && animationTypeMapper[animationType]}
  transition: ${({ theme }) => theme.transitions.default};

  ${space}
`;

Svg.defaultProps = {
  color: "bgDark",
  width: "1.25rem",
  xmlns: "http://www.w3.org/2000/svg",
};

export default Svg;
