import { useScroll, useTransform } from "framer-motion";
// Context
import { useThemeContext } from "context";

const usePinScrollEffect = (ref: React.MutableRefObject<HTMLDivElement | null>, isAnimationActive: boolean) => {
  const { theme } = useThemeContext();

  const maxScale = isAnimationActive ? 0.92 : 1;
  const maxBorderRadius = isAnimationActive ? parseInt(theme.radii.xl) : 0;
  const startAnimationOpacity = isAnimationActive ? 0 : 1;

  const { scrollYProgress } = useScroll({ target: ref });

  const scale = useTransform(scrollYProgress, [0, 1], [1, maxScale]);
  const borderRadius = useTransform(scrollYProgress, [0, 1], [0, maxBorderRadius]);
  const opacityConnector = useTransform(scrollYProgress, [0, 1], [startAnimationOpacity, 1]);

  return {
    scale,
    borderRadius,
    opacityConnector,
  };
};

export default usePinScrollEffect;
