import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components + styling
import { Box, ContactUs, Container, Heading, Page } from "components";
import { StyledSectionPoint } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Constants
import { OPEN_POSITIONS_LIST } from "pages/careers/components/open-positions/constants";
import { BLOCK_ID } from "configs";
import { POSITION_INFO_SECTIONS_LIST } from "./constants";
import { ROUTES } from "navigation/routes";

const PositionInfoPage: React.FC = () => {
  const { positionId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  const selectedPosition =
    positionId && !Number.isNaN(parseInt(positionId)) ? OPEN_POSITIONS_LIST.find(({ id }) => id === +positionId) : null;

  useEffect(() => {
    if (!selectedPosition) {
      navigate(`/${ROUTES.careers}`, { replace: true });
    }
  }, []);

  return (
    <Page maxWidth="100vw" px="0">
      <Container pt={{ _: `calc(${headerHeight}px + 2rem)`, laptop: `calc(${headerHeight}px + 8.25rem)` }}>
        <Heading scale="h2" as="h2" mb={{ _: "1.5rem", laptop: "3rem" }}>
          {selectedPosition?.position ?? ""}
        </Heading>

        {selectedPosition && (
          <>
            {POSITION_INFO_SECTIONS_LIST.map(({ label, value }) => (
              <Box key={label} mb={{ _: "1.5rem", laptop: "3rem" }}>
                <Heading scale="h3" as="h3" mb="1rem">
                  {t(label)}
                </Heading>
                <ul>
                  {selectedPosition[value].map(requirement => (
                    <StyledSectionPoint
                      key={requirement}
                      textScale={isDesktop ? "p22Regular" : "p16Regular"}
                      color="textFifth"
                    >
                      {t(requirement)}
                    </StyledSectionPoint>
                  ))}
                </ul>
              </Box>
            ))}
          </>
        )}
      </Container>

      {selectedPosition && (
        <Box id={BLOCK_ID.contactUs}>
          <ContactUs title="Join the team!" isShowCommentInput isHideBgImage />
        </Box>
      )}
    </Page>
  );
};

export default PositionInfoPage;
