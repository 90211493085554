import React from "react";
import { useNavigate } from "react-router-dom";
// Components + Styling
import { Button, Flex, Heading } from "components";
import { EStatesSlideImage, EStatesVideoBlock } from "./components";
import {
  StyledContentContainer,
  StyledEstatesWrapper,
  StyledEstatesMainWrapper,
  StyledDescriptionBlockText,
  StyledEstatesMotionWrapper,
} from "./styled";
// Animations
import { portfolioItemShow } from "../../animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";

const EStates: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledEstatesMotionWrapper
      initial={isDesktop ? "hidden" : "visible"}
      variants={portfolioItemShow}
      whileInView="visible"
      viewport={{ once: true }}
    >
      <StyledEstatesMainWrapper>
        <StyledEstatesWrapper>
          {!isDesktop && <EStatesSlideImage />}
          <StyledContentContainer>
            <Heading scale="h3" as="h3" color="white" mb={{ _: "1.25rem" }}>
              {t("e-States")}
            </Heading>
            <StyledDescriptionBlockText textScale={isDesktop ? "p18Regular" : "p14Regular"} mb="2rem">
              {t(
                "Introducing the innovative Crowdfunding Marketplace for Tokenized Real Estate. This cutting-edge platform revolutionizes the way we engage with real estate investments. Through the power of tokenization, investors gain unparalleled access to the real estate market, enabling them to seamlessly diversify their portfolios.",
              )}
            </StyledDescriptionBlockText>
            <Flex>
              <Button
                color="bgDark"
                bg="buttonLightFill"
                hoverBg="buttonLightFillHover"
                hoverLinesPosition={isDesktop ? "bottomLeft" : "topRight"}
                hoverLinesColor="white"
                width="15.25rem"
                variant="secondary"
                mx={{ _: "auto", laptop: "0" }}
                onClick={() => navigate(`/${ROUTES.caseStudies}/${ROUTES.eStatesCase}`)}
              >
                {t("View full case")}
              </Button>
            </Flex>
          </StyledContentContainer>

          {isDesktop && <EStatesSlideImage />}
        </StyledEstatesWrapper>
        <EStatesVideoBlock />
      </StyledEstatesMainWrapper>
    </StyledEstatesMotionWrapper>
  );
};

export default EStates;
