import React from "react";
// Components + Styling
import { HashLayoutWrapper, Box, ThankForRequest, Image, Container } from "components";
import { ContactUsForm, ContactUsTitle } from "./components";
import {
  StyledContactUs,
  StyledContactUsContainer,
  StyledContactUsOuterContainer,
  StyledContainerWrapper,
} from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { ContactUsProps } from "./types";

const ContactUs: React.FC<ContactUsProps> = ({
  theme = "light",
  isHideBgImage = false,
  isShowCommentInput = false,
  title,
  isHideTitleIcon = true,
  description,
  ...props
}) => {
  const [isShowThankYou, setIsShowThankYou] = React.useState(false);

  const { isDesktop, isLaptopXL } = useMatchBreakpoints();

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const SelectedContainer = isShowThankYou || isHideBgImage ? Container : StyledContactUsOuterContainer;

  return (
    <StyledContactUs id={BLOCK_ID.contactUs}>
      <StyledContainerWrapper contactUsTheme={isShowThankYou ? "light" : theme}>
        <SelectedContainer>
          <StyledContactUsContainer ref={wrapperRef} isShowCommentInput={isShowCommentInput}>
            <HashLayoutWrapper
              width="100%"
              maxWidth={{
                _: "100%",
                laptop: isShowCommentInput || isShowThankYou ? "100%" : "calc(55% - 1rem)",
                laptopL: isShowCommentInput || isShowThankYou ? "100%" : "calc(45% - 3rem)",
              }}
              pt="0"
            >
              <Box {...props}>
                <>
                  {isShowThankYou ? (
                    <ThankForRequest setIsShowThankYou={setIsShowThankYou} />
                  ) : (
                    <>
                      <ContactUsTitle
                        theme={theme}
                        title={title}
                        isHideTitleIcon={isHideTitleIcon}
                        description={description}
                      />

                      <ContactUsForm
                        theme={theme}
                        setIsShowThankYou={setIsShowThankYou}
                        isShowCommentInput={isShowCommentInput}
                      />
                    </>
                  )}
                </>
              </Box>
            </HashLayoutWrapper>

            {isDesktop && !isShowThankYou && !isHideBgImage && (
              <Image
                width={{ _: "calc(45% - 1rem)", laptopL: "calc(55% - 3rem)" }}
                src={
                  isLaptopXL
                    ? "/images/contact-us/dashboard-contact-us-form.webp"
                    : "/images/contact-us/dashboard-contact-us-form-to-right.webp"
                }
                aspectRatio={isLaptopXL ? 1.75 : 1.12}
              />
            )}
          </StyledContactUsContainer>
        </SelectedContainer>
      </StyledContainerWrapper>
    </StyledContactUs>
  );
};

export default ContactUs;
