import React from "react";
// Components + styling
import { Flex, Box, Text } from "components";
import { CorporateValueItemWrapper } from "./styled";
// Animations
import { workflowItemShow } from "pages/landing/components/workflow/components/workflow-item/animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CorporateValueItemProps } from "./types";

const CorporateValueItem: React.FC<CorporateValueItemProps> = ({ Icon, title, description }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <CorporateValueItemWrapper
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.8 }}
      variants={workflowItemShow}
    >
      <Flex>
        <Box mr={{ _: "1rem", laptop: "1.5rem" }}>
          <Icon width={isDesktop ? "3.5rem" : "3rem"} color="white" />
        </Box>
        <Box>
          <Text textScale={isDesktop ? "p24Bold" : "p16Bold"} mb={{ _: "0.25rem", laptop: "0.5rem" }} color="white">
            {t(title)}
          </Text>
          <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textThird">
            {t(description)}
          </Text>
        </Box>
      </Flex>
    </CorporateValueItemWrapper>
  );
};

export default CorporateValueItem;
