export const UGREATOR_PROJECT_PHASES_LIST = [
  {
    step: 1,
    title: "Research",
    description: "Initially, we carried out an analysis of competitors and conducted interviews.",
    icon: "/images/case-studies/ugreator/ugreator-project-phases-icon-1.webp",
  },
  {
    step: 2,
    title: "Wireframing",
    description: "Drafted rapid concepts and formulated the structure.",
    icon: "/images/case-studies/ugreator/ugreator-project-phases-icon-2.webp",
  },
  {
    step: 3,
    title: "Design",
    description: "Developed the design using the data previously gathered.",
    icon: "/images/case-studies/ugreator/ugreator-project-phases-icon-3.webp",
  },
  {
    step: 4,
    title: "Development",
    description: "Delivered our layouts for subsequent development.",
    icon: "/images/case-studies/ugreator/ugreator-project-phases-icon-4.webp",
  },
];
