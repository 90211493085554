// Styled components
import {
  StyledSlideImage1,
  StyledSlideImage2,
  StyledSlideImage3,
  StyledSlideImage4,
  StyledSlideImage5,
} from "./styled";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage1,
    imageSrc: "/images/case-studies/ugreator/ugreator-try-app-parallax-icon-1.webp",
    ratio: 0.71, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/case-studies/ugreator/ugreator-try-app-parallax-icon-2.webp",
    ratio: 0.83, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/case-studies/ugreator/ugreator-try-app-parallax-icon-3.webp",
    ratio: 0.71, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage4,
    imageSrc: "/images/case-studies/ugreator/ugreator-try-app-parallax-icon-4.webp",
    ratio: 0.71, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage5,
    imageSrc: "/images/case-studies/ugreator/ugreator-try-app-parallax-icon-5.webp",
    ratio: 0.93, // ratio of image width/height
  },
];
