// Constants
import { EXTERNAL_LINKS } from "configs";
// Types
import { AmbassadorItemType } from "./types";

export const AMBASSADORS_LIST: AmbassadorItemType[] = [
  {
    linkLabel: "kazka.band",
    link: EXTERNAL_LINKS.kazkaInstagram,
    linkIconSrc: "/images/instagram-icon.svg",
    description:
      "Ukrainian pop band, first to hit Top 3 World Pop track on Global Shazam with the most popular Ukrainian song in the world. 1B+ views on YouTube and billions of song streams.",
    artistImgSrc: "/images/case-studies/ugreator/ugreator-ambassador-1.webp",
    artistImgRatio: 0.81,
    bgGradientSrc: "/images/case-studies/ugreator/ugreator-ambassador-1-gradient-bg.webp",
  },
  {
    linkLabel: "v_serduchka",
    link: EXTERNAL_LINKS.serduchkaInstagram,
    linkIconSrc: "/images/instagram-icon.svg",
    description:
      "Legendary artist. The very best seller of the 2000s with multi million album sales. Eurovision Sensation and one of the best Eurovision performer in history.",
    artistImgSrc: "/images/case-studies/ugreator/ugreator-ambassador-2.webp",
    artistImgRatio: 0.87,
    bgGradientSrc: "/images/case-studies/ugreator/ugreator-ambassador-2-gradient-bg.webp",
  },
  {
    linkLabel: "demchukmusic",
    link: EXTERNAL_LINKS.demchukTiktok,
    linkIconSrc: "/images/tiktok-icon.svg",
    description:
      "Ukrainian singer, participant of the national selection for Eurovision 2023. TikTok blogger with 2.7 million followers",
    artistImgSrc: "/images/case-studies/ugreator/ugreator-ambassador-3.webp",
    artistImgRatio: 1,
    bgGradientSrc: "/images/case-studies/ugreator/ugreator-ambassador-3-gradient-bg.webp",
  },
  {
    linkLabel: "bilyk_iryna",
    link: EXTERNAL_LINKS.bilykInstagram,
    linkIconSrc: "/images/instagram-icon.svg",
    description:
      "Queen of the Ukrainian pop music scene in last 30 years. People's Artist of Ukraine and the symbol of modern independent Ukrainian music.",
    artistImgSrc: "/images/case-studies/ugreator/ugreator-ambassador-4.webp",
    artistImgRatio: 0.84,
    bgGradientSrc: "/images/case-studies/ugreator/ugreator-ambassador-4-gradient-bg.webp",
  },
];
