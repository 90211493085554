import { useEffect, useRef } from "react";
// Components + styling
import { StyledTestimonialVideoBox, StyledTestimonialVideo, StyledQuoteWrapper, StyledQuote } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Animation
import { imageQuoteShow } from "../../animations";
// Types
import { TestimonialVideoBoxProps } from "./types";

const TestimonialVideoBox: React.FC<TestimonialVideoBoxProps> = ({
  videoUrl,
  videoSrc,
  imageQuote,
  borderRadius,
  isHovered,
  toggleOpen,
}) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (isMobile) {
      refVideo.current?.pause();
      return;
    }

    if (isHovered) {
      refVideo.current?.play();
    } else {
      refVideo.current?.pause();
    }
  }, [isHovered, refVideo, isMobile]);

  return (
    <StyledTestimonialVideoBox borderRadius={borderRadius} isHovered={isHovered} onClick={() => toggleOpen(videoUrl)}>
      <StyledTestimonialVideo
        ref={refVideo}
        src={videoSrc}
        muted
        loop
        borderRadius={borderRadius}
        autoPlay
        playsInline
      />

      {!isMobile && (
        <StyledQuoteWrapper
          initial="visible"
          animate={isHovered ? "visible" : "hidden"}
          variants={imageQuoteShow}
          isShowShadow={isHovered}
        >
          <StyledQuote textScale={isDesktop ? "p20Regular" : "p14Regular"} color="white">
            “{t(imageQuote)}”
          </StyledQuote>
        </StyledQuoteWrapper>
      )}
    </StyledTestimonialVideoBox>
  );
};

export default TestimonialVideoBox;
