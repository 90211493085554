import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Utils
import { scrollToElementById } from "utils/scroll-to-element-by-id";
// Constants
import { MOBILE_MENU_ANIMATION_DURATION } from "components/header/constants";

const useScrollEffect = () => {
  const { hash, pathname, key } = useLocation();

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        scrollToElementById(hash.replace("#", ""));
      }, MOBILE_MENU_ANIMATION_DURATION);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  }, [hash, pathname, key]);
};

export default useScrollEffect;
