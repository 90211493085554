// Styled components
import {
  StyledSlideImage2,
  StyledSlideImage3,
  StyledSlideImage4,
  StyledSlideImage5,
  StyledSlideImage6,
  StyledSlideImage7,
  StyledSlideImage8,
  StyledSlideImage9,
  StyledSlideImage10,
  StyledSlideImage11,
  StyledSlideImage12,
  StyledSlideImage13,
  StyledSlideImage14,
  StyledSlideImage15,
  StyledSlideImage16,
  StyledSlideImage17,
  StyledSlideImage18,
  StyledSlideImage19,
} from "./styled";
// Theme
import { zIndices } from "theme/base";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/services-parallax-image-2.svg",
    zIndex: zIndices.dropdown + 1,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/services-parallax-image-3.svg",
    zIndex: zIndices.dropdown - 1,
    ratio: 0.88, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage4,
    imageSrc: "/images/services-parallax-image-4.svg",
    zIndex: zIndices.dropdown - 2,
    ratio: 0.71, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage5,
    imageSrc: "/images/services-parallax-image-5.svg",
    zIndex: 1,
    ratio: 1.09, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage6,
    imageSrc: "/images/services-parallax-image-6.svg",
    zIndex: zIndices.dropdown + 2,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage7,
    imageSrc: "/images/services-parallax-image-7.svg",
    zIndex: zIndices.dropdown + 2,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage8,
    imageSrc: "/images/services-parallax-image-8.svg",
    zIndex: zIndices.dropdown + 2,
    ratio: 0.9, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage9,
    imageSrc: "/images/services-parallax-image-9.svg",
    zIndex: zIndices.dropdown - 1,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage10,
    imageSrc: "/images/services-parallax-image-10.svg",
    zIndex: zIndices.dropdown - 1,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage11,
    imageSrc: "/images/services-parallax-image-11.svg",
    zIndex: zIndices.dropdown - 1,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage12,
    imageSrc: "/images/services-parallax-image-12.svg",
    zIndex: zIndices.dropdown - 1,
    ratio: 0.86, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage13,
    imageSrc: "/images/services-parallax-image-13.svg",
    zIndex: zIndices.dropdown,
    ratio: 1.06, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage14,
    imageSrc: "/images/services-parallax-image-14.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage15,
    imageSrc: "/images/services-parallax-image-15.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage16,
    imageSrc: "/images/services-parallax-image-16.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage17,
    imageSrc: "/images/services-parallax-image-17.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage18,
    imageSrc: "/images/services-parallax-image-18.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage19,
    imageSrc: "/images/services-parallax-image-19.svg",
    zIndex: zIndices.dropdown,
    ratio: 1.08, // ratio of image width/height
  },
];
