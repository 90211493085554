import styled from "styled-components";
// Components
import { Box } from "components/layout";

export const StyledCloseIcon = styled(Box)`
  cursor: pointer;
  opacity: 0.3;
  transition: ${({ theme }) => theme.transitions.default};
  margin-inline-start: 0.5rem;

  &:hover {
    opacity: 1;
  }
`;
