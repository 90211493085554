import React from "react";
// Components + styling
import { Accordion, ListPoint, Text } from "components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { DATA_PROTECTION_LIST } from "./constants";

const DataProtection: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Accordion title={t("Your data protection rights")} $backgroundColor="white">
      <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mb="1rem">
        {t(
          "If your personal information is protected by EU data protection law, you have the following data protection rights which you may be able to exercise by contacting IDEALOGIC DESIGN using the contact details provided under the “Contact Details” heading below and in the right-hand side column:",
        )}
      </Text>
      <ul>
        {DATA_PROTECTION_LIST.map(point => (
          <ListPoint key={point} textScale={isDesktop ? "p18Regular" : "p14Regular"}>
            {t(point)}
          </ListPoint>
        ))}
      </ul>
      <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mt="1rem" pb="1.5rem">
        {t(
          "You will not have to pay a fee to access your personal information (or to exercise any of the other rights). However, IDEALOGIC DESIGN may charge a reasonable fee or refuse to comply with your request if your request is clearly unfounded, repetitive or excessive. IDEALOGIC DESIGN tries to respond to all legitimate requests within one month. Occasionally, it may take longer if your request is particularly complex or you have made a number of requests. In this case, IDEALOGIC DESIGN will notify you and keep you updated",
        )}
      </Text>
    </Accordion>
  );
};

export default DataProtection;
