import styled, { css } from "styled-components";

export const StyledMainDropdownTitle = styled.div<{ isUnderline?: boolean; isHeaderThemeLight?: boolean }>`
  &::before {
    content: "";
    clear: both;
    width: 100%;
    height: 0.5px;
    position: absolute;
    top: 100%;
    left: 0;
    transition:
      clip-path 0.3s,
      transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
  }

  ${({ isUnderline, isHeaderThemeLight }) =>
    isUnderline &&
    css`
      &::before {
        background: ${({ theme }) => (isHeaderThemeLight ? theme.colors.bgDark : theme.colors.white)};
        transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
        clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
      }

      width: initial;
      background-clip: none;
      -webkit-background-clip: none;
      -webkit-text-fill-color: unset;

      padding: 0;

      transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
      transform: translate3d(0, -2px, 0);
    `}
`;
