import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// Components + styling
import { Button, Flex, MobileMenu, HeaderSocials } from "components";
import { WavingHandIcon } from "components/svg";
import { BurgerMenu, HeaderLogo, NavigationMenu } from "./components";
import { StyledHeader, StyledHeaderWrapper, StyledHeaderContainer, StyledMotionHeader } from "./styled";
// Animations
import { letsTalkButtonSlideVariants } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementByIdHeight, useMatchBreakpoints } from "hooks";
import { useHeaderTheme, useMotionAnimations } from "./hooks";
// Utils
import { findMatchingRoute, removeTrailingSlashIfExists, scrollToElementById } from "utils";
// Constants
import {
  CONTACT_US_OUT_PATHNAME_LIST,
  MOBILE_MENU_ANIMATION_DURATION,
  headerAnimationType,
  headerThemes,
} from "./constants";
import { ROUTES } from "navigation/routes";
import { BLOCK_ID } from "configs";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const { elementHeight } = useElementByIdHeight("header");

  const { t } = useTranslation();
  const { isLargeDesktop, isDesktop } = useMatchBreakpoints();
  const { headerAnimationsVariants, headerAnimation } = useMotionAnimations();
  const {
    headerTheme,
    isPathInLightThemePathList,
    isUserOnIdPage,
    isUserOnPlanetcoinCasePage,
    isUserOnUgreatorCasePage,
  } = useHeaderTheme();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobileMenuOpen = isMenuOpen && !isLargeDesktop;

  useEffect(() => {
    if (isMobileMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [pathname, hash]);

  const handleClickLetsTalk = () => {
    const matchingRoute = findMatchingRoute(pathname);

    if (
      CONTACT_US_OUT_PATHNAME_LIST.includes(removeTrailingSlashIfExists(pathname)) ||
      (!isUserOnIdPage && !matchingRoute)
    ) {
      navigate(`${ROUTES.landing}#${BLOCK_ID.contactUs}`);
    } else {
      // Need to use timeout, because of mobiles menu. For correct logic working, we need to wait closing mobile menu. and then scroll to element.
      setTimeout(
        () => {
          scrollToElementById(BLOCK_ID.contactUs);
        },
        isLargeDesktop ? 0 : MOBILE_MENU_ANIMATION_DURATION,
      );
    }
  };

  return (
    <StyledHeader
      pb={elementHeight}
      isBgWhite={isPathInLightThemePathList || isUserOnIdPage}
      isUserOnPlanetcoinCasePage={isUserOnPlanetcoinCasePage}
      isUserOnUgreatorCasePage={isUserOnUgreatorCasePage}
      isMobileMenuOpen={isMobileMenuOpen}
    >
      <StyledMotionHeader initial="initial" animate={headerAnimation} variants={headerAnimationsVariants}>
        <StyledHeaderWrapper headerTheme={headerTheme} isMobileMenuOpen={isMobileMenuOpen}>
          <StyledHeaderContainer headerTheme={headerTheme} id={BLOCK_ID.header} py={{ _: "1rem", laptop: "2.125rem" }}>
            <HeaderLogo isMobileMenuOpen={isMobileMenuOpen} setIsMenuOpen={setIsMenuOpen} />

            {isLargeDesktop && <NavigationMenu setIsMenuOpen={setIsMenuOpen} />}

            <Flex alignItems="center">
              {isLargeDesktop && <HeaderSocials />}

              <motion.div
                initial="hidden"
                animate={!isMobileMenuOpen ? "visible" : "hidden"}
                variants={letsTalkButtonSlideVariants}
              >
                <Button
                  width={isDesktop ? "9.75rem" : "inherit"}
                  ml={{ _: "0.5rem", tablet: "1.25rem" }}
                  scale={isDesktop ? "md" : "sm"}
                  variant="secondary"
                  buttonTheme={headerTheme !== headerThemes.light ? "light" : "dark"}
                  startIcon={<WavingHandIcon width="1.125rem" />}
                  hoverLinesPosition="bottomLeft"
                  isShowHoverLines={headerAnimation === headerAnimationType.static}
                  ariaLabel="lets-talk"
                  onClick={handleClickLetsTalk}
                >
                  {t("Let's Talk")}
                </Button>
              </motion.div>

              {!isLargeDesktop && (
                <BurgerMenu
                  color={headerTheme === headerThemes.light && !isMobileMenuOpen ? "bgDark" : "white"}
                  isOpen={isMenuOpen}
                  setIsOpen={setIsMenuOpen}
                />
              )}
            </Flex>
          </StyledHeaderContainer>

          <MobileMenu
            isMenuOpen={isMobileMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            onClickLetsTalk={handleClickLetsTalk}
          />
        </StyledHeaderWrapper>
      </StyledMotionHeader>
    </StyledHeader>
  );
};

export default Header;
