import React from "react";
// Components + styling;
import { Box, Container, Flex, Heading, SliderNavButtons, Text } from "components";
import { OpenPositionItem, OpenPositionsSlider } from "./components";
import { StyledOpenPositionItemWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useSliderController } from "hooks";
// Constants
import { OPEN_POSITIONS_LIST } from "./constants";

const OpenPositions: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const { sliderRef, handleClickPrevious, handleClickNext } = useSliderController();

  return (
    <Container pt={{ _: "5.125rem", laptop: "7.5rem" }} pb={{ _: "1.25rem", tablet: "0" }}>
      <Flex justifyContent="space-between" alignItems="flex-end" mb={{ _: "1.5rem", tablet: "3.75rem" }}>
        <Box maxWidth={{ _: "100%", tablet: "35rem", laptop: "50rem" }}>
          <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
            {t("Open positions")}
          </Heading>
          <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>
            {t(
              "We want to enhance our design solutions and looking for fresh views. If you want to join us, check out our vacancies",
            )}
          </Text>
        </Box>
        {!isMobile && <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} />}
      </Flex>
      {isMobile ? (
        <Box>
          {OPEN_POSITIONS_LIST.map(({ id, ...rest }) => (
            <StyledOpenPositionItemWrapper key={id}>
              <OpenPositionItem id={id} {...rest} />
            </StyledOpenPositionItemWrapper>
          ))}
        </Box>
      ) : (
        <OpenPositionsSlider ref={sliderRef} />
      )}
    </Container>
  );
};
export default OpenPositions;
