import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";
// Components
import { Button, Flex, Text } from "components";

export const StyledProposalText = styled(Text)`
  max-width: 11.25rem;
  margin: 0 auto 0.375rem;
  transform: rotate(-2deg);

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 15.5rem;
  }

  ::after {
    ${clearfix}
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
    background: url("/images/curly-arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
`;

export const StyledScheduleCallButtonWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 2.5rem auto 0;
  margin-top: 2.5rem;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-top: 5.625rem;
  }
`;

export const StyledMotionButtonWrapper = styled(motion.div)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  border-radius: ${({ theme }) => theme.radii.circle};
`;

export const StyledScheduleCallButton = styled(Button)`
  width: 7.25rem;
  min-width: 7.25rem;
  height: 7.25rem;
  border-radius: ${({ theme }) => theme.radii.circle};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transform-origin: center;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 10rem;
    min-width: 10rem;
    height: 10rem;
  }
`;

export const StyledScheduleButtonIconWrapper = styled(motion.div)`
  width: 13.875rem;
  height: 13.875rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 20.25rem;
    height: 20.25rem;
  }
`;
