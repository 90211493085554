import styled from "styled-components";
// Components + styling
import { Button } from "components";
import { Flex } from "components/layout";
import { Svg } from "components/svg";

// If not wrap Icon and not specified Svg, styles for Svg also added to StyledButtonHoverLines
export const StyledIconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const StyledIconButton = styled(Button)<{ isStrokeIcon: boolean }>`
  ${StyledIconWrapper} {
    ${Svg} {
      path {
        transition: ${({ theme }) => theme.transitions.default};
        fill: ${({ theme, isStrokeIcon }) => (isStrokeIcon ? theme.colors.transparent : "inherit")};
        stroke: ${({ theme, filledType, isStrokeIcon }) =>
          filledType === "bordered"
            ? theme.colors.textAccent
            : isStrokeIcon
            ? theme.colors.white
            : theme.colors.transparent};
      }
    }
  }

  &:disabled {
    ${Svg} {
      path {
        stroke: ${({ theme, isStrokeIcon }) => (isStrokeIcon ? theme.colors.white : "inherit")};
      }
    }
  }

  &:not([disabled], :active):hover {
    ${StyledIconWrapper} {
      ${Svg} {
        path {
          fill: ${({ theme, isStrokeIcon }) => (isStrokeIcon ? theme.colors.transparent : "inherit")};
          stroke: ${({ theme, isStrokeIcon }) => (isStrokeIcon ? theme.colors.white : theme.colors.transparent)};
        }
      }
    }
  }
`;
