// Components + styling
import { Heading, Image } from "components";
import {
  StyledCardItemWrapper,
  StyledCardItemImage,
  StyledCardItemLogoWrapper,
  StyledCardItem,
  StyledComingSoonWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CaseStudiesCardItemProps } from "./types";

const CaseStudiesCardItemImage: React.FC<CaseStudiesCardItemProps> = ({
  imgSrc,
  logoSrc,
  logoWidth,
  logoAspectRatio,
  isComingSoon,
}) => {
  const { t } = useTranslation();
  const { isDesktop, isTablet } = useMatchBreakpoints();

  return (
    <StyledCardItemWrapper>
      {isComingSoon && (
        <StyledComingSoonWrapper>
          <Heading scale="h2" as="h4" color="white">
            {t("Coming Soon")}
          </Heading>
        </StyledComingSoonWrapper>
      )}

      <StyledCardItem>
        <StyledCardItemImage
          width="100%"
          height="100%"
          aspectRatio={isDesktop ? 1.41 : isTablet ? 1.52 : 0.75}
          src={imgSrc}
        />

        <StyledCardItemLogoWrapper logoWidth={logoWidth}>
          <Image width="100%" height="100%" aspectRatio={logoAspectRatio} src={logoSrc} />
        </StyledCardItemLogoWrapper>
      </StyledCardItem>
    </StyledCardItemWrapper>
  );
};

export default CaseStudiesCardItemImage;
