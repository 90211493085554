import React from "react";
import { useNavigate } from "react-router-dom";
// Components + styling
import { Flex, Text } from "components";
import { StyledFooterLink } from "components/footer/styled";
import { StyledCopyrightsWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Constants
import { ROUTES } from "navigation/routes";

const FooterCopyrights: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const year = new Date().getFullYear();

  return (
    <StyledCopyrightsWrapper>
      <Flex mb={{ _: "1.5rem", tablet: "0" }}>
        <StyledFooterLink
          href={`/${ROUTES.privacyPolicy}`}
          mx="0.5rem"
          onClick={() => {
            navigate(`/${ROUTES.privacyPolicy}`);
          }}
        >
          {t("Privacy Policy")}
        </StyledFooterLink>
        <StyledFooterLink
          href={`/${ROUTES.termsConditions}`}
          mx="0.5rem"
          onClick={() => {
            navigate(`/${ROUTES.termsConditions}`);
          }}
        >
          {t("Terms & Conditions")}
        </StyledFooterLink>
      </Flex>
      <Text textScale="p12Regular" color="textFifth">
        {t("© %year% Idealogic Design All rights reserved.", { year })}
      </Text>
    </StyledCopyrightsWrapper>
  );
};

export default FooterCopyrights;
