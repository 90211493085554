import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M18 11.86V12.14C17.9992 12.4066 17.8588 12.6533 17.63 12.79L8.68 18C8.14 18.32 7.86 18.32 7.62 18.18L7.37 18.04C7.14719 17.907 7.00768 17.6694 7 17.41V6.59005C7.00081 6.32349 7.14121 6.07683 7.37 5.94005L7.62 5.80005C7.86 5.66005 8.14 5.66005 8.84 6.07005L17.63 11.21C17.8588 11.3468 17.9992 11.5935 18 11.86Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
