// Context
import { useCaseStudyContext } from "context";
// Types
import { Category } from "services";
import { CaseStudiesFilterType } from "context/case-studies-context/types";
import { UseFilterHandlingProps, UseFilterHandlingReturn } from "./types";

const useFilterHandling = ({ closeModal, isModalOpen, filters }: UseFilterHandlingProps): UseFilterHandlingReturn => {
  const { selectedFilters, selectedAll, initialFilters, resetAllFilters, handleFilterChange } = useCaseStudyContext();

  const [pendingFilters, setPendingFilters] = React.useState(initialFilters);
  const [localSelectedAll, setLocalSelectedAll] = React.useState(selectedAll);
  const [shouldResetAll, setShouldResetAll] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState(false);

  const filtersData = pendingFilters || selectedFilters;
  const hasSomeFiltersSelectedNew = Object.values(filtersData).some(options => options.length > 0);

  const initialSelected = {
    industry: false,
    category: false,
  };

  const handleFilterChangeAndStore = React.useCallback(
    (type: CaseStudiesFilterType, option: string | Category) => {
      setIsSelected(true);
      setPendingFilters(prevPendingFilters => {
        if (filters) {
          if (typeof option === "string") {
            const allTypeFilters = filters[type].options;

            const isAllFiltersSelected = pendingFilters[type].length === allTypeFilters.length;

            setLocalSelectedAll(prevSelectedAll => ({
              ...prevSelectedAll,
              [type]: !isAllFiltersSelected,
            }));
            return {
              ...prevPendingFilters,
              [type]: isAllFiltersSelected ? [] : allTypeFilters,
            };
          } else {
            const isFilterSelected = prevPendingFilters[type].some(
              optionItem => optionItem.id === (option.id as string),
            );
            const updatedFilters = isFilterSelected
              ? prevPendingFilters[type].filter(optionItem => optionItem.id !== option.id)
              : [...prevPendingFilters[type], option];
            setLocalSelectedAll(prevSelectedAll => ({
              ...prevSelectedAll,
              [type]: updatedFilters.length === filters![type].options.length,
            }));
          }
        }

        const isSelectedCategory = prevPendingFilters[type].includes(option as Category);

        if (isSelectedCategory) {
          return {
            ...prevPendingFilters,
            [type]: prevPendingFilters[type].filter(selectedOption => selectedOption !== option),
          };
        } else {
          return {
            ...prevPendingFilters,
            [type]: [...prevPendingFilters[type], option],
          };
        }
      });
    },
    [localSelectedAll, filters],
  );

  const applyPendingFilters = React.useCallback(() => {
    if (hasSomeFiltersSelectedNew) {
      Object.keys(pendingFilters).forEach(type => {
        pendingFilters[type as CaseStudiesFilterType].forEach(option => {
          handleFilterChange({
            filters,
            type: type as CaseStudiesFilterType,
            isMobileFilter: true,
            selectedOption: option,
            selectedOptions: pendingFilters,
          });
        });
      });
      setPendingFilters(initialFilters);
    } else {
      const isPendingFiltersEmpty = Object.keys(pendingFilters).every(
        key => pendingFilters[key as CaseStudiesFilterType].length === 0,
      );
      if (isPendingFiltersEmpty) {
        resetAllFilters();
      }
    }
  }, [hasSomeFiltersSelectedNew, pendingFilters]);

  const handleRemoveFilter = React.useCallback(
    (filterType: CaseStudiesFilterType, removedOption: Category) => {
      setPendingFilters(prevFilters => {
        const updatedFilters = prevFilters[filterType].filter(filteredOption => filteredOption.id !== removedOption.id);
        setLocalSelectedAll(prevSelectedAll => ({
          ...prevSelectedAll,
          [filterType]: updatedFilters.length === filters![filterType].options.length,
        }));

        return {
          ...prevFilters,
          [filterType]: prevFilters[filterType].filter(option => option !== removedOption),
        };
      });

      setIsSelected(true);
    },
    [pendingFilters],
  );

  const handleClearAll = React.useCallback(() => {
    setPendingFilters(initialFilters);
    setShouldResetAll(true);
    setLocalSelectedAll(initialSelected);
  }, [initialSelected, initialFilters]);

  const handleShowResults = () => {
    if (isSelected) {
      applyPendingFilters();
    }

    closeModal();
    setIsSelected(false);

    if (shouldResetAll) {
      resetAllFilters();
      setShouldResetAll(false);
    }
  };

  React.useEffect(() => {
    if (isModalOpen) {
      setPendingFilters(selectedFilters);
    }
  }, [isModalOpen]);
  return {
    filtersData,
    pendingFilters,
    localSelectedAll,
    hasSomeFiltersSelectedNew,
    handleFilterChangeAndStore,
    handleRemoveFilter,
    handleClearAll,
    handleShowResults,
  };
};

export default useFilterHandling;
