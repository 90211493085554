import styled from "styled-components";
import Slider from "react-slick";

export const StyledRelevantArticlesSlider = styled(Slider)`
  margin: 0 -1rem;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    margin-bottom: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0;
    margin-bottom: 0;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: inherit;
  }

  .slick-slide > div {
    height: 100%;
  }
`;
