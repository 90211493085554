import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components + styling
import { Box, ClickItem, Link, Text, Image } from "components";
import { FullLogoIcon } from "components/svg";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";

const FooterDescription: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const handleClickLogo = () => {
    if (pathname === ROUTES.landing && !hash) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate(ROUTES.landing);
    }
  };

  return (
    <Box width={{ _: "100%", laptop: "50%" }} mb={{ _: "1.5rem", tablet: "0" }} pr={{ _: "0", laptop: "4.5rem" }}>
      <ClickItem cursor="pointer" mb={{ _: "0.375rem", tablet: "3.25rem" }} onClick={handleClickLogo}>
        <FullLogoIcon width={isDesktop ? "13.5rem" : "10rem"} color="white" />
      </ClickItem>

      <Text textScale={isDesktop ? "p14Light" : "p12Regular"} color="textFifth">
        {t(
          "Disclaimer: Idealogic Design is a product design studio for startups. Our goal is to create user-friendly and joyful experiences for platforms and applications. Idealogic Design produces some of the most engaging features in the industry and offers custom solutions for every client.",
        )}
      </Text>

      <ClickItem mb={{ _: "1.5rem", laptop: 0 }} mt="1.5rem" display="flex">
        <Link href={EXTERNAL_LINKS.goodFirms} external>
          <Image
            width="11.25rem"
            src="https://assets.goodfirms.co/badges/white-button/top-web-design-companies.svg"
            aspectRatio={3.26}
            alt="Company"
          />
        </Link>

        <Link href={EXTERNAL_LINKS.goodFirmsIdealogic} external mx="1rem">
          <Image
            width="6rem"
            src="https://assets.goodfirms.co/badges/color-badge/top-web-design-companies.svg"
            aspectRatio={1.16}
            title="Top Web Designing (UI/UX) Company"
            alt="Top Web Designing (UI/UX) Company on GoodFirms"
          />
        </Link>
      </ClickItem>
    </Box>
  );
};

export default FooterDescription;
