import React, { useState } from "react";
import { useTheme } from "styled-components";
import { motion } from "framer-motion";
// Components + styling
import { Container, VideoPlayerModal, Heading, Link, Text, Box } from "components";
import { CirclePlayIcon } from "components/svg";
import { TestimonialVideoBox } from "./components";
import {
  StyledVideoSliderWrapper,
  StyledVideoSlide,
  StyledInfoWrapper,
  StyledDescription,
  StyledLinkedInIcon,
  StyledButtonWrapper,
  StyledDescriptionWrapper,
  StyledLinkedInLinkWrapper,
  StyledProjectLink,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { VIDEO_LIST } from "./constants";
import { hidingElements, videoSlideAnimationVariant } from "./animations";

const VideoTestimonials: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [hoveredItem, setHoveredItem] = useState<number>(1);

  const toggleOpen = (video: string) => {
    setVideoUrl(video);
    setOpen(prevOpen => !prevOpen);
  };

  return (
    <Container pt={{ _: "4rem", laptop: "6.875rem" }} pb={{ _: "2rem", laptop: "4rem" }} position="relative">
      <Box max-width="31.75rem" mb={{ _: "1.5rem", laptop: "2rem" }}>
        <Heading scale="h2" as="h2" mb={{ _: "0.25rem", tablet: "1rem" }}>
          {t("Video Testimonials")}
        </Heading>
        <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>
          {t("Experience the excellence of our products through authentic video testimonials from our clients")}
        </Text>
      </Box>

      <div>
        <StyledVideoSliderWrapper>
          {VIDEO_LIST.map(
            ({ imageQuote, videoUrl, videoSrc, name, position, projectLink, borderRadius, linkedInLink }, index) => {
              const isHovered = index === hoveredItem;

              return (
                <StyledVideoSlide
                  key={index}
                  animate={isMobile ? "fullWidth" : isHovered ? "opened" : "collapsed"}
                  variants={videoSlideAnimationVariant}
                  transition={theme.transitions.videoSlideMotion}
                  onMouseEnter={() => {
                    setHoveredItem(index);
                  }}
                >
                  <TestimonialVideoBox
                    videoUrl={videoUrl}
                    videoSrc={videoSrc}
                    imageQuote={imageQuote}
                    borderRadius={borderRadius}
                    isHovered={isHovered}
                    toggleOpen={toggleOpen}
                  />

                  <StyledDescriptionWrapper>
                    <StyledDescription>
                      <Text textScale="p20Bold" mb={{ _: "0.25rem", laptop: "0.5rem" }} ellipsis color="textMain">
                        {t(name)}
                      </Text>

                      <StyledInfoWrapper>
                        <Text
                          textScale={isDesktop ? "p16Regular" : "p14Regular"}
                          ellipsis={!isMobile}
                          color="textFifth"
                        >
                          {t(position)}
                        </Text>

                        <motion.div animate={!isHovered && !isMobile ? "hide" : "show"} variants={hidingElements}>
                          <Link
                            external
                            href={linkedInLink}
                            color="linkedInLink"
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <StyledLinkedInLinkWrapper>
                              <StyledLinkedInIcon />
                            </StyledLinkedInLinkWrapper>
                          </Link>
                        </motion.div>
                      </StyledInfoWrapper>
                    </StyledDescription>

                    <motion.div animate={!isHovered && !isMobile ? "hide" : "show"} variants={hidingElements}>
                      <StyledProjectLink
                        textScale="p16Medium"
                        underline
                        minWidth={{ _: "6.25rem", tablet: "6.75rem" }}
                        external
                        href={projectLink}
                        color="textLink"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {t("Go to project")}
                      </StyledProjectLink>
                    </motion.div>
                  </StyledDescriptionWrapper>

                  {!isHovered && !isMobile ? null : (
                    <StyledButtonWrapper
                      onClick={e => {
                        e.stopPropagation();
                        toggleOpen(videoUrl);
                      }}
                    >
                      <CirclePlayIcon width="6.25rem" />
                    </StyledButtonWrapper>
                  )}
                </StyledVideoSlide>
              );
            },
          )}
        </StyledVideoSliderWrapper>
      </div>
      <VideoPlayerModal isOpen={open} videoSources={[videoUrl]} />
    </Container>
  );
};

export default VideoTestimonials;
