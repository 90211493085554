import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Column, Flex } from "components";

export const StyledMotion = styled(motion.div)<{ offsetHeight: number }>`
  position: fixed;
  top: ${({ offsetHeight }) => offsetHeight - 1}px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.tooltip - 1};
  background-color: ${({ theme }) => theme.colors.bgDark};
`;

export const StyledMobileMenu = styled(Column)<{ offsetHeight: number }>`
  width: 100%;
  height: 100%;
  min-height: ${({ offsetHeight }) => `calc(100dvh - ${offsetHeight - 2}px)`};
  justify-content: space-between;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndices.tooltip - 1};
  border-top: 1px solid ${({ theme }) => theme.colors.lineDark};
  background-color: ${({ theme }) => theme.colors.bgDark};
  overflow: auto;
`;

export const StyledMobileMenuFooter = styled(Flex)`
  width: 100%;
  height: 6rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.lineDark};
`;
