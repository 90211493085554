import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";

// Components
import { Box, DefaultSlideImage, Text } from "components";

export const StyledSlideImageWrapper = styled(Box)`
  width: 100%;
  height: 11.875rem;
  display: flex;
  flex: initial;
  position: relative;
  margin: auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 11.375rem;
    margin: 0.6875rem 0 0.8125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    height: auto;
    padding: 0 0 0 2.0625rem;
  }
`;

export const StyledMotionWrapper = styled(motion.div)<{ zIndex: number }>`
  ${({ theme }) => theme.mediaQueries.laptop} {
    position: absolute;
    right: 0;
    z-index: ${({ zIndex }) => zIndex};
  }
`;

export const StyledSlideImageTitleLeft = styled(Text)`
  max-width: 6.25rem;
  text-align: end;
  position: absolute;
  top: 0rem;
  right: 18rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    right: 10.2rem;
    top: 0.625rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileM} {
    right: 12rem;
    top: 0.625rem;
  }
  ${({ theme }) => theme.mediaQueries.mobileL} {
    right: 14rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    right: 25.2rem;
    top: 0.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -14.125rem;
    right: 15.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -15rem;
    right: 23.875rem;
  }

  &::after {
    ${clearfix}
    width: 3.375rem;
    height: 3.0625rem;
    position: absolute;
    top: 1rem;
    right: -2.9375rem;
    background: url("images/e-states-slide-arrow-left.svg") no-repeat;
    background-position: center 0;
    background-size: 0.9375rem auto;

    ${({ theme }) => theme.mediaQueries.mobileS} {
      top: calc(100% + -1.125rem);
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      top: calc(100% + -2.225rem);
      right: calc(100% + -9.225rem);
      background-size: contain;
    }
  }
`;

export const StyledSlideImageTitleRight = styled(Text)`
  width: 6.25rem;
  text-align: center;
  position: absolute;
  top: 0;
  right: 8.375rem;
  transform: rotate(-0.91deg);

  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    right: 0.5rem;
    top: 0.625rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileM} {
    right: 1.9rem;
    top: 0.625rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileL} {
    right: 2.9rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    bottom: 0;
    right: 14.5rem;
    top: 0.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 8.25rem;
    max-width: 8.25rem;
    top: -14rem;
    right: -1rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -14.8rem;
    right: 5.8rem;
  }

  &::before {
    ${clearfix}
    width: 4.375rem;
    height: 3.7rem;
    position: absolute;
    right: 5.625rem;
    top: -1.25rem;
    background: url("images/e-states-slide-arrow-right.svg") no-repeat;
    background-size: 0.75rem auto;

    ${({ theme }) => theme.mediaQueries.mobileS} {
      top: calc(100% + -1.225rem);
      right: calc(100% + -4.5rem);
    }
    ${({ theme }) => theme.mediaQueries.tablet} {
      top: calc(100% + 1.125rem);
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 2.375rem;
      height: 3.4rem;
      top: 1.5rem;
      right: 7.4rem;
      background-size: contain;
    }
  }
`;

export const StyledSlideMainImage = styled(DefaultSlideImage)`
  width: 14.5rem;
  height: auto;
  display: block;
  position: absolute;
  top: calc(100% + 3.525rem);
  right: 0.5rem;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};

  ${({ theme }) => theme.mediaQueries.mobileM} {
    top: calc(100% + 3.825rem);
    right: 2rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileL} {
    width: 17rem;
    top: calc(100% + 3.825rem);
    right: 2rem;
  }
  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 18.25rem;
    top: 3.375rem;
    right: 12.625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 23.75rem;
    top: -8.25rem;
    right: -0.5625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 31.75rem;
    top: -9.625rem;
    right: 2rem;
  }
`;
