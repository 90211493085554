import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";
// Components
import { Text } from "components";

export const StyledServiceItemLabel = styled(Text)`
  width: 9.25rem;
  text-align: center;
  position: relative;
  color: ${({ theme }) => theme.colors.textThird};
`;

export const StyledServiceItem = styled(motion.div)`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 10.25rem;
    margin-bottom: 0;
  }

  &:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  &:nth-of-type(1) {
    ${({ theme }) => theme.mediaQueries.tablet} {
      margin-top: 5.875rem;
    }

    ${StyledServiceItemLabel} {
      &::after {
        ${clearfix}
        width: 3rem;
        height: 8.8125rem;
        position: absolute;
        top: -2.25rem;
        left: -0.75rem;
        background: no-repeat url("/images/services-item-connector-left-sm.svg");

        ${({ theme }) => theme.mediaQueries.tablet} {
          display: none;
        }
      }
    }
  }

  &:nth-of-type(2) {
    ${StyledServiceItemLabel} {
      &::after {
        ${clearfix}
        width: 3.5625rem;
        height: 8.875rem;
        position: absolute;
        top: -2.25rem;
        right: -1rem;
        background: no-repeat url("/images/services-item-connector-right-sm.svg");

        ${({ theme }) => theme.mediaQueries.tablet} {
          display: none;
        }
      }
    }
  }

  &:nth-of-type(4) {
    ${({ theme }) => theme.mediaQueries.tablet} {
      margin-top: 4.375rem;
    }
  }
`;
