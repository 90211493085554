import * as yup from "yup";
// Context
import { useTranslation } from "context";

export const initialValues = {
  message: "",
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    message: yup.string().required(t("Message is required")).trim(),
  });

  return { validationSchema, initialValues };
};
