import { motion } from "framer-motion";
// Components + styling
import { Text } from "components";
import { StyledSecondColumnFlowItem, StyledSecondColumnWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Animation
import { ugreatorAnimatedItems } from "../../animations";
// Constants
import { UGREATOR_USER_FLOWS_SECOND_COLUMN_LIST } from "./constants";

const UgreatorUserFlowsSecondColumn: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <motion.div
      style={{ position: "relative" }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={ugreatorAnimatedItems(0.25)}
    >
      <StyledSecondColumnWrapper>
        <Text
          textScale={isDesktop ? "p16Medium" : "p6Medium"}
          textAlign="center"
          mb={{ _: "0.5rem", laptop: "1.5rem" }}
          color="textFourth"
        >
          {t("Navbar")}
        </Text>

        {UGREATOR_USER_FLOWS_SECOND_COLUMN_LIST.map(
          ({ label, variant, connectorLinesSrc, borderGradient, itemGradient }) => (
            <StyledSecondColumnFlowItem
              key={label}
              variant={variant}
              connectorLinesSrc={connectorLinesSrc}
              borderGradient={borderGradient}
              itemGradient={itemGradient}
            >
              <Text textScale={isDesktop ? "p16Medium" : "p6Medium"} color="white">
                {t(label)}
              </Text>
            </StyledSecondColumnFlowItem>
          ),
        )}
      </StyledSecondColumnWrapper>
    </motion.div>
  );
};

export default UgreatorUserFlowsSecondColumn;
