import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Container } from "components";
// Constants
import { headerThemes, headerThemesType } from "./constants";
// Types
import { BoxProps } from "components/layout/components/types";

type StyledHeaderWrapperProps = {
  isMobileMenuOpen: boolean;
  headerTheme: headerThemesType;
};

interface StyledHeaderProps extends BoxProps {
  isMobileMenuOpen: boolean;
  isBgWhite: boolean;
  isUserOnPlanetcoinCasePage: boolean;
  isUserOnUgreatorCasePage: boolean;
}

interface StyledHeaderContainerProps extends BoxProps {
  headerTheme: headerThemesType;
}

export const StyledHeader = styled.div<StyledHeaderProps>`
  transition: ${({ theme }) => theme.transitions.default};
  background: ${({ isBgWhite, isUserOnPlanetcoinCasePage, isUserOnUgreatorCasePage, theme }) =>
    isBgWhite
      ? theme.colors.white
      : isUserOnPlanetcoinCasePage
        ? theme.colors.planetcoinBgDark
        : isUserOnUgreatorCasePage
          ? theme.colors.transparent
          : "inherit"};
`;

export const StyledHeaderWrapper = styled.div<StyledHeaderWrapperProps>`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.header};
  min-height: unset;
  max-width: ${({ theme }) => theme.siteWidth}px;
  margin: 0 auto;
  background-color: ${({ headerTheme, theme }) =>
    headerTheme === headerThemes.light
      ? theme.colors.white
      : headerTheme === headerThemes.planetcoinCase
        ? theme.colors.planetcoinBgDark
        : headerTheme === headerThemes.ugreatorCase
          ? theme.colors.transparent
          : "inherit"};

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 0 3rem 0.25rem;
  }
`;

export const StyledHeaderContainer = styled(Container)<StyledHeaderContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.88rem 0.88rem 0.88rem 1.63rem;
  backdrop-filter: blur(3px);
  background: ${({ headerTheme, theme }) =>
    headerTheme === headerThemes.light
      ? theme.colors.white
      : headerTheme === headerThemes.planetcoinCase
        ? theme.colors.planetcoinBgDark
        : headerTheme === headerThemes.ugreatorCase
          ? theme.colors.transparent
          : /* F2 used for 95% opacity */
            `${theme.colors.bgDark}F2`};
  max-width: unset;
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.laptopL} {
    border-radius: ${({ theme }) => theme.radii.header};
  }
`;

export const StyledMotionHeader = styled(motion.div)`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.header};
  width: 100%;
`;
