import styled from "styled-components";
import { clearfixDisplayNone } from "styles";
// Components
import { Box, Column, Heading, Image } from "components";

export const EStatesCurlyArrowIconWrapper = styled(Box)`
  margin-left: -2rem;
  position: absolute;
  top: -1.25rem;
  right: 7.75rem;
  transform: rotate(48deg);
`;

export const EStatesClientAvatar = styled(Image)`
  margin-bottom: 1.5rem;

  img {
    margin: 0 auto;

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin: 0;
    }
  }
`;

export const EStatesRatingWrapper = styled(Column)`
  align-items: center;
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    align-items: flex-start;
  }

  ::before {
    ${clearfixDisplayNone}
    width: 2rem;
    height: 2rem;
    background: url("/images/e-states-quotes.svg");
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
      margin-bottom: 1.25rem;
    }
  }
`;

export const EStatesTestimonialDescription = styled(Heading)`
  text-align: center;
  margin-bottom: 1.125rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    text-align: left;
    margin-bottom: 2.125rem;
  }
`;
