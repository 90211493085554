import { forwardRef } from "react";
// Components
import { Text, Flex, Heading } from "components";
import { CloseIcon, PaperClipIcon } from "components/svg";
import {
  StyledFileUploadContainer,
  StyledFileUploadInput,
  StyledFileUploadWrapper,
  StyledClearIconWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { FileUploadProps } from "./types";

const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ files, label, handleDragOver, handleDrop, handleFileInputChange, initClickInput, clearFileUploader }, ref) => {
    const { t } = useTranslation();
    const { isLargeDesktop, isLaptop, isDesktop } = useMatchBreakpoints();

    return (
      <StyledFileUploadContainer>
        {label && (
          <>
            {isLargeDesktop ? (
              <Heading minWidth="fit-content" scale="h4" as="h4" color="textMain">
                {label}
              </Heading>
            ) : (
              <Text minWidth="fit-content" textScale={isLaptop ? "p24Regular" : "p20Regular"}>
                {label}
              </Text>
            )}
          </>
        )}

        <Flex mt={{ _: "0.5rem", mobileM: "0" }} alignItems="center">
          <StyledFileUploadWrapper
            width={files.length > 0 ? "calc(50% - 3.125rem)" : "50%"}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={initClickInput}
          >
            <Flex width="100%" justifyContent="center">
              <PaperClipIcon width="1.25rem" />
              <Text
                textScale={isDesktop ? "p24Regular" : "p14Light"}
                textAlign="center"
                mx={{ _: "1rem" }}
                ellipsis
                maxWidth="100%"
              >
                {files.length ? t("Attach files (%qty%)", { qty: files.length }) : t("Attach files")}
              </Text>
            </Flex>

            <StyledFileUploadInput ref={ref} type="file" multiple onChange={handleFileInputChange} />
          </StyledFileUploadWrapper>

          {files.length > 0 && (
            <StyledClearIconWrapper onClick={() => clearFileUploader()}>
              <CloseIcon width="1.5rem" color="textMain" />
            </StyledClearIconWrapper>
          )}
        </Flex>
      </StyledFileUploadContainer>
    );
  },
);

FileUpload.displayName = "FileUpload";

export default FileUpload;
