import { useEffect, useState } from "react";
// Constants
import { breakpointMap, breakpoints, siteWidth } from "theme/base";
import { HEIGHT_BREAKPOINT_ANIMATION } from "pages/landing/components/provided-services/constants";
// Types
import { AnimationStatusCheckTypes, animationStatusCheckVariants } from "./types";

const useAnimationStatus = (type: AnimationStatusCheckTypes) => {
  const [isAnimationActive, setIsAnimationActive] = useState(getAnimationStatus(type));

  useEffect(() => {
    window.addEventListener("resize", updateAnimationStatus);

    return () => {
      window.removeEventListener("resize", updateAnimationStatus);
    };
  }, []);

  const updateAnimationStatus = () => {
    setIsAnimationActive(getAnimationStatus(type));
  };

  function getAnimationStatus(type: AnimationStatusCheckTypes) {
    const heightBreakpoint =
      HEIGHT_BREAKPOINT_ANIMATION[window.innerWidth < breakpointMap.tablet ? breakpoints.mobileS : breakpoints.tablet];

    switch (type) {
      case animationStatusCheckVariants.banner:
        return window.innerHeight <= siteWidth;

      case animationStatusCheckVariants.services:
        return window.innerHeight >= heightBreakpoint && window.innerHeight <= siteWidth;

      case animationStatusCheckVariants.planetcoinBanner:
        return window.innerHeight <= breakpointMap.laptopL;

      case animationStatusCheckVariants.estatesBanner:
        return window.innerHeight <= breakpointMap.laptopL;

      case animationStatusCheckVariants.ugreatorAmbassadors:
        return window.innerHeight >= 700;

      default:
        return window.innerHeight <= siteWidth;
    }
  }

  return { isAnimationActive };
};

export default useAnimationStatus;
