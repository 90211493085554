// Components
import { Box, ContactUs, OurTeam, Page } from "components";
import { CareersBanner, CareersBenefits, CorporateValues, DevelopmentSuggestion, OpenPositions } from "./components";
// Constants
import { BLOCK_ID } from "configs";

const CareersPage: React.FC = () => {
  return (
    <Page maxWidth="100vw" px="0">
      <CareersBanner />

      <OpenPositions />

      <DevelopmentSuggestion />

      <CorporateValues />

      <CareersBenefits />

      <OurTeam />

      <Box id={BLOCK_ID.contactUs}>
        <ContactUs title="Join the team!" isShowCommentInput isHideBgImage />
      </Box>
    </Page>
  );
};

export default CareersPage;
