import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// Components
import { Container } from "../container";
// Context
import { useTranslation } from "context";
// Utils
import { EN, getCustomMeta, languageList } from "configs";
import { cutLocaleFromRoute, removeTrailingSlashIfExists } from "utils/pathname-helpers";
// Constants
import { defaultLinkedData } from "configs/meta";
// Types
import { PageMetaProps, PageProps } from "../types";

export const PageMeta: React.FC<PageMetaProps> = ({
  customTitle,
  customDescription,
  customImg,
  customLinkedData,
  keywords,
  isErrorPage,
}) => {
  const { pathname } = useLocation();
  const { t, currentLanguage } = useTranslation();

  const { title, description, image, keywords: pageKeywords } = getCustomMeta(pathname, t);
  const pageTitle = title ? `${title} | ${t("Idealogic Design")}` : t("Idealogic Design");
  const correctPath = cutLocaleFromRoute(pathname);
  const preparedKeywords = keywords ? keywords?.join(", ") : pageKeywords;

  return (
    <Helmet>
      <meta name="description" content={customDescription || description} />
      {!isErrorPage && <meta name="keywords" content={preparedKeywords} />}

      <meta property="twitter:title" content={customTitle || pageTitle} />
      <meta property="twitter:description" content={customDescription || description} />
      <meta property="twitter:image" content={customImg || image} />

      <meta property="og:title" content={customTitle || pageTitle} />
      <meta property="og:description" content={customDescription || description} />
      <meta property="og:image" content={customImg || image} />
      <meta property="og:locale" content={currentLanguage.locale} />

      {isErrorPage && <meta name="errorpage" content="true" />}
      {isErrorPage && <meta name="errortype" content="404 - Not Found" />}
      {isErrorPage && <meta name="prerender-status-code" content="404" />}

      <script type="application/ld+json">{JSON.stringify(customLinkedData || defaultLinkedData)}</script>

      <link rel="canonical" href={`${process.env.REACT_APP_URL}${removeTrailingSlashIfExists(pathname)}`} />
      <link
        rel="alternate"
        href={`${process.env.REACT_APP_URL}${removeTrailingSlashIfExists(pathname)}`}
        hrefLang="x-default"
      />

      {languageList.map(({ locale }) => {
        const languageCode = locale === EN.locale ? "" : `/${locale}`;
        const href = `${process.env.REACT_APP_URL}${languageCode}${removeTrailingSlashIfExists(correctPath)}`;

        return <link key={locale} rel="alternate" href={href} hrefLang={locale} />;
      })}
      <title>{customTitle || pageTitle}</title>
    </Helmet>
  );
};

export const Page: React.FC<PageProps> = ({
  children,
  customImg,
  keywords,
  customDescription,
  customTitle,
  customLinkedData,
  isErrorPage,
  ...props
}) => {
  return (
    <>
      <PageMeta
        customImg={customImg}
        keywords={keywords}
        customDescription={customDescription}
        customTitle={customTitle}
        customLinkedData={customLinkedData}
        isErrorPage={isErrorPage}
      />
      <Container {...props}>{children}</Container>
    </>
  );
};
