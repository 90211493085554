import styled from "styled-components";
// Components
import { Flex, Link } from "components";
import { moveUpTextAnimation } from "theme/animations";

export const StyledNavList = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: auto 0;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    flex-direction: row;
    justify-content: center;
    margin: 0;
    gap: 2rem;
  }
`;

export const StyledNavItem = styled(Link)`
  -webkit-background-clip: none;
  -webkit-text-fill-color: unset;
  padding: 1rem 0;
  cursor: pointer;
  ${moveUpTextAnimation}

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 0;
  }
`;
