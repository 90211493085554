export const INTERACTION_SECTIONS_LIST = [
  {
    id: 1,
    title: "Overview",
    description: "User can manage his profile, track statistics data, view notifications.",
    image: "/images/planetcoin-interactions-1.webp",
  },
  {
    id: 2,
    title: "Trade",
    description: "Users can quickly find the closest buyer or seller and simplify the transaction process",
    image: "/images/planetcoin-interactions-2.webp",
  },
  {
    id: 3,
    title: "Transactions",
    description: "The user can manage his transactions, see the details of each operation",
    image: "/images/planetcoin-interactions-3.webp",
  },
  {
    id: 4,
    title: "Wallets",
    description: "It contains information about the user's currency, a graph of its performance, price statistics",
    image: "/images/planetcoin-interactions-4.webp",
  },
];
