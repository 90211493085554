import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 93 93" {...props} color="transparent">
      <path
        d="M15.5 15.5h62M37.2 41.85l4.008-4.008c1.033-1.033 1.55-1.55 2.192-1.55.642 0 1.158.517 2.192 1.55l1.816 1.816c1.033 1.034 1.55 1.55 2.192 1.55.642 0 1.158-.516 2.192-1.55L55.8 35.65"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        opacity=".5"
        d="M46.5 74.4V62m0 12.4-6.2 3.1m6.2-3.1 6.2 3.1"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M71.3 15.5v26.35c0 9.499 0 14.248-3.113 17.2C65.074 62 60.064 62 50.043 62h-7.086c-10.02 0-15.03 0-18.144-2.95-3.113-2.952-3.113-7.701-3.113-17.2V15.5"
        stroke={theme.colors[color]}
        strokeWidth="2"
      />
    </Svg>
  );
};

export default Icon;
