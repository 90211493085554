// Components + styling
import { StyledCardContainer, StyledCardItem } from "./styled";
import { CaseStudiesCardItemDescription, CaseStudiesCardItemImage, CaseStudiesListSkeleton } from "./components";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CaseStudiesCardProps } from "./types";

const CaseStudiesCard: React.FC<CaseStudiesCardProps> = ({ filteredCards, isLoading }) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledCardContainer>
      {isLoading ? (
        <CaseStudiesListSkeleton />
      ) : (
        filteredCards.map(item => {
          const {
            logo: { logoSrc, width, aspectRatio },
            imgSrc,
            isComingSoonDesign,
          } = item;
          return (
            <StyledCardItem key={item.title}>
              {isDesktop && (
                <CaseStudiesCardItemImage
                  imgSrc={imgSrc.url}
                  logoSrc={logoSrc.url}
                  logoWidth={width}
                  isComingSoon={isComingSoonDesign}
                  logoAspectRatio={aspectRatio}
                />
              )}

              <CaseStudiesCardItemDescription {...item} />
            </StyledCardItem>
          );
        })
      )}
    </StyledCardContainer>
  );
};

export default CaseStudiesCard;
