import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M10.4629 51.2344H3.44872C1.996 51.2344 0.818359 50.0567 0.818359 48.6041V15.2871C0.818359 13.8344 1.996 12.6568 3.44872 12.6568H52.5475C54.0002 12.6568 55.1777 13.8344 55.1777 15.2871V48.6041C55.1777 50.0567 54.0002 51.2344 52.5475 51.2344H13.9697"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.83247 12.6568C7.83247 16.5305 4.6922 19.6709 0.818359 19.6709"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.818359 44.2203C4.69209 44.2203 7.83247 47.3605 7.83247 51.2344"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.1641 51.2344C48.1641 47.3606 51.3043 44.2203 55.1782 44.2203"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1782 19.6709C51.3044 19.6709 48.1641 16.5305 48.1641 12.6568"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5057 33.1924L38.4685 26.2296C39.2916 25.4064 39.2916 24.0718 38.4685 23.2487L36.978 21.7582C36.1549 20.9351 34.8203 20.9351 33.9971 21.7582L29.877 25.8783"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8732 31.883L19.7968 35.9594C19.3341 36.4222 18.9854 36.9863 18.7784 37.6071L16.8574 43.3701L22.6204 41.4492C23.2413 41.2422 23.8054 40.8935 24.2682 40.4308L28.6002 36.0986"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.584 35.1722L25.0553 39.6436"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5059 23.2484L36.9772 27.7197"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1182 39.4717L17.7812 26.4763C16.4824 25.3758 16.4009 23.4004 17.6047 22.1967C18.8084 20.9929 20.7839 21.0744 21.8844 22.3732L34.8807 37.7112"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9791 38.5921C36.156 37.769 34.8214 37.769 33.9982 38.5921C33.1751 39.4153 33.1751 40.7499 33.9982 41.573C35.4887 43.0635 38.4696 43.0635 38.4696 43.0635C38.4696 43.0635 38.4696 40.0826 36.9791 38.5921Z"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2305 12.6572L20.6788 6.13946C20.8572 5.3372 21.5687 4.76637 22.3906 4.76637H33.6056C34.4275 4.76637 35.1391 5.3372 35.3173 6.13946L36.7657 12.6572"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5449 8.27344H35.4499"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
