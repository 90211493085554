import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, Flex, Text } from "components";

export const StyledWorkflowItemWrapper = styled(motion.div)`
  position: relative;
  margin-bottom: 3rem;

  &:nth-of-type(2) {
    margin-bottom: 0;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const StyledWorkflowItem = styled(Flex)`
  width: 100%;
  align-items: center;
`;

export const StyledStepWrapper = styled(Flex)`
  width: 2.875rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledStepIcon = styled(Box)`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledStepLabel = styled(Text)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};
`;
