import React from "react";
import FsLightbox from "fslightbox-react";
// Components
import { Box, Flex } from "components";
import { TrianglePlay } from "components/svg";
import { StyledButton } from "./styled";
// Context
import { useTranslation } from "context";
// Types
import { EStatesCarouselProps } from "./types";

const EStatesCarousel: React.FC<EStatesCarouselProps> = ({
  onToggleOpen,
  isButtonView = true,
  videoSources,
  children,
  isOpen,
  text,
  ...props
}) => {
  const { t } = useTranslation();

  const hideHeader = () => {
    const header = document.getElementById("header");
    if (header) {
      header.style.display = "none";
    }
  };

  const showHeader = () => {
    const header = document.getElementById("header");
    if (header) {
      header.style.display = "flex";
    }
  };

  return (
    <Flex {...props}>
      {isButtonView ? (
        <StyledButton
          startIcon={<TrianglePlay />}
          scale="sm"
          bgColor="textMain"
          hoverLinesPosition="bottomLeft"
          hoverBg="buttonLightStroke"
          onClick={onToggleOpen}
          mt="1rem"
          width={{ _: "7.5rem", tablet: "10.375rem" }}
          height="2.75rem"
        >
          {text && t(text)}
        </StyledButton>
      ) : (
        <Box onClick={onToggleOpen}>{children}</Box>
      )}
      <FsLightbox toggler={isOpen} sources={[videoSources]} onOpen={hideHeader} onClose={showHeader} />
    </Flex>
  );
};

export default EStatesCarousel;
