import { forwardRef } from "react";
import { motion } from "framer-motion";
// Components + Styling
import { Box } from "components";
// Hooks
import { useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const FindSolutionParallaxBg = forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const parallaxOffset: UseScrollOffsetOptions[] = ["start 20%", "end start"];

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 60, { offset: parallaxOffset }),
    useParallaxEffect(ref, 120, { offset: parallaxOffset }),
    useParallaxEffect(ref, 90, { offset: parallaxOffset }),
    useParallaxEffect(ref, 50, { offset: parallaxOffset }),
    useParallaxEffect(ref, 100, { offset: parallaxOffset }),
    useParallaxEffect(ref, 70, { offset: parallaxOffset }),
    useParallaxEffect(ref, 80, { offset: parallaxOffset }),
  ];

  return (
    <Box position="relative">
      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, zIndex, ratio }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];

        return (
          <motion.div key={imageSrc} style={{ width: "100%", zIndex, y }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </Box>
  );
});

FindSolutionParallaxBg.displayName = "FindSolutionParallaxImage";

export default FindSolutionParallaxBg;
