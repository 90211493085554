import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <circle cx="43" cy="45" r="8" stroke={theme.colors[color]} strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M4 44.7143L9.81818 51L20 39"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-3-inside-1_1542_24871" fill="white">
        <rect x="4" y="5" width="16" height="16" rx="1" />
      </mask>
      <rect
        x="4"
        y="5"
        width="16"
        height="16"
        rx="1"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeLinejoin="round"
        mask="url(#path-3-inside-1_1542_24871)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8284 13.2344L43.2349 20.6408L50.6413 13.2344L43.2349 5.82794L35.8284 13.2344ZM33.7071 12.5273C33.3166 12.9178 33.3166 13.551 33.7071 13.9415L42.5278 22.7621C42.9183 23.1527 43.5514 23.1527 43.942 22.7621L52.7626 13.9415C53.1531 13.551 53.1531 12.9178 52.7626 12.5273L43.942 3.70662C43.5514 3.31609 42.9183 3.31609 42.5278 3.70662L33.7071 12.5273Z"
        fill={theme.colors[color]}
      />
      <line x1="43" y1="22" x2="43" y2="36" stroke={theme.colors[color]} strokeWidth="2" strokeLinecap="round" />
      <line x1="34" y1="13.5" x2="20" y2="13.5" stroke={theme.colors[color]} strokeWidth="2" strokeLinecap="round" />
    </Svg>
  );
};

export default Icon;
