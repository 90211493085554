import styled from "styled-components";
// Components
import { Column } from "components";

export const EStatesWireframesWrapper = styled(Column)`
  width: 100%;
  min-height: 38.25rem;
  position: relative;
  padding-top: 6rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    min-height: 70.5rem;
    padding-top: 18.625rem;
  }
`;
