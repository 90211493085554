import styled from "styled-components";
// Components
import { DefaultSlideImage, Flex } from "components";

export const StyledServicesParallaxWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledSlideImage1_1 = styled(DefaultSlideImage)`
  width: 9.3125rem;
  position: absolute;
  top: 4.25rem;
  transform: translateX(-50%);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 18.5625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 26rem;
    top: 3.625rem;
  }
`;

export const StyledSlideImage1_2 = styled(DefaultSlideImage)`
  width: 9.3125rem;
  position: absolute;
  top: 2.25rem;
  transform: translateX(-50%);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 18.5625rem;
    top: 0.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 26rem;
    top: -2rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 2.5625rem;
  position: absolute;
  top: 17.125rem;
  right: 5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 5.0625rem;
    right: 10rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 7.125rem;
    top: 18.125rem;
    right: 13rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 2.0625rem;
  position: absolute;
  left: 0.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4.125rem;
    left: 1.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.8125rem;
    left: 2.875rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 9.9375rem;
  position: absolute;
  transform: translateX(-50%);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 19.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 27.8125rem;
    top: 12rem;
    right: -11.25rem;
    transform: none;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  width: 1.625rem;
  position: absolute;
  top: 1.875rem;
  right: 7.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 3.25rem;
    right: 15rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 4.5625rem;
    right: 20.125rem;
  }
`;

export const StyledSlideImage6 = styled(DefaultSlideImage)`
  width: 2.125rem;
  position: absolute;
  top: 15.625rem;
  right: 6.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4.3125rem;
    right: 16rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 6rem;
    top: 25rem;
    right: 16.25rem;
  }
`;

export const StyledSlideImage7 = styled(DefaultSlideImage)`
  width: 1.1875rem;
  position: absolute;
  top: 12.5rem;
  right: 6.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.3125rem;
    top: 8.5rem;
    right: 12.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.25rem;
    top: 21.25rem;
    right: 17.875rem;
  }
`;

export const StyledSlideImage8 = styled(DefaultSlideImage)`
  width: 1.25rem;
  position: absolute;
  top: 25rem;
  right: 3.125rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.4375rem;
    top: 30rem;
    right: 6rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.25rem;
    top: 40rem;
    right: 9.25rem;
  }
`;

export const StyledSlideImage9 = styled(DefaultSlideImage)`
  width: 0.875rem;
  position: absolute;
  top: 6.25rem;
  right: 1.5625rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.75rem;
    right: 2rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.4375rem;
    top: 28.125rem;
    right: 3rem;
  }
`;

export const StyledSlideImage10 = styled(DefaultSlideImage)`
  width: 0.8125rem;
  position: absolute;
  top: -4.625rem;
  right: 0.9375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.625rem;
    right: 9rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.3125rem;
    top: 6.625rem;
    right: 5.625rem;
  }
`;

export const StyledSlideImage11 = styled(DefaultSlideImage)`
  width: 1.25rem;
  position: absolute;
  top: 9.875rem;
  right: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.5625rem;
    right: 0.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.5625rem;
    top: 19.875rem;
    right: 0.75rem;
  }
`;

export const StyledSlideImage12 = styled(DefaultSlideImage)`
  width: 1.875rem;
  position: absolute;
  top: -1.25rem;
  right: 0.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 3.6875rem;
    right: 1.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.1875rem;
    right: 2.5rem;
  }
`;

export const StyledSlideImage13 = styled(DefaultSlideImage)`
  width: 2.0625rem;
  position: absolute;
  top: 25rem;
  left: 2.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4.0625rem;
    top: 32rem;
    left: 5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.6875rem;
    top: 40rem;
    left: 9.25rem;
  }
`;

export const StyledSlideImage14 = styled(DefaultSlideImage)`
  width: 2.25rem;
  position: absolute;
  top: 6.25rem;
  left: 6.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4.5rem;
    top: 24rem;
    left: 13.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 6.3125rem;
    top: 36rem;
    left: 18.375rem;
  }
`;

export const StyledSlideImage15 = styled(DefaultSlideImage)`
  width: 0.8125rem;
  position: absolute;
  top: 9.375rem;
  left: 5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.625rem;
    top: 16.75rem;
    left: 10rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.3125rem;
    top: 35.75rem;
    left: 13.625rem;
  }
`;

export const StyledSlideImage16 = styled(DefaultSlideImage)`
  width: 2.25rem;
  position: absolute;
  left: 6.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4.5rem;
    top: 16.5rem;
    left: 12.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 6.3125rem;
    top: 31.75rem;
    left: 17.125rem;
  }
`;

export const StyledSlideImage17 = styled(DefaultSlideImage)`
  width: 0.75rem;
  position: absolute;
  left: 9.375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.5rem;
    left: 18.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.125rem;
    top: 26.125rem;
    left: 26rem;
  }
`;

export const StyledSlideImage18 = styled(DefaultSlideImage)`
  width: 1.125rem;
  position: absolute;
  top: 5rem;
  left: 5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.25rem;
    left: 13rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.1875rem;
    top: 20.5rem;
    left: 15.5rem;
  }
`;

export const StyledSlideImage19 = styled(DefaultSlideImage)`
  width: 1.25rem;
  position: absolute;
  top: -1.25rem;
  left: 5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.4375rem;
    left: 9.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.4375rem;
    top: 0;
    left: 13.75rem;
  }
`;
