import styled from "styled-components";
// Components
import { Box } from "components";

export const BackgroundImageContainer = styled(Box)`
  width: 100%;
  height: 4.5rem;
  position: relative;
  margin: -0.125rem 0;
  background: ${({ theme }) => theme.colors.bgDark};

  &::after {
    content: "";
    width: 100%;
    min-height: 40rem;

    position: absolute;
    bottom: -2rem;

    background: url("/images/home-page-main-bg.webp") center / 40rem no-repeat;

    z-index: ${({ theme }) => theme.zIndices.dropdown};
    transform: scaleY(1.15);

    ${({ theme }) => theme.mediaQueries.tablet} {
      min-height: 60rem;
      bottom: -1re;
      left: -1rem;
      background-size: cover;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      background-size: 90%;
      bottom: 1rem;
    }
  }
`;
