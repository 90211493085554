import styled from "styled-components";
// Components
import { Accordion, Text } from "components";

export const StyledAccordion = styled(Accordion)`
  padding: 0rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CaseStudiesMobileContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lineSecond};
`;

export const StyledFilterTitle = styled(Text)`
  cursor: pointer;
`;
