import styled from "styled-components";
import { clearfix, clearfixDisplayNone } from "styles";
// Components
import { StyledDefaultFlowItem } from "../../styled";
// Types
import { StyledThirdColumnMyProfileItemProps } from "./types";

export const StyledThirdColumnAuctionsItem = styled(StyledDefaultFlowItem)`
  margin-bottom: 0.875rem;

  :nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 3.125rem;
  }
`;

export const StyledThirdColumnMyProfileItem = styled(StyledDefaultFlowItem)<StyledThirdColumnMyProfileItemProps>`
  position: relative;
  margin-bottom: 0.375rem;

  ::after {
    ${({ connectorLinesSrc }) => (connectorLinesSrc ? clearfix : clearfixDisplayNone)};
    width: 2.5rem;
    height: 10.125rem;
    position: absolute;
    top: -4.375rem;
    left: 100%;
    background-image: ${({ connectorLinesSrc }) => `url("${connectorLinesSrc}")`};
    background-repeat: no-repeat;
    background-size: contain;
  }

  :nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 1.375rem;

    ::after {
      width: 7.3125rem;
      height: 29.375rem;
      top: -12.375rem;
      left: 100%;
    }
  }
`;

export const StyledThirdColumnUShopItem = styled(StyledDefaultFlowItem)`
  width: 3.875rem;
  height: 0.9375rem;
  margin-bottom: 0.25rem;

  :nth-last-of-type(1) {
    height: 1.1875rem;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10.8125rem;
    height: 2.75rem;
    margin-bottom: 0.875rem;

    :nth-last-of-type(1) {
      height: 3.5rem;
    }
  }
`;

export const StyledThirdColumnActivitiesItem = styled(StyledDefaultFlowItem)`
  height: 0.9375rem;
  margin-bottom: 0.375rem;

  :nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 2.75rem;
    margin-bottom: 1rem;
  }
`;
