export const siteWidth = 1680; //px

export const breakpointMap = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  laptopXL: 1920,
} as const;

export const breakpointsArray = ["320px", "375px", "425px", "768px", "1024px", "1440px", "1920px"];

export const breakpoints = Object.assign(breakpointsArray, {
  mobileS: breakpointsArray[0],
  mobileM: breakpointsArray[1],
  mobileL: breakpointsArray[2],
  tablet: breakpointsArray[3],
  laptop: breakpointsArray[4],
  laptopL: breakpointsArray[5],
  laptopXL: breakpointsArray[6],
});

export const mediaQueries = {
  mobileS: `@media screen and (min-width: ${breakpointsArray[0]})`,
  mobileM: `@media screen and (min-width: ${breakpointsArray[1]})`,
  mobileL: `@media screen and (min-width: ${breakpointsArray[2]})`,
  tabletS: `@media screen and (min-width: 630px)`,
  tablet: `@media screen and (min-width: ${breakpointsArray[3]})`,
  tabletSquare: `@media screen and (min-width: ${breakpointsArray[3]}) and (min-height: ${breakpointsArray[3]})`,
  laptopSquareHalfHeight: `@media screen and (min-width: ${breakpointsArray[4]}) and (min-height: 512px)`, // 1024px / 2 = 512px
  laptop: `@media screen and (min-width: ${breakpointsArray[4]})`,
  laptopM: `@media screen and (min-width: 1200px)`,
  laptopL: `@media screen and (min-width: ${breakpointsArray[5]})`,
  laptopXL: `@media screen and (min-width: ${breakpointsArray[6]})`,
  smallHeight: `@media screen and (max-height: 580px)`,
  mediumHeight: `@media screen and (min-height: 580px)`,
  semiLargeHeight: `@media screen and (min-height: ${breakpointsArray[5]})`,
  largeHeight: `@media screen and (min-height: ${siteWidth + 1}px)`,
} as const;

export const shadows = {
  card: "0px 6px 44px rgba(78, 94, 106, 0.16)",
  beauty:
    "0px 183px 73px rgba(94, 94, 94, 0.01), 0px 103px 62px rgba(94, 94, 94, 0.03), 0px 46px 46px rgba(94, 94, 94, 0.04), 0px 11px 25px rgba(94, 94, 94, 0.05), 0px 0px 0px rgba(94, 94, 94, 0.05)",
  colored: "0px 4px 114px rgba(130, 67, 227, 0.15)",
  button: "inset 0px 0px 15px rgba(255, 255, 255, 0.5)",
  userCaseItem: "0 0 0 rgba(78, 94, 106, 0.16)",
  userCaseItemHover: "0 6px 44px rgba(78,94,106,.16)",
  extraInfo: "0 4px 114px rgba(130,67,227,.15)",
  openPositionItem: "0 0 0 rgba(130, 67, 227, 0.15)",
  openPositionItemHover: "0 4px 18px rgba(130, 67, 227, 0.15)",
  caseStudiesListInputs: "0px 44px 130px 0px rgba(51, 51, 51, 0.40)",

  planetcoinBannerCategory:
    "-3.62px -3.62px 3.62px 0px rgba(52, 52, 52, 0.2), 3.62px 3.62px 27.13px 0px rgba(0, 0, 0, 0.25), -3.62px -3.62px 27.13px 0px rgba(0, 0, 0, 0.05) inset, 12.66px 3.62px 3.62px 0px rgba(52, 52, 52, 0.2) inset;",
  planetcoinShortSummaryRocket: "20px 54px 115px 0px rgba(0, 0, 0, 0.45);",
  planetcoinShortSummaryImageDropShadow: "drop-shadow(9.68px 5.38px 4.68px rgba(0, 0, 0, 0.2))",
  planetcoinPhasesConnectorLine: "drop-shadow(0px 24px 10px rgba(55, 52, 169, 0.3))",
  planetcoinPhasesConnectorLineSm: "0px 24px 24px 0px rgba(55, 52, 169, 0.3)",
  planetcoinShortSummaryCard:
    "0px 1.85px 3.15px 0px rgba(0, 0, 0, 0), 0px 8.15px 6.52px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.52px 25.48px 0px rgba(0, 0, 0, 0.01), 0px 64.82px 46.85px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.02)",
  planetcoinWireframe: "0px 24px 94px 0px rgba(0, 0, 0, 0.25)",
  planetcoinBannerImg2Sm:
    "-0.78px -0.78px 0.78px 0px rgba(52, 52, 52, 0.2), 0.78px 0.78px 5.83px 0px rgba(0, 0, 0, 0.25), -0.78px -0.78px 5.83px 0px rgba(0, 0, 0, 0.05) inset, 2.72px 0.78px 0.78px 0px rgba(52, 52, 52, 0.2) inset",
  planetcoinBannerImg2:
    "-2.25px -2.25px 16.86px 0px rgba(0, 0, 0, 0.05) inset, 7.87px 2.25px 2.25px 0px rgba(52, 52, 52, 0.2) inset",
  planetcoinBannerImg3Sm:
    "-0.94px -0.94px 0.94px 0px rgba(52, 52, 52, 0.2), 0.94px 0.94px 7.06px 0px rgba(0, 0, 0, 0.25), -0.94px -0.94px 7.06px 0px rgba(0, 0, 0, 0.05) inset, 3.29px 0.94px 0.94px 0px rgba(52, 52, 52, 0.2) inset",
  planetcoinBannerImg3: "-2px -2px 15px 0px rgba(0, 0, 0, 0.05) inset, 7px 2px 2px 0px rgba(52, 52, 52, 0.2) inset",
  planetcoinBannerImg4:
    "-3.62px -3.62px 27.13px 0px rgba(0, 0, 0, 0.05) inset, 12.66px 3.62px 3.62px 0px rgba(52, 52, 52, 0.2) inset",
  planetcoinBannerImg6: "",

  eStatesPointCard: "-15px 17px 45px rgba(27,189,228, 0.3)",
  eStatesPointCardSM: "-4px 4px 20px  rgba(27, 189, 228, 0.2)",
  eStatesBannerImgRight: "-15px 20px 40px rgba(94,94,94, 0.3)",
  eStatesBannerImgLeft: "10px 15px 40px rgba(94,94,94, 0.2)",
  eStatesProjectPhasesShadows: "0px 24px 24px 0px rgba(27, 189, 228, 0.30)",

  ugreatorProjectPhasesShadow: "0px 24px 24px 0px #3734a94d",
  ugreatorProjectPhasesIconShadow: "0px 1px 14px 0px #00000026",
  ugreatorWireframesShadow: "0px 4px 54px 2px #19548B21",

  chat: "0px 0px 14px 0px rgba(0, 0, 0, 0.13)",
} as const;

export const gradients = {
  textAccent: "linear-gradient(266.13deg, #A85AE7 36.68%, #6537E5 75.39%)",

  buttonLightFill: "linear-gradient(194.94deg, #FFFFFF 0.69%, #E0E0E0 89.47%)",
  buttonLightFillHover: "linear-gradient(194.94deg,#fff .69%,#c7c7c7 89.47%)",
  buttonLightStroke: "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
  buttonDarkStroke: "linear-gradient(180deg, rgba(244, 245, 245, 0.1) 0%, rgba(239, 239, 239, 0) 100%)",

  buttonAccent: "linear-gradient(58.44deg, #7433FF 14.81%, #DE5CFE 91.59%)",
  buttonAccentHover: "linear-gradient(58.44deg, #864CFF 14.81%, #E47BFE 91.59%)",
  buttonAccentActive: "linear-gradient(58.44deg, #631AFF 14.81%, #D943FE 91.59%)",

  bannerSlider: "linear-gradient(90deg, #0D0D0D 31.11%, rgba(0, 0, 0, 0) 90.37%)",

  planetCoinBtnBg:
    "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #58b873, #58b873)",
  planetCoinBtnBgHover: "linear-gradient(180deg,#8bcd9d 0,#7cc791 100%)",

  testimonialBg1: "linear-gradient(175.85deg, #f6aa93 2.8%, #f2b588 96.62%)",
  testimonialBg2: "linear-gradient(175.85deg, #695cbd 2.8%, #4a3ca6 96.62%)",
  testimonialBg3: "linear-gradient(180deg, rgba(50, 50, 50, 1) 0%, rgba(249, 133, 79, 1) 100%)",

  planetcoinBannerCategory: "linear-gradient(136deg, #353535 0%, rgba(38, 38, 38, 0.3) 100%)",
  planetcoinBannerPointDivider:
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.5) 50%,  rgba(255, 255, 255, 0) 85%, rgba(255, 255, 255, 0) 100%)",

  blogMainPost: "linear-gradient(180deg, rgba(187, 187, 187, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
  eStatesProjectPhasesGradients:
    "linear-gradient(265deg, rgba(27, 189, 228, 0.00) -0.03%, #1BBDE4 11.74%, #1BBDE4 52.19%, rgba(27, 189, 228, 0.00) 98.24%)",

  ugreatorProjectPhasesGradient:
    "linear-gradient(265.43deg, #fe9e05 0.54%, #ec3172 22.8%, #e40d9e 43.64%, #ae07fd 64.47%, #11a6fa 86.9%, #11a6fa 91.47%)",
  ugreatorUserFlowsHomepage: "linear-gradient(117.43deg, #2ACBD2 16.12%, #29BFE4 67.08%)",
  ugreatorUserFlowsHomepageBorder:
    "linear-gradient(117.43deg, rgba(42, 203, 210, 0.2) 16.12%, rgba(41, 191, 228, 0.2) 67.08%)",
  ugreatorUserFlowsAuctions: "linear-gradient(117.43deg, #29BFE4 16.12%, #29B0F9 67.08%)",
  ugreatorUserFlowsAuctionsBorder:
    "linear-gradient(117.43deg, rgba(41, 191, 228, 0.2) 16.12%, rgba(41, 176, 249, 0.2) 67.08%)",
  ugreatorUserFlowsMyProfile: "linear-gradient(117.43deg, #29B2F6 16.12%, #5484FB 67.08%)",
  ugreatorUserFlowsMyProfileBorder:
    "linear-gradient(117.43deg, rgba(41, 178, 246, 0.2) 16.12%, rgba(84, 132, 251, 0.2) 67.08%)",
  ugreatorUserFlowsUShop: "linear-gradient(117.43deg, #C62ADB 16.12%, #DB39AA 67.08%)",
  ugreatorUserFlowsUShopBorder:
    "linear-gradient(117.43deg, rgba(198, 42, 219, 0.2) 16.12%, rgba(219, 57, 170, 0.2) 67.08%)",
  ugreatorUserFlowsActivities: "linear-gradient(117.43deg, #F15A6C 16.12%, #FDA125 67.08%)",
  ugreatorUserFlowsActivitiesBorder:
    "linear-gradient(117.43deg, rgba(241, 90, 108, 0.2) 16.12%, rgba(253, 161, 37, 0.2) 67.08%)",
} as const;

export const transitions = {
  default: "all 0.3s ease",
  defaultWithPause_0_5s: "all 0.3s 0.5s ease-in-out",
  easeIn_0_2s: "all 0.2s ease-in",
  $easeInOut_0_2s: "0.2s ease-in-out",
  $easeInOut_0_5s: "0.5s ease-in-out",
  easeInOut_0_5s: "all 0.5s ease-in-out",
  easeInOut_0_8s: "all 0.8s ease-in-out",
  linear_0_2s: "all 0.2s linear",
  cubic_bezier_0_3s: "all .3s cubic-bezier(0.2, 1, 0.8, 1)",
  cubic_bezier_0_5s: "all .5s cubic-bezier(0.2, 1, 0.4, 1)",
  defaultMotion: {
    duration: 0.3,
    type: "easyInOut",
  },
  defaultParallaxMotion: {
    type: "spring",
    stiffness: 300,
    damping: 50,
    mass: 1,
    restDelta: 0.001,
    duration: 0.5,
  },
  linearMotion_0_2s: {
    type: "linear",
    duration: 0.2,
  },
  bounceEffect: {
    type: "spring",
    bounce: 0.6,
    duration: 0.8,
  },
  bannerTitleMotion: {
    type: "linear",
    duration: 2.25,
    delay: 1,
  },
  portfolioItemShowMotion: {
    duration: 0.75,
    type: "linear",
  },
  imageQuoteHideMotion: {
    type: "spring",
    duration: 0.3,
  },
  imageQuoteDelayShowMotion: {
    type: "spring",
    bounce: 0.4,
    duration: 0.5,
    delay: 0.5,
  },
  videoSlideMotion: {
    duration: 0.5,
    type: "spring",
  },
} as const;

export const radii = {
  semiSmall: "4px",
  small: "6px",
  semiMedium: "8px",
  medium: "16px",
  semiLarge: "20px",
  testimonialsInnerWrapper: "23px",
  large: "24px",
  xl: "32px",
  xxl: "40px",
  userFlowsItem: "50px",
  contactForm: "60px",
  header: "70px",
  cookies: "150px",
  circle: "50%",
} as const;

export const zIndices = {
  dropdown: 10,
  header: 50,
  tooltip: 101,
  buttonGradient: 1,
  buttonContent: 2,
} as const;

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
} as const;

export const fonts = {
  ubuntu: "Ubuntu, sans-serif",
  kalam: "Kalam, cursive",
} as const;

export default {
  siteWidth,
  breakpoints,
  mediaQueries,
  shadows,
  radii,
  zIndices,
  fonts,
  fontWeight,
  gradients,
  transitions,
};
