// Components + styled
import { Box, Flex, Text } from "components";
import { StyledFilterDropdown } from "../filter-dropdown/styled";
import { StyledFilterList, StyledFilterOption, StyledCheckbox } from "./styled";
// Context
import { useCaseStudyContext, useTranslation } from "context";
// Types
import { Category } from "services";
import { allCategories, allIndustries } from "context/case-studies-context/types";
import { CaseStudiesDropdownProps } from "./types";

const CaseStudiesDropdown: React.FC<CaseStudiesDropdownProps> = ({ type, filters }) => {
  const { t } = useTranslation();
  const { selectedFilters, handleFilterChange, selectedAll } = useCaseStudyContext();

  const filteredOptions = filters ? filters[type].options : [];

  const isOptionSelected = (option: Category) => {
    return selectedFilters[type].some(optionItem => optionItem.id === option.id);
  };

  const isAllOption = (option: string | Category) => {
    const industryCheck =
      option === allIndustries &&
      filters?.industry &&
      selectedFilters.industry.length === filters.industry.options.length;
    const categoryCheck =
      option === allCategories &&
      filters?.category &&
      selectedFilters.category.length === filters.category.options.length;

    return industryCheck || categoryCheck;
  };

  return (
    <Box mt="-1rem">
      <StyledFilterDropdown key={type}>
        <StyledFilterList>
          <StyledFilterOption
            isSelected={selectedAll[type]}
            onClick={() => handleFilterChange({ type, filters, selectedOption: `All ${type}` })}
          >
            <Flex alignItems="center">
              <Flex alignItems="center" minWidth="1.25rem">
                <StyledCheckbox checked={selectedAll[type]} />
              </Flex>

              <Text color="textMain" mx="0.75rem">
                {t(`All ${type}`)}
              </Text>
            </Flex>
          </StyledFilterOption>
          {filteredOptions.map(option => {
            const isSelected = isOptionSelected(option) || isAllOption(option);
            const isChecked = selectedFilters[type].some(optionItem => optionItem.id === option.id);

            return (
              <StyledFilterOption
                key={option.id}
                onClick={() => handleFilterChange({ type, filters, selectedOption: option })}
                isSelected={!!isSelected}
              >
                <Flex alignItems="center">
                  <Flex alignItems="center" minWidth="1.25rem">
                    <StyledCheckbox checked={isChecked} />
                  </Flex>

                  <Text color="textMain" mx="0.75rem">
                    {t(option.title)}
                  </Text>
                </Flex>
              </StyledFilterOption>
            );
          })}
        </StyledFilterList>
      </StyledFilterDropdown>
    </Box>
  );
};

export default CaseStudiesDropdown;
