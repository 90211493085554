export const SHORT_SUMMARY_LIST = [
  { value: "$850K", label: "Investments raised", description: "After we made redesign of the project" },
  { value: "+23%", label: "New registered users", description: "Increased flow of new customers" },
  {
    value: "+18%",
    label: "Amount of new transactions",
    description: "New users have started trusting the product more",
  },
  { value: "-47%", label: "Complaints of new customers", description: "Reduced support department workload" },
];
