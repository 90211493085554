import styled, { css } from "styled-components";
import { border, opacity } from "styled-system";
import { clearfix } from "styles";
// Components + styles
import { Flex } from "components";
// Types
import { BoxItemStyledProps } from "./types";

export const EStatesBox = styled(Flex)<BoxItemStyledProps>`
  position: relative;
  width: 150px;
  height: 63px;
  padding: 0.5rem 0.7rem;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.medium};
  border: 2px solid ${({ theme }) => theme.colors.textMain};
  background: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? theme.colors[$backgroundColor] : theme.colors.white};

  ${({ isMultiply }) =>
    isMultiply &&
    css`
      ::before {
        ${clearfix}
        position: absolute;
        width: 90%;
        height: 1.5rem;
        top: -1.1rem;
        left: 0.5rem;
        background: url("/images/e-states-user-flow-multiply-box.svg");
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
      }
    `}

  ${opacity}
  ${border}
`;
