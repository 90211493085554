export const PROJECT_PHASES_LIST = [
  {
    step: 1,
    title: "Research",
    description: "First we conducted a competitor analysis and interviews",
    bgSrc: "/images/project-phases-step-1.svg",
  },
  {
    step: 2,
    title: "Wireframing",
    description: "Sketched out quick concepts and worked out the structure",
    bgSrc: "/images/project-phases-step-2.svg",
  },
  {
    step: 3,
    title: "Design",
    description: "Design creation based on previously collected data",
    bgSrc: "/images/project-phases-step-3.svg",
  },
  {
    step: 4,
    title: "Development",
    description: "Handed over our layouts for further development",
    bgSrc: "/images/project-phases-step-4.svg",
  },
];
