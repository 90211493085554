import styled from "styled-components";
// Components
import { Flex } from "components";

export const StyledCopyrightsWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.lineDark};
  padding-top: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-top: 2.19rem;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;
