import React from "react";
// Context
import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "eStatesPrimary", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg width="187" height="65" viewBox="0 0 187 65" {...props}>
      <path
        d="M185.013 1C185.013 0.454911 185.455 0.0130291 186 0.0130291C186.545 0.0130291 186.987 0.454911 186.987 1H185.013ZM186.987 1V16.4733H185.013V1H186.987ZM166.261 37.1997H20.7394V35.2258H166.261V37.1997ZM1.98697 55.9522V76H0.0130291V55.9522H1.98697ZM20.7394 37.1997C10.3827 37.1997 1.98697 45.5955 1.98697 55.9522H0.0130291C0.0130291 44.5053 9.29254 35.2258 20.7394 35.2258V37.1997ZM186.987 16.4733C186.987 27.9202 177.707 37.1997 166.261 37.1997V35.2258C176.617 35.2258 185.013 26.83 185.013 16.4733H186.987Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
