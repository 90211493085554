import styled from "styled-components";

export const CaseStudiesFilterCount = styled.div`
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.error};
  border-radius: ${({ theme }) => theme.radii.circle};

  padding: 0.125rem;
  min-width: 1rem;
  max-height: 1rem;
`;
