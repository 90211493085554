export const PROJECT_PHASES_LIST = [
  {
    step: 1,
    title: "Research",
    description: "First we conducted a competitor analysis and interviews",
    icon: "/images/e-states-project-phases-point-1.svg",
  },
  {
    step: 2,
    title: "Wireframing",
    description: "Sketched out quick concepts and worked out the structure",
    icon: "/images/e-states-project-phases-point-2.svg",
  },
  {
    step: 3,
    title: "Design",
    description: "Design creation based on previously collected data",
    icon: "/images/e-states-project-phases-point-3.svg",
  },
  {
    step: 4,
    title: "Development",
    description: "Handed over our layouts for further development",
    icon: "/images/e-states-project-phases-point-4.svg",
  },
];
