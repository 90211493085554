import styled, { css } from "styled-components";
import { clearfix } from "styles";
// Components
import { Box, Flex, Text } from "components";
import {
  StyledPlanetcoinBannerWrapper,
  StyledBannerPointItem,
  StyledBannerPointItemWrapper,
  StyledBannerPointsList,
  StyledBannerStatisticValue,
  StyledPlanetcoinBannerSubtitle,
  StyledStatisticItem,
} from "pages/case-pages/components/planetcoin/components/planetcoin-banner/styled";

const positionAndWidth = css`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const EStatesBannerWrapper = styled(StyledPlanetcoinBannerWrapper)`
  background:
    0% 42% repeat url("/images/e-states-banner-bg.webp"),
    ${({ theme }) => theme.colors.transparent};
  background-size: 65%;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    background:
      0% 28% no-repeat url("/images/e-states-banner-bg.webp"),
      ${({ theme }) => theme.colors.transparent};
    background-size: 100%;
  }

  ${({ theme }) => theme.mediaQueries.mobileM} {
    background-position-y: 29%;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-position-y: 41%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-top: 4.375rem;
    background-position-y: 26%;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    background-size: 60%;
    background-repeat: no-repeat;
    background-repeat: repeat-x;
  }

  ${({ theme }) => theme.mediaQueries.laptopXL} {
    background-position-y: 39%;
    background-size: 60%;
  }

  ${({ theme }) => theme.mediaQueries.semiLargeHeight} {
    min-height: 50vh;
  }

  ::before {
    ${clearfix}
    ${positionAndWidth}
    height: 50%;
    background: ${({ theme }) => theme.colors.transparent};
  }

  ::after {
    ${clearfix}
    ${positionAndWidth}
    top: 0;
    z-index: ${({ theme }) => theme.zIndices.buttonGradient};

    ${({ theme }) => theme.mediaQueries.mobileS} {
      background: url("/images/e-states-banner-gradient.svg") -125% 27% / 95% repeat-x;
    }

    ${({ theme }) => theme.mediaQueries.tablet} {
      background-position: 0% 35%;
      background-size: 100%;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      background-repeat: no-repeat;
      background-position: 50% 30%;
      background-size: 110%;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      background-position: 60% 30%;
      background-size: 100%;
    }

    ${({ theme }) => theme.mediaQueries.laptopXL} {
      background-position: center 30%;
      background-size: contain;
    }
  }
`;

export const EStatesBannerSubtitle = styled(StyledPlanetcoinBannerSubtitle)``;

export const EStatesBannerCategory = styled(Box)`
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: ${({ theme }) => theme.radii.large};
  background: ${({ theme }) => theme.colors.eStatesBannerCategory};
`;

export const EStatesStatisticsList = styled(Flex)`
  justify-content: space-between;
  margin: 0 auto;
  z-index: ${({ theme }) => theme.zIndices.header};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    position: relative;
    padding: 1rem 0rem;

    border-radius: ${({ theme }) => theme.radii.medium};
    box-shadow: ${({ theme }) => theme.shadows.eStatesPointCardSM};

    ::before {
      ${clearfix}
      ${positionAndWidth}
      border-radius: ${({ theme }) => theme.radii.semiMedium};
      background: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme }) => theme.mediaQueries.mobileM} {
    padding: 1.225rem 0.8rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 2.625rem 1rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    box-shadow: ${({ theme }) => theme.shadows.eStatesPointCard};
  }
`;

export const EStatesStatisticItem = styled(StyledStatisticItem)`
  ${({ theme }) => theme.mediaQueries.mobileS} {
    width: 100%;
  }

  ::after {
    background: ${({ theme }) => theme.colors.textThird};

    ${({ theme }) => theme.mediaQueries.mobileS} {
      display: block;
    }
  }
`;

export const EStatesBannerStatisticValue = styled(StyledBannerStatisticValue)`
  color: ${({ theme }) => theme.colors.textMain};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    font-size: 1.125rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 2.125rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    font-weight: 700;
  }
`;

export const EStatesBannerStatisticLabel = styled(Text)`
  margin-top: 0.3rem;
  ${({ theme }) => theme.mediaQueries.mobileS} {
    font-size: 0.9rem;
    line-height: 1.2;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const EStatesBannerPointsList = styled(StyledBannerPointsList)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    gap: 30px;
  }
`;

export const EStatesBannerPointItemWrapper = styled(StyledBannerPointItemWrapper)`
  border-radius: ${({ theme }) => theme.radii.medium};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    box-shadow: ${({ theme }) => theme.shadows.eStatesPointCardSM};
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    box-shadow: ${({ theme }) => theme.shadows.eStatesPointCard};
  }
`;

export const EStatesBannerPointItem = styled(StyledBannerPointItem)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 4.5rem 3.375rem;
  }
`;
