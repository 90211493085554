import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
// Components + styling
import { Box, Heading, Text } from "components";
import { UgreatorProjectPhasesConnectorLineIcon } from "components/svg";
import {
  StyledUgreatorDesktopConnectorImage,
  StyledUgreatorDesktopConnectorImageWrapper,
  StyledUgreatorProjectPhaseItemMotion,
  StyledUgreatorProjectPhaseItemWrapper,
  StyledUgreatorProjectPhasesListWrapper,
} from "./styled";
// Animations
import { animatePhase } from "theme/animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { UGREATOR_PROJECT_PHASES_LIST } from "../../constants";

const UgreatorProjectPhasesList: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const phasesListRef = useRef<HTMLDivElement | null>(null);
  const isPhasesListInView = useInView(phasesListRef, { once: true, amount: 1 });

  const animatedItem = animatePhase(1, isDesktop);

  return (
    <StyledUgreatorProjectPhasesListWrapper ref={phasesListRef}>
      {UGREATOR_PROJECT_PHASES_LIST.map(({ step, title, description, icon }, index) => {
        const animatedProjectPhases = animatePhase(index, isDesktop);

        return (
          <StyledUgreatorProjectPhaseItemWrapper key={step}>
            <StyledUgreatorProjectPhaseItemMotion
              src={icon}
              initial="hidden"
              animate={isDesktop ? (isPhasesListInView ? "visible" : "hidden") : ""}
              whileInView={!isDesktop ? "visible" : ""}
              viewport={!isDesktop ? { once: true, amount: 0.3 } : {}}
              variants={animatedProjectPhases}
            >
              <Box
                width={{ _: "calc(100% - 3.75rem)", laptop: "fit-content" }}
                mx={{ _: "0.5rem", laptop: "0" }}
                pb="2.625rem"
              >
                <Heading scale="h3" as="h3" mb={{ _: "0.25rem", laptop: "0" }}>
                  {t(title)}
                </Heading>
                <Text textScale="p18Regular" color="textFifth" minHeight={{ _: "3.75rem", tablet: "initial" }}>
                  {t(description)}
                </Text>
              </Box>
            </StyledUgreatorProjectPhaseItemMotion>
          </StyledUgreatorProjectPhaseItemWrapper>
        );
      })}

      {isDesktop && isPhasesListInView && (
        <>
          <StyledUgreatorDesktopConnectorImageWrapper>
            <UgreatorProjectPhasesConnectorLineIcon width="100%" />
          </StyledUgreatorDesktopConnectorImageWrapper>
          <motion.div
            initial="hidden"
            animate={isDesktop ? (isPhasesListInView ? "visible" : "hidden") : ""}
            whileInView={!isDesktop ? "visible" : ""}
            viewport={!isDesktop ? { once: true, amount: 0.3 } : {}}
            variants={animatedItem}
          >
            <StyledUgreatorDesktopConnectorImage
              isHideSkeleton
              src="/images/case-studies/ugreator/ugreator-project-phases-icons-image.webp"
              aspectRatio={2.5}
            />
          </motion.div>
        </>
      )}
    </StyledUgreatorProjectPhasesListWrapper>
  );
};

export default UgreatorProjectPhasesList;
