import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 76 76" {...props} color="transparent">
      <path
        opacity=".5"
        d="M40.541 58.317a1 1 0 0 0-1.414 1.414l1.414-1.414Zm15.106.707.707.707-.707-.707ZM16.115 19.492l.707.707-.707-.707Zm-.707 16.52a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM35.88 15.54l.707.707a1 1 0 0 0 0-1.414l-.707.707Zm-15.813 0-.707-.707.707.707ZM59.6 39.259l.707-.708a1 1 0 0 0-1.414 0l.707.707Zm0 15.812-.707-.707.707.707Zm-31.015-33.65a1 1 0 1 0 1.414 1.414l-1.414-1.415Zm23.72 23.719a1 1 0 0 0 1.413 1.414l-1.414-1.414ZM19.36 14.832l-3.954 3.953 1.415 1.414 3.953-3.953-1.414-1.414Zm36.993 44.9 3.953-3.954-1.414-1.414-3.953 3.953 1.414 1.414Zm-17.227 0c1.843 1.843 3.296 3.298 4.583 4.28 1.31 1 2.566 1.603 4.03 1.603v-2c-.851 0-1.685-.329-2.817-1.193-1.155-.881-2.498-2.221-4.382-4.105l-1.414 1.414Zm15.813-1.415c-1.884 1.884-3.227 3.224-4.382 4.105-1.133.864-1.966 1.193-2.818 1.193v2c1.465 0 2.72-.603 4.03-1.603 1.288-.982 2.74-2.437 4.584-4.28l-1.414-1.415ZM15.408 18.785c-1.844 1.844-3.3 3.296-4.281 4.583-1 1.31-1.603 2.566-1.603 4.03h2c0-.85.329-1.684 1.193-2.817.881-1.155 2.221-2.498 4.105-4.382l-1.414-1.414Zm1.414 15.813c-1.884-1.884-3.224-3.227-4.105-4.382-.864-1.132-1.193-1.966-1.193-2.817h-2c0 1.464.603 2.72 1.603 4.03.982 1.287 2.437 2.74 4.28 4.583l1.415-1.414Zm19.766-19.766c-1.844-1.844-3.296-3.3-4.583-4.281-1.31-1-2.566-1.603-4.03-1.603v2c.85 0 1.684.329 2.817 1.193 1.155.882 2.498 2.222 4.382 4.105l1.414-1.414Zm-15.813 1.414c1.884-1.883 3.227-3.223 4.382-4.105 1.132-.864 1.966-1.193 2.817-1.193v-2c-1.464 0-2.72.603-4.03 1.603-1.287.982-2.74 2.437-4.583 4.28l1.414 1.415Zm38.118 23.72c1.883 1.883 3.223 3.226 4.105 4.381.864 1.133 1.193 1.966 1.193 2.818h2c0-1.465-.603-2.72-1.603-4.03-.982-1.288-2.437-2.74-4.281-4.584l-1.414 1.414Zm1.414 15.812c1.844-1.843 3.299-3.296 4.28-4.583 1-1.31 1.604-2.566 1.604-4.03h-2c0 .85-.33 1.685-1.193 2.817-.882 1.155-2.222 2.498-4.105 4.382l1.414 1.414ZM35.174 14.832l-6.59 6.588L30 22.835l6.589-6.589-1.414-1.414Zm23.719 23.72-6.589 6.588 1.414 1.414 6.589-6.589-1.414-1.414Z"
        fill={theme.colors[color]}
      />
      <path
        d="m16.532 63.735 2.192-.73 6.776-2.26c2.117-.705 3.175-1.058 4.17-1.532a17.916 17.916 0 0 0 3.311-2.046c.87-.678 1.659-1.467 3.236-3.045L59.51 30.831l2.533-2.534c4.198-4.197 4.198-11.003 0-15.2-4.197-4.198-11.003-4.198-15.2 0l-2.534 2.533L21.017 38.92c-1.578 1.578-2.367 2.367-3.045 3.236a17.914 17.914 0 0 0-2.046 3.311c-.475.996-.827 2.054-1.533 4.17l-2.259 6.777-.73 2.192m5.128 5.128-2.193.731a2.898 2.898 0 0 1-3.666-3.666l.73-2.193m5.129 5.128-5.129-5.128"
        stroke={theme.colors[color]}
        strokeWidth="2"
      />
      <path
        opacity=".5"
        d="M44.309 15.63s.317 5.383 5.067 10.134c4.75 4.75 10.134 5.067 10.134 5.067"
        stroke={theme.colors[color]}
        strokeWidth="2"
      />
    </Svg>
  );
};

export default Icon;
