import styled from "styled-components";
// Components
import { Flex, Link } from "components";
// Types
import { Colors } from "theme/types";

export const StyledHeaderSocialsLink = styled(Link)<{ iconName: string }>`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.circle};
  background: ${({ theme, iconName }) => theme.colors[`${iconName}Icon` as keyof Colors]};
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 2.25rem;
    height: 2.25rem;
  }

  &:hover,
  &:focus {
    background: ${({ theme, iconName }) => theme.colors[`${iconName}IconHover` as keyof Colors]};
  }
`;

export const StyledFlex = styled(Flex)`
  gap: 1rem;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    gap: 0.37rem;
  }
`;
