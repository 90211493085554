import { useState } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
// Components + styling
import { getHeaderDropVariants } from "components/header/animations";
// Constants
import { headerAnimationType } from "components/header/constants";
import { BLOCK_ID } from "configs";
// Hooks
import { useMatchBreakpoints } from "hooks";

export const useMotionAnimations = () => {
  const [headerAnimation, setHeaderAnimation] = useState<keyof typeof headerAnimationType>(headerAnimationType.static);
  const { isLargeDesktop } = useMatchBreakpoints();
  const headerAnimationsVariants = getHeaderDropVariants(isLargeDesktop);

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", handleHeaderAnimation);

  function handleHeaderAnimation(latest: number) {
    const servicesSection = document.getElementById(BLOCK_ID.services);
    const header = document.getElementById("header");

    if (servicesSection && header) {
      const rect = servicesSection.getBoundingClientRect();
      const headerRect = header.getBoundingClientRect();

      if (latest === 0) {
        setHeaderAnimation(headerAnimationType.initial);
      }

      if (latest > 0) {
        setHeaderAnimation(headerAnimationType.hidden);
      }

      if (latest > rect.height / 1.5) {
        setHeaderAnimation(headerAnimationType.fixed);
      }

      if (latest > rect.top && +rect.bottom.toFixed() < headerRect.height) {
        setHeaderAnimation(headerAnimationType.drop);
      }
    } else {
      toggleHeaderAfterScroll(latest);
    }
  }

  const toggleHeaderAfterScroll = (latestScrollVal: number) => {
    if (latestScrollVal > 0) {
      setHeaderAnimation(headerAnimationType.drop);
    } else {
      setHeaderAnimation(headerAnimationType.initial);
    }
  };

  return {
    headerAnimation,
    headerAnimationsVariants,
  };
};
