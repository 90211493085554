import { useTheme } from "styled-components";
// Components
import { BoxItem } from "..";
// Types
import { EStatesUserFlowItemProps } from "./types";

const EStatesUserFlowItem: React.FC<EStatesUserFlowItemProps> = ({
  text,
  isShowTopLine,
  opacity,
  isBorder,
  backgroundColor,
  isShowLeftLine,
  isShowRightLine,
  topLineColor,
  isMultiply,
  children,
}) => {
  const theme = useTheme();

  const baseBorder = `2px solid ${theme.colors.eStatesUserFlowBorder}`;

  return (
    <BoxItem
      isShowLeftLine={isShowLeftLine}
      isShowRightLine={isShowRightLine}
      text={text}
      isShowTopLine={isShowTopLine}
      opacity={opacity}
      border={isBorder ? baseBorder : null}
      $backgroundColor={backgroundColor}
      topLineColor={topLineColor}
      isMultiply={isMultiply}
    >
      {children}
    </BoxItem>
  );
};

export default EStatesUserFlowItem;
