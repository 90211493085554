import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, Flex, Image, Text } from "components";
import { LinkedInIcon } from "components/svg";
// Types
import { Colors } from "theme/types";

export const StyledTeamMemberItem = styled(motion.div)`
  margin: 0 0.75rem;
`;

export const StyledImageLottieAnimationWrapper = styled(Box)`
  opacity: 0;
`;

export const StyledTeamMemberImage = styled(Image)`
  border-radius: ${({ theme }) => theme.radii.large};
  background-color: ${({ theme }) => theme.colors.transparent};
  transition: ${({ theme }) => theme.transitions.default};

  img {
    border-radius: ${({ theme }) => theme.radii.large};
    mix-blend-mode: multiply;
  }
`;

export const StyledTeamMemberContent = styled(Box)<{ hoverColor?: keyof Colors; isImageLoading?: boolean }>`
  width: calc(26rem / 2.6);
  position: relative;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(26rem / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 26rem;
  }

  &:hover,
  &:focus,
  &:active {
    ${StyledImageLottieAnimationWrapper} {
      opacity: ${({ isImageLoading }) => (isImageLoading ? 0 : 1)};
    }

    ${StyledTeamMemberImage} {
      background-color: ${({ theme, hoverColor, isImageLoading }) =>
        !isImageLoading && hoverColor && theme.colors[hoverColor]};
    }
  }
`;

export const StyledLinkedInText = styled(Text)`
  padding: 0 0.25rem;
  background: text ${({ theme }) => theme.colors.linkedInLink};
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0 0.625rem;
  }
`;

export const StyledLinkedInIcon = styled(LinkedInIcon)`
  width: 1rem;
  height: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

export const StyledLinkedInLinkWrapper = styled(Flex)`
  border-left: none;
  padding: 0;
  margin: 0 0 0.25rem 0;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    border-left: 1px solid ${({ theme }) => theme.colors.textThird};
    padding: 0 1rem;
    margin: 0 1rem;
  }

  ${StyledLinkedInIcon} {
    path {
      transition: ${({ theme }) => theme.transitions.default};
    }
  }

  &:hover {
    ${StyledLinkedInIcon} {
      path {
        &:first-child {
          fill: ${({ theme }) => theme.colors.linkedInLinkHover};
        }
      }
    }

    ${StyledLinkedInText} {
      background: text ${({ theme }) => theme.colors.linkedInLinkHover};
    }
  }
`;
