import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledBatmanAnimation = styled(Lottie)`
  width: calc(100rem / 16 / 2.6);
  position: absolute;
  left: calc(30rem / 16 / 2.6);
  top: calc(30rem / 16 / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(100rem / 16 / 1.6);
    top: calc(30rem / 16 / 1.6);
    left: calc(30rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 6.25rem;
    top: 1.875rem;
    left: 1.875rem;
  }
`;

export const StyledMaskAnimation = styled(Lottie)`
  width: calc(166rem / 16 / 2.6);
  position: absolute;
  top: calc(96rem / 16 / 2.6);
  left: calc(141rem / 16 / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(166rem / 16 / 1.6);
    top: calc(96rem / 16 / 1.6);
    left: calc(141rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10.375rem;
    top: 6rem;
    left: 8.8125rem;
  }
`;
