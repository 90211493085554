import styled, { css } from "styled-components";
import { space, typography } from "styled-system";
import { getFontSettings } from "components/text";
// Types
import { InputProps, scales, ThemedProps, BorderProps, scalesToTextScale } from "./types";

const getHeight = ({ scale }: ThemedProps) => {
  switch (scale) {
    case scales.SM:
      return "1.75rem";
    case scales.LG:
      return "3.125rem";
    case scales.MD:
      return "2rem";
  }
};

const getBorderStyles = ({ error, isTouched, borderColor, isFullyWrapBorder, theme }: BorderProps) => {
  if (error && isTouched) {
    return css`
      ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors.error};
      &:focus {
        ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors.error} !important;
      }
      &:hover {
        ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors.error} !important;
      }
    `;
  } else if (borderColor) {
    return css`
      ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors[borderColor]};
      &:focus {
        ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${borderColor} !important;
      }
      &:hover {
        ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${borderColor} !important;
      }
    `;
  }

  return css`
    ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors.lineLight};
  `;
};

export const Input = styled.input<InputProps>`
  width: 100%;
  height: ${({ isFullyWrapBorder }) => (isFullyWrapBorder ? "3.75rem" : getHeight)};
  display: block;
  padding: ${({ isFullyWrapBorder }) => (isFullyWrapBorder ? "1rem 1.5rem" : "0.5rem 1.5rem")};
  outline: 0;
  border: none;
  border-radius: ${({ theme, isFullyWrapBorder }) => (isFullyWrapBorder ? theme.radii.xl : "0")};
  font-family: ${({ theme }) => theme.fonts.ubuntu};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 1.875rem;
  line-height: 1.2;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.textMain)};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? theme.colors[backgroundColor] : theme.colors.transparent};
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme, scale, isFullyWrapBorder }) =>
    scale && getFontSettings({ theme, textScale: isFullyWrapBorder ? "p18Regular" : scalesToTextScale[scale] })};

  &::placeholder {
    font-weight: ${({ theme }) => theme.fontWeight.light};
    color: ${({ theme }) => theme.colors.textThird};
    opacity: 0.6;
  }

  &:disabled {
    border: none;
    color: ${({ theme }) => theme.colors.textFifth};
    background-color: ${({ theme }) => theme.colors.textSecond};
    box-shadow: none;
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    ${({ isFullyWrapBorder, theme }) => `
      ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors.textFourth}
    `};
  }

  &:hover:not(:disabled) {
    ${({ isFullyWrapBorder, theme }) => `
      ${isFullyWrapBorder ? "border" : "border-bottom"}: 1px solid ${theme.colors.textFourth}
    `};
  }
  ${getBorderStyles}

  ${typography}
  ${space}
`;

Input.defaultProps = {
  scale: "md",
};
