import React from "react";
// Components + styling
import { Box, Link } from "components";
import { StyledFooterLink, StyledSectionTitle } from "components/footer/styled";
import { StyledFooterSocialItem } from "./styled";
// Context
import { useTranslation } from "context";
// Constants
import { FOOTER_SOCIALS_LIST } from "components/footer/constants";

const FooterSocial: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <StyledSectionTitle>{t("Social")}</StyledSectionTitle>
      {FOOTER_SOCIALS_LIST.map(({ Icon, label, link }) => (
        <StyledFooterSocialItem key={label}>
          <Link href={link} external px="0.5rem">
            <Icon width="1.4375rem" />
          </Link>
          <StyledFooterLink href={link} external>
            {t(label)}
          </StyledFooterLink>
        </StyledFooterSocialItem>
      ))}
    </Box>
  );
};

export default FooterSocial;
