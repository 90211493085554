import styled from "styled-components";
import { space, typography, layout } from "styled-system";
// Types
import { FormProps } from "./types";

export const Form = styled.form<FormProps>`
  ${space}
  ${typography}
  ${layout}
`;
