import { useEffect } from "react";
// Utils
import { scrollToElementById } from "utils";

const useTableContent = () => {
  useEffect(() => {
    const tableOfContentsList = document.querySelectorAll(".table-of-content-item");

    tableOfContentsList.forEach(element => {
      element.addEventListener("click", handleTableContentClick);
    });

    return () => {
      tableOfContentsList.forEach(element => {
        element.removeEventListener("click", handleTableContentClick);
      });
    };
  }, []);

  const handleTableContentClick = (e: Event) => {
    const text = (e?.target as HTMLParagraphElement).textContent;
    if (text) {
      const id = text.replaceAll(" ", "-");

      scrollToElementById(id);
    }
  };
};

export default useTableContent;
