import React from "react";
// Components + styling
import { Box, Flex, Text } from "components";
import { EmailIcon, LocationIcon, PhoneIcon } from "components/svg";
import { StyledFooterLink, StyledSectionTitle } from "components/footer/styled";
// Context
import { useTranslation } from "context";
// Constants
import { FOOTER_PHONE_LIST } from "components/footer/constants";

const FooterContacts: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <StyledSectionTitle>{t("Contacts")}</StyledSectionTitle>

      <Flex alignItems="center" mb="1rem">
        <EmailIcon width="1.5rem" color="white" />
        <StyledFooterLink href="mailto:hello@idealogic.design" external mx="0.5rem">
          hello@idealogic.design
        </StyledFooterLink>
      </Flex>

      <Flex alignItems="center" mb="1rem">
        <LocationIcon width="1.5rem" color="white" />
        <Text textScale="p14Regular" color="textFourth" mx="0.5rem" maxWidth={{ _: "100%", tablet: "12.5rem" }}>
          Księcia Witolda 48/1, Wroclaw, Lower Silesia 50-203
        </Text>
      </Flex>

      <Flex alignItems="flex-start">
        <Box>
          <PhoneIcon width="1.5rem" color="white" />
        </Box>
        <Box mx="0.5rem">
          {FOOTER_PHONE_LIST.map(({ label, tel }) => (
            <StyledFooterLink key={label} href={`tel:${tel}`} external mb={{ _: "0.25rem" }}>
              {label}
            </StyledFooterLink>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};

export default FooterContacts;
