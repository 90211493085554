import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 125 10" {...props} animationType="inputLabelUnderline" color="transparent">
      <path
        d="M1 3.62026C38.838 1.78669 116.411 -0.902552 124 3.00907C94.8046 2.49974 35.5918 2.58122 32.3033 6.98179C49.2725 6.16687 84.9815 5.0871 92.0643 7.28739C99.147 9.48767 65.1877 9.25108 47.7969 8.33429"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
