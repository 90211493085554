import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";
// Components
import { Image, Svg } from "components";

export const StyledUgreatorProjectPhasesListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 90rem;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin: 5.625rem auto 3.125rem;
    position: relative;
  }
`;

export const StyledUgreatorDesktopConnectorImage = styled(Image)`
  width: 80%;
  height: auto;
  position: absolute;
  top: 9.75rem;
  left: 1.25rem;

  ${({ theme }) => theme.mediaQueries.laptopM} {
    width: 80%;
    top: 5.625rem;
    left: 1.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 100%;
    top: -21.875rem;
    left: -7.5rem;

    img {
      margin: 0 auto;
    }
  }
`;

export const StyledUgreatorDesktopConnectorImageWrapper = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 7.75rem;
  left: 0;

  ${Svg} {
    filter: ${({ theme }) => theme.shadows.planetcoinPhasesConnectorLine};
  }

  ${({ theme }) => theme.mediaQueries.laptopM} {
    top: 3rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -21.25rem;
    left: 0;

    img {
      margin: 0 auto;
    }
  }
`;

export const StyledUgreatorProjectPhaseItemMotion = styled(motion.div)<{ src?: string }>`
  width: 100%;
  display: flex;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.4375rem;
    min-height: 16.25rem;
    flex-direction: column;
    justify-content: flex-end;
    background-size: 50% 50%;
  }

  ::before {
    ${clearfix}
    width: 2.75rem;
    height: 2.75rem;
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.dropdown + 1};
    border-radius: ${({ theme }) => theme.radii.circle};
    background-image: ${({ src }) => `url("${src}")`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadows.ugreatorProjectPhasesIconShadow};

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: none;
    }
  }
`;

export const StyledUgreatorProjectPhaseItemWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: initial;
    margin-top: 6rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin: 0;
  }

  &:nth-of-type(1) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: 7rem;
      left: 1rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: 5.25rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: 6.875rem;
      left: 3.75rem;
    }

    ::before {
      ${({ theme }) => theme.mediaQueries.laptop} {
        margin-bottom: 3rem;
      }
    }

    ${StyledUgreatorProjectPhaseItemMotion} {
      ::after {
        ${clearfix}
        width: 0.1875rem;
        height: 300%;
        position: absolute;
        top: 0;
        left: 1.125rem;
        z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
        rotate: 180deg;
        background: ${({ theme }) => theme.gradients.ugreatorProjectPhasesGradient};
        box-shadow: ${({ theme }) => theme.shadows.ugreatorProjectPhasesShadow};

        ${({ theme }) => theme.mediaQueries.laptop} {
          display: none;
        }
      }
    }
  }

  &:nth-of-type(2) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: 2.25rem;
      left: 1.625rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: 1rem;
      left: 1.625rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: 2.125rem;
      left: 1.875rem;
    }
  }

  &:nth-of-type(3) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: -2.25rem;
      left: 1.625rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: -4rem;
      left: 3.125rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: -4rem;
      left: 2.75rem;
    }
  }

  &:nth-of-type(4) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      top: -13.125rem;
      left: -5.5rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopM} {
      top: -16.25rem;
      left: -3rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      top: -15.875rem;
      left: -2.5rem;
    }
  }
`;
