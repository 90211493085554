import styled from "styled-components";
import { grid } from "styled-system";
// Container
import { Box } from "../container";
// Types
import { GridProps } from "../types";

export const Grid = styled(Box)<GridProps>`
  display: grid;
  ${grid}
`;
