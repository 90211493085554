// Constants
import { breakpointMap } from "theme/base";
// Types
import { Settings } from "react-slick";

export const sliderSettings: Settings = {
  dots: false,
  arrows: false,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  // Lib use breakpoint as screen max-width value
  responsive: [
    {
      breakpoint: breakpointMap.laptopL - 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpointMap.tablet - 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpointMap.mobileM - 1,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
