import styled, { css } from "styled-components";
// Components
import { Box } from "components";
// Types
import { StyledWireframeImageProps } from "./types";

export const StyledWireframeImage = styled(Box)<StyledWireframeImageProps>`
  /* CC is the transparency specified with a hexadecimal value 80% alpha */
  margin: 0 0.25rem;
  border: 0.5rem solid ${({ theme, color }) => color && `${theme.colors[color]}CC`};
  border-radius: ${({ theme }) => theme.radii.medium};
  background-color: ${({ theme, color }) => color && `${theme.colors[color]}CC`};
  background-image: ${({ src, srcHover }) => `url("${src}"), url("${srcHover}")`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  :hover {
    border-radius: ${({ theme, hoverBorderRadius }) => theme.radii[hoverBorderRadius]};
    background-image: ${({ src, srcHover, isHideBaseImgOnHover }) =>
      `url("${srcHover}")${!isHideBaseImgOnHover ? `, url("${src}")` : ""}`};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 0 1.5rem;

    ${({ isBorder, shadow }) =>
      isBorder &&
      css`
        border: none;
        box-shadow: ${({ theme }) => theme.shadows[shadow]};
      `}
  }
`;
