import React, { useRef } from "react";
import { useInView } from "framer-motion";
// Components + styling
import { Box, Container, Heading, Text } from "components";
import { WireframeItem } from "./components";
import { StyledLineMotion, StyledWireframesLine, StyledWireframesLineWrapper } from "./styled";
// Hooks
import { useScrollVelocity } from "hooks";
// Context
import { useTranslation } from "context";
// Types
import { WireframesProps } from "./types";

const Wireframes: React.FC<WireframesProps> = ({
  upLineImages,
  downLineImages,
  upBaseVelocity,
  downBaseVelocity,
  titleColor = "white",
  subTitleColor = "white",
  zIndexForContainer,
  wireframesTitle,
  wireframesSubTitle,
  wireframeItemWidth,
  wireframeItemRatio,
  wireframeItemBorderColor,
  wireframeItemShadow = "planetcoinWireframe",
  wireframeItemHoverBorderRadius = "medium",
  isBorder,
  isHideBaseImgOnHover = false,
}) => {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(wrapperRef);

  const upLineVelocityMotion = useScrollVelocity({ ...upBaseVelocity, isInView });
  const downLineVelocityMotion = useScrollVelocity({ ...downBaseVelocity, isInView });

  return (
    <Box ref={wrapperRef} minHeight={{ _: "31.25rem", laptop: "60rem" }}>
      <Container width="100%" mb={{ _: "1rem", laptop: "2.5rem" }} zIndex={zIndexForContainer}>
        <Heading scale="h2" as="h2" mb="0.25rem" color={titleColor}>
          {t(wireframesTitle)}
        </Heading>
        <Text textScale="p16Regular" color={subTitleColor}>
          {t(wireframesSubTitle)}
        </Text>
      </Container>

      <StyledWireframesLineWrapper
        minHeight={{
          _: `${wireframeItemWidth.mobile / wireframeItemRatio}rem`,
          laptop: `${wireframeItemWidth.desktop / wireframeItemRatio}rem`,
        }}
      >
        <StyledWireframesLine>
          <StyledLineMotion style={{ x: upLineVelocityMotion.x }}>
            {upLineImages.map(({ src, srcHover }, index) => (
              <WireframeItem
                key={src + index}
                src={src}
                srcHover={srcHover}
                itemWidth={wireframeItemWidth}
                itemRatio={wireframeItemRatio}
                borderColor={wireframeItemBorderColor}
                shadow={wireframeItemShadow}
                hoverBorderRadius={wireframeItemHoverBorderRadius}
                isBorder={isBorder}
                isHideBaseImgOnHover={isHideBaseImgOnHover}
              />
            ))}
          </StyledLineMotion>
        </StyledWireframesLine>
      </StyledWireframesLineWrapper>

      <StyledWireframesLineWrapper
        minHeight={{
          _: `${wireframeItemWidth.mobile / wireframeItemRatio}rem`,
          laptop: `${wireframeItemWidth.desktop / wireframeItemRatio}rem`,
        }}
      >
        <StyledWireframesLine>
          <StyledLineMotion style={{ x: downLineVelocityMotion.x }}>
            {downLineImages.map(({ src, srcHover }, index) => (
              <WireframeItem
                key={index}
                src={src}
                srcHover={srcHover}
                itemWidth={wireframeItemWidth}
                itemRatio={wireframeItemRatio}
                borderColor={wireframeItemBorderColor}
                shadow={wireframeItemShadow}
                hoverBorderRadius={wireframeItemHoverBorderRadius}
                isBorder={isBorder}
                isHideBaseImgOnHover={isHideBaseImgOnHover}
              />
            ))}
          </StyledLineMotion>
        </StyledWireframesLine>
      </StyledWireframesLineWrapper>
    </Box>
  );
};

export default Wireframes;
