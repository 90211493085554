import React from "react";
// Components + styling
import { Button, Container, Flex, Box, Heading, Text, Image } from "components";
import { StyledInnerWrapper, StyledBannerDescription } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";

const AboutUsBanner: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isLargeDesktop, isLaptop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Box $backgroundColor="white">
      <StyledInnerWrapper headerHeight={headerHeight}>
        <Container>
          <Flex flexDirection={isDesktop ? "row" : "column"} justifyContent="space-between">
            <StyledBannerDescription>
              <Heading scale="h2" as="h2" color="white" mb={{ _: "0.5rem", laptop: "1rem" }}>
                {t("About Us")}
              </Heading>
              <Text
                textScale={isDesktop ? "p24Regular" : "p16Regular"}
                color="textFourth"
                textAlign={isDesktop ? "left" : "center"}
                mb={{ _: "1.125rem", laptop: "1.5rem" }}
              >
                {t("Find out our core values and approaches for each product we design")}
              </Text>
              <Button width="18.75rem" onClick={() => scrollToElementById(BLOCK_ID.contactUs)}>
                {t("Contact us")}
              </Button>
            </StyledBannerDescription>
            <Image
              width={isLargeDesktop ? "43.875rem" : isLaptop ? "33.75rem" : "100%"}
              src="/images/about-us-banner.webp"
              aspectRatio={1.3}
            />
          </Flex>
        </Container>
      </StyledInnerWrapper>
    </Box>
  );
};

export default AboutUsBanner;
