import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box } from "components";

export const StyledInfoWrapper = styled(Box)`
  position: relative;
  margin-bottom: 1.5rem;
  border-radius: ${({ theme }) => theme.radii.large};
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(50% - 0.75rem);
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 2.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 3.75rem 3.4375rem;
  }

  &:hover {
    ${({ theme }) => theme.mediaQueries.laptop} {
      box-shadow: ${({ theme }) => theme.shadows.extraInfo};

      &::before {
        opacity: 1;
      }
    }
  }

  &::before {
    ${clearfix}
    width: 3.125rem;
    height: 2.5625rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0;
    background: url("/images/extra-info.svg") no-repeat;
    transition: ${({ theme }) => theme.transitions.default};
  }

  &::after {
    ${clearfix}
    width: 100%;
    /* bg-image ratio width/height is 19.16 */
    height: calc(100vw / 19.16);
    margin: 1.5rem 0;
    background: url("/images/wave-services.svg") no-repeat center center;
    background-size: 100% auto;

    ${({ theme }) => theme.mediaQueries.tablet} {
      display: none;
    }
  }

  &:nth-last-of-type(1) {
    &::after {
      display: none;
    }
  }
`;
