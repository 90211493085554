import React, { useRef } from "react";
// Components + Styling
import { Box } from "components";
import {
  StyledSlideImageWrapper,
  StyledMotionWrapper,
  StyledSlideImageTitle1,
  StyledSlideImageTitle2,
  StyledSlideImage1,
  StyledSlideImage2,
  StyledSlideImage3,
} from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useParallaxEffect } from "hooks";

const PlanetCoinSlideImage: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { isDesktop } = useMatchBreakpoints();

  const ref = useRef(null);
  const parallaxTitle1 = useParallaxEffect(ref, 55);
  const parallaxTitle2 = useParallaxEffect(ref, 95);
  const parallaxImage1 = useParallaxEffect(ref, 75);
  const parallaxImage2 = useParallaxEffect(ref, 75);
  const parallaxImage3 = useParallaxEffect(ref, 75);

  return (
    <StyledSlideImageWrapper ref={ref}>
      <Box position="absolute" right="0">
        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxTitle1.y } : {}}
          transition={parallaxTitle1.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImageTitle1 textScale={isDesktop ? "decorative22Light" : "decorative14Light"} color="white">
            {t("Fast operations")}
          </StyledSlideImageTitle1>
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxTitle2.y } : {}}
          transition={parallaxTitle2.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImageTitle2 textScale={isDesktop ? "decorative22Light" : "decorative14Light"} color="white">
            {t("Manage crypto assets")}
          </StyledSlideImageTitle2>
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage1.y } : {}}
          transition={parallaxImage1.selectedTransition}
          zIndex={theme.zIndices.dropdown + 1}
        >
          <StyledSlideImage1 src="/images/planet-coin-slide-1.webp" aspectRatio={1.53} />
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage2.y } : {}}
          transition={parallaxImage2.selectedTransition}
          zIndex={theme.zIndices.dropdown}
        >
          <StyledSlideImage2 src="/images/planet-coin-slide-2.webp" aspectRatio={1.32} />
        </StyledMotionWrapper>

        <StyledMotionWrapper
          style={isDesktop ? { y: parallaxImage3.y } : {}}
          transition={parallaxImage3.selectedTransition}
          zIndex={theme.zIndices.dropdown - 2}
        >
          <StyledSlideImage3 src="/images/planet-coin-slide-gradient.webp" aspectRatio={1.06} isHideSkeleton />
        </StyledMotionWrapper>
      </Box>
    </StyledSlideImageWrapper>
  );
};

export default PlanetCoinSlideImage;
