import { motion } from "framer-motion";
// Components + Styling
import { StyledClientTestimonialParallaxImageWrapper } from "./styled";
// Hooks
import { useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const ClientTestimonialParallaxImage = React.forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const parallaxOffset: UseScrollOffsetOptions[] = ["start end", "end start"];

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 40, { offset: parallaxOffset }),
    useParallaxEffect(ref, 50, { offset: parallaxOffset }),
    useParallaxEffect(ref, 65, { offset: parallaxOffset }),
    useParallaxEffect(ref, 55, { offset: parallaxOffset }),
    useParallaxEffect(ref, 45, { offset: parallaxOffset }),
  ];

  return (
    <StyledClientTestimonialParallaxImageWrapper>
      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, ratio }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];

        return (
          <motion.div key={imageSrc} style={{ y }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </StyledClientTestimonialParallaxImageWrapper>
  );
});

ClientTestimonialParallaxImage.displayName = "ClientTestimonialParallaxImage";

export default ClientTestimonialParallaxImage;
