import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 187 97" {...props}>
      <path
        d="M57.192 56.323h11.917v-2.939h-8.636v-3.453h7.513v-2.938h-7.513v-3.346h8.528v-2.939H57.19v15.615Zm15.221 0h3.303V50.21h7.6v-3.003h-7.6v-3.496h8.592v-3.003H72.413v15.615Zm21.425.236c4.21 0 6.865-2.317 6.865-7.036v-8.815H97.4v8.966c0 2.552-1.338 3.86-3.519 3.86-2.18 0-3.497-1.372-3.497-3.99v-8.836h-3.325v8.944c0 4.569 2.59 6.907 6.779 6.907Zm10.62-.236h3.26V46.156l7.815 10.167h2.828V40.708h-3.281v9.845l-7.578-9.845h-3.044v15.615Zm17.92 0h6.066c4.944 0 8.355-3.41 8.355-7.808v-.043c0-4.397-3.411-7.764-8.355-7.764h-6.066v15.615Zm6.066-12.634c2.914 0 4.88 1.995 4.88 4.826v.043c0 2.831-1.966 4.783-4.88 4.783h-2.763V43.69h2.763Zm11.664 12.634h11.917v-2.939h-8.636v-3.453h7.513v-2.938h-7.513v-3.346h8.528v-2.939h-11.809v15.615Zm18.525-8v-4.634h3.562c1.748 0 2.827.794 2.827 2.295v.043c0 1.373-1.036 2.295-2.762 2.295h-3.627Zm-3.303 8h3.303v-5.084h2.871l3.475 5.084h3.887l-3.93-5.641c2.029-.708 3.433-2.295 3.433-4.826v-.043c0-1.437-.453-2.66-1.317-3.518-1.037-1.03-2.59-1.587-4.577-1.587h-7.145v15.615Z"
        fill="#B1BEDE"
        fillOpacity=".5"
      />
      <path
        d="m18.64 47.878 3.162-6.805a.621.621 0 0 1 1.135.023L29.3 56.377a.621.621 0 0 0 1.154-.02l7.08-18.938a.621.621 0 0 1 1.138-.053l7.685 15.808c.25.512 1.004.444 1.156-.104l8.812-31.778"
        stroke="#B1BEDE"
        strokeOpacity=".5"
        strokeWidth="4.863"
      />
    </Svg>
  );
};

export default Icon;
