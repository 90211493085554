import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 51 51" {...props} color="transparent">
      <path
        d="M41.8353 28.1546L42.2249 24.0147C42.5309 20.7633 42.6839 19.1376 42.1277 18.4655C41.8268 18.102 41.4177 17.8792 40.9802 17.8408C40.1715 17.7698 39.156 18.9259 37.1248 21.2382C36.0744 22.434 35.5492 23.0319 34.9633 23.1245C34.6386 23.1758 34.3078 23.1231 34.0081 22.9722C33.467 22.7 33.1063 21.9608 32.3849 20.4825L28.5822 12.6903C27.219 9.89675 26.5373 8.5 25.5 8.5C24.4627 8.5 23.781 9.89675 22.4178 12.6903L18.6151 20.4825C17.8937 21.9608 17.533 22.7 16.9919 22.9722C16.6922 23.1231 16.3614 23.1758 16.0367 23.1245C15.4508 23.0319 14.9256 22.434 13.8752 21.2382C11.844 18.9259 10.8285 17.7698 10.0198 17.8408C9.58232 17.8792 9.17316 18.102 8.87228 18.4655C8.31605 19.1376 8.46906 20.7633 8.77507 24.0147L9.1647 28.1546C9.80671 34.9761 10.1277 38.3868 12.1382 40.4434C14.1486 42.5 17.1618 42.5 23.1881 42.5H27.8119C33.8382 42.5 36.8514 42.5 38.8618 40.4434C40.8723 38.3868 41.1933 34.9761 41.8353 28.1546Z"
        stroke={theme.colors[color]}
        strokeWidth="3.33"
      />
      <path d="M20.4023 35.7002H30.6023" stroke={theme.colors[color]} strokeWidth="3.33" strokeLinecap="round" />
    </Svg>
  );
};

export default Icon;
