import React from "react";
// Components + styling
import { Button, HeaderSocials, NavigationMenu } from "components";
import { WavingHandIcon } from "components/svg";
import { StyledMotion, StyledMobileMenu, StyledMobileMenuFooter } from "./styled";
// Animations
import { slideVariants } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementByIdHeight, useHideOverflow } from "hooks";
// Types
import { MobileMenuProps } from "./types";

export const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, setIsMenuOpen, onClickLetsTalk }) => {
  const { t } = useTranslation();
  const { elementHeight } = useElementByIdHeight("header");

  useHideOverflow({ trigger: isMenuOpen });

  return (
    <StyledMotion
      initial="hidden"
      animate={isMenuOpen ? "visible" : "hidden"}
      variants={slideVariants}
      offsetHeight={elementHeight}
    >
      <StyledMobileMenu offsetHeight={elementHeight}>
        <NavigationMenu setIsMenuOpen={setIsMenuOpen} />

        <StyledMobileMenuFooter>
          <HeaderSocials />
          <Button
            scale="md"
            height="2.75rem"
            mx="0.5rem"
            variant="secondary"
            startIcon={<WavingHandIcon width="1.125rem" />}
            ariaLabel="lets-talk"
            onClick={() => {
              setIsMenuOpen(false);
              onClickLetsTalk();
            }}
          >
            {t("Let's Talk")}
          </Button>
        </StyledMobileMenuFooter>
      </StyledMobileMenu>
    </StyledMotion>
  );
};
