// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textThird", ...props }) => {
  return (
    <Svg viewBox="0 0 32 32" {...props} color={color}>
      <path d="M7.19919 26.6666L5.33252 24.8L20.7992 9.33329H11.9992V6.66663H25.3325V20H22.6659V11.2L7.19919 26.6666Z" />
    </Svg>
  );
};

export default Icon;
