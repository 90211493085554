import * as yup from "yup";
// Context
import { useTranslation } from "context";
// Constants
import { REGEX } from "configs";

export const initialValues = {
  name: "",
  email: "",
  comment: "",
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    name: yup.string(),
    email: yup
      .string()
      .required(t("E-mail is required"))
      .matches(REGEX.emailRegex, "This e-mail doesn’t exist. Please check it"),
    comment: yup.string(),
  });

  return { validationSchema, initialValues };
};
