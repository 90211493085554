import { useRef } from "react";
import { useScroll, useTransform, useMotionValue, useVelocity, useAnimationFrame } from "framer-motion";
import { wrap } from "@motionone/utils";

const useScrollVelocity = ({
  isInView,
  baseVelocity = 1,
  speedToScroll = 1000,
  opacityRange = 3,
}: {
  baseVelocity: number;
  speedToScroll?: number;
  isInView?: boolean;
  opacityRange?: number;
}) => {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();

  const scrollVelocity = useVelocity(scrollY);
  const velocityFactor = useTransform(
    scrollVelocity,
    [-speedToScroll, 0, 0, speedToScroll],
    [-opacityRange, 0, 0, opacityRange],
  );

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  const x = useTransform(baseX, v => `${wrap(-25, -75, v)}%`);

  const directionFactor = useRef<number>(1);
  useAnimationFrame((_, delta) => {
    if (isInView) {
      let moveBy = directionFactor.current * baseVelocity * (delta / 1000);
      /**
       * This is what changes the direction of the scroll once we
       * switch scrolling directions.
       */
      if (velocityFactor.get() < 0) {
        directionFactor.current = -1;
      } else if (velocityFactor.get() > 0) {
        directionFactor.current = 1;
      }

      moveBy += directionFactor.current * moveBy * velocityFactor.get();

      baseX.set(baseX.get() + moveBy);
    }
  });

  return { x };
};

export default useScrollVelocity;
