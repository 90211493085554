// Constants
import { ROUTES } from "navigation/routes";

export const getMetaLabelForCaseStudies = (path: string) => {
  const [_empty, _caseStudies, caseStudy] = path.split("/");

  switch (caseStudy) {
    case ROUTES.planetcoinCase:
      return "Planetcoin";
    case ROUTES.eStatesCase:
      return "E-States";
    case ROUTES.ugreatorCase:
      return "Ugreator";
    default:
      return "";
  }
};
