import styled from "styled-components";
import { clearfixDisplayNone } from "styles";
// Components
import { Box } from "components";
// Animations
import { clientsShow, sliderAutoplay } from "./animations";

export const StyledSliderWrapper = styled(Box)`
  width: 100%;
  opacity: 0;
  position: relative;
  animation: ${clientsShow} 0.5s linear 1s forwards;
  z-index: ${({ theme }) => theme.zIndices.header};

  &::before,
  &::after {
    ${clearfixDisplayNone}
    width: 16.875rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
    background: ${({ theme }) => theme.gradients.bannerSlider};
    transition: ${({ theme }) => theme.transitions.default};

    ${({ theme }) => theme.mediaQueries.tablet} {
      display: block;
    }

    ${({ theme }) => theme.mediaQueries.largeHeight} {
      width: ${({ theme }) => `calc((150vw - ${theme.siteWidth}px) / 2)`};
    }
  }

  &::after {
    left: auto;
    right: 0;
    transform: scale(-1);
  }
`;

export const StyledClientsWrapper = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const StyledClientsWrapperIn = styled(Box)`
  display: flex;
  height: 6rem;
  /* TODO Check animation, maybe need refactor to not use triple copy of clientsLogoList */
  animation: ${sliderAutoplay} 14s linear infinite;
`;

export const StyledClientsItem = styled(Box)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 6rem;
  min-width: 12rem;
  padding: 0 0.375rem;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 6rem;
  }
`;
