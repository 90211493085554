import styled from "styled-components";
// Components + styling
import { Box } from "components";
import {
  styles as headingStyles,
  fontSizes as headingFontSizes,
  lineHeights as headingLineHeights,
} from "components/heading/theme";
import { styles as textStyles } from "components/text/theme";
import { fillTextWithGradient } from "components/text";

export const StyledArticleWrapper = styled(Box)`
  border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledArticleContent = styled(Box)`
  margin-bottom: 1.5rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.25rem;
    ${headingStyles.h3};
    font-size: ${headingFontSizes.h3.mobile};
    line-height: ${headingLineHeights.h3.mobile};
    color: ${({ theme }) => theme.colors.textMain};

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin-bottom: 1rem;
      font-size: ${headingFontSizes.h3.laptop};
      line-height: ${headingLineHeights.h3.laptop};
    }
  }

  ul,
  ol {
    margin: -1rem 0 1rem;
    padding-left: 1.25rem;
    color: ${({ theme }) => theme.colors.textFifth};

    ${({ theme }) => theme.mediaQueries.laptop} {
      padding-left: 1.875rem;
      margin: -1.5rem 0 1.5rem;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }

  li,
  p {
    ${textStyles.p16Regular}

    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.textFifth};
  }

  li {
    margin-bottom: 0;
  }

  p > a {
    display: inline-block;
    ${fillTextWithGradient("buttonAccent")}
  }

  button {
    display: none;
  }
`;
