// Components
import { ContactUs, Page } from "components";
// Hooks
import { useElementDimensions } from "hooks";
// Constants
import { BLOCK_ID } from "configs";

const CasePages: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Page maxWidth="100vw" px="0" mt={{ laptop: `-${headerHeight}px` }} pt={{ laptop: `${headerHeight}px` }}>
      {children}
      <ContactUs pt="2.5rem" title="Want to have similar?" isHideTitleIcon />
    </Page>
  );
};

export default CasePages;
