import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "bgDark", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 23 23" {...props}>
      <path
        d="M23 11.5C23 17.8513 17.8513 23 11.5 23C5.14873 23 0 17.8513 0 11.5C0 5.14873 5.14873 0 11.5 0C17.8513 0 23 5.14873 23 11.5Z"
        fill="white"
      />
      <path
        d="M7 9.625H8.875V16H7V9.625ZM7.93187 8.875H7.92137C7.36187 8.875 7 8.458 7 7.93712C7 7.405 7.37312 7 7.94275 7C8.51312 7 8.8645 7.405 8.875 7.93712C8.875 8.45762 8.51312 8.875 7.93187 8.875ZM16 16H14.125V12.5879C14.125 11.7636 13.6656 11.2011 12.928 11.2011C12.3651 11.2011 12.0606 11.5806 11.9129 11.9474C11.8589 12.0786 11.875 12.4416 11.875 12.625V16H10V9.625H11.875V10.606C12.1454 10.1875 12.5688 9.625 13.6518 9.625C14.9935 9.625 15.9996 10.4688 15.9996 12.3527L16 16Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
