import React, { useRef, useState } from "react";
import { MotionValue, motion, useAnimation } from "framer-motion";
// Components + styling
import { ButtonHoverLinesIcon } from "components/svg";
import {
  StyledBannerButtonWrapper,
  StyledBannerButtonInnerWrapper,
  StyledBannerButton,
  StyledButtonFillWrapper,
  StyledButtonFill,
  StyledButtonLabelWrapper,
  StyledButtonLabel,
  StyledHoverButtonLines,
} from "./styled";
// Animations
import { hoverVariants } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
import { useMagneticButton } from "../../hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";

const MagneticButton: React.FC<{ scale: MotionValue }> = ({ scale }) => {
  const { t } = useTranslation();
  const { isLargeDesktop } = useMatchBreakpoints();

  const buttonRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLParagraphElement | null>(null);

  const { x, y, labelX, labelY, handleMouseMove, resetPosition } = useMagneticButton(buttonRef);
  const fillControls = useAnimation();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    fillControls.start("enter");
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    fillControls.start("leave");
    setIsHovered(false);
    resetPosition();
  };

  return (
    <StyledBannerButtonWrapper isHovered={isHovered}>
      <StyledBannerButtonInnerWrapper
        ref={buttonRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <motion.div style={{ x, y, scale }}>
          <StyledBannerButton
            variant="primary"
            filledType="bordered"
            buttonTheme="dark"
            isShowHoverLines={false}
            mx="auto"
            onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
            isHovered={isHovered}
            hideHoverGradient
          >
            <StyledButtonLabelWrapper style={{ x: labelX, y: labelY }}>
              <StyledButtonLabel
                ref={textRef}
                isHovered={isHovered}
                textScale={isLargeDesktop ? "decorative22Bold" : "decorative16Bold"}
                color="white"
              >
                {t("Have a project?")}
              </StyledButtonLabel>
            </StyledButtonLabelWrapper>

            <StyledButtonFillWrapper>
              <StyledButtonFill variants={hoverVariants} animate={fillControls} />
            </StyledButtonFillWrapper>

            <StyledHoverButtonLines iconPosition="left">
              <ButtonHoverLinesIcon width="2.25rem" animationType="bannerButtonLeftLines" />
            </StyledHoverButtonLines>

            <StyledHoverButtonLines iconPosition="right">
              <ButtonHoverLinesIcon width="2.25rem" animationType="bannerButtonRightLines" />
            </StyledHoverButtonLines>
          </StyledBannerButton>
        </motion.div>
      </StyledBannerButtonInnerWrapper>
    </StyledBannerButtonWrapper>
  );
};

export default MagneticButton;
