import styled from "styled-components";
import { clearfix } from "styles";

export const StyledInnerWrapper = styled.div<{ headerHeight: number }>`
  padding: ${({ headerHeight }) => `calc(${headerHeight}px + 2rem) 0 1.5rem`};
  background: ${({ theme }) => theme.colors.bgDark} url("/images/about-us-banner-bg.webp") no-repeat right bottom;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: ${({ headerHeight }) => `calc(${headerHeight}px + 3rem) 0 5rem`};
    border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
  }
`;

export const StyledBannerDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 1.875rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 26.5rem;
    align-items: flex-start;
    padding: 5.75rem 0 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 32.75rem;
  }
`;

export const StyledBannerImageWrapper = styled.div`
  width: 100%;
  max-width: 18.75rem;
  position: relative;
  margin: 2rem auto 3.125rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 31.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 22.5rem;
    max-width: initial;
    margin: 0.5rem 2.5rem 0 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 26rem;
    margin: 0.5rem 7.5rem 0 0;
  }

  &::before {
    ${clearfix}
    width: 22.875rem;
    height: 22.8125rem;
    position: absolute;
    left: -1.75rem;
    top: 1.0625rem;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    background: url("/images/careers-banner-circle.svg") no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.tablet} {
      left: -3rem;
      height: 36.875rem;
      top: 0.8125rem;
      width: 38.75rem;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      height: 33.9375rem;
      width: 28.75rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      left: -4.25rem;
      width: 35rem;
    }
  }

  &::after {
    ${clearfix}
    width: 24rem;
    height: 26rem;
    position: absolute;
    top: -0.5625rem;
    left: -2.75rem;
    z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
    background: url("/images/careers-banner-star.svg") no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.tablet} {
      height: 36.875rem;
      left: -6.0625rem;
      width: 38.75rem;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      left: -4.1875rem;
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      left: -6.0625rem;
      width: 41rem;
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    display: block;
    position: relative;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;
