import styled from "styled-components";
// Components
import { Box, Heading, Text } from "components";

export const StyledShortSummaryWrapper = styled(Box)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    background-image: url("/images/planetcoin-short-summary-bg-lines.webp");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 60% 100%;
  }
`;

export const StyledSummaryItemTextHover = styled(Text)`
  transition: ${({ theme }) => theme.transitions.default};
`;

export const StyledSummaryItemHeadingHover = styled(Heading)`
  transition: ${({ theme }) => theme.transitions.default};
`;

export const StyledSummaryItem = styled(Box)`
  width: 100%;
  max-width: 21.5rem;
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.medium};
  background: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(50% - 0.75rem);
    max-width: unset;
    align-self: stretch;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 25%;
    margin: 0 1.125rem;
    padding: 2.375rem 2.5rem;
  }

  &:nth-of-type(1) {
    ${({ theme }) => theme.mediaQueries.laptop} {
      margin-left: 0;
    }
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin-right: 0;
    }
  }

  &:nth-last-of-type(2) {
    ${({ theme }) => theme.mediaQueries.tablet} {
      margin-bottom: 0;
    }
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.planetcoinCaseMain};
    box-shadow: ${({ theme }) => theme.shadows.planetcoinShortSummaryCard};

    ${StyledSummaryItemTextHover},
    ${StyledSummaryItemHeadingHover} {
      color: ${({ theme }) => theme.colors.planetcoinCaseMain};
    }
  }
`;
