// Components
import { BehanceFooterIcon, DribbbleFooterIcon, LinkedInFooterIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS, BLOCK_ID } from "configs";

export const FOOTER_ABOUT_LIST = [
  { label: "About us", link: `/${ROUTES.aboutUs}` },
  { label: "Careers", link: `/${ROUTES.careers}` },
  { label: "Portfolio", link: `${ROUTES.landing}#${BLOCK_ID.portfolio}` },
  { label: "How we work", link: `${ROUTES.landing}#${BLOCK_ID.howWeWork}` },
  { label: "Blog", link: `/${ROUTES.blog}` },
];

export const FOOTER_SOCIALS_LIST = [
  { label: "Behance", Icon: BehanceFooterIcon, link: EXTERNAL_LINKS.behance },
  { label: "Dribbble", Icon: DribbbleFooterIcon, link: EXTERNAL_LINKS.dribbble },
  {
    label: "Linkedin",
    Icon: LinkedInFooterIcon,
    link: EXTERNAL_LINKS.linkedIn,
  },
];

export const FOOTER_PHONE_LIST = [
  { label: "+4 871 880 8151 (Poland)", tel: "+48718808151" },
  { label: "+1 929 560 3730 (USA)", tel: "+19295603730" },
  { label: "+4 420 457 71515 (UK)", tel: "+442045771515" },
  { label: "+3 726 03 92 65 (Estonia)", tel: "+3726039265" },
];
