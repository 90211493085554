import React from "react";
// Components + styling
import { StyledDmytroHatAnimation } from "./styled";
// Animations
import dmytroHat from "assets/animations/dmytro-hat.json";
// Types
import { SliderAnimationProps } from "../types";

const DmytroKovalovAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref }) => {
  return (
    <>
      <StyledDmytroHatAnimation lottieRef={lottieAnimation1Ref} animationData={dmytroHat} autoplay={false} loop />
    </>
  );
};

export default DmytroKovalovAnimation;
