// Components + styling
import { Button, Flex, Text } from "components";
import { CloseCircleIcon } from "components/svg";
// Context
import { useTranslation } from "context";
// Types
import { MobileFilterHeaderProps } from "./types";

const MobileFilterHeader: React.FC<MobileFilterHeaderProps> = ({ closeModal, handleClearAll }) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Button
        minWidth="2rem"
        scale="sm"
        variant="primary"
        filledType="bordered"
        onClick={closeModal}
        bgColor="white"
        color="white"
        width="2rem"
      >
        <CloseCircleIcon width="2rem" color="textMain" />
      </Button>
      <Text textScale="p18Regular" color="textMain">
        {t("Filters by")}
      </Text>
      <Button
        minWidth="4.5rem"
        scale="sm"
        variant="secondary"
        filledType="bordered"
        onClick={handleClearAll}
        bgColor="white"
        color="textMain"
        textScale="p14Medium"
      >
        {t("Clear all")}
      </Button>
    </Flex>
  );
};

export default MobileFilterHeader;
