import { useRef } from "react";
// Components + styling
import { Container } from "components";
import {
  StyledMotionWrapper,
  StyledBgImage,
  StyledParallaxPhoneLeft,
  StyledParallaxPhoneRight,
  StyledParallaxImageWrapper,
} from "./styled";
// Hooks
import { useMatchBreakpoints, useParallaxEffect } from "hooks";

const UgreatorBannerParallaxImage: React.FC = () => {
  const { isDesktop, isLaptopXL } = useMatchBreakpoints();

  const ref = useRef<HTMLDivElement | null>(null);

  const parallaxImageLeft = useParallaxEffect(ref, isDesktop ? 600 : 300, {
    offset: ["start end", "end start"],
  });
  const parallaxImageRight = useParallaxEffect(ref, isDesktop ? 900 : 550, {
    offset: ["start end", "end start"],
  });

  return (
    <StyledParallaxImageWrapper ref={ref}>
      <Container width="100%" position="relative">
        <StyledMotionWrapper style={{ y: parallaxImageLeft.y }} transition={parallaxImageLeft.selectedTransition}>
          <StyledParallaxPhoneLeft
            src="/images/case-studies/ugreator/ugreator-banner-phone-left.webp"
            aspectRatio={0.56}
          />
        </StyledMotionWrapper>

        <StyledMotionWrapper style={{ y: parallaxImageRight.y }} transition={parallaxImageRight.selectedTransition}>
          <StyledParallaxPhoneRight
            src="/images/case-studies/ugreator/ugreator-banner-phone-right.webp"
            aspectRatio={0.57}
          />
        </StyledMotionWrapper>

        {isLaptopXL && <StyledBgImage src="/images/case-studies/ugreator/ugreator-banner-bg.webp" aspectRatio={1.29} />}
      </Container>

      {!isLaptopXL && (
        <StyledBgImage
          src={
            isDesktop
              ? "/images/case-studies/ugreator/ugreator-banner-bg-lg.webp"
              : "/images/case-studies/ugreator/ugreator-banner-bg-sm.webp"
          }
          aspectRatio={isDesktop ? 1.04 : 0.82}
        />
      )}
    </StyledParallaxImageWrapper>
  );
};

export default UgreatorBannerParallaxImage;
