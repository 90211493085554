// Constants
import { ROUTES } from "navigation/routes";

export const findMatchingRoute = (basePath: string) => {
  for (const route in ROUTES) {
    if (basePath === `/${ROUTES[route as keyof typeof ROUTES]}`) {
      return route;
    }
  }
};
