import { useEffect, useState } from "react";
import { ActiveInteractionItem } from "./types";

const useSlideAutoChange = (interactionSectionsList: ActiveInteractionItem[]) => {
  const [activeInteractionSection, setActiveInteractionSection] = useState(0);

  useEffect(() => {
    const changeTimer = setInterval(() => {
      if (activeInteractionSection === interactionSectionsList.length - 1) {
        setActiveInteractionSection(0);
      } else {
        setActiveInteractionSection(activeInteractionSection + 1);
      }
    }, 5000);
    return () => clearInterval(changeTimer);
  }, [activeInteractionSection, interactionSectionsList]);

  return {
    activeInteractionSection,
    setActiveInteractionSection,
  };
};

export default useSlideAutoChange;
