import React from "react";
import { useNavigate } from "react-router-dom";
// Components + Styling
import { Heading, Text } from "components";
import { PlanetCoinSlideImage } from "./components";
import {
  StyledPlanetCoinMotionWrapper,
  StyledContentContainer,
  StyledPlanetCoinWrapper,
  StyledPlanetcoinButton,
} from "./styled";
// Animations
import { portfolioItemShow } from "../../animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";

const PlanetCoin: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledPlanetCoinMotionWrapper
      initial={isDesktop ? "hidden" : "visible"}
      variants={portfolioItemShow}
      whileInView="visible"
      viewport={{ once: true }}
    >
      <StyledPlanetCoinWrapper>
        <StyledContentContainer>
          <Heading scale="h3" as="h3" color="white" mb={{ _: "0.5rem" }}>
            {t("Planetcoin")}
          </Heading>
          <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="white" opacity="0.7">
            {t(
              "This multifunctional platform was designed to enable efficient cryptocurrency management. Here users can control their transactions, trade available assets as well as track the currency rates.",
            )}
          </Text>
          {isDesktop && (
            <StyledPlanetcoinButton
              bgColor="planetcoinButtonGreen"
              hoverBg="planetCoinBtnBgHover"
              hoverLinesPosition="bottomLeft"
              width="15.25rem"
              mx={{ _: "auto", laptopL: "0" }}
              mt="1.25rem"
              onClick={() => navigate(`/${ROUTES.caseStudies}/${ROUTES.planetcoinCase}`)}
            >
              {t("View full case")}
            </StyledPlanetcoinButton>
          )}
        </StyledContentContainer>

        {!isDesktop && <PlanetCoinSlideImage />}

        {!isDesktop && (
          <StyledContentContainer>
            <StyledPlanetcoinButton
              bgColor="planetcoinButtonGreen"
              hoverBg="planetCoinBtnBgHover"
              width="15.25rem"
              mx={{ _: "auto", laptopL: "0" }}
              onClick={() => navigate(`/${ROUTES.caseStudies}/${ROUTES.planetcoinCase}`)}
            >
              {t("View full case")}
            </StyledPlanetcoinButton>
          </StyledContentContainer>
        )}

        {isDesktop && <PlanetCoinSlideImage />}
      </StyledPlanetCoinWrapper>
    </StyledPlanetCoinMotionWrapper>
  );
};

export default PlanetCoin;
