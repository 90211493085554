import styled from "styled-components";
// Components
import { Flex } from "components";
import { CircleWrapperIcon } from "components/svg";

export const StyledCircleWrapperIcon = styled(CircleWrapperIcon)`
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledUserCase = styled(Flex)`
  width: 100%;
  align-items: center;
  position: relative;
  top: 0;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.large};
  transition: ${({ theme }) => theme.transitions.default};
  background: ${({ theme }) => `${theme.colors.bgSecond}4D`};
  box-shadow: ${({ theme }) => theme.shadows.userCaseItem};

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 23.25rem;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0.75rem;
  }

  &:nth-of-type(1) {
    ${({ theme }) => theme.mediaQueries.tablet} {
      margin-left: 0;
    }
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;

    ${({ theme }) => theme.mediaQueries.tablet} {
      margin-right: 0;
    }
  }

  &:hover {
    top: -0.25rem;
    background: ${({ theme }) => theme.colors.bgSecond};
    box-shadow: ${({ theme }) => theme.shadows.userCaseItemHover};

    ${StyledCircleWrapperIcon} {
      display: block;
    }
  }
`;
