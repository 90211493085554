import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 51 51" {...props} color="transparent">
      <path
        d="M14.1299 23.3947C19.0911 14.5984 21.5717 10.2002 25.5 10.2002C29.4283 10.2002 31.9089 14.5984 36.8701 23.3947L37.4883 24.4908C41.611 31.8006 43.6724 35.4554 41.8094 38.1278C39.9463 40.8002 35.3369 40.8002 26.1182 40.8002H24.8818C15.6631 40.8002 11.0537 40.8002 9.19064 38.1278C7.32758 35.4554 9.38895 31.8006 13.5117 24.4909L14.1299 23.3947Z"
        strokeWidth="3.33"
        stroke={theme.colors[color]}
      />
      <path d="M25.5 18.7002V27.2002" stroke={theme.colors[color]} strokeWidth="3.33" strokeLinecap="round" />
      <circle cx="25.5008" cy="32.3006" r="1.7" fill={theme.colors[color]} />
    </Svg>
  );
};

export default Icon;
