export const MANAGERS_LIST = [
  {
    name: "Mel",
    surname: "Dyu",
    position: "Business Development Manager",
    image: "/images/mel-round.webp",
  },
  {
    name: "Irene",
    surname: "Avryutova",
    position: "Customer Care Manager",
    image: "/images/irene-avryutova-round.webp",
  },
];
