import styled, { css } from "styled-components";
import { clearfix } from "styles";
// Components
import { Flex } from "components/layout";
// Types
import { StyledHeaderProps, StyledIconWrapperProps } from "./types";

const pseudoStyles = css`
  ${clearfix}
  width: 1.125rem;
  height: 0.125rem;
  position: absolute;
  top: 1.5rem;
  right: 0;
  border-radius: ${({ theme }) => theme.radii.contactForm};
  background: ${({ theme }) => theme.colors.textAccent};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 1.5rem;
    top: 2.5rem;
  }
`;

export const StyledHeader = styled(Flex)<StyledHeaderProps>`
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  &::after {
    ${pseudoStyles};
    display: ${({ isHaveRotateIcon }) => (isHaveRotateIcon ? "none" : "block")};
  }

  &::before {
    ${pseudoStyles}
    display: ${({ isHaveRotateIcon }) => (isHaveRotateIcon ? "none" : "block")};
    transform: ${({ isOpen }) => `rotate(${isOpen ? "0deg" : "90deg"})`};
    transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
  }
`;

export const StyledIconWrapper = styled.div<StyledIconWrapperProps>`
  display: flex;
  justify-content: center;
  transform: ${({ rotateDeg }) => `rotate(${rotateDeg}deg)`};
  transition: ${({ theme }) => theme.transitions.default};
`;

export const AccordionContent = styled.div<{ isOpen: boolean }>`
  width: 100%;
  position: relative;
  z-index: 2;
  max-height: ${({ isOpen }) => (isOpen ? "200vh" : "0")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
  overflow: hidden;
`;
