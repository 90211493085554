// Components + styling
import { Accordion, Box, Container, Text } from "components";
import { StyledContactLink, StyledInnerWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { AGREEMENTS_CONTACTS_LIST } from "./constants";

const AgreementsContactDetails: React.FC<{ isShowDescription?: boolean }> = ({ isShowDescription }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Box $backgroundColor="bgDark">
      <StyledInnerWrapper>
        <Container pb={{ _: "3.75rem", laptop: "5rem" }}>
          <Accordion title={t("Contact details")} $backgroundColor="white">
            {isShowDescription && (
              <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mb="1.5rem">
                {t(
                  "It is important that the personal information IDEALOGIC DESIGN holds about you is accurate and current. Please keep IDEALOGIC DESIGN informed if your personal information changes during your relationship with IDEALOGIC DESIGN. Additionally, if you have any questions about this Privacy Policy, the practices of IDEALOGIC DESIGN WEBSITE or your interaction with IDEALOGIC DESIGN via IDEALOGIC DESIGN WEBSITE, please contact:",
                )}
              </Text>
            )}
            {AGREEMENTS_CONTACTS_LIST.map(({ company, address, phone, phoneLabel, phoneCountry }) => (
              <Box key={company} mb="1.5rem">
                <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth">
                  {t(company)}
                </Text>
                <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth">
                  {t(address)}
                </Text>
                <StyledContactLink href={`tel:${phone}`} textScale={isDesktop ? "p18Regular" : "p14Regular"}>
                  {phoneLabel} {t(phoneCountry)}
                </StyledContactLink>
              </Box>
            ))}
            <StyledContactLink href="tel:+19295603730" textScale={isDesktop ? "p18Regular" : "p14Regular"}>
              +1 929 560 3730 {t("(USA)")}
            </StyledContactLink>
            <StyledContactLink href="tel:+442045771515" textScale={isDesktop ? "p18Regular" : "p14Regular"}>
              +4 420 457 71515 {t("(UK)")}
            </StyledContactLink>
            <StyledContactLink
              href="mailto:legal@idealogic.design"
              mt="1.5rem"
              mb="0.25rem"
              textScale={isDesktop ? "p18Regular" : "p14Regular"}
            >
              legal@idealogic.design
            </StyledContactLink>
          </Accordion>
        </Container>
      </StyledInnerWrapper>
    </Box>
  );
};

export default AgreementsContactDetails;
