import React from "react";
// Components + styling
import { StyledAirplaneAnimation, StyledHeartAnimation } from "./styled";
// Animations
import elinaAirplane from "assets/animations/elina-airplane.json";
import elinaHeart from "assets/animations/elina-heart.json";
// Types
import { SliderAnimationProps } from "../types";

const ElinaSliderAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref, lottieAnimation2Ref }) => {
  return (
    <>
      <StyledAirplaneAnimation
        lottieRef={lottieAnimation1Ref}
        animationData={elinaAirplane}
        autoplay={false}
        loop={false}
      />
      <StyledHeartAnimation lottieRef={lottieAnimation2Ref} animationData={elinaHeart} autoplay={false} loop={false} />
    </>
  );
};

export default ElinaSliderAnimation;
