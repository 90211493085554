import React from "react";
// Components + Styling
import { StyledServiceItem, StyledServiceItemLabel } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { ServiceItemProps } from "./types";

const ServiceItem: React.FC<ServiceItemProps> = ({ label, Icon, y, opacity }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledServiceItem style={{ y, opacity }}>
      <Icon width={isDesktop ? "5.875rem" : "4.625rem"} mb="0.5rem" color="white" />
      <StyledServiceItemLabel textScale="p14Regular">{t(label)}</StyledServiceItemLabel>
    </StyledServiceItem>
  );
};

export default ServiceItem;
