import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M21.7619 46.999H40.3699C45.6884 46.999 50 42.6875 50 37.3689V37.3689C50 32.0503 45.6884 27.7387 40.3699 27.7387H14.8699C9.97117 27.7387 6 23.7676 6 18.8689V18.8689C6 13.9702 9.97117 9.99902 14.8699 9.99902H33.9524"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="44.6667"
        cy="9.66569"
        r="6.66667"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        cx="10.6667"
        cy="46.6657"
        r="6.66667"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
