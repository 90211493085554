// Components
import { Box } from "components";
import { EStatesUserFlowZigZagLineRouteIcon } from "components/svg";
// Types
import { EStatesUserFlowItemProps } from "./components/e-states-user-flow-item/types";

export const ESTATES_USER_FLOW_FIRST_ROW_LIST: EStatesUserFlowItemProps[] = [
  { text: "Overview", isMultiply: true },
  { text: "My Properties", isMultiply: true },
  { text: "Inbox", isMultiply: true },
  { text: "Marketplace", isMultiply: true },
  { text: "Settings", isMultiply: true },
  { text: "Your account", isMultiply: true },
];

export const ESTATES_USER_FLOW_SECOND_ROW_LIST: EStatesUserFlowItemProps[] = [
  { text: "Notifications", isShowTopLine: true },
  { text: "View property", isShowTopLine: true },
  { text: "", opacity: "0" },
  { text: "List", isShowTopLine: true },
  { text: "", opacity: "0" },
  { text: "Edit profile", isShowTopLine: true },
];

export const ESTATES_USER_FLOW_THIRD_ROW_LIST: EStatesUserFlowItemProps[] = [
  { text: "Upcoming Dividends", isShowTopLine: true },
  { text: "Notifications", isShowTopLine: true },
  { text: "", opacity: "0" },
  { text: "Map", isShowTopLine: true },
  { text: "", opacity: "0" },
  { text: "Verification", isShowTopLine: true },
];

export const ESTATES_USER_FLOW_FOURTH_ROW_LIST: EStatesUserFlowItemProps[] = [
  { text: "", opacity: "0" },
  { text: "Upcoming Dividends", isShowTopLine: true },
  { text: "", opacity: "0" },
  {
    text: "Search",
    isShowTopLine: true,
    isBorder: true,
    backgroundColor: "eStatesUserFlowBoxBgLight",
  },
  { text: "", opacity: "0" },
  { text: "Documents", isShowTopLine: true },
];

export const ESTATES_USER_FLOW_FIFTH_ROW_LIST: EStatesUserFlowItemProps[] = [
  { text: "", opacity: "0" },
  {
    text: "Upload Property",
    isShowTopLine: true,
    isBorder: true,
    backgroundColor: "eStatesUserFlowBoxBgLight",
  },
  {
    text: "Buy",
    isShowLeftLine: true,
    isShowRightLine: true,
    isBorder: true,
    backgroundColor: "eStatesUserFlowBoxBgLight",
  },
  {
    isShowTopLine: true,
    text: "View Property",
    topLineColor: "eStatesUserFlowBorder",
    isBorder: true,
    backgroundColor: "eStatesUserFlowBoxBgLight",
  },
  {
    text: "Transactions",
    isBorder: true,
    backgroundColor: "eStatesUserFlowBoxBgLight",
  },
  {
    text: "Statements",
    isShowTopLine: true,
    topLineColor: "eStatesUserFlowBorder",
    isBorder: true,
    backgroundColor: "eStatesUserFlowBoxBgLight",
    children: (
      <Box position="absolute" bottom={56} right={74}>
        <EStatesUserFlowZigZagLineRouteIcon />
      </Box>
    ),
  },
];

export const ESTATES_USER_FLOW_WIDTH = 1266;

export const ESTATES_USER_FLOW_ROWS = [
  { items: ESTATES_USER_FLOW_FIRST_ROW_LIST, mt: "6.5rem" },
  { items: ESTATES_USER_FLOW_SECOND_ROW_LIST },
  { items: ESTATES_USER_FLOW_THIRD_ROW_LIST },
  { items: ESTATES_USER_FLOW_FOURTH_ROW_LIST },
  {
    items: ESTATES_USER_FLOW_FIFTH_ROW_LIST,
    gridTemplateColumns: "224.2px 150.39px 296.31px 259.77px 185.94px 150.39px",
  },
];
