import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledBohdanClockAnimation = styled(Lottie)`
  width: calc(25rem / 2.6);
  position: absolute;
  top: calc(0.125rem / 2.6);
  left: calc(0.5rem / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(25rem / 1.6);
    top: calc(0.125rem / 1.6);
    left: calc(0.5rem / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 25rem;
    top: 0.125rem;
    left: 0.5rem;
  }
`;
