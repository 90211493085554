import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 66 43" {...props} animationType="crown" color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.1917 40.9024C43.8159 34.8376 45.2512 28.6584 46.6293 22.5408C43.3595 23.9854 40.5403 25.79 37.6042 27.8005C35.9847 25.2152 32.6204 21.7725 30.161 19.9268C27.3174 22.6544 25.2617 25.1552 23.0073 28.2676C20.54 26.3719 18.0666 24.8874 15.1777 23.6384C16.1492 29.4356 17.5117 35.1339 19.2095 40.7719C22.6109 39.6492 24.853 39.736 27.8975 39.6492C33.1294 39.4996 37.015 39.9561 42.1917 40.9024Z"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDashoffset="10"
      />
      <path
        d="M2.59668 20.9756C3.99605 21.3198 5.38103 21.6987 6.79023 22.0244"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2742 3.14648C17.9213 4.91771 18.6995 6.63078 19.3709 8.39039"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.2422 8.39034C39.3553 6.33374 40.2791 4.15701 41.3873 2.09766"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1133 22.0243C59.2229 21.0352 61.3841 20.0632 63.4036 18.8779"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
