import styled from "styled-components";
// Components
import { ArrowLeftIcon } from "components/svg";

export const StyledFilterDropdownWrapper = styled.div<{ hasSomeFiltersSelected: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ hasSomeFiltersSelected }) => (hasSomeFiltersSelected ? "1.75rem" : "0")};
`;

export const StyledFilterDropdown = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  cursor: pointer;
`;

export const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  margin: 0 0.25rem;
  transform: rotate(270deg);

  ${StyledFilterDropdown}:hover & {
    transform: rotate(90deg);
  }
`;
