// Components
import { Box, ContactUs, Container, Heading, Image, Page } from "components";
import { CaseStudiesFilter } from "./components";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";

const CaseStudiesPage: React.FC = () => {
  const { t } = useTranslation();

  const { isDesktop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Page maxWidth="100vw" px="0">
      <Box width="100%" pt={`${headerHeight}px`}>
        <Container pt={{ _: "1rem", laptop: "4.5rem" }} pb={{ _: "1.5rem" }} position="relative">
          <Box mb={{ _: "2rem", laptop: "3rem" }} position="relative">
            <Heading as="h2" scale="h2">
              {t("Case studies")}
            </Heading>

            {isDesktop && (
              <Box right="0" top="-2.5rem" width="30.5rem" height="14.125rem" position="absolute" zIndex="-1">
                <Image aspectRatio={2.23} src="/images/case-studies/case-studies-elements.webp" />
              </Box>
            )}
          </Box>

          <CaseStudiesFilter />
        </Container>

        <ContactUs />
      </Box>
    </Page>
  );
};

export default CaseStudiesPage;
