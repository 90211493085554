import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 56 56" {...props} color="transparent">
      <path
        d="M37.5621 26.5345C37.8843 25.6639 39.1157 25.6639 39.4379 26.5345L41.7166 32.6926C41.8178 32.9663 42.0337 33.1822 42.3074 33.2834L48.4655 35.5621C49.3361 35.8843 49.3361 37.1157 48.4655 37.4379L42.3074 39.7166C42.0337 39.8178 41.8178 40.0337 41.7166 40.3074L39.4379 46.4655C39.1157 47.3361 37.8843 47.3361 37.5621 46.4655L35.2834 40.3074C35.1822 40.0337 34.9663 39.8178 34.6926 39.7166L28.5345 37.4379C27.6639 37.1157 27.6639 35.8843 28.5345 35.5621L34.6926 33.2834C34.9663 33.1822 35.1822 32.9663 35.2834 32.6926L37.5621 26.5345Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
      />
      <path
        d="M15.0621 11.5345C15.3843 10.6639 16.6157 10.6639 16.9379 11.5345L18.5413 15.8679C18.6426 16.1416 18.8584 16.3574 19.1321 16.4587L23.4655 18.0621C24.3361 18.3843 24.3361 19.6157 23.4655 19.9379L19.1321 21.5413C18.8584 21.6426 18.6426 21.8584 18.5413 22.1321L16.9379 26.4655C16.6157 27.3361 15.3843 27.3361 15.0621 26.4655L13.4587 22.1321C13.3574 21.8584 13.1416 21.6426 12.8679 21.5413L8.53451 19.9379C7.66389 19.6157 7.66389 18.3843 8.53451 18.0621L12.8679 16.4587C13.1416 16.3574 13.3574 16.1416 13.4587 15.8679L15.0621 11.5345Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9999 37C14.5739 37 14.2285 37.3454 14.2285 37.7714V40.8571C14.2285 41.2832 14.5739 41.6286 14.9999 41.6286C15.426 41.6286 15.7714 41.2832 15.7714 40.8571V37.7714C15.7714 37.3454 15.426 37 14.9999 37ZM14.9999 43.168C14.5739 43.168 14.2285 43.5133 14.2285 43.9394V47.0251C14.2285 47.4512 14.5739 47.7965 14.9999 47.7965C15.426 47.7965 15.7714 47.4512 15.7714 47.0251V43.9394C15.7714 43.5133 15.426 43.168 14.9999 43.168ZM9.59961 42.4042C9.59961 41.9782 9.94499 41.6328 10.371 41.6328H13.4568C13.8828 41.6328 14.2282 41.9782 14.2282 42.4042C14.2282 42.8303 13.8828 43.1757 13.4568 43.1757H10.371C9.94499 43.1757 9.59961 42.8303 9.59961 42.4042ZM16.5419 41.6328C16.1159 41.6328 15.7705 41.9782 15.7705 42.4042C15.7705 42.8303 16.1159 43.1757 16.5419 43.1757H19.6277C20.0537 43.1757 20.3991 42.8303 20.3991 42.4042C20.3991 41.9782 20.0537 41.6328 19.6277 41.6328H16.5419Z"
        fill={theme.colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0162 10.8178C34.7149 11.1191 34.7149 11.6075 35.0162 11.9088L37.1981 14.0907C37.4994 14.392 37.9878 14.392 38.2891 14.0907C38.5903 13.7894 38.5903 13.301 38.2891 12.9997L36.1072 10.8178C35.8059 10.5165 35.3174 10.5165 35.0162 10.8178ZM39.3804 15.185C39.0792 15.4862 39.0792 15.9747 39.3804 16.276L41.5624 18.4579C41.8636 18.7591 42.3521 18.7591 42.6533 18.4579C42.9546 18.1566 42.9546 17.6682 42.6533 17.3669L40.4714 15.185C40.1701 14.8837 39.6817 14.8837 39.3804 15.185ZM35.0162 18.4556C34.7149 18.1544 34.7149 17.6659 35.0162 17.3647L37.1981 15.1827C37.4994 14.8815 37.9878 14.8815 38.2891 15.1827C38.5903 15.484 38.5903 15.9724 38.2891 16.2737L36.1072 18.4556C35.8059 18.7569 35.3174 18.7569 35.0162 18.4556ZM39.3804 12.9975C39.0792 13.2987 39.0792 13.7872 39.3804 14.0885C39.6817 14.3897 40.1701 14.3897 40.4714 14.0885L42.6533 11.9065C42.9546 11.6053 42.9546 11.1168 42.6533 10.8156C42.3521 10.5143 41.8636 10.5143 41.5624 10.8156L39.3804 12.9975Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
