import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 85 85" {...props} color="transparent">
      <path
        d="M14.858 42.57c0 14.41 10.828 26.283 24.77 27.889 2.058.236 4.052-.64 5.517-2.109a4.706 4.706 0 0 0 0-6.643c-1.465-1.468-2.659-3.658-1.554-5.415 4.415-7.021 27.267 9.103 27.267-13.722 0-15.504-12.536-28.073-28-28.073s-28 12.569-28 28.073Z"
        stroke={theme.colors[color]}
        strokeWidth="2"
      />
      <circle opacity=".5" cx="58.258" cy="41.097" r="4.2" stroke={theme.colors[color]} strokeWidth="2" />
      <circle opacity=".5" cx="27.458" cy="41.097" r="4.2" stroke={theme.colors[color]} strokeWidth="2" />
      <path
        opacity=".5"
        d="M40.057 28.497a4.2 4.2 0 1 1-8.4 0 4.2 4.2 0 0 1 8.4 0ZM54.057 28.497a4.2 4.2 0 1 1-8.4 0 4.2 4.2 0 0 1 8.4 0Z"
        stroke={theme.colors[color]}
        strokeWidth="2"
      />
    </Svg>
  );
};

export default Icon;
