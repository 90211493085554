import React, { useState } from "react";
import Cookies from "js-cookie";
// Components + styling
import { Text } from "components";
import { StyledCookiesBar, StyledCookiesButton, StyledCookiesTitle, StyledImage } from "./styled";
// Animations
import { cookiesShow } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { COOKIES_KEYS } from "configs";

const CookiesBar: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const [isHideCookies, setIsHideCookies] = useState(() => {
    return Cookies.get(COOKIES_KEYS.setCookies) === "true";
  });

  const handleSetCookies = () => {
    Cookies.set(COOKIES_KEYS.setCookies, "true", { expires: 365 });
    setIsHideCookies(true);
  };

  const variants = cookiesShow(isDesktop);

  return (
    <StyledCookiesBar initial="hidden" animate={isHideCookies ? "hidden" : "visible"} variants={variants}>
      <StyledCookiesTitle textScale="p12Regular">
        <StyledImage src="/images/img-cookie.webp" aspectRatio={1} />

        <Text as="span" $fontWeight="bold">
          {t("We use cookies")}
        </Text>
        {t(
          " to improve analytics and ensure your best experience. By continuing to use this website, you accept our use of cookies. Please check our Privacy Policy for more information.",
        )}
      </StyledCookiesTitle>

      <StyledCookiesButton
        scale={isDesktop ? "xs" : "md"}
        isShowHoverLines={false}
        onClick={() => {
          handleSetCookies();
        }}
      >
        {t("Got it")}
      </StyledCookiesButton>
    </StyledCookiesBar>
  );
};

export default CookiesBar;
