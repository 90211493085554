import styled from "styled-components";
// Components
import { Container } from "components";

export const StyledOuterWrapper = styled.div<{ isAnimationActive: boolean; headerHeight: number }>`
  display: block;
  padding: 0 0 1.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: ${({ isAnimationActive }) => (isAnimationActive ? "600vh" : "auto")};
    min-height: ${({ isAnimationActive }) => (isAnimationActive ? "37.5rem" : "auto")};
    box-sizing: content-box;
    position: relative;
    inset: auto;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0 0 4.375rem;
  }
`;

export const StyledInnerWrapper = styled.div<{ headerHeight: number }>`
  width: 100%;
  padding-top: 3.75rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    box-sizing: border-box;
    position: sticky;
    position: -webkit-sticky;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    margin: auto;
    padding-top: ${({ headerHeight }) => `calc(${headerHeight}px + 1.5rem)`};
    inset: 0px auto auto 0px;
  }
`;

export const StyledContentContainer = styled(Container)`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 5.625rem;
  }
`;
