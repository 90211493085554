// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";

export const EXTRA_INFO_LIST = [
  {
    title: "We are hiring",
    description:
      "If you are passionate about product marketing and have more than 5 years of experience, one of our positions may attract you.",
    btnLabel: "Check positions",
    link: `/${ROUTES.careers}`,
  },
  {
    title: "Development",
    description: "Does your startup need custom software development? Our parent company does it for startups.",
    btnLabel: "Visit website",
    href: EXTERNAL_LINKS.idealogicDev,
    isExternal: true,
  },
];
