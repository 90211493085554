import { useState } from "react";
// Components + styling
import { Box, FlexGap, Heading, Text, Tag } from "components";
import { TitleStraightLinesIcon } from "components/svg";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { HeadingAndFiltersProps } from "./types";

export const HeadingAndFilters: React.FC<HeadingAndFiltersProps> = ({ onFilterClick, filters, tags }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const [isShowAllTags, setIsShowAllTags] = useState(false);

  const viewedTags = isShowAllTags ? tags : tags.slice(0, 5);

  return (
    <>
      <Box mb={{ _: "1.5rem", laptopL: "2.69rem" }}>
        <Text
          textScale={isDesktop ? "p22Regular" : "p16Light"}
          color="textFifth"
          textAlign="center"
          mb={{ _: "0.5rem" }}
        >
          {t("Idealogic’s blog")}
        </Text>
        <Box position="relative" width="max-content" mx="auto">
          <Heading scale="h2" as="h2" textAlign="center">
            {t("Stories by our team")}
          </Heading>
          <Box position="absolute" top={-16} left={-22}>
            <TitleStraightLinesIcon width="1.93rem" />
          </Box>
        </Box>
      </Box>

      <FlexGap
        flexWrap="wrap"
        columnGap="0.25rem"
        rowGap={isDesktop ? "0.5rem" : "0.25rem"}
        mb={{ _: "1.5rem", laptopL: "2.62rem" }}
      >
        <Tag onClick={() => onFilterClick(undefined)} selected={filters.length === 0}>
          {t("All")}
        </Tag>
        {viewedTags.map(({ title, id }) => (
          <Tag onClick={() => onFilterClick(id)} selected={filters.includes(id)} key={id}>
            {title}
          </Tag>
        ))}
        {!!tags.length && (
          <Tag onClick={() => setIsShowAllTags(!isShowAllTags)} isShowMore>
            {t(`Show ${isShowAllTags ? "less" : "more"}`)}
          </Tag>
        )}
      </FlexGap>
    </>
  );
};
