export const INTERACTION_SECTIONS_LIST = [
  {
    id: 1,
    title: "Profile page",
    description: "The user can adjust his profile, see his level and activity on his auction and premium stories",
    image: "/images/case-studies/ugreator/ugreator-interactions-1.webp",
  },
  {
    id: 2,
    title: "Auctions and Premium stories",
    description:
      "Users can follow the art they liked, make a bid or buy the art immediately. You can also unlock a premium story",
    image: "/images/case-studies/ugreator/ugreator-interactions-2.webp",
  },
  {
    id: 3,
    title: "Gamification",
    description:
      "By using the application every day, the user receives daily bonuses. Also complete daily and weekly quests for U-stars",
    image: "/images/case-studies/ugreator/ugreator-interactions-3.webp",
  },
  {
    id: 4,
    title: "Withdraw",
    description: "The user can convert gifts into money and withdraw them to his own bank card",
    image: "/images/case-studies/ugreator/ugreator-interactions-4.webp",
  },
];
