import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 166 29" {...props}>
      <g clipPath="url(#clip0_9387_114021)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.353 0.763672L30.2006 28.2374H16.9074L22.8302 16.851H22.5645C17.6782 23.1498 10.3878 27.2964 0 28.2374V17.0087C0 17.0087 6.6453 16.6189 10.5519 12.5403H0V0.763889H11.8592V10.4499L12.1253 10.4488L16.9715 0.763889H25.9403V10.3884L26.2063 10.388L31.2343 0.763672H44.353Z"
        />
        <path d="M121.527 25.2037H125.347V3.43359H121.527V25.2037Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.471 24.9811C101.301 25.3238 102.145 25.4952 103.003 25.4952C104.421 25.4952 105.689 25.1525 106.809 24.4671C107.928 23.7818 108.795 22.8371 109.411 21.633C110.026 20.4197 110.334 19.0442 110.334 17.5068C110.334 15.9693 110.017 14.594 109.383 13.3807C108.748 12.1674 107.867 11.2273 106.738 10.5604C105.61 9.88429 104.327 9.55087 102.891 9.56011C101.977 9.56011 101.1 9.7361 100.261 10.0881C99.4215 10.44 98.722 10.9401 98.1624 11.5885C98.1192 11.6378 98.0772 11.6877 98.0365 11.738V3.44727H94.2031V25.2035H98.0085L98.0008 23.1711C98.0992 23.2909 98.2045 23.408 98.3162 23.5224C98.9224 24.143 99.6407 24.6292 100.471 24.9811ZM104.388 21.4663C103.763 21.8461 103.05 22.036 102.248 22.036C101.455 22.036 100.727 21.8414 100.065 21.4524C99.4028 21.0541 98.8759 20.5124 98.4841 19.8269C98.1017 19.1416 97.9105 18.3635 97.9105 17.4929C97.9012 16.6223 98.0877 15.8443 98.4701 15.1589C98.8619 14.4643 99.3888 13.9271 100.051 13.5474C100.713 13.1584 101.445 12.9685 102.248 12.9778C103.05 12.9685 103.763 13.1537 104.388 13.5335C105.022 13.9039 105.507 14.4365 105.843 15.1312C106.188 15.8165 106.361 16.6038 106.361 17.4929C106.361 18.3821 106.188 19.1694 105.843 19.8547C105.507 20.5402 105.022 21.0773 104.388 21.4663Z"
        />
        <path d="M49.8867 4.75391H54.2518L58.1564 18.9144L62.3103 4.75391H65.9478L70.4527 18.6302L74.2022 4.75391H78.2034L72.2995 25.2042H68.5361L63.9551 11.6481L59.75 25.2042H55.9446L49.8867 4.75391Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.6494 25.5648C83.1289 25.5742 81.7579 25.2407 80.5361 24.5647C79.3236 23.8792 78.3677 22.9298 77.668 21.7165C76.9779 20.5033 76.6328 19.1186 76.6328 17.5625C76.6328 16.0528 76.9873 14.6868 77.6961 13.4642C78.4049 12.2416 79.3655 11.2877 80.5781 10.6023C81.7906 9.91691 83.1429 9.57422 84.6352 9.57422C86.3142 9.57422 87.7831 9.94469 89.0422 10.6856C90.3108 11.4266 91.2574 12.4778 91.8824 13.8393C92.5166 15.1915 92.7451 16.7429 92.5679 18.4934H80.5841C80.6176 19.1908 80.7928 19.8253 81.1097 20.3967C81.4642 21.0265 81.9585 21.5174 82.5927 21.8693C83.227 22.2213 83.9357 22.3973 84.7193 22.3973C85.3162 22.388 85.8712 22.2862 86.3841 22.0917C86.8971 21.888 87.3307 21.6147 87.6852 21.2719C88.049 20.9293 88.3055 20.5404 88.4548 20.1051H92.456C92.2135 21.1701 91.7285 22.1195 91.001 22.953C90.2734 23.7774 89.3595 24.421 88.2588 24.8841C87.1583 25.3472 85.9551 25.5742 84.6494 25.5648ZM81.1237 14.6729C80.9002 15.0599 80.7453 15.4767 80.6591 15.9233H88.6029C88.5453 15.3591 88.3699 14.8451 88.077 14.3811C87.7411 13.8347 87.2842 13.4133 86.7059 13.1169C86.1277 12.8112 85.4748 12.6584 84.7473 12.6584C83.9731 12.6584 83.269 12.8344 82.6346 13.1864C82.0005 13.5383 81.4968 14.0338 81.1237 14.6729Z"
        />
        <path d="M113.146 9.89506C113.146 8.65396 113.43 7.5518 113.999 6.58855C114.568 5.61606 115.366 4.86121 116.392 4.32402C117.427 3.77757 118.635 3.49508 120.015 3.47656V6.85251C119.372 6.86178 118.821 6.99608 118.364 7.25542C117.917 7.50549 117.572 7.87133 117.329 8.35294C117.106 8.79634 116.985 9.31037 116.968 9.89506H119.931V13.1043H116.965V25.205H113.146V13.1043H110.684V9.89506H113.146Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.058 25.5373C133.5 25.5373 132.105 25.1992 130.874 24.5232C129.653 23.8377 128.692 22.8931 127.992 21.689C127.302 20.4757 126.957 19.1004 126.957 17.5628C126.957 16.0161 127.302 14.6361 127.992 13.4228C128.692 12.2002 129.653 11.2509 130.874 10.5747C132.105 9.89861 133.5 9.56055 135.058 9.56055C136.624 9.56055 138.024 9.89861 139.255 10.5747C140.495 11.2509 141.461 12.1956 142.151 13.4089C142.841 14.6222 143.191 16.0068 143.2 17.5628C143.191 19.1004 142.841 20.4757 142.151 21.689C141.47 22.8931 140.509 23.8377 139.269 24.5232C138.028 25.1992 136.624 25.5373 135.058 25.5373ZM135.058 22.0503C135.878 22.0503 136.606 21.865 137.24 21.4946C137.874 21.1148 138.364 20.5869 138.709 19.9107C139.054 19.2254 139.227 18.4427 139.227 17.5628C139.227 16.6737 139.054 15.8864 138.709 15.201C138.364 14.5157 137.874 13.9877 137.24 13.6173C136.606 13.2375 135.878 13.0477 135.058 13.0477C134.246 13.0477 133.523 13.2375 132.889 13.6173C132.264 13.9877 131.779 14.5157 131.434 15.201C131.089 15.8864 130.921 16.6737 130.93 17.5628C130.93 18.4427 131.103 19.2254 131.448 19.9107C131.802 20.5869 132.287 21.1148 132.903 21.4946C133.528 21.865 134.246 22.0503 135.058 22.0503Z"
        />
        <path d="M147.327 9.89453H143.074L147.677 25.2045H151.398L154.426 15.426L157.694 25.2045H161.359L165.977 9.89453H162.129L159.51 19.424L156.616 9.89453H152.895L150.015 19.6339L147.327 9.89453Z" />
      </g>
      <defs>
        <clipPath id="clip0_9387_114021">
          <rect width="166" height="29" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
