import styled from "styled-components";
// Components
import { Flex } from "components";

export const StyledFileUploadContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaQueries.mobileM} {
    flex-wrap: nowrap;
  }
`;

export const StyledFileUploadWrapper = styled(Flex)`
  max-width: 13.75rem;
  height: 3.125rem;
  flex-grow: 1;
  align-items: center;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: 1px dashed ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.large};
  background-color: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.mobileL} {
    margin: 0 1rem;
    padding: 0.5rem 0.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: initial;
    width: 19.375rem;
    height: 3.875rem;
    padding: 1rem 2rem;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.textThird};
  }
`;

export const StyledFileUploadInput = styled.input`
  display: none;
`;

export const StyledClearIconWrapper = styled(Flex)`
  width: 3.125rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.textThird};
  }
`;
