import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledUfoAnimation = styled(Lottie)`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.radii.large};
  overflow: hidden;
`;
