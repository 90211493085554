import styled from "styled-components";
import { flexbox, layout, space } from "styled-system";
// Components
import { Box, Flex, Text } from "components";
// Types
import { InputIconProps, StyledInputGroupProps } from "./types";
import { Scales, scales as inputScales } from "components/inputs/input/types";

const getPadding = (scale: Scales, hasIcon: boolean) => {
  if (!hasIcon) {
    return "1.5rem";
  }

  switch (scale) {
    case inputScales.SM:
      return "1.75rem";
    case inputScales.LG:
      return "3rem";
    case inputScales.MD:
      return "3rem";
  }
};

export const StyledInputGroup = styled(Flex)<StyledInputGroupProps>`
  width: 100%;
  flex-wrap: ${({ isColumnLabel }) => (isColumnLabel ? "wrap" : "nowrap")};
  align-items: flex-start;
  margin-bottom: 0.5rem;

  input {
    width: 100%;
    padding-left: ${({ hasStartIcon, scale }) => getPadding(scale, hasStartIcon)};
    padding-right: ${({ hasEndIcon, scale }) => getPadding(scale, hasEndIcon)};
  }
  ${layout}
  ${space}
  ${flexbox}
`;

export const StyledUnderlineIconWrapper = styled(Box)`
  position: absolute;
  top: 100%;
  right: 0;
`;

export const InputIcon = styled.div<InputIconProps>`
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;

  ${({ isEndIcon, scale }) =>
    isEndIcon
      ? `
      right: ${scale === inputScales.SM ? "0.5rem" : "1rem"};
    `
      : `
      left: ${scale === inputScales.SM ? "0.5rem" : "1rem"};
    `}
`;

export const InputError = styled(Text)`
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.error};
  white-space: pre-wrap;
`;
