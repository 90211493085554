import styled from "styled-components";
// Components
import { Box, Flex, Link, Text } from "components";
import { moveUpTextAnimation } from "theme/animations";

export const StyledPortfolioLines = styled(Box)`
  position: absolute;
  top: 0;
  right: 0.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 2rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 3.375rem;
  }
`;

export const StyledPortfolioDescription = styled(Flex)`
  flex-direction: column;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledWaveUnderlinedText = styled(Text)`
  position: relative;
`;

export const StyledWaveWrapper = styled.span`
  width: 100%;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledProjectsList = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const StyledLinkMoreCases = styled(Link)`
  ${moveUpTextAnimation}
  margin: 0 auto;
`;
