import styled from "styled-components";
// Components
import { Column } from "components";

export const StyledPlanetcoinWireframesWrapper = styled(Column)`
  width: 100%;
  min-height: 28.25rem;
  position: relative;
  margin-bottom: 4.75rem;
  padding-top: 5rem;
  background: url("/images/planetcoin-wireframes-gradient-bg-sm.webp") 0% 0% / 100% 100% no-repeat;

  ${({ theme }) => theme.mediaQueries.laptop} {
    min-height: 60.5rem;
    padding-top: 10.625rem;
    margin-bottom: 12.5rem;
    background: url("/images/planetcoin-wireframes-gradient-bg.webp") 0% 0% / 100% 100% no-repeat;
  }
`;
