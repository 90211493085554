import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
// Components + styling
import { ContactUs, Container, Page } from "components";
import { BlogContent, RelevantArticlesSlider, SkeletonLoader } from "./components";
import { NoBlogPlug } from "components/post-preview/components";
// Hooks
import { useElementDimensions } from "hooks";
// Services
import { GetPostResponse, getPostBySlug } from "services";
// Constants
import { BLOCK_ID } from "configs";

const SingleBlogPage: React.FC = () => {
  const { slug } = useParams();
  const { data, loading } = useQuery<GetPostResponse>(getPostBySlug, { variables: { slug } });

  const { post } = data || {};
  const { tags = [] } = post || {};

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  const formateDescription = (description: string) => {
    if (!description) {
      return "";
    } else {
      const sentencesArr = description.split(". ");
      const firstSentence = sentencesArr[0].replace("<p>", "");
      return [firstSentence, sentencesArr[1]].join(". ");
    }
  };

  return (
    <Page
      customDescription={post?.seo?.description || formateDescription(post?.content?.html || "")}
      customTitle={post?.seo?.title || post?.title || slug?.replaceAll("-", " ")}
      customImg={post?.seo?.image?.url || post?.coverImage?.url || ""}
      keywords={post?.seo?.keywords || tags.map(({ title }) => title)}
      maxWidth="100vw"
      px="0"
      pt={`calc(${headerHeight}px + 3.75rem)`}
    >
      <Container padding="0" mb={{ _: 0, laptopL: "1.12rem" }}>
        {!loading && !post && <NoBlogPlug />}
        {loading ? <SkeletonLoader /> : <BlogContent post={post} />}
        <RelevantArticlesSlider currentPost={post} tags={tags} />
      </Container>

      <ContactUs />
    </Page>
  );
};

export default SingleBlogPage;
