import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledWireframesParallaxImageWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 7.75rem;
  position: absolute;
  top: 3.75rem;
  right: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 12.125rem;
    top: -6.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -18.75rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 18.375rem;
  display: none;
  position: absolute;
  top: -8.75rem;
  right: 10.875rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: block;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 4.75rem;
  display: none;
  position: absolute;
  top: -0.625rem;
  right: 27.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: block;
  }
`;
