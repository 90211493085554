import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Link } from "components";
import { LinkedInIcon, Svg } from "components/svg";
// Animations
import { moveUpTextAnimation } from "theme/animations";
// Types

export const StyledVideoSliderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    margin-top: 2rem;
  }
`;

export const StyledVideoSlide = styled(motion.div)`
  height: 31.125rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 37.75rem;
  }
`;

export const StyledDescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: start;
  margin-top: 0.75rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-top: 1.5rem;
  }
`;

export const StyledDescription = styled.div`
  display: flex;
  display: grid;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  position: absolute;
  cursor: pointer;

  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    ${Svg} {
      transform: scale(1.2);
    }
  }
`;

export const StyledInfoWrapper = styled.div`
  height: auto;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
`;

export const StyledLinkedInIcon = styled(LinkedInIcon)`
  width: 1rem;
  height: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

export const StyledLinkedInLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.colors.textThird};
  padding-left: 0.625rem;
  margin-left: 0.625rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-left: 1rem;
    margin-left: 1rem;
  }

  ${StyledLinkedInIcon} {
    path {
      transition: ${({ theme }) => theme.transitions.default};
    }
  }

  &:hover {
    ${StyledLinkedInIcon} {
      path {
        &:first-child {
          fill: ${({ theme }) => theme.colors.linkedInLinkHover};
        }
      }
    }
  }
`;

export const StyledProjectLink = styled(Link)`
  ${moveUpTextAnimation}
  line-height: 1.75rem;
`;
