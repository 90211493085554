import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box } from "components/layout";

export const StyledWireframesLineWrapper = styled(Box)`
  position: relative;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 1.5rem;
  }
`;

export const StyledWireframesLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
`;

export const StyledLineMotion = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
`;
