import styled from "styled-components";
// Components
import { StyledAddMedDescription, StyledAddMedWrapper, StyledContentContainer } from "../add-med/styled";

export const StyledUgreatorWrapper = styled(StyledAddMedWrapper)`
  background: url("/images/ugreator-bg.webp") 0 no-repeat;
  background-size: cover;
`;

export const StyledUgreatorContentContainer = styled(StyledContentContainer)``;

export const StyledUgreatorDescription = styled(StyledAddMedDescription)``;
