import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix, clearfixDisplayNone } from "styles";
// Components
import { Text } from "components";

export const StyledTestimonialVideoBox = styled.div<{ borderRadius: string; isHovered: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.radii.medium};
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.tablet} {
    border-radius: ${({ borderRadius }) => borderRadius};

    ::after {
      ${clearfix}
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: ${({ isHovered }) => (isHovered ? 0 : 1)};
      border-radius: ${({ borderRadius }) => borderRadius};
      backdrop-filter: blur(10px);
      background-color: ${({ theme }) => theme.colors.white}20;
      transition: ${({ theme }) => theme.transitions.cubic_bezier_0_5s};
    }
  }
`;

export const StyledTestimonialVideo = styled.video<{ borderRadius: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.radii.medium};
  background-color: ${({ theme }) => theme.colors.disabledLight};

  ${({ theme }) => theme.mediaQueries.tablet} {
    border-radius: ${({ borderRadius }) => borderRadius};
  }
`;

export const StyledQuoteWrapper = styled(motion.div)<{ isShowShadow: boolean }>`
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  margin: 0 2.5rem;

  ::after {
    ${({ isShowShadow }) => (isShowShadow ? clearfix : clearfixDisplayNone)}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
    filter: blur(24px);
    background: ${({ theme }) => theme.colors.dark}59;
  }
`;

export const StyledQuote = styled(Text)`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;
