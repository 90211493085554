// Types
import { UgreatorUserFlowsSecondColumnItem, ugreatorUserFlowsSecondColumnConnectionVariant } from "./types";

export const UGREATOR_USER_FLOWS_SECOND_COLUMN_LIST: UgreatorUserFlowsSecondColumnItem[] = [
  {
    label: "Homepage",
    variant: ugreatorUserFlowsSecondColumnConnectionVariant.homepage,
    connectorLinesSrc: "/images/case-studies/ugreator/ugreator-user-flows-homepage-connectors.svg",
    borderGradient: "ugreatorUserFlowsHomepageBorder",
    itemGradient: "ugreatorUserFlowsHomepage",
  },
  {
    label: "Auctions",
    variant: ugreatorUserFlowsSecondColumnConnectionVariant.auctions,
    connectorLinesSrc: "/images/case-studies/ugreator/ugreator-user-flows-auctions-connectors.svg",
    borderGradient: "ugreatorUserFlowsAuctionsBorder",
    itemGradient: "ugreatorUserFlowsAuctions",
  },
  {
    label: "My Profile",
    variant: ugreatorUserFlowsSecondColumnConnectionVariant.myProfile,
    connectorLinesSrc: "/images/case-studies/ugreator/ugreator-user-flows-my-profile-connectors.svg",
    borderGradient: "ugreatorUserFlowsMyProfileBorder",
    itemGradient: "ugreatorUserFlowsMyProfile",
  },
  {
    label: "U-shop",
    variant: ugreatorUserFlowsSecondColumnConnectionVariant.uShop,
    connectorLinesSrc: "/images/case-studies/ugreator/ugreator-user-flows-u-shop-connectors.svg",
    borderGradient: "ugreatorUserFlowsUShopBorder",
    itemGradient: "ugreatorUserFlowsUShop",
  },
  {
    label: "Activities",
    variant: ugreatorUserFlowsSecondColumnConnectionVariant.activities,
    connectorLinesSrc: "/images/case-studies/ugreator/ugreator-user-flows-activities-connectors.svg",
    borderGradient: "ugreatorUserFlowsActivitiesBorder",
    itemGradient: "ugreatorUserFlowsActivities",
  },
];
