// Types
import { UgreatorUserFlowsThirdColumnItem } from "./types";

export const UGREATOR_USER_FLOWS_THIRD_COLUMN_HOMEPAGE_LIST: UgreatorUserFlowsThirdColumnItem[] = [
  {
    label: "Auction page",
    borderColor: "ugreatorUserFlowThirdColumnHomepage",
  },
  {
    label: "Explore",
    borderColor: "ugreatorUserFlowThirdColumnHomepage",
  },
];

export const UGREATOR_USER_FLOWS_THIRD_COLUMN_AUCTIONS_LIST: UgreatorUserFlowsThirdColumnItem[] = [
  {
    label: "Make a bid flow",
    borderColor: "ugreatorUserFlowThirdColumnAuctions",
  },
  {
    label: "Create an auction flow",
    borderColor: "ugreatorUserFlowThirdColumnAuctions",
  },
];

export const UGREATOR_USER_FLOWS_THIRD_COLUMN_MY_PROFILE_LIST: UgreatorUserFlowsThirdColumnItem[] = [
  {
    label: "Settings",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
    connectorLinesSrc: "/images/case-studies/ugreator/ugreator-user-flows-settings-connectors.svg",
  },
  {
    label: "Wallet",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Create a story",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
];

export const UGREATOR_USER_FLOWS_THIRD_COLUMN_U_SHOP_LIST: UgreatorUserFlowsThirdColumnItem[] = [
  {
    label: "PRO subscription",
    borderColor: "ugreatorUserFlowThirdColumnUShop",
  },
  {
    label: "Buy U-Stars",
    borderColor: "ugreatorUserFlowThirdColumnUShop",
  },
  {
    label: "Buy Frames/ Music/ Effects,Equalizers",
    borderColor: "ugreatorUserFlowThirdColumnUShop",
  },
];

export const UGREATOR_USER_FLOWS_THIRD_COLUMN_ACTIVITIES_LIST: UgreatorUserFlowsThirdColumnItem[] = [
  {
    label: "Notifications",
    borderColor: "ugreatorUserFlowThirdColumnActivities",
  },
  {
    label: "Stories",
    borderColor: "ugreatorUserFlowThirdColumnActivities",
  },
  {
    label: "Quests",
    borderColor: "ugreatorUserFlowThirdColumnActivities",
  },
];
