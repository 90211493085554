// Types
import { Colors } from "theme/types";
import { StyledDefaultFlowItemProps } from "../../types";

export const ugreatorUserFlowsFirstColumnConnectionType = {
  top: "top",
  right: "right",
} as const;

export type UgreatorUserFlowsFirstColumnConnectionType = keyof typeof ugreatorUserFlowsFirstColumnConnectionType;

export type UgreatorUserFlowsFirstColumnItem = {
  label: string;
  borderColor: keyof Colors;
  connectionType: UgreatorUserFlowsFirstColumnConnectionType;
};

export interface StyledFirstColumnFlowItemProps extends StyledDefaultFlowItemProps {
  connectionType: UgreatorUserFlowsFirstColumnConnectionType;
}
