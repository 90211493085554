// Components + styling
import { Wireframes } from "components";
import { StyledPlanetcoinWireframesWrapper } from "./styled";
// Utils
import { generateRepeatedArray } from "utils";
// Constants
import { DOWN_LINE_IMAGES_LIST, UP_LINE_IMAGES_LIST } from "./constants";

const PlanetcoinWireframes: React.FC = () => {
  const upLineImages = generateRepeatedArray({
    sourceArray: UP_LINE_IMAGES_LIST,
    repeatCount: 6,
  });
  const downLineImages = generateRepeatedArray({
    sourceArray: DOWN_LINE_IMAGES_LIST,
    repeatCount: 6,
  });

  return (
    <StyledPlanetcoinWireframesWrapper>
      <Wireframes
        upLineImages={upLineImages}
        downLineImages={downLineImages}
        wireframesTitle="Wireframes"
        wireframesSubTitle="The structure for each page and function"
        upBaseVelocity={{ baseVelocity: 0.5 }}
        downBaseVelocity={{ baseVelocity: -0.5 }}
        wireframeItemWidth={{ desktop: 39.125, mobile: 17.25 }}
        wireframeItemRatio={1.6}
        wireframeItemBorderColor="planetcoinWireframesBorder"
        isBorder={true}
      />
    </StyledPlanetcoinWireframesWrapper>
  );
};

export default PlanetcoinWireframes;
