import { motion } from "framer-motion";
// Components + styling
import { Column, Text } from "components";
import { StyledFirstColumnFlowItem } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Animation
import { ugreatorAnimatedItems } from "../../animations";
// Constants
import { UGREATOR_USER_FLOWS_FIRST_COLUMN_LIST } from "./constants";

const UgreatorUserFlowsFirstColumn: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <motion.div
      style={{ position: "relative" }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={ugreatorAnimatedItems(0)}
    >
      <Column mt={{ _: "3rem", laptop: "8.875rem" }}>
        {UGREATOR_USER_FLOWS_FIRST_COLUMN_LIST.map(({ label, borderColor, connectionType }) => (
          <StyledFirstColumnFlowItem key={label} $borderColor={borderColor} connectionType={connectionType}>
            <Text textScale={isDesktop ? "p16Medium" : "p6Medium"}>{t(label)}</Text>
          </StyledFirstColumnFlowItem>
        ))}
      </Column>
    </motion.div>
  );
};

export default UgreatorUserFlowsFirstColumn;
