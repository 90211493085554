import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", animationType, ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 37 75" {...props} animationType={animationType} color="transparent">
      <path
        d="M17 2C15.1667 12.1667 12.5999 35.6 22.9999 36C35.9999 36.5 40 20.5 25 17.5C10 14.5 4.49994 28.5 10 43.5C15.5001 58.5 11.4999 67.5 11.4999 73M11.4999 73L22.9999 63.5M11.4999 73L1.5 63.5"
        stroke={theme.colors[color]}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
