import styled from "styled-components";
// Components
import { Flex } from "components";
import { StyledFooterLink } from "components/footer/styled";

export const StyledFooterSocialItem = styled(Flex)`
  align-items: center;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 1.5rem;
  }

  &:hover,
  &:focus {
    ${StyledFooterLink} {
      border-bottom-color: ${({ theme }) => theme.colors.textFourth};
    }
  }
`;
