import styled from "styled-components";
import Slider from "react-slick";

export const StyledOurTeamSlider = styled(Slider)`
  .slick-list {
    ${({ theme }) => theme.mediaQueries.laptopL} {
      padding-left: 3.75rem;
    }
  }
`;
