import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
// Components + styling
import { Flex, Image, Text } from "components";
import {
  StyledChatInboxMessage,
  StyledChatMessagesList,
  StyledChatSentMessage,
  StyledChatSentMessageWrapper,
  StyledManagerStatus,
} from "./styled";
// Context
import { useSocketContext, useTranslation } from "context";
// Types
import { userRoleTypes } from "context/socket-context/types";
import { ChatMessageListProps } from "./types";

const ChatMessageList: React.FC<ChatMessageListProps> = ({ isChatOpen, currentManagerData }) => {
  const { t } = useTranslation();
  const { chatMessages } = useSocketContext();
  const { image, name } = currentManagerData;

  const [isManagerTyping, setIsManagerTyping] = useState(false);

  const bottomElementList = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isChatOpen) {
      scrollToBottomElemList();
    }
  }, [isChatOpen]);

  useEffect(() => {
    scrollToBottomElemList();

    if (chatMessages.length && chatMessages[chatMessages.length - 1].role === userRoleTypes.USER) {
      setTimeout(() => {
        setIsManagerTyping(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setIsManagerTyping(false);
      }, 1000);
    }
  }, [chatMessages]);

  const scrollToBottomElemList = () => {
    bottomElementList?.current?.scrollIntoView({ behavior: "instant" });
  };

  return (
    <StyledChatMessagesList>
      {chatMessages.map(({ content, role, created_at, isRead, error }, index) => {
        return role === userRoleTypes.ASSISTANT ? (
          <StyledChatInboxMessage
            key={content + index}
            isPreviousMessageTypeSame={chatMessages[index - 1]?.role === userRoleTypes.ASSISTANT}
            ref={index === chatMessages.length - 1 ? bottomElementList : null}
          >
            <Text textScale="p14Regular">{content}</Text>
            <Text textScale="p14Regular" color="textFifth" textAlign="right">
              {moment(created_at * 1000).format("DD MMM HH:mm")}
            </Text>
          </StyledChatInboxMessage>
        ) : (
          <StyledChatSentMessageWrapper
            key={content + index}
            isPreviousMessageTypeSame={chatMessages[index - 1]?.role === userRoleTypes.USER}
            ref={index === chatMessages.length - 1 ? bottomElementList : null}
          >
            <StyledChatSentMessage isPreviousMessageTypeSame={chatMessages[index - 1]?.role === userRoleTypes.USER}>
              <Text textScale="p14Regular" color={error ? "error" : "white"}>
                {content}
              </Text>
              <Flex justifyContent="flex-end">
                <Text textScale="p14Regular" color="textFifth" mx="0.25rem">
                  {moment(created_at * 1000).format("DD MMM, HH:mm")}
                </Text>
                <Image
                  width="1.25rem"
                  src={`/images/chat-message-${error ? "error" : isRead ? "read" : "unread"}.svg`}
                  aspectRatio={1}
                />
              </Flex>
            </StyledChatSentMessage>
            {error && (
              <Text textScale="p12Regular" color="error" mt="0.25rem" textAlign="right">
                {error}
              </Text>
            )}
          </StyledChatSentMessageWrapper>
        );
      })}

      <StyledManagerStatus isTyping={isManagerTyping || !chatMessages.length}>
        <Image width="1.75rem" src={image} aspectRatio={1} variant="circle" />
        <Text textScale="p14Regular" color="textThird" mx="0.5rem" fontStyle="italic">
          {t("%name%'s typing...", { name })}
        </Text>
      </StyledManagerStatus>
    </StyledChatMessagesList>
  );
};

export default ChatMessageList;
