import React from "react";
// Components + styling
import { Tab } from "components";
import { StyledTabPanelWrapper } from "./styled";
// Types
import { TabsPanelProps } from "./types";

const TabsPanel: React.FC<TabsPanelProps> = ({ titles, activeTab, onTabClick, color }) => {
  return (
    <StyledTabPanelWrapper>
      {titles.map((title, index) => (
        <Tab color={color} key={title} title={title} onClick={() => onTabClick(index)} isActive={activeTab === index} />
      ))}
    </StyledTabPanelWrapper>
  );
};

export default TabsPanel;
