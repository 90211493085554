// Components + styling
import { Text } from "components/text";
import { StyledTag, StyledTagContent } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TagProps } from "./types";

const Tag: React.FC<TagProps> = ({ selected, isShowMore = false, children, ...rest }) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledTag
      selected={selected}
      $backgroundColor={isShowMore ? "bgSecond" : "lineLight"}
      padding="1px"
      overflow="hidden"
      {...rest}
    >
      <StyledTagContent selected={selected} onClick={rest.onClick}>
        <Text textScale={isDesktop ? "p16Medium" : "p12Medium"} color={isShowMore ? "textAccent" : "textMain"}>
          {children}
        </Text>
      </StyledTagContent>
    </StyledTag>
  );
};

export default Tag;
