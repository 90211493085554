import React from "react";
// Components + styling
import { StyledPipeAnimation } from "./styled";
// Animations
import irenePipe from "assets/animations/irene-pipe.json";
// Types
import { SliderAnimationProps } from "../types";

const IreneSliderAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref, ImageLottieWrapper }) => {
  const SelectedLottieWrapper = ImageLottieWrapper ?? StyledPipeAnimation;
  return <SelectedLottieWrapper lottieRef={lottieAnimation1Ref} animationData={irenePipe} autoplay={false} loop />;
};

export default IreneSliderAnimation;
