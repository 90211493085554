import styled from "styled-components";
import { clearfixDisplayNone } from "styles";

export const StyledInnerWrapper = styled.div<{ headerHeight: number }>`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  padding: ${({ headerHeight }) => `calc(${headerHeight}px + 2.5rem) 0`};
  background: ${({ theme }) => theme.colors.bgDark} url("/images/find-solution-banner-bg-sm.webp") no-repeat center top;
  background-size: contain;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: ${({ headerHeight }) => `calc(${headerHeight}px + 2.5rem) 0 5.625rem`};
    border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
    background: ${({ theme }) => theme.colors.bgDark};
  }
`;

export const StyledBgWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
  overflow: hidden;

  ::after {
    ${clearfixDisplayNone}
    width: 21.5rem;
    height: 35.25rem;
    border-radius: 565px;
    position: absolute;
    top: 2.5rem;
    left: 50%;
    opacity: 0.15;
    z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
    filter: blur(77px);
    transform: translateX(-50%);
    background: ${({ theme }) => theme.colors.findSolutionBannerBg};

    ${({ theme }) => theme.mediaQueries.laptop} {
      display: block;
    }
  }
`;
