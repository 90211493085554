export const PRIVACY_POLICY_DEFAULTS_LIST = [
  {
    label: "Compliance",
    description:
      'You have the right to opt out of marketing communications that IDEALOGIC DESIGN sends you at any time. You can exercise this right by clicking on the "unsubscribe" link in the marketing emails IDEALOGIC DESIGN sends you or by using the relevant opt-out mechanism for non-email based marketing.',
  },
  {
    label: "Use of information",
    description:
      "to engage in, and providing higher quality solutions and services to IDEALOGIC DESIGN’s customers. IDEALOGIC DESIGN uses your location information to tailor your experience with IDEALOGIC DESIGN WEBSITE. It may also use any non-personal information collected to enhance IDEALOGIC DESIGN WEBSITE, and your IP address for website diagnostics and administration. IDEALOGIC DESIGN will only retain your personal information for as long as necessary to fullfil the purposes it was collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal information, IDEALOGIC DESIGN considers the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which IDEALOGIC DESIGN processes your personal information and whether it can achieve those purposes through other means, and the applicable legal requirements",
  },
  {
    label: "International transfers",
    description:
      "IDEALOGIC DESIGN processes information on servers in many countries around the world and you consent to your personal information being processed on a server located outside the country in which you reside. These countries may have data protection laws that are different to the laws of your country (and, in some cases, may not be as protective). However, IDEALOGIC DESIGN has taken appropriate safeguards to require that your personal information will remain protected in accordance with this Privacy Policy, including, utilizing the European Commission’s Standard Contractual Clauses for transfers of personal information between its group companies, which requires all group companies to protect personal information being processed from the European Economic Area to an equivalent standard to that required under European Union data protection law",
  },
  {
    label: "Information security",
    description:
      "IDEALOGIC DESIGN protects your personal information in the same way that it protects its other confidential information, such as storing it on secure servers that only select IDEALOGIC DESIGN personnel have access to, and by encrypting it to help prevent access by any unauthorized parties. IDEALOGIC DESIGN has put in place procedures to deal with any personal information breach and will notify you and any applicable regulator of a breach when legally required to do so",
  },
  {
    label: "Marketing",
    description:
      "As explained above, IDEALOGIC DESIGN will collect and use your personal data for marketing purposes. Your data will be stored in our CRM system. We will use your data to inform you about IDEALOGIC DESIGN products and services we think may be of your interest and sending you our newsletters. For statistical purposes, we will collect information about your response to our emails and our interaction with you to make decisions on future marketing. If you wish to stop that we collect and use this information please sent e-mail ",
  },
  {
    label: "Links",
    description:
      "IDEALOGIC WEBSITE contains links, plug-ins and applications to various third party websites. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. IDEALOGIC DESIGN has no control over, and is not responsible for the content, privacy policies or reliability of such third party connections. When you leave IDEALOGIC DESIGN WEBSITE, IDEALOGIC DESIGN encourages you to read the privacy notice and privacy policy of every website you visit.",
  },
  {
    label: "Cookies",
    description:
      "You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies some parts of IDEALOGIC DESIGN WEBSITE may become inaccessible or not function properly. For more information about the cookies that IDEALOGIC DESIGN uses, please see IDEALOGIC DESIGN’s Cookie Policy",
  },
  {
    label: "Changes to the policy",
    description:
      "IDEALOGIC DESIGN reserves the right to modify or amend this Privacy Policy at its own discretion from time to time. The changes can be viewed here or elsewhere on IDEALOGIC DESIGN WEBSITE. Your continued use of IDEALOGIC DESIGN WEBSITE constitutes your acceptance of those changes. This version was last updated on 06 July 2018 and historic versions can be obtained by contacting IDEALOGIC DESIGN",
  },
];
