import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledAmbassadorsSliderWrapper = styled(motion.div)<{ isAnimationActive: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  transition: ${({ theme }) => theme.transitions.linear_0_2s};

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    flex-wrap: ${({ isAnimationActive }) => (isAnimationActive ? "nowrap" : "wrap")};
    justify-content: space-between;
    align-items: center;
    column-gap: 2.5rem;
    position: relative;
    left: ${({ isAnimationActive }) => (isAnimationActive ? "30%" : "0")};
    rotate: ${({ isAnimationActive }) => (isAnimationActive ? "3deg" : "0")};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    left: ${({ isAnimationActive }) => (isAnimationActive ? "45%" : "0")};
  }
`;
