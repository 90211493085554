// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFifth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M10.4227 9.57563C10.1884 9.34131 9.80846 9.34131 9.57415 9.57563C9.33983 9.80994 9.33983 10.1898 9.57415 10.4242L11.1499 11.9999L9.57417 13.5756C9.33985 13.8099 9.33985 14.1898 9.57417 14.4242C9.80848 14.6585 10.1884 14.6585 10.4227 14.4242L11.9984 12.8484L13.5741 14.4241C13.8084 14.6585 14.1883 14.6585 14.4227 14.4241C14.657 14.1898 14.657 13.8099 14.4227 13.5756L12.8469 11.9999L14.4227 10.4242C14.657 10.1899 14.657 9.80996 14.4227 9.57564C14.1884 9.34133 13.8085 9.34133 13.5741 9.57564L11.9984 11.1514L10.4227 9.57563Z"
        fill={theme.colors[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={theme.colors[color]}
        d="M11.9984 3.3999C7.24879 3.3999 3.39844 7.25025 3.39844 11.9999C3.39844 16.7496 7.24879 20.5999 11.9984 20.5999C16.7481 20.5999 20.5984 16.7496 20.5984 11.9999C20.5984 7.25025 16.7481 3.3999 11.9984 3.3999ZM4.59844 11.9999C4.59844 7.913 7.91153 4.5999 11.9984 4.5999C16.0853 4.5999 19.3984 7.913 19.3984 11.9999C19.3984 16.0868 16.0853 19.3999 11.9984 19.3999C7.91153 19.3999 4.59844 16.0868 4.59844 11.9999Z"
      />
    </Svg>
  );
};

export default Icon;
