// Components + styling
import { Box, Container, Flex, HashLayoutWrapper, Heading, Text } from "components";
import { EStatesBannerParallaxImage } from "./components";
import {
  EStatesBannerWrapper,
  EStatesBannerSubtitle,
  EStatesBannerCategory,
  EStatesStatisticsList,
  EStatesStatisticItem,
  EStatesBannerStatisticValue,
  EStatesBannerStatisticLabel,
  EStatesBannerPointsList,
  EStatesBannerPointItemWrapper,
  EStatesBannerPointItem,
} from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useAnimationStatus, useMatchBreakpoints } from "hooks";
// Constants
import { BANNER_CATEGORY_LIST, BANNER_POINTS_LIST, BANNER_STATISTICS_LIST } from "./constants";
// Types
import { animationStatusCheckVariants } from "hooks/use-animation-status/types";

const EStatesBanner: React.FC = () => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { isDesktop } = useMatchBreakpoints();

  const { isAnimationActive } = useAnimationStatus(animationStatusCheckVariants.estatesBanner);

  return (
    <Box>
      <HashLayoutWrapper minHeight={isAnimationActive ? "100vh" : "50vh"} pt="0" $backgroundColor="white">
        <EStatesBannerWrapper>
          <Container pt={{ _: "2rem", laptop: "4.5rem" }}>
            <Box maxWidth={{ _: "40rem", laptop: "53.625rem" }} position="relative">
              <Heading scale="h2" as="h2" mb={{ _: "0.5rem", laptop: "1.5rem" }} color="textMain" textAlign="center">
                {t("e-States")}
              </Heading>
              <EStatesBannerSubtitle scale={isDesktop ? "h5" : "h4"} as="h4">
                {t(
                  "Innovative Crowdfunding Marketplace for Tokenized Real Estate. This cutting-edge platform revolutionizes the way we engage with real estate investments.",
                )}
              </EStatesBannerSubtitle>
            </Box>
            <Flex justifyContent="center" mb="2.5rem">
              {BANNER_CATEGORY_LIST.map(category => (
                <EStatesBannerCategory key={category}>
                  <Text textScale="p14Regular" color="textEStates">
                    {t(category)}
                  </Text>
                </EStatesBannerCategory>
              ))}
            </Flex>
            <EStatesStatisticsList maxWidth="47.85rem" justifyContent="space-between" position="relative">
              {BANNER_STATISTICS_LIST.map(({ value, label }) => (
                <EStatesStatisticItem key={label}>
                  <Box
                    p={{ mobileM: "0 0.875rem", mobileS: "0 0.475rem" }}
                    width="100%"
                    position="relative"
                    zIndex={theme.zIndices.dropdown}
                  >
                    <EStatesBannerStatisticValue scale="h4" as="h4">
                      {t(value)}
                    </EStatesBannerStatisticValue>
                    <EStatesBannerStatisticLabel
                      textScale="p16Regular"
                      color="textMain"
                      textAlign="center"
                      opacity="0.8"
                    >
                      {t(label)}
                    </EStatesBannerStatisticLabel>
                  </Box>
                </EStatesStatisticItem>
              ))}
            </EStatesStatisticsList>
          </Container>

          <EStatesBannerParallaxImage />

          <Container mb={{ _: "5.625rem", laptop: "14rem" }} position="relative">
            <EStatesBannerPointsList>
              {BANNER_POINTS_LIST.map(({ Icon, label, description }) => (
                <EStatesBannerPointItemWrapper key={label}>
                  <EStatesBannerPointItem>
                    <Icon
                      color="eStatesPrimary"
                      width={isDesktop ? "5.25rem" : "3.1875rem"}
                      mb={{ _: "0.5rem", laptop: "0.75rem" }}
                    />
                    <Heading
                      scale={isDesktop ? "h3" : "h2"}
                      as="h2"
                      mb={{ _: "0.5rem", laptop: "0.75rem" }}
                      color="textMain"
                      textAlign="center"
                    >
                      {t(label)}
                    </Heading>
                    <Text textScale="p18Regular" color="textFourth" textAlign="center">
                      {t(description)}
                    </Text>
                  </EStatesBannerPointItem>
                </EStatesBannerPointItemWrapper>
              ))}
            </EStatesBannerPointsList>
          </Container>
        </EStatesBannerWrapper>
      </HashLayoutWrapper>
    </Box>
  );
};

export default EStatesBanner;
