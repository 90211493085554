import { CaseIcon, ProfessionalGrowthIcon, TeamIcon } from "components/svg";

export const CAREERS_BENEFITS_LIST = [
  {
    title: "Strong cases in your portfolio",
    description:
      "You will work on product design projects with great potential. Some of our clients have the opportunity to become part of the top 100 list of startups this year.",
    Icon: CaseIcon,
  },
  {
    title: "Professional recognition and growth",
    description:
      "We hire people to develop their strongest sides and make our team more mature and crafty. We support self education and personal growth, offering best projects to work on.",
    Icon: ProfessionalGrowthIcon,
  },
  {
    title: "Team of experts to back you",
    description:
      "Our company treats everyone with dignity and equality. We support your creativity, originality and team spirit as well.",
    Icon: TeamIcon,
  },
];
