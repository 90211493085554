import {
  crownAnimation,
  bannerButtonLeftLinesAnimation,
  bannerButtonRightLinesAnimation,
  defaultButtonHoverLinesAnimation,
  spinAnimation,
  circleWrapperAnimation,
  inputLabelUnderlineAnimation,
  textWaveAnimation,
  portfolioRemarkRhombusAnimation,
  planetcoinPhasesLineAnimation,
  curlyArrowAnimation,
  titleStraightLinesAnimation,
  findSolutionArrowAnimation,
  ellipseWrapperAnimation,
} from "./animations";

export const animationTypeMapper = {
  spin: spinAnimation,
  crown: crownAnimation,
  defaultButtonHoverLines: defaultButtonHoverLinesAnimation,
  bannerButtonLeftLines: bannerButtonLeftLinesAnimation,
  bannerButtonRightLines: bannerButtonRightLinesAnimation,
  circleWrapper: circleWrapperAnimation,
  inputLabelUnderline: inputLabelUnderlineAnimation,
  textWave: textWaveAnimation,
  portfolioRemarkRhombus: portfolioRemarkRhombusAnimation,
  planetcoinPhasesLine: planetcoinPhasesLineAnimation,
  curlyArrow: curlyArrowAnimation,
  titleStraightLines: titleStraightLinesAnimation,
  findSolutionArrow: findSolutionArrowAnimation,
  ellipseWrapper: ellipseWrapperAnimation,
};
