export const ugreatorAnimatedItems = (delay: number) => {
  return {
    hidden: {
      top: "6.25rem",
      opacity: 0,
      transition: {
        duration: 0.75,
        type: "easyInOut",
        delay,
      },
    },
    visible: {
      top: 0,
      opacity: 1,
      transition: {
        duration: 0.75,
        type: "easyInOut",
        delay,
      },
    },
  };
};
