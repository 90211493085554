import { useRef } from "react";
import Slider from "react-slick";

const useSliderController = () => {
  const sliderRef = useRef<Slider | null>(null);

  const handleClickPrevious = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleClickNext = () => {
    sliderRef?.current?.slickNext();
  };

  const handleGoToSlide = (index: number) => {
    sliderRef?.current?.slickGoTo(index);
  };

  return { sliderRef, handleClickPrevious, handleClickNext, handleGoToSlide };
};

export default useSliderController;
