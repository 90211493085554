import { useRef, useState } from "react";
import { useAnimation } from "framer-motion";
import { LottieRefCurrentProps } from "lottie-react";
// Hooks
import useMatchBreakpoints from "./use-match-breakpoints/use-match-breakpoints";

const useLottieAnimation = () => {
  const { isDesktop } = useMatchBreakpoints();

  const controls = useAnimation();
  const lottieAnimation1Ref = useRef<LottieRefCurrentProps | null>(null);
  const lottieAnimation2Ref = useRef<LottieRefCurrentProps | null>(null);

  const lottieAnimationRefsList = [lottieAnimation1Ref, lottieAnimation2Ref];

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const handleShowAnimations = () => {
    if (isDesktop) {
      controls.start("visible");
    }

    if (!isImageLoading) {
      lottieAnimationRefsList.forEach(animation => {
        animation.current?.play();
        animation.current?.setSpeed(0.75);
      });
    }

    setIsActive(true);
  };

  const handleHideAnimations = () => {
    if (isDesktop) {
      controls.start("hidden");
    }

    lottieAnimationRefsList.forEach(animation => animation.current?.goToAndStop(0, true));
    setIsActive(false);
  };

  return {
    controls,
    lottieAnimation1Ref,
    lottieAnimation2Ref,
    isActive,
    isImageLoading,
    setIsImageLoading,
    handleShowAnimations,
    handleHideAnimations,
  };
};

export default useLottieAnimation;
