import styled from "styled-components";
// Components
import { Box } from "components";
// Types
import { BackgroundImageWireframeProps } from "./types";
import { backgroundSize } from "styled-system";

export const StyledWireframeBackgroundImage = styled(Box)<BackgroundImageWireframeProps>`
  background-image: ${({ src }) => `url("${src}")`};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.mobileS} {
    background-repeat: no-repeat;
    background-repeat: repeat-x;
  }

  ${backgroundSize};
`;
