import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledPipeAnimation = styled(Lottie)`
  width: calc(105rem / 16 / 2.6);
  position: absolute;
  left: calc(71rem / 16 / 2.6);
  bottom: calc(10rem / 16 / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(105rem / 16 / 1.6);
    left: calc(71rem / 16 / 1.6);
    bottom: calc(16rem / 16 / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 6.5625rem;
    bottom: 1.3rem;
    left: 4.4375rem;
  }
`;
