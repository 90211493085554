import styled from "styled-components";
import { Button } from "components";

export const StyledButton = styled(Button)`
  border-radius: ${({ theme }) => theme.radii.xl};

  border: 3px solid ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.disabledFillDark};

  z-index: 0;
`;
