import { useEffect } from "react";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { Post } from "services";

const useContactUsLinks = (post: Post | undefined) => {
  useEffect(() => {
    const callToActionButtons = document.querySelectorAll(".contact-us-link");
    if (!post?.content?.html || !callToActionButtons.length) {
      return;
    }

    callToActionButtons.forEach(element => {
      element.addEventListener("click", handleCallToActionClick);
    });

    return () => {
      callToActionButtons.forEach(element => {
        element.removeEventListener("click", handleCallToActionClick);
      });
    };
  }, [post]);

  const handleCallToActionClick = (e: Event) => {
    e.preventDefault();
    scrollToElementById(BLOCK_ID.contactUs);
  };
};

export default useContactUsLinks;
