import React from "react";
// Components
import { Container, Flex, Box, Heading, Text } from "components";
import { CorporateValueItem } from "./components";
import { StyledCorporateValuesTitleWrapper, StyledCorporateValuesWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { CORPORATE_VALUES_LIST } from "./constants";

const CorporateValues: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchBreakpoints();

  return (
    <StyledCorporateValuesWrapper>
      <Container py={{ _: "2rem", laptop: "3.125rem", laptopL: "7.5rem" }}>
        <Flex flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "flex-start" : "space-between"}>
          <StyledCorporateValuesTitleWrapper>
            <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }} color="white">
              {t("Corporate values")}
            </Heading>
            <Text textScale={isDesktop ? "p22Regular" : "p16Regular"} color="textThird">
              {t("We are your easiest solution ever")}
            </Text>
          </StyledCorporateValuesTitleWrapper>

          <Box position="relative">
            {CORPORATE_VALUES_LIST.map(({ title, description, Icon }) => (
              <CorporateValueItem key={title} title={title} description={description} Icon={Icon} />
            ))}
          </Box>
        </Flex>
      </Container>
    </StyledCorporateValuesWrapper>
  );
};

export default CorporateValues;
