import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { StyledDefaultFlowItem } from "../../styled";
// Theme
import { firstColumnFlowVariantStyles } from "./theme";
// Types
import { StyledFirstColumnFlowItemProps } from "./types";

export const StyledFirstColumnFlowItem = styled(StyledDefaultFlowItem)<StyledFirstColumnFlowItemProps>`
  width: 3.25rem;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 9.375rem;
  }

  ::before {
    ${clearfix}
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }

  ${({ connectionType }) => firstColumnFlowVariantStyles(connectionType)};
`;
