import React from "react";
// Components + styling
import { StyledHornAnimation, StyledCatAnimation } from "./styled";
// Animations
import yuliaHorn from "assets/animations/yulia-horn.json";
import yuliaCat from "assets/animations/yulia-cat.json";

// Types
import { SliderAnimationProps } from "../types";

const YuliaSliderAnimation: React.FC<SliderAnimationProps> = ({ lottieAnimation1Ref, lottieAnimation2Ref }) => {
  return (
    <>
      <StyledHornAnimation lottieRef={lottieAnimation1Ref} animationData={yuliaHorn} autoplay={false} loop={false} />
      <StyledCatAnimation lottieRef={lottieAnimation2Ref} animationData={yuliaCat} autoplay={false} loop />
    </>
  );
};

export default YuliaSliderAnimation;
