import React from "react";
// Components + styling
import { Container, Flex, Box, Text, Column, Heading } from "components";
import { StyledApproachImage, StyledContentWrapper, StyledApproachItem } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { OUR_APPROACH_LIST } from "./constants";

const OurApproach: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isLargeDesktop, isLaptop } = useMatchBreakpoints();

  return (
    <Container pt={{ _: "2.5rem", laptop: "6.25rem" }} pb={{ _: "0.5rem", laptop: "3.75rem" }}>
      <Flex flexDirection={isDesktop ? "row" : "column"}>
        {!isDesktop && (
          <>
            <Text textScale="p24Bold" mb="0.25rem">
              {t("Our approach")}
            </Text>
            <Text textScale="p16Regular" mb="1rem">
              {t("Find out our core values and approaches for each product we design")}
            </Text>
          </>
        )}
        <StyledApproachImage
          width={isLargeDesktop ? "32.875rem" : isLaptop ? "26.625rem" : "100%"}
          src={`/images/${isDesktop ? "our-approach" : "our-approach-sm"}.webp`}
          aspectRatio={isDesktop ? 0.81 : 1.31}
        />
        <StyledContentWrapper p={{ _: "0", laptop: "1.875rem 0 0 1.875rem", laptopL: "2.5rem 0 0 3.25rem" }}>
          {isDesktop && (
            <>
              <Heading scale={isLargeDesktop ? "h2" : "h4"} as="h2" mb="1rem" $fontWeight="bold">
                {t("Our approach")}
              </Heading>
              <Text textScale={isLargeDesktop ? "p22Regular" : "p16Regular"} mb="1.5rem">
                {t("Find out our core values and approaches for each product we design")}
              </Text>
            </>
          )}
          {OUR_APPROACH_LIST.map(({ Icon, title, description }) => (
            <StyledApproachItem key={title}>
              <Box>
                <Icon width={isLargeDesktop ? "3.5rem" : "3rem"} />
              </Box>
              <Column mx={{ _: "1rem", laptopL: "1.5rem" }}>
                <Text
                  textScale={isLargeDesktop ? "p24Bold" : isDesktop ? "p20Bold" : "p16Bold"}
                  mb={{ _: "0.25rem", laptop: "0.5rem" }}
                >
                  {t(title)}
                </Text>
                <Text
                  textScale={isLargeDesktop ? "p18Regular" : isDesktop ? "p16Regular" : "p14Regular"}
                  color="textFifth"
                >
                  {t(description)}
                </Text>
              </Column>
            </StyledApproachItem>
          ))}
        </StyledContentWrapper>
      </Flex>
    </Container>
  );
};

export default OurApproach;
