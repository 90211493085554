// Components + styled
import { Text } from "components";
import { CloseCircleIcon } from "components/svg";
import {
  StyledRemoveFilterButton,
  StyledSelectedFilterItem,
  StyledSelectedFiltersContainer,
  StyledSelectedFiltersList,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CaseStudiesFilterType } from "context/case-studies-context/types";
import { SelectedFiltersProps } from "./types";

const SelectedFilters: React.FC<SelectedFiltersProps> = ({
  selectedFilters,
  filters,
  hasSomeFiltersSelected,
  onRemove,
}) => {
  const { t } = useTranslation();

  const { isMobile } = useMatchBreakpoints();

  const selectedFilterTypesQuantity = Object.values(selectedFilters).filter(options => options.length).length;

  return (
    <StyledSelectedFiltersContainer>
      {hasSomeFiltersSelected && isMobile && (
        <Text my="0.75rem" textScale="p16Bold">
          {t("Your previous filters")}
        </Text>
      )}

      {Object.entries(selectedFilters).map(([filterType, options]) => {
        const { name } = filters[filterType as CaseStudiesFilterType];

        return (
          <StyledSelectedFiltersList
            key={filterType as CaseStudiesFilterType}
            isSeveralTypes={!!selectedFilterTypesQuantity && selectedFilterTypesQuantity !== 1}
          >
            {options.length > 0 && !isMobile && <Text color="textFifth">{t(name)}:</Text>}

            {options.map((option, index) => (
              <StyledSelectedFilterItem key={`${option.title}-${index}`}>
                <Text textScale="p16Medium">{t(option.title)}</Text>

                <StyledRemoveFilterButton
                  isShowHoverLines={false}
                  variant="primary"
                  filledType="bordered"
                  scale="xs"
                  onClick={() => onRemove(filterType as CaseStudiesFilterType, option)}
                >
                  <CloseCircleIcon color="textMain" />
                </StyledRemoveFilterButton>
              </StyledSelectedFilterItem>
            ))}
          </StyledSelectedFiltersList>
        );
      })}
    </StyledSelectedFiltersContainer>
  );
};

export default SelectedFilters;
