import React from "react";
import { useNavigate } from "react-router-dom";
// Components + styling
import { Box, Button, Flex, Heading, Image } from "components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { NoBlogPlugProps } from "./types";

export const NoBlogPlug: React.FC<NoBlogPlugProps> = ({
  navigateLinkText = "Go to all articles",
  navigateLink = `/${ROUTES.blog}`,
  text = "Article not found :(",
  hideButton = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();

  return (
    <Flex alignItems="center" flexDirection="column">
      <Image src="/images/article-not-found.webp" width={isMobile ? "100%" : "32.875rem"} aspectRatio={1.18} />
      <Heading scale="h3" as="h3" mt="3rem" mb="1.12rem" textAlign="center">
        {t(text)}
      </Heading>

      {!hideButton && (
        <Box maxWidth="20rem" width="100%" mx="auto">
          <Button onClick={() => navigate(navigateLink)} scale="lg" width="100%" isShowHoverLines={false}>
            {t(navigateLinkText)}
          </Button>
        </Box>
      )}
    </Flex>
  );
};
