import styled from "styled-components";
// Components
import { Flex, Column, Image } from "components";

export const StyledApproachImage = styled(Image)`
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 0;
  }
  img {
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;

export const StyledContentWrapper = styled(Column)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(100% - 26.625rem);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: calc(100% - 32.875rem);
  }
`;

export const StyledApproachItem = styled(Flex)`
  align-items: flex-start;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-bottom: 2.5rem;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;
