import styled from "styled-components";
// Components
import { HygraphImage } from "components";

export const StyledCardItemWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptopSquareHalfHeight} {
    max-width: calc(50% - 0.75rem);
  }
`;

export const StyledComingSoonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  /* 99 - it's opacity for 0.6 in hex */
  background: ${({ theme }) => theme.colors.dark}99;
`;

export const StyledCardItem = styled.div`
  width: 100%;
  min-width: 16.437rem;
  min-height: 28.125rem;
  display: flex;
  position: relative;
`;

export const StyledCardItemImage = styled(HygraphImage)`
  img {
    width: 100%;
    height: 100%;
    min-height: 18.75rem;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.radii.semiLarge};
  }
`;

export const StyledCardItemLogoWrapper = styled.div<{ logoWidth: string }>`
  width: ${({ logoWidth }) => logoWidth};
  max-width: 11.75rem;
  height: 3rem;

  position: absolute;
  top: 2rem;
  left: 1.5rem;
  z-index: ${({ theme }) => theme.zIndices.buttonContent};

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: none;
    left: 2rem;
  }
`;
