export const AGREEMENTS_CONTACTS_LIST = [
  {
    company: "IDEALOGIC DEVELOPMENT OÜ",
    address: "Estonia, Harju maakond, Tallinn, Lasnamäe linnaosa, Katusepapi tn 6-502, 11412",
    phone: "+3726039265",
    phoneLabel: "+3 726 03 92 65",
    phoneCountry: "(Estonia)",
  },
  {
    company: "IDEALOGIC DESIGN",
    address: "Poland, Księcia Witolda 48/1, Wroclaw, Lower Silesia 50-203",
    phone: "+48718808151",
    phoneLabel: "+4 871 880 8151",
    phoneCountry: "(Poland)",
  },
];
