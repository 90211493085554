import styled from "styled-components";
// Components
import { Box, Column, Container } from "components";

export const StyledChatOuterWrapper = styled(Container)`
  width: 100%;
  position: fixed;
  bottom: 2.25rem;
  right: 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledChatInnerWrapper = styled(Box)<{ isChatOpen: boolean }>`
  width: 100%;
  max-width: ${({ isChatOpen }) => (isChatOpen ? "25rem" : "0")};
  height: 60vh;
  max-height: ${({ isChatOpen }) => (isChatOpen ? "60vh" : "0")};
  position: absolute;
  bottom: 0;
  right: 0;
  transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};

  ${({ theme }) => theme.mediaQueries.mediumHeight} {
    height: 28.5rem;
    max-height: ${({ isChatOpen }) => (isChatOpen ? "28.5rem" : "0")};
  }
`;

export const StyledChat = styled(Column)<{ isChatOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  opacity: ${({ isChatOpen }) => (isChatOpen ? "1" : "0")};
  z-index: ${({ theme, isChatOpen }) => (isChatOpen ? theme.zIndices.dropdown : 0)};
  border-radius: ${({ theme }) => theme.radii.large} ${({ theme }) => theme.radii.large} 0 0;
  box-shadow: ${({ theme }) => theme.shadows.chat};
  background: ${({ theme }) => theme.colors.white};
  transition: ${({ theme, isChatOpen }) =>
    isChatOpen ? theme.transitions.easeInOut_0_8s : theme.transitions.easeInOut_0_5s};
  overflow: hidden;
`;

export const StyledChatIconWrapper = styled(Box)<{ isChatOpen: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${({ theme, isChatOpen }) => (isChatOpen ? 0 : theme.zIndices.dropdown)};
  opacity: ${({ isChatOpen }) => (isChatOpen ? "0" : "1")};
  transition: ${({ theme, isChatOpen }) =>
    isChatOpen ? theme.transitions.default : theme.transitions.defaultWithPause_0_5s};
`;

export const StyledChatContainer = styled(Column)`
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;
  padding: 0.75rem 1rem 1rem;
  overflow-y: scroll;

  ${({ theme }) => theme.mediaQueries.mediumHeight} {
    overflow-y: initial;
  }
`;
