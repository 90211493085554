import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box, DefaultSlideImage, Text } from "components";

export const StyledSlideImageWrapper = styled(Box)`
  width: 100%;
  height: 14.875rem;
  position: relative;
  margin: auto;

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: auto;
    flex: 1 1 0;
    padding: 0.625rem 0 0 2.0625rem;
    transform: scale(0.7);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 3.75rem 0 0 2.0625rem;
    transform: scale(1);
  }
`;

export const StyledSlideImageTopLeftTitle = styled(Text)`
  text-align: center;
  position: absolute;
  top: 10.875rem;
  left: calc(50% - 9rem);
  right: auto;
  transform: rotate(-11.5deg);

  ${({ theme }) => theme.mediaQueries.mobileM} {
    top: 9.875rem;
    left: calc(50% - 10rem);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 21.5rem;
    left: initial;
    right: calc(50% + 15.375rem);
    transform: rotate(-12deg);
  }

  &::before {
    ${clearfix}
    width: 2.0625rem;
    height: 2.25rem;
    position: absolute;
    top: -3rem;
    right: -0.625rem;
    transform: rotate(6deg);
    background: url("images/ugreator-slide-arrow-top-left-sm.svg") no-repeat;
    background-size: 2.0625rem 2.25rem;

    ${({ theme }) => theme.mediaQueries.mobileM} {
      top: -2rem;
      transform: rotate(16deg);
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 3.3125rem;
      height: 4.0625rem;
      top: -5rem;
      right: -1rem;
      transform: rotate(0);
      background: url("/images/ugreator-slide-arrow-top-left-lg.svg") no-repeat;
      background-size: contain;
    }
  }
`;

export const StyledSlideImageTopRightTitle = styled(Text)`
  width: 4.625rem;
  text-align: center;
  position: absolute;
  top: -0.5rem;
  left: calc(50% + 4rem);
  right: auto;

  ${({ theme }) => theme.mediaQueries.mobileM} {
    top: 0.5rem;
    left: calc(50% + 6rem);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 7.25rem;
    top: 26.5rem;
    left: initial;
    right: calc(50% - 17rem);
  }

  &::after {
    ${clearfix}
    width: 1.4375rem;
    height: 2rem;
    position: absolute;
    top: 2.375rem;
    right: 0.6rem;
    background: url("images/ugreator-slide-arrow-top-right-sm.svg") no-repeat;
    background-size: 1.4375rem 2rem;

    ${({ theme }) => theme.mediaQueries.mobileM} {
      right: 1.2rem;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 3.625rem;
      height: 4.1875rem;
      top: -4.375rem;
      left: 1.5rem;
      right: -1.6875rem;
      background: url("/images/ugreator-slide-arrow-top-right-lg.svg") no-repeat;
      background-size: contain;
    }
  }
`;

export const StyledSlideMiddleImage = styled(DefaultSlideImage)`
  width: 6.25rem;
  height: auto;
  display: block;
  position: absolute;
  left: calc(50% - 3.3rem);
  top: 0.4375rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 15.375rem;
    top: -5.125rem;
    left: initial;
    right: calc(50% - 5.75rem);
  }
`;

export const StyledSlideTopLeftImage = styled(DefaultSlideImage)`
  width: 10rem;
  height: auto;
  display: block;
  position: absolute;
  top: 0.625rem;
  left: calc(50% - 8.2rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 21rem;
    top: 0.8125rem;
    left: initial;
    right: calc(50% + -1.6rem);
  }
`;

export const StyledSlideTopRightImage = styled(DefaultSlideImage)`
  width: 10.25rem;
  height: auto;
  display: block;
  position: absolute;
  top: 0.625rem;
  left: calc(50% - 3.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 21.75rem;
    top: -1.9375rem;
    right: initial;
    left: calc(50% + -4.4rem);
  }
`;

export const StyledSlideBottomLeftImage = styled(DefaultSlideImage)`
  width: 7.625rem;
  height: auto;
  display: block;
  position: absolute;
  top: 5rem;
  left: calc(50% - 7rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 17.75rem;
    top: 10.5rem;
    left: initial;
    right: calc(50% + -1rem);
  }
`;

export const StyledSlideBottomRightImage = styled(DefaultSlideImage)`
  width: 10.25rem;
  height: auto;
  display: block;
  position: absolute;
  left: calc(50% + -2.7rem);
  top: 3.125rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 24.75rem;
    top: 2.1875rem;
    left: initial;
    right: calc(50% - 16.6rem);
  }
`;
