import React from "react";
// Components + styling
import { Column, Flex, Image, Text } from "components";
import { StyledCeoSticker, StyledStickerDescription } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";

const CeoSticker: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledCeoSticker>
      <Flex mb={{ _: "1.375rem", laptop: "1.625rem" }}>
        <Image
          width={isDesktop ? "4.625rem" : "3.5rem"}
          src="/images/viktor-zhytomyrskyi-round.webp"
          aspectRatio={1}
          variant="circle"
        />

        <Column justifyContent="center" mx={{ _: "0.75rem", laptop: "1rem" }}>
          <Text textScale={isDesktop ? "p24Bold" : "p16Bold"}>{t("Viktor Zhytomyrskyi")}</Text>
          <Text textScale={isDesktop ? "p22Regular" : "p14Regular"} color="textFourth">
            {t("CEO")}
          </Text>
        </Column>
      </Flex>
      <StyledStickerDescription textScale={isDesktop ? "decorative22Light" : "decorative16Light"} color="textFifth">
        {t(
          "Design is not only visual representation, but also an emotional connection between the product and the user. Our team of experts understands how to accomplish this goal.",
        )}
      </StyledStickerDescription>
    </StyledCeoSticker>
  );
};

export default CeoSticker;
