import styled, { css } from "styled-components";
import { space, typography, layout, opacity, flexbox } from "styled-system";
// Theme
import { styles } from "components/text/theme";
// Types
import { Gradients } from "theme/types";
import { ThemedProps, TextProps } from "components/text/types";

export const getEllipsis = ({ ellipsis }: ThemedProps) => {
  if (ellipsis) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
};

export const getFontSettings = ({ theme, textScale, $fontWeight }: ThemedProps) => {
  return css`
    ${textScale && styles[textScale]}

    font-weight: ${$fontWeight ? theme.fontWeight[$fontWeight] : ""};
  `;
};

export const fillTextWithGradient = (bgGradiend: keyof Gradients) => {
  return css`
    background: ${({ theme }) => theme.gradients[bgGradiend]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `;
};

export const Text = styled.p<TextProps>`
  ${getFontSettings}

  position: relative;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.textMain)};

  &::before {
    display: ${({ underline }) => (underline ? "inline-block" : "none")};
    content: "";
    clear: both;
    width: 100%;
    height: 0.5px;
    position: absolute;
    top: 100%;
    left: 0;
    background: ${({ theme, color }) => (color ? theme.colors[color] : theme.gradients.buttonAccent)};
    transform-origin: 50% 100%;
    transition:
      clip-path 0.3s,
      transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
    pointer-events: none;
  }

  &:hover,
  &:focus {
    &::before {
      transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
      clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
    }
  }

  ${getEllipsis}
  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${flexbox}
`;
