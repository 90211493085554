import { Route, Routes } from "react-router-dom";
// Components
import { NotFoundPage } from "components";
import { MainOutlet } from "./components";
// Pages
import {
  AboutUsPage,
  BlogsPage,
  CareersPage,
  LandingPage,
  PositionInfoPage,
  PrivacyPolicyPage,
  TermsConditionsPage,
  SingleBlogPage,
  EStatesCasePage,
  PlanetcoinCasePage,
  UgreatorCasePage,
  FindSolutionPage,
  CaseStudiesPage,
  CasePages,
} from "pages";
// Constants
import { ROUTES, ROUTE_PARAMS } from "./routes";

const Navigation: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.landing} element={<MainOutlet />}>
        <Route index element={<LandingPage />} />
        <Route path={ROUTES.aboutUs} element={<AboutUsPage />} />

        <Route path={ROUTES.careers}>
          <Route index element={<CareersPage />} />

          <Route path={`:${ROUTE_PARAMS.positionId}`}>
            <Route index element={<PositionInfoPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.blog}>
          <Route index element={<BlogsPage />} />

          <Route path={`:${ROUTE_PARAMS.slug}`}>
            <Route index element={<SingleBlogPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.termsConditions} element={<TermsConditionsPage />} />
        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />

        <Route path={ROUTES.findSolution} element={<FindSolutionPage />} />

        <Route path={ROUTES.caseStudies}>
          <Route index element={<CaseStudiesPage />} />

          <Route path={`:${ROUTE_PARAMS.caseStudy}?`}>
            <Route
              path={ROUTES.planetcoinCase}
              element={
                <CasePages>
                  <PlanetcoinCasePage />
                </CasePages>
              }
            />

            <Route
              path={ROUTES.eStatesCase}
              element={
                <CasePages>
                  <EStatesCasePage />
                </CasePages>
              }
            />

            <Route
              path={ROUTES.ugreatorCase}
              element={
                <CasePages>
                  <UgreatorCasePage />
                </CasePages>
              }
            />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Navigation;
