import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 1440 576" {...props} animationType="planetcoinPhasesLine" color="transparent">
      <g filter="url(#filter0_d_10090_26665)">
        <path
          d="M-1 382C75.6667 447.5 247.8 565 323 511C417 443.5 427 360 597.5 371C768 382 752.5 375.5 836.5 293C920.5 210.5 928.5 130 1070.5 129C1212.5 128 1330.5 218.5 1440 3"
          stroke="url(#paint0_linear_10090_26665)"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_10090_26665"
          x="-27.5"
          y="0.499023"
          width="1494"
          height="574.795"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.105882 0 0 0 0 0.741176 0 0 0 0 0.894118 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10090_26665" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10090_26665" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_10090_26665"
          x1="1439.5"
          y1="38.8219"
          x2="-16.9368"
          y2="381.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FE9E05" stopOpacity="0" />
          <stop offset="0.0282605" stopColor="#FE9E05" stopOpacity="0.43" />
          <stop offset="0.244792" stopColor="#EC3172" />
          <stop offset="0.473958" stopColor="#E40D9E" />
          <stop offset="0.703125" stopColor="#AE07FD" />
          <stop offset="0.949787" stopColor="#11A6FA" />
          <stop offset="1" stopColor="#11A6FA" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
