import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box } from "components";

export const StyledAddMedWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
  border-radius: ${({ theme }) => theme.radii.xxl};
  background: ${({ theme }) => theme.colors.textAccent} url("/images/add-med-bg.webp") 0 no-repeat;
  background-size: cover;
  transform-origin: center;
  overflow: initial;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% - 0.75rem);
    height: 40.625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 48.75rem;
  }
`;

export const StyledContentContainer = styled(Box)`
  padding: 1.25rem 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 2.5rem 3rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 4.5rem 6.0625rem 4.5rem 6rem;
  }

  &:nth-of-type(1) {
    padding-bottom: 1.25rem;
  }

  &:nth-of-type(2) {
    padding-top: 0;
  }
`;

export const StyledAddMedDescription = styled(Box)`
  flex: initial;

  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex: 1 0 50%;
  }
`;
