import styled from "styled-components";
// Components
import { Column, Image } from "components";

export const StyledBenefitsImage = styled(Image)`
  width: 100%;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 26.625rem;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 32.875rem;
  }

  img {
    width: 100%;
    border-radius: ${({ theme }) => theme.radii.large};
  }
`;

export const StyledContentWrapper = styled(Column)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(100% - 26.625rem);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: calc(100% - 32.875rem);
  }
`;
