import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, Column, Row, Text } from "components";

export const StyledEstatesMotionWrapper = styled(motion.div)`
  position: relative;
`;

export const StyledEstatesMainWrapper = styled(Row)`
  width: 100%;
  display: flex;
  border-radius: ${({ theme }) => theme.radii.xxl};
  background: url("/images/e-states-bg.webp") no-repeat;
  background-size: cover;
  transition: ${({ theme }) => theme.transitions.default};
  padding: 1rem 1.5rem 1.6rem 1.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 5.2rem 6rem 3.2rem 6rem;
  }
`;

export const StyledEstatesWrapper = styled(Column)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 24.375rem;
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 30.625rem;
  }
`;

export const StyledContentContainer = styled(Box)`
  ${({ theme }) => theme.mediaQueries.mobileS} {
    margin-top: 3.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    margin-top: -5.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-top: -4.5rem;
  }

  &:nth-of-type(2) {
    position: relative;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    padding-bottom: 1.25rem;

    ${({ theme }) => theme.mediaQueries.tablet} {
      width: 100%;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      position: absolute;
      margin: 0;
    }
  }
`;

export const StyledDescriptionBlockText = styled(Text)`
  /* D9 is the transparency specified with a hexadecimal value 85% alpha */
  color: ${({ theme }) => `${theme.colors.white}D9`};
`;
