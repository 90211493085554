import styled from "styled-components";
import { clearfixDisplayNone } from "styles";

export const StyledPlaceItemWrapper = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 0;
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  &:nth-of-type(2) {
    ::before,
    ::after {
      ${clearfixDisplayNone};
      width: 1px;
      height: 3.375rem;
      background: ${({ theme }) => `${theme.colors.bgDark}10`};

      ${({ theme }) => theme.mediaQueries.tablet} {
        display: block;
      }
    }

    ${({ theme }) => theme.mediaQueries.tablet} {
      width: 33%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

export const StyledPlaceItem = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledIconWrapper = styled.div<{ isFirstPlace: boolean }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme, isFirstPlace }) =>
    isFirstPlace ? `${theme.colors.textAccent}10` : `${theme.colors.disabledLight}20`};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.375rem;
    height: 2.375rem;
    margin-bottom: 1.25rem;
  }
`;
