const firebaseConfig = {
  apiKey: "AIzaSyDR0vCRnfBlL7CGroo_K-Un0JeNQaa0-4I",
  authDomain: "idealogic-dev.firebaseapp.com",
  databaseURL: "https://idealogic-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "idealogic-dev",
  storageBucket: "idealogic-dev.appspot.com",
  messagingSenderId: "423278963549",
  appId: "1:423278963549:web:47a9583f3580986eb056b4",
};

export default firebaseConfig;
