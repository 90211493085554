// Theme
import { transitions } from "theme/base";

export const hoverVariants = {
  enter: {
    y: ["110%", "0%"],
    scale: 1.3,
    transition: transitions.linearMotion_0_2s,
  },
  leave: {
    y: "-110%",
    scale: 1,
    transition: transitions.linearMotion_0_2s,
  },
};
