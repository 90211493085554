// Components
import { Page } from "components";
import {
  EStatesBanner,
  EStatesWireframes,
  EStatesInteractions,
  EStatesProjectPhases,
  EStatesClientsTestimonials,
  EStatesUserFlow,
} from "./components";

const EStatesCasePage: React.FC = () => {
  return (
    // If in one of the components will use position: sticky, need to remove overflowX="hidden" for correct work sticky.(but need to add wrapper with overflowX="hidden" for <EStateWireframes />)
    <Page maxWidth="100vw" overflowX="hidden" px="0">
      <EStatesBanner />

      <EStatesProjectPhases />

      <EStatesUserFlow />

      <EStatesWireframes />

      <EStatesInteractions />

      <EStatesClientsTestimonials />
    </Page>
  );
};

export default EStatesCasePage;
