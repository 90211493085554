import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 26 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8 12.982a12.812 12.812 0 0 1-1.007 4.975 12.892 12.892 0 0 1-2.742 4.063 13.153 13.153 0 0 1-1.894 1.56c-.684.46-1.416.857-2.175 1.179a12.853 12.853 0 0 1-9.965 0 12.938 12.938 0 0 1-2.175-1.179 12.898 12.898 0 0 1-3.455-3.452 12.771 12.771 0 0 1-1.928-4.573 12.939 12.939 0 0 1 0-5.146 12.71 12.71 0 0 1 1.928-4.574c.457-.678.983-1.312 1.562-1.892a12.856 12.856 0 0 1 4.068-2.74A12.675 12.675 0 0 1 10.422.46a12.899 12.899 0 0 1 7.56.744 12.847 12.847 0 0 1 4.069 2.74 12.886 12.886 0 0 1 2.742 4.063 12.8 12.8 0 0 1 1.008 4.976ZM8.336 3.11a10.932 10.932 0 0 0-6.033 7.623h.01c.374.003 4.888.045 10.082-1.333a65.735 65.735 0 0 0-4.06-6.29Zm-6.259 9.525c.194.008 5.595.129 11.223-1.554.313.613.613 1.235.887 1.855-.144.041-.289.084-.431.131-5.9 1.905-8.89 7.204-8.887 7.21a10.86 10.86 0 0 1-2.8-7.294c0-.08.002-.16.005-.24l.003-.108ZM20.22 4.79a10.887 10.887 0 0 0-7.22-2.722c-.888 0-1.75.107-2.575.305l.018.024c.258.346 2.25 3.01 4.068 6.344 4.091-1.532 5.656-3.872 5.708-3.95Zm-5.313 9.962.026-.008c1.539 3.992 2.173 7.334 2.335 8.288a10.887 10.887 0 0 1-4.267.865c-2.535 0-4.863-.87-6.719-2.31.023-.05 2.13-4.574 8.579-6.82l.046-.015Zm4.202 7.283a10.918 10.918 0 0 0 4.684-7.322c-.225-.073-3.283-1.04-6.812-.475 1.434 3.935 2.017 7.139 2.128 7.797Zm-3.03-10.085a34.424 34.424 0 0 0-.726-1.583C19.64 8.62 21.398 6.11 21.44 6.05a10.85 10.85 0 0 1 2.486 6.821l-.013-.003c-.287-.06-3.916-.814-7.6-.351a61.342 61.342 0 0 0-.236-.566Z"
      />
    </Svg>
  );
};

export default Icon;
