// Types
import { UgreatorUserFlowsThirdColumnItem } from "../ugreator-user-flows-third-column/types";

export const UGREATOR_USER_FLOWS_FOURTH_COLUMN_LIST: UgreatorUserFlowsThirdColumnItem[] = [
  {
    label: "Notifications",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Feed settings",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Profile",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Terms of use",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "FAQs",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Support",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Restore purchases",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Security",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Block list",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
  {
    label: "Delete account",
    borderColor: "ugreatorUserFlowThirdColumnMyProfile",
  },
];
