import React, { useRef } from "react";
import { motion } from "framer-motion";
// Components + styling
import { Box, Column, Container, HashLayoutWrapper } from "components";
import { CrownIcon } from "components/svg";
import { BannerTitle, ClientsSlider, MagneticButton } from "./components";
import { StyledBannerWrapper, StyledIconWrapper, StyledIconDescription } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useAnimationStatus, useElementDimensions } from "hooks";
import { useBannerMotions } from "./hooks";
// Constants
import { BLOCK_ID } from "configs";
import { animationStatusCheckVariants } from "hooks/use-animation-status/types";

const Banner: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { isAnimationActive } = useAnimationStatus(animationStatusCheckVariants.banner);

  const iconRef = useRef<HTMLDivElement | null>(null);
  const { y, xLeft, xRight, opacity, scaleMagneticButton } = useBannerMotions(iconRef, isAnimationActive);
  const iconRefMotion = useBannerMotions(iconRef, isAnimationActive, ["end end", "end 99%"]);

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Box ref={iconRef} overflowX="hidden">
      <HashLayoutWrapper
        minHeight={isAnimationActive ? `calc(100vh + ${headerHeight}px)` : "50vh"}
        pt="0"
        $backgroundColor="bgDark"
      >
        <StyledBannerWrapper>
          <Container my="auto">
            <Column pt={{ laptopL: "5.25rem" }}>
              <StyledIconWrapper style={{ y }}>
                <motion.div style={{ opacity: iconRefMotion.opacity }}>
                  <CrownIcon width={isDesktop ? "4.125rem" : "3rem"} mb="0.5rem" opacity="0.6" />
                </motion.div>

                <StyledIconDescription textScale={isDesktop ? "decorative22Light" : "decorative16Light"}>
                  {t("we are strong experts in")}
                </StyledIconDescription>
              </StyledIconWrapper>

              <BannerTitle xLeft={xLeft} xRight={xRight} opacity={opacity} />

              <MagneticButton scale={scaleMagneticButton} />
            </Column>
          </Container>

          <ClientsSlider />
        </StyledBannerWrapper>
      </HashLayoutWrapper>
    </Box>
  );
};

export default Banner;
