import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Box, DefaultSlideImage, Text } from "components";

export const StyledSlideImageWrapper = styled(Box)`
  width: 100%;
  height: 14.875rem;
  position: relative;
  margin: auto;

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: auto;
    flex: 1 1 0;
    padding: 0 0 0 2.0625rem;
    transform: scale(0.7);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    transform: scale(1);
  }
`;

export const StyledSlideImageTitle1 = styled(Text)`
  text-align: center;
  position: absolute;
  top: 5.875rem;
  left: calc(50% - 9rem);
  right: auto;
  transform: rotate(-20deg);

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 17.5rem;
    left: initial;
    right: calc(50% + 13.25rem);
    transform: rotate(-6deg);
  }

  &::after {
    ${clearfix}
    width: 2.0625rem;
    height: 2.25rem;
    position: absolute;
    top: 1.375rem;
    right: -0.625rem;
    transform: rotate(34deg);
    background: url("images/add-med-slide-arrow-1-sm.svg") no-repeat;
    background-size: 2.0625rem 2.25rem;

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 3.3125rem;
      height: 4.0625rem;
      top: 1.6875rem;
      right: -1.6875rem;
      transform: rotate(0);
      background: url("/images/add-med-slide-arrow-1-lg.svg") no-repeat;
      background-size: contain;
    }
  }
`;

export const StyledSlideImageTitle2 = styled(Text)`
  text-align: center;
  position: absolute;
  top: 0.9375rem;
  left: calc(50% + 4.6rem);
  right: auto;
  transform: rotate(2deg);

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 12.5rem;
    left: initial;
    right: calc(50% - 16rem);
    transform: rotate(2deg);
  }

  &::after {
    ${clearfix}
    width: 1.4375rem;
    height: 2rem;
    position: absolute;
    top: 1.375rem;
    right: 2rem;
    background: url("images/add-med-slide-arrow-2-sm.svg") no-repeat;
    background-size: 1.4375rem 2rem;

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 3.625rem;
      height: 4.1875rem;
      top: -4.375rem;
      left: -1.875rem;
      right: -1.6875rem;
      background: url("/images/add-med-slide-arrow-2-lg.svg") no-repeat;
      background-size: contain;
    }
  }
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 10.625rem;
  height: auto;
  display: block;
  position: absolute;
  left: calc(50% - 5.3rem);
  top: 0.4375rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 21.5rem;
    top: 1.125rem;
    left: initial;
    right: calc(50% - 10.75rem);
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 2.25rem;
  height: auto;
  display: block;
  position: absolute;
  top: 0.625rem;
  left: calc(50% - 8.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.1875rem;
    top: 5.0625rem;
    left: initial;
    right: calc(50% + 10.4rem);
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 2.0625rem;
  height: auto;
  display: block;
  position: absolute;
  top: 10.5rem;
  left: calc(50% - 8.3rem);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 4.0625rem;
    top: 24.5rem;
    left: initial;
    right: calc(50% + 10rem);
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 2.3125rem;
  height: auto;
  display: block;
  position: absolute;
  left: calc(50% + 6.3rem);
  top: 7.125rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 5.25rem;
    top: 18.1875rem;
    left: initial;
    right: calc(50% - 17.6rem);
  }
`;
