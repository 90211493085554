import { useNavigate } from "react-router-dom";
// Components + styling
import { Box, Button, Flex, Heading, Text } from "components";
import { LinkIcon } from "components/svg";
import { CaseStudiesCardItemImage } from "..";
import { StyledCategoriesText, StyledCategoriesWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CaseStudiesCardItemDescriptionProps } from "./types";

const CaseStudiesCardItemDescription: React.FC<CaseStudiesCardItemDescriptionProps> = ({
  title,
  imgSrc,
  logo,
  category,
  subtitle,
  cardDescription,
  isComingSoonDesign,
  designRedirectedLink,
  designBehanceLink,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();
  const navigate = useNavigate();

  const onButtonClickHandler = () => {
    if (designRedirectedLink) {
      navigate(designRedirectedLink);
    }
  };

  const linkHrefProp = {
    href: designBehanceLink || "/",
  };

  return (
    <Flex maxWidth={{ _: "100%", laptop: "calc(50% - 0.75rem)" }} justifyContent="space-between" flexDirection="column">
      <div>
        <Heading as="h3" scale="h3" mb={{ _: "0.25rem", laptop: "0.5rem" }}>
          {t(title)}
        </Heading>
        <Text textScale={isDesktop ? "p22Regular" : "p18Regular"} color="textFifth" mb={{ _: "1rem", laptop: "2rem" }}>
          {t(subtitle)}
        </Text>

        {!isDesktop && (
          <CaseStudiesCardItemImage
            imgSrc={imgSrc.url}
            logoWidth={logo.width}
            logoAspectRatio={logo.aspectRatio}
            logoSrc={logo.logoSrc.url}
            isComingSoon={isComingSoonDesign}
          />
        )}

        <StyledCategoriesWrapper>
          {category.map((category, index) => (
            <StyledCategoriesText key={index} color="textFourth" textScale={isDesktop ? "p18Regular" : "p14Regular"}>
              {t(category.title)}
            </StyledCategoriesText>
          ))}
        </StyledCategoriesWrapper>

        <Text textScale={isDesktop ? "p22Regular" : "p18Regular"} mb="1rem" color="textFifth">
          {t(cardDescription)}
        </Text>
      </div>

      <Box width="100%" maxWidth="21.5rem">
        <Button
          width="100%"
          scale={isDesktop ? "lg" : "md"}
          endIcon={designBehanceLink ? <LinkIcon color="white" /> : <></>}
          disabled={isComingSoonDesign && !designRedirectedLink}
          color="textMain"
          onClick={onButtonClickHandler}
          external={!!designBehanceLink}
          as={designBehanceLink ? "a" : "button"}
          {...linkHrefProp}
        >
          {!isComingSoonDesign || designRedirectedLink ? t("Case study") : t("Case study coming soon")}
        </Button>
      </Box>
    </Flex>
  );
};

export default CaseStudiesCardItemDescription;
