import axios from "axios";

export const baseUrl = process.env.REACT_APP_API_URL;

export function getInstance(baseURL = process.env.REACT_APP_API_URL) {
  const instance = axios.create({
    baseURL,
    timeout: 10000,
  });

  return instance;
}
