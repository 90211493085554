import React from "react";
// Components + Styling
import { Column, Button, Heading, Text } from "components";
import { LinkIcon } from "components/svg";
import { AiBookSlideImage } from "./components";
import { StyledContentContainer, StyledAiBookDescription, StyledAiBookWrapper } from "./styled";
// Animations
import { portfolioItemShow } from "../../animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { EXTERNAL_LINKS } from "configs";

const AiBook: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledAiBookWrapper
      initial={isDesktop ? "hidden" : "visible"}
      variants={portfolioItemShow}
      whileInView="visible"
      viewport={{ once: true }}
    >
      {isDesktop && <AiBookSlideImage />}

      <Column alignItems={isDesktop ? "flex-start" : "center"}>
        <StyledContentContainer>
          <StyledAiBookDescription>
            <Heading scale="h3" as="h3" mb={{ _: "0.5rem" }}>
              {t("AIBook")}
            </Heading>
            <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} opacity="0.7">
              {t(
                "The idea behind the project was to create AI capable of generating unique and beautiful NFT images. To simplify the process for the user, we chose the finest authors of literature and selected quotes from their works.",
              )}
            </Text>
          </StyledAiBookDescription>
        </StyledContentContainer>

        {!isDesktop && <AiBookSlideImage />}

        <StyledContentContainer>
          <Button
            bgColor="bgDark"
            hoverBg="buttonDarkStroke"
            hoverLinesPosition="bottomLeft"
            width="15.25rem"
            variant="primary"
            mx={{ _: "auto", laptop: "0" }}
            endIcon={isDesktop && <LinkIcon color="white" />}
            as="a"
            external
            href={EXTERNAL_LINKS.behanceAIBook}
          >
            {t("View full case")}
          </Button>
        </StyledContentContainer>
      </Column>
    </StyledAiBookWrapper>
  );
};

export default AiBook;
