import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
// Constants
import { COOKIES_KEYS } from "configs";

const useUserSession = () => {
  useEffect(() => {
    updateSessionId();
    checkUserId();
  }, []);

  const checkUserId = () => {
    const userId = Cookies.get(COOKIES_KEYS.userId);

    if (!userId || userId === "") {
      const randomId = uuidv4();
      Cookies.set(COOKIES_KEYS.userId, randomId, { expires: 365 });
    }
  };

  const updateSessionId = () => {
    const randomId = uuidv4();
    Cookies.set(COOKIES_KEYS.sessionId, randomId);
  };
};
export default useUserSession;
