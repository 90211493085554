import React from "react";
// Components + styling
import { Flex } from "components";
import { ArrowLeftIcon } from "components/svg";
import { StyledArrowRightIcon, StyledIconButton } from "./styled";
// Types
import { SliderNavButtonsProps } from "./types";
// Hooks
import { useMatchBreakpoints } from "hooks";

const SliderNavButtons: React.FC<SliderNavButtonsProps> = ({ onClickLeftBtn, onClickRightBtn }) => {
  const { isLargeDesktop } = useMatchBreakpoints();

  return (
    <Flex>
      <StyledIconButton
        variant="primary"
        filledType="bordered"
        Icon={ArrowLeftIcon}
        mx="0.25rem"
        hoverLinesPosition="bottomLeft"
        onClick={onClickLeftBtn}
        ariaLabel="back"
      />
      <StyledIconButton
        variant="primary"
        filledType="bordered"
        Icon={StyledArrowRightIcon}
        mx="0.25rem"
        onClick={onClickRightBtn}
        hoverLinesPosition={isLargeDesktop ? "topRight" : "topRightMobile"}
        ariaLabel="next"
      />
    </Flex>
  );
};

export default SliderNavButtons;
