import styled from "styled-components";
// Components
import { Box, Flex } from "components";

export const EStatesInteractionsTabsPanelWrapper = styled(Flex)`
  div > p {
    min-width: fit-content;
  }
`;

export const EStatesInteractionsContent = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const EStatesInteractionsTab = styled(Box)<{ isActive: boolean }>`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
`;

export const EStatesInteractionsImageWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  position: relative;
  padding: 0 1rem;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  background-image: url("/images/e-states-interactions-bg.webp");
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: center;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 50%;
    flex-direction: column;
    padding: 0 2rem;
    overflow: hidden;
  }
`;

export const EStatesInteractionsTabImage = styled(EStatesInteractionsTab)<{ activeIndex: number }>`
  width: calc(100% - 2rem);
  top: 50%;
  left: 58%;
  transform: translate(-50%, -50%);

  transition:
    opacity ${({ theme }) => theme.transitions.easeInOut_0_5s},
    visibility ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: ${({ activeIndex }) => `${50 - 100 * (activeIndex - 0)}%`};
    left: 50%;
    transform: translate(-50%, -50%);

    transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
  }
`;
