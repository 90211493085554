// Styled components
import {
  StyledSlideImage1,
  StyledSlideImage2,
  StyledSlideImage3,
  StyledSlideImage4,
  StyledSlideImage5,
} from "./styled";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage1,
    imageSrc: "/images/case-studies/ugreator/ugreator-client-testimonial-decor-1.svg",
    ratio: 0.94, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/case-studies/ugreator/ugreator-client-testimonial-decor-2.svg",
    ratio: 0.95, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/case-studies/ugreator/ugreator-client-testimonial-decor-3.svg",
    ratio: 0.95, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage4,
    imageSrc: "/images/case-studies/ugreator/ugreator-client-testimonial-decor-4.svg",
    ratio: 0.97, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage5,
    imageSrc: "/images/case-studies/ugreator/ugreator-client-testimonial-decor-5.svg",
    ratio: 0.93, // ratio of image width/height
  },
];
