// Animations
import {
  DmytroKovalovAnimation,
  BohdanTerpeliukAnimation,
  ElinaSliderAnimation,
  IliaSliderAnimation,
  IreneSliderAnimation,
  IreneMalchenkoAnimation,
  VictorSliderAnimation,
  YuliaSliderAnimation,
} from "./components/slider-item-animations";
// Types
import { OurTeamItemType } from "./types";

export const LINKEDIN_LIST = {
  viktorZhytomyrskyi: "https://www.linkedin.com/in/viktor-zhitomirskiy/",
};

export const OUR_TEAM_LIST: OurTeamItemType[] = [
  {
    image: "/images/viktor-zhytomyrskyi.webp",
    name: "Viktor Zhytomyrskyi",
    position: "CEO",
    description: "Viktor focuses on data-driven product design, so the solution fits the clients’ needs.",
    hoverColor: "victorHover",
    ImageLottieAnimation: VictorSliderAnimation,
    linkedInLink: LINKEDIN_LIST.viktorZhytomyrskyi,
  },
  {
    image: "/images/bohdan-terpeliuk.webp",
    name: "Bohdan Terpeliuk",
    position: "UX/UI designer",
    description:
      "Bohdan combines artistic flair with user-centric principles to create seamless and visually appealing digital experiences.",
    hoverColor: "bohdanHover",
    ImageLottieAnimation: BohdanTerpeliukAnimation,
  },
  {
    image: "/images/yulia-ostapenko.webp",
    name: "Yulia Ostapenko",
    position: "Product designer",
    description:
      "Yulia is into product branding, design schemes, and interphases. She focuses on A/B testing and post-launch support.",
    hoverColor: "yuliaHover",
    ImageLottieAnimation: YuliaSliderAnimation,
  },
  {
    image: "/images/dmytro-kovalov.webp",
    name: "Dmytro Kovalov",
    position: "Product designer",
    description:
      "Dmytro, a passionate product designer, excels in crafting captivating design solutions through innovative research methodologies, intuitive interfaces, and a keen focus on enhancing user experiences.",
    hoverColor: "dmytroHover",
    ImageLottieAnimation: DmytroKovalovAnimation,
  },
  {
    image: "/images/irene-malchenko.webp",
    name: "Irene Malchenko",
    position: "UX/UI designer",
    description: "Irene is interested in animation and app design for startups. ",
    hoverColor: "ireneMHover",
    ImageLottieAnimation: IreneMalchenkoAnimation,
  },
  {
    image: "/images/irene-avryutova.webp",
    name: "Irene Avryutova",
    position: "Customer Care Manager",
    description: "Irene helps to find the touch-points between the client’s needs and the company's capabilities.",
    hoverColor: "ireneHover",
    ImageLottieAnimation: IreneSliderAnimation,
  },
  {
    image: "/images/illia-yampolskyi.webp",
    name: "Illia Yampolskyi",
    position: "Solution Analyst",
    description:
      "Illia helps to find ways for implementing the best possible technical solutions according to the business needs.",
    hoverColor: "illiaHover",
    ImageLottieAnimation: IliaSliderAnimation,
  },
  {
    image: "/images/elina-krasnikova.webp",
    name: "Elina Krasnikova",
    position: "Business Analyst",
    description: "Elina helps clients to define the scope and vision of the product and ensures qualitative delivery.",
    hoverColor: "elinaHover",
    ImageLottieAnimation: ElinaSliderAnimation,
  },
];
