import styled from "styled-components";
import { motion } from "framer-motion";
import { grid } from "styled-system";
// Components
import { Box, Container, Flex, Grid, Image, Text } from "components";

export const EStatesUserFlowWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 88.125rem;
`;

export const EStatesUserFlowText = styled(Text)`
  color: ${({ theme }) => theme.colors.textMain};
  text-align: start;
  font-size: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    color: ${({ theme }) => theme.colors.textFourth};
    text-align: center;
  }
`;

export const EStatesUserFlowMapWrap = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    height: 12rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileM} {
    height: 14rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileL} {
    height: 16rem;
  }

  ${({ theme }) => theme.mediaQueries.tabletS} {
    height: 22rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 28rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 36rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopM} {
    height: 43rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: 50rem;
  }
`;

export const EStatesUserFlowMapBox = styled(Box)<{ width: number }>`
  position: absolute;
  // IMPORTANT use only px for map user flow
  width: 100%;
  min-width: ${({ width }) => width}px;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    transform: scale(0.23);
  }

  ${({ theme }) => theme.mediaQueries.mobileM} {
    transform: scale(0.27);
  }

  ${({ theme }) => theme.mediaQueries.mobileL} {
    transform: scale(0.32);
  }

  ${({ theme }) => theme.mediaQueries.tabletS} {
    transform: scale(0.46);
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    transform: scale(0.55);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    transform: scale(0.7);
  }

  ${({ theme }) => theme.mediaQueries.laptopM} {
    transform: scale(0.9);
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    transform: scale(1);
  }
`;

export const EStatesUserFlowMainRoutingImage = styled(Image)`
  img {
    margin-left: auto;
    width: 100%;
  }
`;

export const EStatesUserFlowRoutingContainer = styled(Container)`
  position: absolute;
  top: 3.7rem;
  left: 0;
  right: 0;
`;

export const EStatesUserFlowGridRow = styled(Grid)`
  // IMPORTANT use only px for map user flow
  grid-template-columns: 223.11px 223.11px 223.11px 223.11px 223.11px 0fr;
  ${grid}
`;

export const EStatesUserFlowItemWrapper = styled(motion.div)`
  position: relative;
  margin-bottom: 3rem;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const EStatesUserFlowLine = styled(Flex)`
  flex-wrap: nowrap;
  position: absolute;
  width: 100%;
  top: 11.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 13rem;
  }
`;

export const EStatesUserFlowLineMotion = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
`;
