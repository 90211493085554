import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Text, Button, Image } from "components";

export const StyledCookiesBar = styled(motion.div)`
  width: 100%;
  max-width: 21.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  z-index: 10000000;
  transform: translateX(-50%);
  padding: 2.75rem 2.125rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.lineLight};
  border-radius: ${({ theme }) => theme.radii.header};
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 30.625rem;
    flex-direction: row;
    bottom: 4.375rem;
    left: 4.5rem;
    transform: translateX(0);
    padding: 1.5rem 2rem;
    border-radius: ${({ theme }) => theme.radii.cookies};
  }
`;

export const StyledCookiesTitle = styled(Text)`
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-left: 4.125rem;
  }
`;

export const StyledImage = styled(Image)`
  width: 3.8125rem;
  height: 4.1875rem;
  position: absolute;
  top: -4.8125rem;
  left: 50%;
  transform: translateX(-50%);

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 3.125rem;
    height: auto;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(0);
    background-size: 3.125rem 3.4375rem;
  }
`;

export const StyledCookiesButton = styled(Button)`
  width: 100%;
  max-width: 10.625rem;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 5rem;
    min-width: 5rem;
    max-height: 5rem;
    min-height: 5rem;
    margin: 0 0 0 1rem;
  }
`;
