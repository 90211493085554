import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledIreneMalchenkoAnimation = styled(Lottie)`
  width: calc(25rem / 2.6);
  position: absolute;
  top: calc(0.875rem / 2.6);
  left: calc(0.4rem / 2.6);

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(25rem / 1.6);
    top: calc(0.875rem / 1.6);
    left: calc(0.4rem / 1.6);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 28rem;
    top: -0.875rem;
    left: -1.1rem;
  }
`;
