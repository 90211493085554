import styled from "styled-components";
import Lottie from "lottie-react";

export const StyledEStateBuildingAnimation = styled(Lottie)`
  width: 30.20192rem;
  opacity: 0.4;
  position: absolute;
  right: 6.66rem;
  top: -1.7rem;

  ${({ theme }) => theme.mediaQueries.mobileS} {
    width: 21rem;
    right: -3rem;
    top: 0.625rem;
  }
  ${({ theme }) => theme.mediaQueries.mobileM} {
    width: 20rem;
    right: -1rem;
    top: 1.25rem;
  }

  ${({ theme }) => theme.mediaQueries.mobileL} {
    width: 25rem;
    right: -2rem;
    top: 0.2rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 26rem;
    position: absolute;
    right: 8.66rem;
    top: -0.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 34rem;
    top: -13.06rem;
    right: -5.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 46rem;
    top: -16.2rem;
    right: -5rem;
  }
`;
