import React from "react";
import { useNavigate } from "react-router-dom";
// Components + styling
import { Box } from "components";
import { StyledFooterLink, StyledSectionTitle } from "components/footer/styled";
// Context
import { useTranslation } from "context";
// Constants
import { FOOTER_ABOUT_LIST } from "components/footer/constants";

const FooterAbout: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickLink = (link: string) => {
    navigate(link);
  };

  return (
    <Box width="50%">
      <StyledSectionTitle>{t("About")}</StyledSectionTitle>
      {FOOTER_ABOUT_LIST.map(({ label, link }) => (
        <StyledFooterLink
          href={link}
          key={label}
          mb={{ _: "1rem", tablet: "1.5rem" }}
          onClick={() => {
            handleClickLink(link);
          }}
        >
          {label}
        </StyledFooterLink>
      ))}
    </Box>
  );
};

export default FooterAbout;
