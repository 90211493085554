export const baseColors = {
  transparent: "transparent",
  white: "#FFFFFF",
  dark: "#000000",

  behanceIcon: "#1769FF",
  behanceIconHover: "#4C8CFF",
  behanceIconActive: "#0051E6",

  dribbbleIcon: "#F26798",
  dribbbleIconHover: "#F58AAF",
  dribbbleIconActive: "#EE3A79",

  victorHover: "#6abffe",
  denisHover: "#cbd24a",
  yuliaHover: "#ff8885",
  elinaHover: "#55c2ef",
  illiaHover: "#c9a43c",
  ireneHover: "#fbad4c",
  melHover: "#ffc165",
  bohdanHover: "#E0FFB9",
  dmytroHover: "#FFB992",
  ireneMHover: "#87B7FF",

  ratingYellow: "#ffbe37",
  ratingOrange: "#FF3D2E",

  postRelatedLinkBg: "#F9FAFF",
} as const;

export const lightColors = {
  ...baseColors,
  bgDark: "#0D0D0D",
  bgSecond: "#FCFCFC",

  textMain: "#333333",
  textSecond: "#E5E5E5",
  textThird: "#C4C4C4",
  textFourth: "#9CA3AF",
  textFourthForDarkBg: "#BFBFBF",
  textFifth: "#737780",
  textFifthContrast: "#3C4650",
  textAccent: "#8243E3",
  textEStates: "#1E3C3E",

  buttonDarkFill: "#1D1D1D",

  disabledLight: "#BEC6DA",
  disabledFillDark: "#1D2230",
  disabledStrokeDark: "#232939",
  disabledTextDark: "#3D455C",

  lineDark: "#333541",
  lineLight: "#C7C9CD",
  lineSecond: "#E9EAEC",

  success: "#3AE474",
  error: "#F02C2C",
  warning: "#F5AF46",
  pink: "#ef3fe8",
  grey: "#ababab",

  planetcoinBgDark: "#232323",
  planetcoinWhite: "#FBFBFB",
  planetcoinWireframesBorder: "#4A4A4A",
  planetcoinCaseMain: "#51B56D",
  planetcoinButtonGreen: "#58B873",

  findSolutionBannerBg: "#7D17FF",
  findSolutionGreyBg: "#D9D9D9",

  textLink: "#228AE9",

  linkedInLink: "#6C85A3",
  linkedInLinkHover: "#2867B2",

  eStatesBgBlue: "#22B4D7",
  eStatesPrimary: "#1BBDE4",
  eStatesBannerCategory: "#32C4E7",
  eStatesWireframesBorder: "#CFF1F9",
  eStatesUserFlowBoxBg: "#C3F4FF",
  eStatesUserFlowBoxBgLight: "#F6FAFF",
  eStatesUserFlowBorder: "#1EB9DE",
  eStatesUserFlowInteractions: "#1BBEE4",

  ugreatorMainPurple: "#8333FD",
  ugreatorText: "#1A2023",
  ugreatorUserFlowColumn1: "#2AD2C7",
  ugreatorUserFlowThirdColumnHomepage: "#29C2DF",
  ugreatorUserFlowThirdColumnAuctions: "#29B1F8",
  ugreatorUserFlowThirdColumnMyProfile: "#5F79FC",
  ugreatorUserFlowThirdColumnUShop: "#D635B6",
  ugreatorUserFlowThirdColumnActivities: "#F77D49",

  gray: "#9C9C9C",
  // Put light colors here
} as const;

export const darkColors = {
  ...baseColors,
  bgDark: "#0D0D0D",
  bgSecond: "#FCFCFC",

  textMain: "#333333",
  textSecond: "#E5E5E5",
  textThird: "#C4C4C4",
  textFourth: "#9CA3AF",
  textFourthForDarkBg: "#BFBFBF",
  textFifth: "#737780",
  textFifthContrast: "#3C4650",
  textAccent: "#8243E3",
  textEStates: "#1E3C3E",

  buttonDarkFill: "#1D1D1D",

  disabledLight: "#BEC6DA",
  disabledFillDark: "#1D2230",
  disabledStrokeDark: "#232939",
  disabledTextDark: "#3D455C",

  lineDark: "#333541",
  lineLight: "#C7C9CD",
  lineSecond: "#E9EAEC",

  success: "#3AE474",
  error: "#F02C2C",
  warning: "#F5AF46",
  pink: "#ef3fe8",
  grey: "#ababab",

  planetcoinBgDark: "#232323",
  planetcoinWhite: "#FBFBFB",
  planetcoinWireframesBorder: "#4A4A4A",
  planetcoinCaseMain: "#51B56D",
  planetcoinButtonGreen: "#58B873",

  findSolutionBannerBg: "#7D17FF",
  findSolutionGreyBg: "#D9D9D9",

  textLink: "#228AE9",

  linkedInLink: "#2867B2",
  linkedInLinkHover: "#163760",

  eStatesBgBlue: "#22B4D7",
  eStatesPrimary: "#1BBDE4",
  eStatesBannerCategory: "#32C4E7",
  eStatesWireframesBorder: "#CFF1F9",
  eStatesUserFlowBoxBg: "#C3F4FF",
  eStatesUserFlowBoxBgLight: "#F6FAFF",
  eStatesUserFlowBorder: "#1EB9DE",
  eStatesUserFlowInteractions: "#1BBEE4",

  ugreatorMainPurple: "#8333FD",
  ugreatorText: "#1A2023",
  ugreatorUserFlowColumn1: "#2AD2C7",
  ugreatorUserFlowThirdColumnHomepage: "#29C2DF",
  ugreatorUserFlowThirdColumnAuctions: "#29B1F8",
  ugreatorUserFlowThirdColumnMyProfile: "#5F79FC",
  ugreatorUserFlowThirdColumnUShop: "#D635B6",
  ugreatorUserFlowThirdColumnActivities: "#F77D49",

  gray: "#9C9C9C",
  // Put dark colors here
} as const;
