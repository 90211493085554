import { motion } from "framer-motion";
// Components + styling
import { Column, Text } from "components";
import { StyledFourthColumnItem } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Animations
import { ugreatorAnimatedItems } from "../../animations";
// Constants
import { UGREATOR_USER_FLOWS_FOURTH_COLUMN_LIST } from "./constants";

const UgreatorUserFlowsFourthColumn: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <motion.div
      style={{ position: "relative" }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.2 }}
      variants={ugreatorAnimatedItems(0.75)}
    >
      <Column m={{ _: "4rem 0 0 2rem", laptop: "11.375rem 0 0 7.125rem" }}>
        {UGREATOR_USER_FLOWS_FOURTH_COLUMN_LIST.map(({ label, borderColor }) => (
          <StyledFourthColumnItem key={label} $borderColor={borderColor}>
            <Text textScale={isDesktop ? "p16Medium" : "p5Medium"}>{t(label)}</Text>
          </StyledFourthColumnItem>
        ))}
      </Column>
    </motion.div>
  );
};

export default UgreatorUserFlowsFourthColumn;
