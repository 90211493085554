import React, { useRef } from "react";
// Components + styling
import { Box, Image, Text } from "components";
import {
  StyledAmbassadorImageWrapper,
  StyledAmbassadorItem,
  StyledInfoWrapper,
  StyledLinkLabel,
  StyledLinkWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { AmbassadorItemType } from "../../types";

const AmbassadorItem: React.FC<AmbassadorItemType> = ({
  linkLabel,
  link,
  linkIconSrc,
  description,
  artistImgSrc,
  artistImgRatio,
  bgGradientSrc,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const itemWrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box width="100%" minWidth={{ tablet: "calc(100vh * 0.7)", laptop: "calc(100vh * 0.6)" }} position="relative">
      <StyledAmbassadorItem
        ref={itemWrapperRef}
        bgGradientSrc={bgGradientSrc}
        height={itemWrapperRef.current?.offsetWidth}
      >
        <StyledInfoWrapper>
          <StyledLinkWrapper href={link} external>
            <Image width={isMobile ? "1.5rem" : "2.5rem"} src={linkIconSrc} aspectRatio={artistImgRatio} />
            <StyledLinkLabel textScale={isMobile ? "p18Bold" : "p32Bold"} color="textMain" underline>
              {linkLabel}
            </StyledLinkLabel>
          </StyledLinkWrapper>
          <Text
            maxWidth={{ _: "8.75rem", tablet: "12.5rem", laptop: "15rem" }}
            textScale={isMobile ? "decorative12Regular" : "decorative22Regular"}
            color="white"
          >
            {t(description)}
          </Text>
        </StyledInfoWrapper>
      </StyledAmbassadorItem>

      {/* 0.8, 0.7 - it's 80, 70% width of StyledAmbassadorImageWrapper */}
      <StyledAmbassadorImageWrapper
        height={((itemWrapperRef.current?.offsetWidth || 1) * (isMobile ? 0.8 : 0.7)) / artistImgRatio}
      >
        <Image width="100%" src={artistImgSrc} aspectRatio={artistImgRatio} />
      </StyledAmbassadorImageWrapper>
    </Box>
  );
};

export default AmbassadorItem;
