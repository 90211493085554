import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledClientTestimonialParallaxImageWrapper = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 5.9375rem;
  position: absolute;
  top: 1.75rem;
  left: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 8.125rem;
    top: 4.25rem;
    left: 4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 2.625rem;
    left: 2.5rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 2.25rem;
  position: absolute;
  top: 1rem;
  right: 4rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.875rem;
    top: 6rem;
    right: 10.625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 3rem;
    right: 6.5rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 4.625rem;
  position: absolute;
  top: -1.25rem;
  right: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 5rem;
    top: 1.375rem;
    right: 1.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 0.375rem;
    right: 0.25rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 3rem;
  position: absolute;
  top: 18.75rem;
  left: 0.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 4rem;
    top: 40.625rem;
    left: 2rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 28.125rem;
    left: 1rem;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  width: 4rem;
  position: absolute;
  top: 16.875rem;
  right: 1.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 5.25rem;
    top: 37.5rem;
    right: 4.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 22.5rem;
    right: 2.75rem;
  }
`;
