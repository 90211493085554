import { FlexGap, Skeleton } from "components";

export const SkeletonLoader: React.FC = () => {
  return (
    <>
      <Skeleton
        mb={{ _: "1rem", laptop: "1.12rem" }}
        mx="auto"
        width={{ _: "12rem", laptop: "16rem" }}
        height={{ _: "3rem" }}
      />

      <FlexGap
        mb={{ _: "1rem", laptop: "1.12rem" }}
        mx="auto"
        gap="0.5rem"
        width={{ _: "11rem", laptop: "14rem" }}
        height={{ _: "1.125rem" }}
      >
        <Skeleton width="60%" height="100%" />
        <Skeleton width="40%" height="100%" />
      </FlexGap>

      <Skeleton mb={{ _: "1.5rem", laptop: "3rem" }} width="100%" height={{ _: "14rem", laptopL: "25rem" }} />

      <Skeleton maxWidth="53.5rem" mx="auto" height={{ _: "10rem", laptopL: "17rem" }} />
    </>
  );
};
