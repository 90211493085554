import React from "react";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path
        d="M38.1 19.1405C39.09 16.6506 40.65 14.1906 42.735 11.8205C43.395 11.0705 43.485 9.99057 42.945 9.15057C42.525 8.49057 41.835 8.13058 41.085 8.13058C40.875 8.13058 40.665 8.14551 40.455 8.22058C36.045 9.51057 25.74 14.0855 25.455 28.7556C25.35 34.4105 29.49 39.2705 34.875 39.8255C37.86 40.1255 40.83 39.1506 43.035 37.1706C45.24 35.1755 46.5 32.3255 46.5 29.3555C46.5 24.4056 42.99 20.0705 38.1 19.1405Z"
        fill="url(#paint0_linear_917_6856)"
      />
      <path
        d="M10.9348 39.8255C13.9049 40.1255 16.8749 39.1506 19.0798 37.1706C21.2849 35.1755 22.5449 32.3255 22.5449 29.3555C22.5449 24.4056 19.0349 20.0705 14.1449 19.1405C15.1349 16.6506 16.6949 14.1906 18.7799 11.8205C19.4399 11.0705 19.5299 9.99058 18.9898 9.15059C18.5699 8.49058 17.8798 8.1306 17.1298 8.1306C16.9199 8.1306 16.7099 8.14552 16.4999 8.2206C12.0899 9.51058 1.78488 14.0856 1.49988 28.7556V28.9655C1.49988 34.5306 5.59485 39.2705 10.9348 39.8255Z"
        fill="url(#paint1_linear_917_6856)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_917_6856"
          x1="27.6899"
          y1="54.9862"
          x2="45.0724"
          y2="11.7909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D974E8" />
          <stop offset="1" stopColor="#5C32E4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_917_6856"
          x1="3.73655"
          y1="54.9862"
          x2="21.1202"
          y2="11.7921"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D974E8" />
          <stop offset="1" stopColor="#5C32E4" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
