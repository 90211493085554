import React from "react";
// Components
import { Box, ContactUs, OurTeam, Page } from "components";
import { AboutUsBanner, ExtraInfo, OurApproach, SendEmail } from "./components";
// Constants
import { BLOCK_ID } from "configs";

const AboutUsPage: React.FC = () => {
  return (
    <Page maxWidth="100vw" px="0">
      <AboutUsBanner />

      <OurApproach />

      <OurTeam />

      <SendEmail />

      <ExtraInfo />

      <Box id={BLOCK_ID.contactUs}>
        <ContactUs />
      </Box>
    </Page>
  );
};

export default AboutUsPage;
