export const UP_LINE_IMAGES_LIST = [
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-1.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-1-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-2.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-2-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-3.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-3-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-4.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-4-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-5.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-5-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-6.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-6-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-up-line-7.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-up-line-7-hover.webp",
  },
];

export const DOWN_LINE_IMAGES_LIST = [
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-1.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-1-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-2.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-2-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-3.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-3-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-4.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-4-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-5.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-5-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-6.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-6-hover.webp",
  },
  {
    src: "/images/case-studies/ugreator/ugreator-wireframes-down-line-7.webp",
    srcHover: "/images/case-studies/ugreator/ugreator-wireframes-down-line-7-hover.webp",
  },
];
