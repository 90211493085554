import styled from "styled-components";
import { clearfix } from "styles";
// Theme
import { secondColumnFlowConnectorVariantStyles } from "./theme";
// Types
import { StyledSecondColumnFlowItemProps, ugreatorUserFlowsSecondColumnConnectionVariant } from "./types";

export const StyledSecondColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1.375rem 1.625rem;
  padding: 0.5rem 0.75rem 1rem;
  background-image: url("/images/case-studies/ugreator/ugreator-user-flows-gradient-border.svg");
  background-repeat: no-repeat;
  background-size: contain;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 4.25rem 5.125rem;
    padding: 1.5rem 2rem 2.875rem;
  }
`;

export const StyledSecondColumnFlowItem = styled.div<StyledSecondColumnFlowItemProps>`
  width: 3.75rem;
  height: 1.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: ${({ variant }) =>
    variant === ugreatorUserFlowsSecondColumnConnectionVariant.myProfile
      ? "6.375rem"
      : variant === ugreatorUserFlowsSecondColumnConnectionVariant.uShop
        ? "4.125rem"
        : "1rem"};
  border-radius: ${({ theme }) => theme.radii.userFlowsItem};
  background: ${({ theme, itemGradient }) => theme.gradients[itemGradient]};

  ::before {
    ${clearfix}
    width: 4rem;
    height: 1.625rem;
    position: absolute;
    top: -0.125rem;
    left: -0.125rem;
    border-radius: ${({ theme }) => theme.radii.userFlowsItem};
    background: ${({ theme, borderGradient }) => theme.gradients[borderGradient]};
  }

  ::after {
    ${clearfix}
    position: absolute;
    left: calc(100% + 0.125rem);
    background: ${({ connectorLinesSrc }) => `url("${connectorLinesSrc}")`};
    background-repeat: no-repeat;
    background-size: contain;

    ${({ variant }) => secondColumnFlowConnectorVariantStyles(variant)};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 10.75rem;
    height: 3.9375rem;
    margin-bottom: 2.875rem;
    margin-bottom: ${({ variant }) =>
      variant === ugreatorUserFlowsSecondColumnConnectionVariant.myProfile
        ? "19rem"
        : variant === ugreatorUserFlowsSecondColumnConnectionVariant.uShop
          ? "11.375rem"
          : "2.875rem"};

    ::before {
      width: 11.5rem;
      height: 4.6875rem;
      top: -0.375rem;
      left: -0.375rem;
    }
  }
`;
