// Components + styling
import { Box, Column, Container, Flex, Heading, Text } from "components";
import {
  StyledAvatar,
  StyledInterviewHeading,
  StyledInterviewItem,
  StyledInterviewList,
  StyledInterviewPointItem,
  StyledInterviewText,
  StyledTitleImage,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { INTERVIEW_LIST, INTERVIEW_POINTS_TITLES } from "./constants";

const PlanetcoinInterview: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isLaptop } = useMatchBreakpoints();

  return (
    <Container pt="3.125rem" pb={{ _: "3.125rem", laptop: "10.625rem" }}>
      <Flex justifyContent="space-between" align-items="center" mb={{ _: "2.25rem", laptop: "0" }}>
        <Column justifyContent="center" maxWidth="46.25rem">
          <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "0.75rem" }}>
            {t("Interview")}
          </Heading>
          <Text textScale={isDesktop ? "p22Regular" : "p16Regular"}>
            {t(
              "To better understand the problems and desires of users of cryptocurrency applications, we conducted an interview. Here is a short summary",
            )}
          </Text>
        </Column>

        {isDesktop && (
          <StyledTitleImage
            width={isLaptop ? "60.875rem" : "24.875rem"}
            src="/images/interview-title-bg.webp"
            aspectRatio={2.09}
          />
        )}
      </Flex>

      <StyledInterviewList>
        {INTERVIEW_LIST.map(({ name, bio, avatar, goals, painPoints, mainConclusions }) => {
          const interviewPointList = [
            {
              title: INTERVIEW_POINTS_TITLES.goals,
              list: goals,
            },
            {
              title: INTERVIEW_POINTS_TITLES.painPoints,
              list: painPoints,
            },
            {
              title: INTERVIEW_POINTS_TITLES.mainConclusions,
              list: mainConclusions,
            },
          ];

          return (
            <StyledInterviewItem key={name}>
              <Flex alignItems="center" mb={{ _: "1rem", laptop: "2rem" }}>
                <StyledAvatar
                  src={avatar}
                  alt={name}
                  variant="circle"
                  width={isDesktop ? "6.375rem" : "4.75rem"}
                  aspectRatio={1}
                />
                <Box mx={{ _: "0.75rem", laptop: "1.5rem" }}>
                  <StyledInterviewHeading scale={isDesktop ? "h5" : "h3"} as="h3" mb="0.125rem">
                    {t(name)}
                  </StyledInterviewHeading>
                  <StyledInterviewText textScale="p16Regular" color="textFifth">
                    {t(bio)}
                  </StyledInterviewText>
                </Box>
              </Flex>
              {interviewPointList.map(({ title, list }) => (
                <StyledInterviewPointItem key={title}>
                  <StyledInterviewHeading scale={isDesktop ? "h6" : "h3"} as="h3" mb="0.5rem">
                    {t(title)}
                  </StyledInterviewHeading>
                  {list.map((item, index) => (
                    <StyledInterviewText
                      key={item}
                      maxWidth={title !== INTERVIEW_POINTS_TITLES.mainConclusions ? "auto" : isDesktop ? "80%" : "auto"}
                      textScale="p16Regular"
                      color="textFifth"
                    >
                      {index + 1}. {t(item)}
                    </StyledInterviewText>
                  ))}
                </StyledInterviewPointItem>
              ))}
            </StyledInterviewItem>
          );
        })}
      </StyledInterviewList>
    </Container>
  );
};

export default PlanetcoinInterview;
