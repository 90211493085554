import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 105 11" {...props} animationType="textWave" color="transparent">
      <path
        d="M0.900587 1.66706C1.19751 2.97091 1.78309 4.19087 2.51404 5.28782C9.46127 15.7124 23.924 1.13628 33.07 1.67997C40.9678 2.14949 42.058 9.87507 53.5475 10.2068C64.6791 10.5283 69.1213 4.28255 78.4449 1.47913C85.9739 -0.784848 89.533 7.57467 104.028 7.48118"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
