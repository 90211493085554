// Components + styling
import { Container, Flex, FlexGap, Heading, Tag, Text } from "components";
import { DotSeparatorIcon } from "components/svg";
import { StyledBlogImage, StyledContent } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
import { useContactUsLinks, useTableContent } from "pages/single-blog/hooks";
// Types
import { Post } from "services";

export const BlogContent: React.FC<{ post: Post | undefined }> = ({ post }) => {
  const { isDesktop } = useMatchBreakpoints();
  useTableContent();
  useContactUsLinks(post);

  if (!post) {
    return null;
  }

  const postDate = new Date(post.date).toLocaleString("en-GB", { day: "numeric", month: "long", year: "numeric" });

  return (
    <>
      <Heading
        mx="auto"
        mb={{ _: "1rem", laptopL: "1.125rem" }}
        maxWidth="46.25rem"
        scale={isDesktop ? "h3" : "h5"}
        as={isDesktop ? "h3" : "h5"}
        textAlign="center"
      >
        {post.title}
      </Heading>

      <Flex justifyContent="center" mb={{ _: "1rem", laptopL: "1.125rem" }}>
        <Text textScale={isDesktop ? "p16Medium" : "p12Medium"} textAlign="center">
          {post.author?.name}
        </Text>
        <DotSeparatorIcon width="1.5rem" color="textMain" />
        <Text textScale={isDesktop ? "p16Medium" : "p12Medium"} color="textFourth" textAlign="center">
          {postDate}
        </Text>
      </Flex>

      <StyledBlogImage
        width="100%"
        src={post.bannerImage?.url || ""}
        aspectRatio={(post.bannerImage?.width || 1) / (post.bannerImage?.height || 1)}
      />

      {post.content ? (
        <Container maxWidth="53.5rem" padding={{ laptopL: 0 }}>
          <StyledContent dangerouslySetInnerHTML={{ __html: post.content.html }} />

          <FlexGap rowGap="0.75rem" flexWrap="wrap" mt="0.5rem" columnGap="0.25rem">
            {post.tags.map(({ title }) => (
              <Tag key={`tag-${title}`}>{title}</Tag>
            ))}
          </FlexGap>
        </Container>
      ) : null}
    </>
  );
};
