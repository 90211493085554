import React, { useState } from "react";
// Components + Styling

import { Box, EStatesCarousel, Image, Text } from "components";
import {
  StyledMatthewFeedbackImageWrapper,
  StyledAuthorInformation,
  StyledDescriptionBlock,
  StyledVideoWrapper,
  StyledVideoFeedbackTitle,
  StyledVideoFeedbackTitleWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { EXTERNAL_LINKS } from "configs";

const EStatesVideoBlock: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(prevOpen => !prevOpen);

  return (
    <StyledVideoWrapper>
      <Box position="relative">
        <StyledMatthewFeedbackImageWrapper onClick={toggleOpen}>
          <Image width="10.125rem" src="/images/e-states-matthew-feedback.webp" aspectRatio={1} />
        </StyledMatthewFeedbackImageWrapper>
        <StyledVideoFeedbackTitleWrapper>
          <StyledVideoFeedbackTitle textScale={isDesktop ? "decorative26Regular" : "decorative14Light"} color="white">
            {t("Video Testimonial")}
          </StyledVideoFeedbackTitle>
        </StyledVideoFeedbackTitleWrapper>
      </Box>
      <StyledAuthorInformation>
        <Text textScale={isDesktop ? "p20Bold" : "p16Regular"}>{t("Matthew Schneider")}</Text>
        <Text textScale={isDesktop ? "p14Regular" : "p12Regular"}>{t("CEO, Co-founder of e-States")}</Text>

        <EStatesCarousel
          videoSources={EXTERNAL_LINKS.youtubeMatthewReview}
          isOpen={open}
          onToggleOpen={toggleOpen}
          text={t("Play %duration%", { duration: "1:58" })}
        />
      </StyledAuthorInformation>
      <StyledDescriptionBlock>
        <Text textScale={isDesktop ? "p16Regular" : "p12Regular"}>
          {t(
            "We've been working with ideologic for about five contracts now over the course of 6 to 8 months and we are absolutely satisfied. Above satisfied. We are thrilled to have a team that is this competent and communicative and just a joy to work with...",
          )}
        </Text>
      </StyledDescriptionBlock>
    </StyledVideoWrapper>
  );
};

export default EStatesVideoBlock;
