import { useScroll, useTransform } from "framer-motion";

const useServiceItemMotions = (ref: React.MutableRefObject<HTMLDivElement | null>, isAnimationActive: boolean) => {
  const { scrollYProgress } = useScroll({ target: ref, offset: ["start center", "start start"] });

  const startAnimationY = isAnimationActive ? 50 : 0;
  const startAnimationOpacity = isAnimationActive ? 0 : 1;

  const y1 = useTransform(scrollYProgress, [0.5, 0.75], [startAnimationY, 0]);
  const opacity1 = useTransform(scrollYProgress, [0.5, 0.75], [startAnimationOpacity, 1]);

  const y2 = useTransform(scrollYProgress, [0, 0.25], [startAnimationY, 0]);
  const opacity2 = useTransform(scrollYProgress, [0, 0.25], [startAnimationOpacity, 1]);

  const y3 = useTransform(scrollYProgress, [0.25, 0.5], [startAnimationY, 0]);
  const opacity3 = useTransform(scrollYProgress, [0.25, 0.5], [startAnimationOpacity, 1]);

  const y4 = useTransform(scrollYProgress, [0.75, 1], [startAnimationY, 0]);
  const opacity4 = useTransform(scrollYProgress, [0.75, 1], [startAnimationOpacity, 1]);

  const serviceItemMotionsList = [
    { y: y1, opacity: opacity1 },
    { y: y2, opacity: opacity2 },
    { y: y3, opacity: opacity3 },
    { y: y4, opacity: opacity4 },
  ];

  return { serviceItemMotionsList };
};

export default useServiceItemMotions;
