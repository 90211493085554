import React from "react";
// Components + Styling
import { Button, FlexGap, Form, Input, InputGroup, Text } from "components";
import { StyledButtonInnerContainer, StyledButtonWrapper, StyledDivider, StyledFormGroupWrapper } from "./styled";
import { StyledInlinedLink, StyledSubmitInfo } from "components/contact-us/styled";
import { LinkArrowShortIcon } from "components/svg";
// Context
import { useTranslation } from "context";
// Hooks
import { useValidationSchema } from "./hooks";
import { useForm, useMatchBreakpoints } from "hooks";
// Utils
import { submitFeedback } from "./utils";
import { customAnalyticsEvent, CustomAnalyticsEventsList } from "utils";
import { contactUsColorsSchema } from "components/contact-us/constants";
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Type
import { FieldsChangedType } from "./types";
import { ContactUsFormProps } from "components/contact-us/types";

const ContactUsForm: React.FC<ContactUsFormProps> = ({ theme, setIsShowThankYou, isShowCommentInput }) => {
  const { t } = useTranslation();
  const { isLaptop, isLargeDesktop, isDesktop, isMobile } = useMatchBreakpoints();

  const { validationSchema, initialValues } = useValidationSchema();
  const { values, errors, touched, isValid, fieldProps, handleSubmit, resetForm } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  const [pending, setPending] = React.useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = React.useState<FieldsChangedType>({
    name: false,
    email: false,
    comment: false,
  });

  const inputScale = isLargeDesktop ? "lg" : isLaptop ? "md" : "sm";

  const handleFormChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const field = e.target.name;

    if (!isFieldsChanged[field]) {
      customAnalyticsEvent(CustomAnalyticsEventsList.FORM_FIELD_INTERACTION, { field });
      setIsFieldsChanged(prevState => ({ ...prevState, [field]: true }));
    }
  };

  const submitHandler = async (formValues: typeof values) => {
    try {
      setPending(true);

      const isDataAdded = await submitFeedback(formValues);
      if (isDataAdded) {
        customAnalyticsEvent(CustomAnalyticsEventsList.FORM_SUBMISSION_SUCCESS, { formType: "contactUs" });

        setIsShowThankYou(true);
        resetForm();
      } else {
        console.error("Error: Data was not added successfully");
        resetForm();
      }

      setPending(false);
    } catch (error) {
      console.error("Error:", error);
      setPending(false);
    }
  };

  return (
    <Form width="100%" onSubmit={handleSubmit} onChange={handleFormChange}>
      <StyledFormGroupWrapper>
        <InputGroup
          width={{ _: "100%", tablet: isShowCommentInput ? "100%" : "75%" }}
          scale={inputScale}
          label={t("Please contact me at:")}
          error={errors.email}
          isTouched={touched.email}
          labelColor={contactUsColorsSchema[theme].textMain}
          flexDirection="column"
        >
          <Input
            mt="1.3125rem"
            {...fieldProps("email")}
            placeholder={t("Your email here")}
            fontSize={{ _: "1.125rem", laptopL: "1.5rem" }}
            color={contactUsColorsSchema[theme].textMain}
          />
        </InputGroup>
      </StyledFormGroupWrapper>

      {isShowCommentInput && (
        <InputGroup
          scale={inputScale}
          width={{ _: "100%", tablet: isShowCommentInput ? "100%" : "75%" }}
          label={t("I want to leave a comment")}
          error={errors.comment}
          isTouched={touched.comment}
          labelColor={contactUsColorsSchema[theme].textMain}
          flexDirection="column"
        >
          <Input
            mt="1.3125rem"
            {...fieldProps("comment")}
            placeholder={t("Leave your comment here")}
            color={contactUsColorsSchema[theme].textMain}
          />
        </InputGroup>
      )}

      <StyledButtonWrapper>
        <StyledButtonInnerContainer>
          <Button
            width="100%"
            scale={isDesktop ? "lg" : "md"}
            hoverLinesPosition={isLargeDesktop ? "bottomLeft" : "mobileTopLeft"}
            disabled={!isValid}
            isLoading={pending}
            type="submit"
          >
            <Text textScale="p18Bold" color="white">
              {t("Contact me")}
            </Text>
          </Button>

          <StyledSubmitInfo
            textScale={isDesktop ? "p16Light" : "p12Light"}
            color={contactUsColorsSchema[theme].textFourthForDarkBg}
          >
            {t("By clicking “Contact me” you confirm that you accept the ")}
            <StyledInlinedLink
              href={`/${ROUTES.privacyPolicy}`}
              color={contactUsColorsSchema[theme].textFourthForDarkBg}
              underline
            >
              {t("Privacy Policy.")}
            </StyledInlinedLink>
          </StyledSubmitInfo>
        </StyledButtonInnerContainer>

        {!isShowCommentInput && (
          <>
            <FlexGap alignItems="center" gap="1rem" width={{ _: "100%", tablet: "unset" }}>
              {isMobile && <StyledDivider contactUsTheme={theme} />}

              <Text
                textScale="decorative22Light"
                pt={{ _: "0", tablet: "1rem", laptop: "1.25rem" }}
                color={contactUsColorsSchema[theme].gray}
              >
                {t("or")}
              </Text>

              {isMobile && <StyledDivider contactUsTheme={theme} />}
            </FlexGap>

            <StyledButtonInnerContainer>
              <Button
                as="a"
                external
                href={EXTERNAL_LINKS.calendly}
                scale={isDesktop ? "lg" : "md"}
                minWidth={{ _: "100%", laptop: "13.5rem" }}
                isLoading={pending}
                color={contactUsColorsSchema[theme].textMain}
                variant="transparent"
                filledType="bordered"
                endIcon={<LinkArrowShortIcon width="1.25rem" color={contactUsColorsSchema[theme].textMain} />}
                hoverLinesPosition={isLargeDesktop ? "bottomLeft" : "mobileTopLeft"}
              >
                {t("Book a call")}
              </Button>

              <StyledSubmitInfo
                textScale={isDesktop ? "p16Light" : "p12Light"}
                color={contactUsColorsSchema[theme].textFourthForDarkBg}
              >
                {t("You will be redirected to our calendar to choose the date")}
              </StyledSubmitInfo>
            </StyledButtonInnerContainer>
          </>
        )}
      </StyledButtonWrapper>
    </Form>
  );
};

export default ContactUsForm;
