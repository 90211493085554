import styled from "styled-components";
// Components
import { Button } from "components";

export const StyledSelectedFiltersContainer = styled.div`
  max-height: 18.75rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 100%;
  }
`;

export const StyledSelectedFiltersList = styled.div<{ isSeveralTypes: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 0.75rem;
  margin-bottom: ${({ isSeveralTypes }) => (isSeveralTypes ? "1rem" : "0")};

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
`;

export const StyledSelectedFilterItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRemoveFilterButton = styled(Button)`
  margin: 0 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;
