import styled from "styled-components";
// Components
import { StyledDefaultFlowItem } from "../../styled";

export const StyledFourthColumnItem = styled(StyledDefaultFlowItem)`
  width: 3.5rem;
  height: 0.9375rem;
  margin-bottom: 0.125rem;

  :nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 9.375rem;
    height: 2.75rem;
    margin-bottom: 0.4375rem;
  }
`;
