import styled, { css } from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, DefaultSlideImage } from "components";

export const StyledSlideImageWrapper = styled(Box)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 100%;
    height: auto;
    display: flex;
    flex: initial;
    position: relative;
    margin: 0.6875rem 0 0.8125rem;
    padding: 0 0 0 2.0625rem;
  }
`;

export const StyledMotionWrapper = styled(motion.div)<{ zIndex: number }>`
  ${({ theme }) => theme.mediaQueries.laptop} {
    position: absolute;
    right: 0;
    z-index: ${({ zIndex }) => zIndex};
  }
`;

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 19.25rem;
    height: auto;
    display: block;
    position: absolute;
    top: -18.25rem;
    right: 4.125rem;
    z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
    border-radius: ${({ theme }) => theme.radii.circle};
    box-shadow: ${({ theme }) => theme.shadows.planetcoinShortSummaryRocket};
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 9rem;
  }
`;

const parallaxImageStyles = css`
  ${({ theme }) => theme.mediaQueries.laptop} {
    height: auto;
    display: block;
    position: absolute;
    z-index: ${({ theme }) => theme.zIndices.dropdown};
    border-radius: ${({ theme }) => theme.radii.circle};
    filter: ${({ theme }) => theme.shadows.planetcoinShortSummaryImageDropShadow};
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    ${parallaxImageStyles}
    width: 1.25rem;
    top: -24.25rem;
    right: 20.625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 25.5rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    ${parallaxImageStyles}
    width: 2.6875rem;
    top: -21.875rem;
    right: 4.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 9.375rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    ${parallaxImageStyles}
    width: 5.125rem;
    top: -8.5rem;
    right: 20.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 25.375rem;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    ${parallaxImageStyles}
    width: 1.25rem;
    top: -2.5rem;
    right: 2rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 6.875rem;
  }
`;
