import { keyframes } from "styled-components";
import { transitions } from "theme/base";

export const bannerButtonShow = keyframes`
  0% {
    opacity: 1;
    transform: scale(0) ;
  }
  1% {
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(-9deg);
    opacity: 1;
  }
`;

export const bannerTitleVariants = {
  hidden: {
    top: "100%",
    transition: transitions.bannerTitleMotion,
  },
  visible: {
    top: "0",
    transition: transitions.bannerTitleMotion,
  },
};
