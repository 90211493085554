import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 2.375rem;
  position: absolute;
  top: -17.875rem;
  left: 0.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: -13.125rem;
    left: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -14.375rem;
    left: 6.25rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 2.0625rem;
  position: absolute;
  top: -17.375rem;
  right: 0.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: -14.375rem;
    right: 5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -16.25rem;
    right: 10.625rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 20.625rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 0.5rem;
  position: absolute;
  top: -10.625rem;
  right: -0.375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: -13.375rem;
    right: 1.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    right: 5rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 0.9375rem;
  position: absolute;
  top: -5.25rem;
  left: -0.625rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: -7.5rem;
    left: 0.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    left: 1.25rem;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  width: 0.5rem;
  position: absolute;
  top: 4.375rem;
  left: 1.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 1.875rem;
    left: 1.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 0.625rem;
    left: 8.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    left: 11.875rem;
  }
`;

export const StyledSlideImage6 = styled(DefaultSlideImage)`
  width: 1.25rem;
  position: absolute;
  top: 3.75rem;
  right: -0.625rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 1.875rem;
    right: 5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -7.5rem;
    right: 7.75rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 9.375rem;
  }
`;

export const StyledSlideImage7 = styled(DefaultSlideImage)`
  width: 1.9375rem;
  position: absolute;
  top: 5rem;
  right: 6.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 4.375rem;
    right: 12.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -4.375rem;
    right: 16.25rem;
  }
`;
