import styled from "styled-components";
// Components
import { Link, Text } from "components";
// Animations
import { moveUpTextAnimation } from "theme/animations";

export const StyledAmbassadorItem = styled.div<{ height?: number; bgGradientSrc: string }>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "initial")};
  padding: 2.125rem 1.25rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  background-image: ${({ bgGradientSrc }) => `url("${bgGradientSrc}")`};
  background-size: contain;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 4rem 2.5rem;
  }
`;

export const StyledInfoWrapper = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 0.625rem;
  padding: 0.25rem 0.375rem;
  border-radius: ${({ theme }) => theme.radii.small};
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 1.25rem;
  }
`;

export const StyledLinkLabel = styled(Text)`
  ${moveUpTextAnimation}
  margin: 0 0.5rem;
  background-clip: initial;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: initial;
`;

export const StyledAmbassadorImageWrapper = styled.div<{ height: number }>`
  width: 80%;
  height: ${({ height }) => (height ? `${height}px` : "initial")};
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0 0 ${({ theme }) => theme.radii.semiLarge} 0;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 70%;
  }
`;
