import React, { createContext, PropsWithChildren, useContext, useCallback, useEffect, useState, useMemo } from "react";
import { io } from "socket.io-client";
import Cookies from "js-cookie";
// Constants
import { SOCKET_EVENTS } from "./constants";
import { COOKIES_KEYS } from "configs";
// Types
import { ChatMessage, ChatUpdateResponse, userRoleTypes } from "./types";

type ContextType = {
  socketConnect: () => void;
  socketDisconnect: () => void;
  startChat: (searchValue: string, searchResult?: string) => void;
  sendMessage: (message: string) => void;
  chatMessages: ChatMessage[];
};

const SocketContext = createContext<ContextType | null>(null);

const socket = io(`${process.env.REACT_APP_SOCKET_URL}` as string, {
  transports: ["websocket"],
  autoConnect: false,
  reconnection: false,
});

const SocketContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [messagesList, setMessagesList] = useState<ChatMessage[]>([]);

  useEffect(() => {
    socket.on(SOCKET_EVENTS.chat_response, handleChatResponse);

    socket.on(SOCKET_EVENTS.chat_error, function (data) {
      console.error("SOCKET_EVENTS.chat_error: ", data);
    });

    return () => {
      socket.off(SOCKET_EVENTS.chat_response);
      socket.off(SOCKET_EVENTS.chat_error);
    };
  }, []);

  const socketConnect = useCallback(() => {
    socket.connect();
  }, []);

  const socketDisconnect = useCallback(() => {
    socket.disconnect();
    setMessagesList([]);
  }, []);

  const startChat = useCallback((searchValue: string, searchResult?: string) => {
    socket.emit(SOCKET_EVENTS.start_chat, {
      user_id: Cookies.get(COOKIES_KEYS.userId),
      session_id: Cookies.get(COOKIES_KEYS.sessionId),
      first_request: searchValue,
      second_request: searchResult || " ",
      source: "design",
    });
  }, []);

  const sendMessage = useCallback(
    (message: string) => {
      const timestamp = new Date().getTime() / 1000;
      socket.emit("chat_message", {
        content: message,
        role: userRoleTypes.USER,
        user_id: Cookies.get(COOKIES_KEYS.userId),
        session_id: Cookies.get(COOKIES_KEYS.sessionId),
      });
      setMessagesList([
        ...messagesList,
        { content: message, role: userRoleTypes.USER, created_at: timestamp, isRead: false },
      ]);
    },
    [messagesList],
  );

  const chatMessages = useMemo(() => messagesList, [messagesList]);

  const handleChatResponse = useCallback(({ messages }: ChatUpdateResponse) => {
    const filteredMessage = messages
      .filter(({ role }) => role !== userRoleTypes.SYSTEM)
      .map(message => {
        return { ...message, isRead: true };
      });

    setMessagesList([...filteredMessage]);
  }, []);

  const context = {
    socketConnect,
    socketDisconnect,
    startChat,
    sendMessage,
    chatMessages,
  };

  return <SocketContext.Provider value={context}>{children}</SocketContext.Provider>;
};

export const useSocketContext = () => {
  const socketContext = useContext(SocketContext);
  if (socketContext === null) {
    throw new Error("Socket context is not found");
  }
  return socketContext;
};

export default SocketContextProvider;
