import React from "react";
// Components + styling
import { Accordion, AgreementsContactDetails, Container, Heading, Page, Text } from "components";
import { StyledAccordionWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";
import { TERMS_CONDITIONS_LIST } from "./constants";

const TermsConditionsPage: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const {
    elementDimensions: { offsetHeight: headerHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  return (
    <Page
      maxWidth="100vw"
      px="0"
      pt={{ _: `calc(${headerHeight}px + 2rem)`, laptop: `calc(${headerHeight}px + 4rem)` }}
    >
      <Container>
        <Heading scale="h2" as="h2" textAlign="center" mb="1.375rem">
          {t("Terms & Conditions")}
        </Heading>
        <Text textScale={isDesktop ? "p22Regular" : "p16Light"} textAlign="center" mb={{ _: "2rem", laptop: "2.5rem" }}>
          {t(
            "IDEALOGIC DESIGN is a product design company specializing in design creation for startups and scaling companies. These Terms and Conditions (“Terms and Conditions” “Terms”,)  are between You (“User”, “You” or “Your”) and Private Limited Company “IDEALOGIC DEVELOPMENT OÜ”  (“IDEALOGIC DESIGN”, “We”, “Us” or “Our”). This website (“Website”) is operated and owned by IDEALOGIC DEVELOPMENT OÜ. Please, review these Terms and Conditions thoroughly before accessing or using our Website. Your access or usage of any section of this Website implies your consent to comply with these Terms and Conditions. These Terms apply to all users of this Website. If you do not agree with all the provisions of these Terms, you are not allowed to access or use the Website.",
          )}
        </Text>

        {TERMS_CONDITIONS_LIST.map(({ label, description }) => (
          <StyledAccordionWrapper key={label}>
            <Accordion title={t(label)} $backgroundColor="white">
              <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" pb="1.5rem">
                {t(description)}
              </Text>
            </Accordion>
          </StyledAccordionWrapper>
        ))}
      </Container>

      <AgreementsContactDetails />
    </Page>
  );
};

export default TermsConditionsPage;
