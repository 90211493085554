import { ClutchLogoIcon, GoodFirmsLogoIcon } from "components/svg";
import { CompaniesRateItem, TestimonialsReviewItem } from "./components/types";

export const COMPANIES_RATE_LIST: CompaniesRateItem[] = [
  { Icon: ClutchLogoIcon, rate: 4.9, starColor: "ratingOrange" },
  { Icon: GoodFirmsLogoIcon, rate: 5, starColor: "ratingYellow" },
];

export const TESTIMONIALS_REVIEW_LIST: TestimonialsReviewItem[] = [
  {
    image: "/images/testimonials-image-1.webp",
    imageRatio: 0.5,
    imageBg: "testimonialBg1",
    title: "I had a great experience working with Idealogic and would recommend them to anyone.",
    description:
      "I really enjoyed working with Idealogic. I hired their team to help me with our brand's visual identity and they did an amazing job. We brought their team a few general ideas for inspiration and their designer took that and transformed it into an identity that really resonated. They gave us a few options for creative direction and worked with us to fine-tune our top choice. We're in love with our brand and couldn't have done it without them.",
    company: "CIO, Entertainment Startup",
    RatingCompanyIcon: GoodFirmsLogoIcon,
    rating: 5,
    starColor: "ratingYellow",
  },
  {
    image: "/images/testimonials-image-2.webp",
    imageRatio: 0.5,
    imageBg: "testimonialBg2",
    title: "We’re most impressed with Idealogic’s flexibility and personable approach.",
    description:
      "Idealogic has done a great job of rewriting the existing source code and delivering a visuallyappealing, easy-to-navigate UI/UX design for the app. They're quick to resolve issues that arise and are consistent in delivering on time. They impress with their flexibility and personable approach.",
    company: "CIO, Entertainment Startup",
    RatingCompanyIcon: ClutchLogoIcon,
    rating: 5,
    starColor: "ratingOrange",
  },
  {
    image: "/images/testimonials-image-3.webp",
    imageRatio: 1.73,
    imageBg: "testimonialBg3",
    title:
      "Their company was really focused on making sure that I was satisfied with how things were going from start to finish.",
    description:
      "The client was pleased with the final design for their brand identity and website, which was exactly what they were looking for. As a result, they received inquiries for potential partnerships. Idealogic led a smooth process, executing each task efficiently while sharing regular updates throughout.",
    company: "CIO, Entertainment Startup",
    RatingCompanyIcon: ClutchLogoIcon,
    rating: 5,
    starColor: "ratingOrange",
  },
];
